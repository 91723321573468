<template>
	<div>
		<span id="open-media-library-wrapper" class="d-inline-block">
			<b-button :disabled="!templateId" size="lg" class="d-flex align-items-center" @click="openMediaUploader">
				<icon name="file" class="mr-2" /> {{ $t('Open media library') }}
			</b-button>
		</span>
		<b-tooltip v-if="!templateId" target="open-media-library-wrapper">
			{{ $t('The template needs to be saved to manage its assets!') }}
		</b-tooltip>

		<MediaUploader
			:title="$t('Choose Template Asset')"
			:show="isMediaUploaderVisible"
			:path="`pimienta/templates/${templateId}`"
			:active-tab="1"
			:on-close="closeMediaUploader"
			disable-url
			@file-picked="onFilePicked"
		/>
		<b-modal :title="$t('Media file URL')" :visible="isCopyModalVisible" ok-only @hide="closeCopyModal">
			<div class="row">
				<div class="col-md-10">
					<b-input ref="fileUrlInput" :value="fileUrl" readonly />
				</div>
				<div class="col-md-2 text-center">
					<b-button v-tippy :content="$t('Copy to clipboard')" @click="copyToClipboard">
						<icon name="clipboard" />
					</b-button>
				</div>
			</div>
			<b-alert variant="success" class="mb-0 mt-2" :show="isUrlCopied">{{ $t('Copied to clipboard!') }}</b-alert>
		</b-modal>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import MediaUploader from '@/components/MediaUploader';

export default {
	components: {
		MediaUploader
	},
	props: {
		templateId: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			isMediaUploaderVisible: false,
			isCopyModalVisible: false,
			fileUrl: '',
			isUrlCopied: false
		};
	},
	methods: {
		copyToClipboard() {
			this.$refs.fileUrlInput.select();
			document.execCommand('copy');
			this.isUrlCopied = true;
		},
		async onFilePicked(file) {
			this.fileUrl = file.urlData.url;
			this.openCopyModal();
		},
		openCopyModal() {
			this.isCopyModalVisible = true;
		},
		closeCopyModal() {
			this.isCopyModalVisible = false;
			this.isUrlCopied = false;
		},
		openMediaUploader() {
			this.isMediaUploaderVisible = true;
		},
		closeMediaUploader() {
			this.isMediaUploaderVisible = false;
		}
	}
};
</script>
