<template>
	<AppLayout>
		<TopBar slot="top-bar">
			<img slot="logo" src="../../../public/images/pimienta-logo-white.svg" />
			<template slot="account">
				<b-button v-t="'Sign Out'" class="text-white px-0" variant="link" size="sm" @click="onLogOutClick" />
			</template>
		</TopBar>
		<div slot="content">
			<slot></slot>
			<VersionNotification />
		</div>
		<template slot="left-menu">
			<LeftPanel v-if="true">
				<template slot="rowAttribute" slot-scope="{ menuItem }">
					<span v-if="menuItem.counter >= 0" class="MenuItem-counter">
						{{ menuItem.counter }}
					</span>
				</template>
			</LeftPanel>
			<div v-else></div>
		</template>
	</AppLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { AppLayout, TopBar, LeftPanel, AccountSwitcher } from '@oneflow/ofs-vue-layout';
import VersionNotification from '../VersionNotification';

export default {
	components: {
		AppLayout,
		TopBar,
		LeftPanel,
		VersionNotification
	},
	computed: {
		...mapGetters('account', ['accounts'])
	},
	methods: {
		...mapActions({
			logOut: 'auth0/logOut'
		}),
		async onLogOutClick() {
			await this.logOut();
		}
	}
};
</script>
