import ShippingMethodsList from './ShippingMethods/ShippingMethodsList';
import AdminUsersList from './Users/AdminUsers';
import UserEdit from './Users/UserEdit';
import CreatorUsersList from './Users/CreatorUsers';
import AccountsList from './Accounts/AccountsList';
import AccountEdit from './Accounts/AccountEdit';
import LayoutsList from './Layouts/LayoutsList';
import LayoutsEdit from './Layouts/LayoutsEdit';
import CreatorEdit from './Creators/CreatorEdit';
import CreatorList from './Creators/CreatorsList';
import DataTypesList from './DataTypes/DataTypesList';
import DataTypesEdit from './DataTypes/DataTypesEdit';
import RecommendationRulesList from './RecommendationRules/RecommendationRulesList';

const routes = [
	{
		path: '/',
		name: 'admin',
		redirect: '/shipping-methods'
	},
	{
		name: 'shippingMethods.list',
		path: '/shipping-methods',
		component: ShippingMethodsList
	},
	{
		name: 'admin-users.list',
		path: '/admin-users',
		component: AdminUsersList
	},
	{
		name: 'creator-users.list',
		path: '/creator-users',
		component: CreatorUsersList
	},
	{
		name: 'users.edit',
		path: '/users/:id',
		component: UserEdit
	},
	{
		name: 'accounts.list',
		path: '/accounts',
		component: AccountsList
	},
	{
		name: 'accounts.edit',
		path: '/accounts/:id',
		component: AccountEdit
	},
	{
		name: 'layouts.list',
		path: '/layouts',
		component: LayoutsList
	},
	{
		name: 'layouts.edit',
		path: '/layouts/:id',
		component: LayoutsEdit,
		meta: { layout: 'FullscreenLayout' }
	},
	{
		name: 'creators.list',
		path: '/creators',
		component: CreatorList
	},
	{
		name: 'creators.edit',
		path: '/creators/:id',
		component: CreatorEdit
	},
	{
		name: 'dataTypes.list',
		path: '/data-types',
		component: DataTypesList
	},
	{
		name: 'dataTypes.edit',
		path: '/data-types/:id',
		component: DataTypesEdit
	},
	{
		name: 'recommendationRules.list',
		path: '/recommendation-rules',
		component: RecommendationRulesList
	}
];

export default routes;
