/* eslint-disable indent */
export const urlSafe = value => (value ? /^[a-zA-Z0-9_-]*$/g.test(value) : true);

export const step = stepValue => value => {
	if (!value) return true;

	const fractions = v => (String(v).split('.')[1] || '').length; // get max decimal fractions length
	const maxPower = Math.max(fractions(value), fractions(stepValue));
	return Math.round(value * 10 ** maxPower) % Math.round(stepValue * 10 ** maxPower) === 0;
};

export const correctUrl = value => {
	return value
		? new RegExp(
				'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)',
				'g'
		  ).test(value)
		: true;
};

export const json = value => {
	if (!value) return true;
	try {
		JSON.parse(value);
	} catch {
		return false;
	}
	return true;
};

export const codeSafe = value => (value ? /^[a-zA-Z0-9_]*$/g.test(value) : true);
