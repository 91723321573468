<template>
	<div>
		<b-card>
			<template #header>
				<div class="d-flex justify-content-between">
					{{ title }}
					<icon
						name="times-circle"
						class="action-btn"
						:class="{ 'action-btn--disabled': disabled }"
						@click="onRemoveRule"
					/>
				</div>
			</template>
			<b-row>
				<b-col>
					<OfFormSelect v-model="rootLogicOperator" :options="logicOperatorOptions" :disabled="disabled" no-label />
				</b-col>
				<b-col md="2" lg="1" class="flex-center">
					<b>{{ $t('pay to') }}</b>
				</b-col>
				<b-col>
					<OfMultiSelect
						:name="`${formPath}.payeeId`"
						:options="creatorOptions"
						:placeholder="$t('Payee')"
						:is-loading="isCreatorsLoading"
						:disabled="disabled"
						no-label
						@search-change="onCreatorSearchChange"
					/>
				</b-col>
				<b-col md="2" lg="1" class="flex-center">
					<b>{{ $t('amount of') }}</b>
				</b-col>
				<b-col>
					<OfFormInput
						:name="`${formPath}.rule.if.1`"
						:placeholder="$t('percent')"
						:normalize="normalizeNumber"
						:disabled="disabled"
						no-label
					>
						<template v-slot:append>
							<b-input-group-text>%</b-input-group-text>
						</template>
					</OfFormInput>
				</b-col>
			</b-row>
			<template v-if="!isAlwaysApplied">
				<template v-for="(statement, index) of conditions">
					<b-row v-for="(params, operator) of statement" :key="`${index}-${operator}`" class="mt-2">
						<b-col md="1" class="flex-center">
							<OfToggle v-model="customConditions[index]" :label="$t('Custom')" :disabled="disabled" :vuex="false" />
						</b-col>
						<b-col class="flex-center">
							<OfFormInput
								v-if="customConditions[index]"
								:name="`${conditionsPath}[${index}][${operator}][0].var`"
								:disabled="disabled"
								no-label
							/>
							<OfFormSelect
								v-else
								:options="dataFieldOptions"
								:name="`${conditionsPath}[${index}][${operator}][0].var`"
								:disabled="disabled"
								no-label
							/>
						</b-col>
						<b-col class="flex-center">
							<OfFormSelect
								:options="getFieldOperators(params)"
								:value="operator"
								:disabled="disabled"
								no-label
								@input="updateField(`${conditionsPath}[${index}]`, { [$event]: [params[0], null] })"
							/>
						</b-col>
						<b-col class="flex-center">
							<template v-if="operator === operators.IN">
								<OfMultiSelect
									:name="`${conditionsPath}[${index}][${operator}][1]`"
									:disabled="disabled"
									class="w-100"
									no-label
									taggable
								/>
							</template>
							<template v-else>
								<OfFormInput
									v-if="getFieldType(params) === dataFieldType.STRING"
									:name="`${conditionsPath}[${index}][${operator}][1]`"
									:disabled="disabled"
									no-label
								/>
								<OfFormInput
									v-else
									:normalize="Number"
									:name="`${conditionsPath}[${index}][${operator}][1]`"
									:disabled="disabled"
									no-label
								/>
							</template>
						</b-col>
						<b-col md="1" class="flex-center justify-content-around">
							<icon
								v-if="isRemoveConditionShown()"
								name="times-circle"
								class="action-btn"
								:class="{ 'action-btn--disabled': disabled }"
								@click="onRemoveCondition(index)"
							/>
							<icon
								v-if="isAddConditionShown(index)"
								name="plus-circle"
								class="action-btn"
								:class="{ 'action-btn--disabled': disabled }"
								@click="onAddCondition"
							/>
						</b-col>
					</b-row>
				</template>
			</template>
		</b-card>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, between } from 'vuelidate/lib/validators';
import { withForm, OfFormSelect, OfFormInput, OfMultiSelect, OfToggle } from '@oneflow/ofs-vue-layout';
import { logicOperatorOptions, operators, dataFieldOptions, dataFieldType } from './constants';
import { step } from '../../lib/validators';
import { number as normalizeNumber } from '../../lib/normalizers';

const defaultCondition = { '==': [{ var: dataFieldOptions[0].value }, null] };

export default {
	components: {
		OfFormSelect,
		OfFormInput,
		OfMultiSelect,
		OfToggle
	},
	mixins: [withForm('productEdit', { preserveDataOnMount: true })],
	props: {
		title: {
			type: String,
			default: ''
		},
		rule: {
			type: Object,
			required: true
		},
		formPath: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			creators: [],
			isCreatorsLoading: false,
			creatorSearch: null,
			dataFieldOptions,
			logicOperatorOptions,
			operators,
			dataFieldType,
			customConditions: []
		};
	},
	computed: {
		validationRules() {
			return {
				formData: {
					payoutRules: {
						$each: {
							payeeId: {
								required
							},
							rule: {
								if: {
									0: {
										[this.rootLogicOperator]: {
											$each: {
												validOperator: condition => {
													if (!_.isPlainObject(condition)) return true;

													const [operator, params] = _.first(_.toPairs(condition));
													const operatorOptions = this.getFieldOperators(params);

													return _.some(operatorOptions, ['value', operator]);
												},
												requiredValue: condition => {
													if (!_.isPlainObject(condition)) return true;

													const value = _.get(_.values(condition), '[0][1]');
													if (_.isNumber(value)) return _.isFinite(value);

													return !_.isNil(value) && !_.isEmpty(value);
												}
											}
										}
									},
									1: {
										required,
										between: between(-100, 100),
										step: step(0.01)
									}
								}
							}
						}
					}
				}
			};
		},
		rootLogicOperator: {
			get() {
				const ifStatements = _.get(this.rule, 'rule.if[0]', { and: [] });
				const operator = _.first(_.keys(ifStatements));

				const isAlways = _.get(this.rule, `rule.if[0][${operator}][0]`) === true;
				return isAlways ? operators.ALWAYS : operator;
			},
			set(operator) {
				const path = `${this.formPath}.rule.if[0]`;
				const value = operator === operators.ALWAYS ? { and: [true] } : { [operator]: [defaultCondition] };

				this.updateField(path, value);
			}
		},
		conditionsPath() {
			return `${this.formPath}.rule.if[0][${this.rootLogicOperator}]`;
		},
		conditions() {
			return _.get(this.formData, this.conditionsPath, []);
		},
		isAlwaysApplied() {
			return _.first(this.conditions) === true;
		},
		creatorOptions() {
			return _.map(this.creators, creator => ({ text: creator.username, value: creator._id }));
		}
	},
	async mounted() {
		this.calculateCustomConditions();

		if (this.rule.payeeId) {
			const query = { $where: { _id: this.rule.payeeId } };
			await this.fetchCreators(query);
		}
	},
	methods: {
		normalizeNumber,
		...mapActions({
			findCreators: 'creator/find'
		}),
		async onCreatorSearchChange(search) {
			if (_.isEqual(search, this.creatorSearch)) return;
			this.creatorSearch = search;

			const query = {
				$limit: 10,
				$select: ['username', 'profile.handle']
			};
			if (_.isEmpty(search?.text)) {
				if (this.rule.payeeId) {
					query.$where = { _id: this.rule.payeeId };
				}
			} else {
				query.$where = {
					$or: [
						{ username: { $regex: `^${search.text}`, $options: 'i' } },
						{ 'profile.handle': { $regex: `^${search.text}`, $options: 'i' } }
					]
				};
			}

			await this.searchCreators(query);
		},
		searchCreators: _.debounce(async function(...args) {
			this.isCreatorsLoading = true;
			await this.fetchCreators(...args);
			this.isCreatorsLoading = false;
		}, 800),
		async fetchCreators(query = {}) {
			const { data } = await this.findCreators({ query: { query }, options: { skipMutations: true } });
			this.creators = data;
		},
		isAddConditionShown(index) {
			return _.size(this.conditions) === index + 1;
		},
		isRemoveConditionShown() {
			return _.size(this.conditions) > 1;
		},
		onRemoveRule() {
			if (this.disabled) return;
			this.$emit('remove');
		},
		onAddCondition() {
			if (this.disabled) return;
			this.updateField(this.conditionsPath, [...this.conditions, defaultCondition]);
		},
		onRemoveCondition(index) {
			if (this.disabled) return;

			const filteredConditions = _.filter(this.conditions, (_, idx) => idx !== index);
			this.updateField(this.conditionsPath, filteredConditions);

			this.calculateCustomConditions();
		},
		getFieldType(operatorParams) {
			const fieldKey = _.get(operatorParams, '[0].var');
			const field = _.find(dataFieldOptions, { value: fieldKey });

			return field?.type || null;
		},
		getFieldOperators(operatorParams) {
			const type = this.getFieldType(operatorParams);
			const options = [
				{ text: this.$t('Equals'), value: operators.EQUAL },
				{ text: this.$t('Strict equals'), value: operators.STRICT_EQUAL },
				{ text: this.$t('Not equal'), value: operators.NOT_EQUAL }
			];
			if (!type || type === dataFieldType.STRING) {
				options.push({ text: this.$t('One of'), value: operators.IN });
			}
			if (!type || type === dataFieldType.NUMBER) {
				options.push(
					{ text: this.$t('Greater than'), value: operators.GT },
					{ text: this.$t('Greater than equal'), value: operators.GTE },
					{ text: this.$t('Less than'), value: operators.LT },
					{ text: this.$t('Less than equal'), value: operators.LTE }
				);
			}
			return options;
		},
		calculateCustomConditions() {
			this.customConditions = _.map(this.conditions, condition => {
				const conditionParams = _.first(_.values(condition));
				const fieldType = this.getFieldType(conditionParams);

				return _.isNil(fieldType);
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.action-btn {
	cursor: pointer;

	&--disabled {
		cursor: not-allowed;
	}
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
