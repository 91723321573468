<template>
	<div class="mt-4">
		<h2>{{ title }}</h2>
		<Datatable :fields="versionFields" :items="items">
			<template #cell(externalSourceOrderId)="{ item }">
				<a v-if="item.externalOrderId" :href="getExternalLink(item)" target="_blank">{{
					item.externalSourceOrderId
				}}</a>
				<span v-else>
					{{ item.externalSourceOrderId }}
				</span>
			</template>

			<template #cell(submittedAt)="{ item }">
				<div v-if="!formatResubmittedDate(item.submittedAt)" class="active-badge">
					<b-badge variant="success">Active</b-badge>
				</div>
				<span v-else>
					{{ formatResubmittedDate(item.submittedAt) }}
				</span>
			</template>
			<template #cell(status)="{ item }">
				{{ item.status }}
			</template>
			<template #cell(fulfilment)="{ item }">
				{{ item.fulfilment }}
			</template>

			<template #cell(orderData)="{ item }">
				<b-button class="mt-2 mr-2" size="sm" @click="showViewSourceModal(item)">
					{{ $t('Source') }}
				</b-button>
				<b-button
					v-if="!item.submittedAt"
					class="mt-2 mr-2"
					size="sm"
					variant="outline-info"
					:disabled="isResubmitDisabled"
					@click="handleResubmitVisible(item)"
				>
					{{ $t('Resubmit') }}
				</b-button>
				<b-button
					v-if="!item.submittedAt && item.fulfilment !== 'Digital File'"
					class="mt-2 mr-2"
					size="sm"
					variant="outline-danger"
					:disabled="isCancelDisabled(item)"
					@click="showCancelModal(item)"
				>
					{{ $t('Cancel') }}
				</b-button>
			</template>
		</Datatable>
		<b-modal
			:visible="isSourceModalVisible"
			:title="sourceModalTitle"
			size="xl"
			modal-class="SourceModal"
			ok-only
			@hide="isSourceModalVisible = false"
		>
			<CodeEditor :value="versionSource" />
		</b-modal>
	</div>
</template>

<script>
import moment from 'moment';
import { Datatable } from '@oneflow/ofs-vue-layout';
import CodeEditor from '@/components/CodeEditor';

export default {
	components: {
		Datatable,
		CodeEditor
	},
	props: {
		items: {
			type: Array,
			default: () => []
		},
		title: {
			type: String,
			default: ''
		},
		isResubmitDisabled: {
			type: Boolean,
			default: false
		},
		isCancelButtonVisible: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isSourceModalVisible: false,
			selectedVersion: null,
			versionFields: [
				{ label: this.$t('Source Order Id'), key: 'externalSourceOrderId' },
				{ label: this.$t('Submitted At'), key: 'submittedAt', sortable: true },
				{ label: this.$t('Status'), key: 'status', sortable: true },
				{ label: this.$t('Fulfilment'), key: 'fulfilment', sortable: true },
				{ label: this.$t('Actions'), key: 'orderData', thClass: 'text-center', tdClass: 'text-center' }
			]
		};
	},
	computed: {
		versionSource() {
			return JSON.stringify(this.selectedVersion, null, 2);
		},
		sourceModalTitle() {
			return `${this.$t('Production Order')} #${this.selectedVersion?._id}`;
		}
	},
	methods: {
		isCancelDisabled(item) {
			const defaultRules = item.submittedAt || item.status === 'cancelled';
			if (item.fulfilment === 'SiteFlow') {
				return defaultRules || item.status === 'shipped';
			}
			return defaultRules;
		},
		showViewSourceModal(item) {
			this.isSourceModalVisible = true;
			this.selectedVersion = item;
		},
		formatResubmittedDate(date) {
			return date ? moment.utc(date).format('YYYY.MM.DD hh.mm') : null;
		},
		getExternalLink(item) {
			return `${window.$config.brandcentreBase}#/orders/view/${item.externalOrderId}`;
		},
		handleResubmitVisible(item) {
			this.$emit('resubmit', item);
		},
		showCancelModal(item) {
			this.$emit('cancel', item);
		}
	}
};
</script>

<style scoped lang="scss">
.active-badge {
	text-align: center;
}
</style>
