import Vue from 'vue';
import * as actions from './actions';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'media-uploader',
		basePath: 'file',
		pluralName: 'files',
		transport: 'davinci',
		state: {},
		actions
	});
}
