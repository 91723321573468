<template>
	<div>
		<slot></slot>
		<VersionNotification />
	</div>
</template>

<script type="text/javascript">
import VersionNotification from '../VersionNotification';

export default {
	components: {
		VersionNotification
	}
};
</script>
