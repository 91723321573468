import Vue from 'vue';
import Router from 'vue-router';
import qs from 'qs';
import routes from './routes';
import menuStructure from './menuStructure';
import store from '@/vuex';

Vue.use(Router);
const router = new Router({
	routes: [...routes],
	parseQuery(query) {
		return qs.parse(query);
	},
	stringifyQuery(query) {
		const stringifiedQuery = qs.stringify(query, { encode: false, arrayFormat: 'brackets' });
		return stringifiedQuery ? `?${stringifiedQuery}` : '';
	}
});

router.beforeEach((to, from, next) => {
	const defaultLayout = 'DefaultLayout';
	store.commit('layoutProvider/SET_LAYOUT', to.meta.layout || defaultLayout);
	next();
});

export default router;

export { routes, menuStructure };
