<template>
	<b-modal
		:title="$t('Add account member')"
		size="md"
		:ok-title="$t('Add')"
		:ok-disabled="!canSubmit"
		:visible="isVisible"
		@ok="onSubmit"
		@hidden="$emit('hidden')"
	>
		<b-row>
			<b-col>
				<OfMultiSelect
					:label="$t('User')"
					name="email"
					:options="userOptions"
					@input="handleInput"
					@search-change="handleUserSearch"
				/>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { withForm, OfMultiSelect, validateWithMessage } from '@oneflow/ofs-vue-layout';
import { email, required } from 'vuelidate/lib/validators';

export default {
	components: {
		OfMultiSelect
	},
	mixins: [withForm('accountMemberCreateForm')],
	props: {
		isVisible: {
			type: Boolean,
			required: true
		},
		accountId: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			searchTerm: null,
			selectedUser: null
		};
	},
	computed: {
		...mapGetters({ users: 'user/users' }),
		validationRules() {
			return {
				formData: {
					email: {
						required: validateWithMessage(this.$t('Field required'), required),
						email: validateWithMessage(this.$t('Must be a valid email address'), email)
					}
				}
			};
		},
		userOptions() {
			const data = _.get(this, 'users.data', []);
			return data.map(user => ({ text: `${user.name} - ${user.email}`, value: user.email })) ?? [];
		}
	},
	watch: {
		isVisible: {
			immediate: true,
			handler() {
				if (this.isVisible) return this.fetchData();
			}
		}
	},
	methods: {
		...mapActions({
			createAccountMember: 'account-management/createAccountMember',
			findUsers: 'user/find'
		}),
		async fetchData() {
			try {
				const query = {
					$limit: 100,
					$where: { role: 'pimienta-api-creator' }
				};

				await this.findUsers({ query: { query } });
			} catch (err) {
				this.$notify({ type: 'error', text: this.$t('An error happened while fetching the list of users') });

				throw err;
			}
		},
		async onSubmit(event) {
			event.preventDefault();

			try {
				await this.dispatchSubmit(
					this.createAccountMember({
						accountId: this.accountId,
						email: this.selectedUser.email,
						name: this.selectedUser.name
					})
				);

				this.$notify({
					type: 'success',
					title: this.$t('Success'),
					text: this.$t('Account member added successfully')
				});

				this.resetFormData();
				this.$emit('hidden');
			} catch (error) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: _.get(error, 'response.data.message', error.message)
				});
			}
		},
		handleInput(email) {
			this.selectedUser = email ? this.users?.data?.find(user => user.email === email) : null;
		},
		handleUserSearch: _.debounce(function(search) {
			this.searchTerm = search?.text;
			return this.fetchData();
		}, 500)
	}
};
</script>
