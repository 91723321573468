<template>
	<OfsPanel class="ProductEdit">
		<div v-if="isLoading" class="text-center p-5">
			<Loader />
		</div>
		<template v-else>
			<ContentHeader
				:title="productId ? `${$t('Product')} &quot;${formData.name || ''}&quot;` : $t('New product')"
				class="mb-3"
				no-padding
			/>

			<WarningArea v-if="showWarningArea" :validation-errors="validationErrors" />
			<b-tabs v-model="activeTabIndex" content-class="mt-3">
				<b-tab :title="$t('Summary')">
					<b-row class="product-top-panel mt-2 mb-2">
						<div>
							<b>{{ $t('Status') }}</b> : <OfsBadge :text="productStatus" status="ready" />
						</div>
						<div>
							<b>{{ $t('Availability') }}</b> : <OfsBadge :text="productAvailability" status="started" />
						</div>
						<div>
							<b>{{ $t('Expected Available Date') }}</b> : {{ releaseDateFormatted }}
						</div>

						<b-button v-if="productId" v-t="$t('Update')" size="sm" @click="isUpdateStatusModalVisible = true" />

						<UpdateProductStateModal
							:is-visible="isUpdateStatusModalVisible"
							@close="isUpdateStatusModalVisible = false"
							@complete="onUpdateProductStatusModalComplete"
						/>
					</b-row>
					<b-row class="product-top-panel mb-4">
						<div class="product-top-panel__releases">
							<b>{{ $t('Releases') }}</b> :
							<span v-for="delivery in productDeliveryTypes" :key="delivery" class="mr-2">
								<template v-if="!!activeReleasesByDeliveryType[delivery]">
									{{ delivery }}
									<OfsBadge text="✔" status="ready" />
								</template>

								<template v-else>
									{{ delivery }}
									<OfsBadge text="!" status="warning" />
								</template>
							</span>
						</div>
					</b-row>
					<b-row>
						<b-col md="8">
							<OfFormInput :label="$t('Name')" name="name" required show-errors />
						</b-col>
					</b-row>
					<div class="row">
						<div class="col-md-6">
							<div class="row ml-0 align-items-center">
								<div
									v-b-tooltip.hover
									title="You cannot activate this flag because another product is currently marked as the featured product."
								>
									<OfFormCheckbox
										name="featured"
										:right-side-label="$t('Featured Product')"
										:disabled="!isCurrentProductFeatured"
									/>
								</div>
								<router-link
									v-if="!isCurrentProductFeatured && featuredProduct && featuredProduct._id && formData.accountId"
									:to="`/products/${featuredProduct._id}`"
									target="_blank"
								>
									(Link to the featured product)
								</router-link>
							</div>

							<OfFormTextarea :label="$t('Short Description')" name="shortDescription" />

							<AccountSelect :disabled="!!productId" />

							<OfFormTextarea :label="$t('Full Description')" name="fullDescription" :rows="15" />

							<OfMultiSelect
								:label="$t('Tags')"
								:placeholder="$t('Add custom tags')"
								:taggable="true"
								name="tags"
								show-errors
							/>

							<OfMultiSelect
								name="categories"
								:label="$t('Categories')"
								:options="categories"
								track-by="id"
								label-by="name"
								multiple
								@search-change="onCategoriesSearch"
							/>
						</div>
						<div class="col-md-6">
							<LogsTable :items="formData.logs" :title="$t('Product Logs')" />
						</div>
					</div>
				</b-tab>

				<b-tab :title="$t('Media')">
					<div class="d-flex">
						<div
							v-for="(_, index) in formData.previewImages"
							:key="index"
							class="ThumbnailItem"
							@click="openPreviewImageOptions(index)"
						>
							<Thumbnail
								:src="formData.previewImages[index].url"
								size="150"
								class="mb-2 cursor-pointer"
								style="margin-right: 20px;"
							/>
							<icon
								name="times-circle"
								scale="1.2"
								class="ThumbnailItem-RemoveBtn"
								@click="e => removePreviewImage(e, index)"
							/>
							<b-badge
								v-if="!!get(formData.previewImages[index], 'options.componentId')"
								variant="primary"
								class="ThumbnailItem-Options"
							>
								<icon name="sliders-h" scale="1.5" />
							</b-badge>
						</div>
						<span id="open-media-library-wrapper" class="d-inline-block">
							<b-card
								class="add-media-btn"
								:class="{ 'add-media-btn--disabled': !productId }"
								@click="openMediaUploader(null)"
							>
								<icon name="plus" scale="1.5" />
							</b-card>
						</span>
						<b-tooltip v-if="!productId" target="open-media-library-wrapper">
							{{ $t('The product needs to be saved first to manage its assets') }}
						</b-tooltip>
						<MediaUploader
							:title="$t('Upload product file')"
							:show="isMediaUploaderVisible"
							:path="mediaUploaderPath"
							:active-tab="1"
							:on-close="closeMediaUploader"
							:accepted-file-types="acceptedFileTypes"
							:account-id="product ? product.accountId : null"
							disable-url
							@file-picked="onFilePicked"
						/>
						<ImageOptionsModal
							:visible="isImageOptionsModalVisible"
							:options="get(formData, `previewImages[${imageOptionsIndex}].options`)"
							:component-options="componentOptions"
							@ok="onSaveImageOptions"
							@hidden="closePreviewImageOptions"
						/>
					</div>

					<hr />

					<div class="row">
						<div role="group" class="form-row form-group col-md-6">
							<label class="col-12 col-form-label">Video</label>
							<div class="col">
								<div v-if="formData.videoFile" class="mb-2">
									<b-embed type="video" :src="formData.videoFile.urlData.url" controls />
								</div>
								<div @click.prevent.stop="handleVideoUploaderOpen">
									<b-input-group v-if="formData.videoFileId">
										<b-form-file :browse-text="$t('Change')" no-drop>
											<template #placeholder>
												{{ formData.videoFile.name }}
											</template>
										</b-form-file>
										<b-input-group-append>
											<b-input-group-text @click.prevent.stop="() => handleVideoPicked()">
												Remove
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
									<b-form-file v-else :browse-text="$t('Select Video')" no-drop />
								</div>
							</div>

							<MediaUploader
								:title="$t('Upload Video file')"
								:show="isVideoUploaderVisible"
								:path="videoMediaUploaderPath"
								:account-id="product.accountId"
								disable-url
								:on-close="() => (isVideoUploaderVisible = false)"
								accepted-file-types="video/mpeg, video/mp4"
								@file-picked="handleVideoPicked"
							/>
						</div>
					</div>
				</b-tab>

				<b-tab :title="$t('Components')">
					<b-card v-if="isProductBasedOnJSONLayout">
						<b-row>
							<router-link
								target="_blank"
								:to="{
									name: 'templates.edit',
									params: { id: productTemplateId }
								}"
								class="mr-3"
							>
								{{ $t('Product content template') }}
							</router-link>
						</b-row>
					</b-card>
					<b-card no-body>
						<b-tabs pills card vertical>
							<b-tab v-for="(item, index) in formData.components" :key="item._id" :title="item.name">
								<div>
									<h2>{{ $t('Specification') }}</h2>
									<FloatButton icon="times-circle" @click="removeComponent(index)" />
								</div>

								<b-row>
									<b-col md="2">
										<OfFormInput
											:label="$t('Component name')"
											:name="`components[${index}].name`"
											show-errors
											required
										/>
									</b-col>
									<b-col md="2">
										<OfFormInput
											:label="$t('Component code')"
											:name="`components[${index}].componentCode`"
											show-errors
											required
										/>
									</b-col>
									<b-col md="3">
										<OfFormRadio
											:label="$t('Type')"
											:name="`components[${index}].type`"
											:options="typeOptions"
											required
										/>
									</b-col>
									<b-col>
										<b-row>
											<b-col v-if="!isProductBasedOnJSONLayout">
												<OfMultiSelect
													:name="`components[${index}].templateId`"
													:options="templateOptions(index)"
													:placeholder="$t('Template')"
													:label="$t('Template')"
													:is-loading="isTemplateLoading(index)"
													:disabled="isTemplateSelectDisabled(index)"
													@search-change="search => onTemplatesSearchChange(search, index)"
												/>
											</b-col>
											<b-col>
												<div
													v-if="formData.components[index].pdf != undefined"
													style="position: relative; width: 125px;"
												>
													<Thumbnail
														:src="formData.components[index].pdf.thumbnailUrl"
														size="100"
														class="mb-2 cursor-pointer"
														style="margin-right: 20px;"
													/>
													<icon
														name="times-circle"
														scale="1.2"
														class="ThumbnailItem-RemoveBtn"
														@click="removePdfComponent(item._id)"
													/>
												</div>
												<b-button
													v-else
													variant="primary"
													style="margin-top: 28px;"
													:disabled="!item._id"
													@click="openMediaUploader(item._id)"
												>
													{{ $t('Select pdf component') }}
												</b-button>
											</b-col>
										</b-row>
									</b-col>
								</b-row>

								<b-row v-if="!formData.components[index].pdf">
									<b-col lg="4">
										<OfFormInput
											type="number"
											:label="$t('Width')"
											:name="`components[${index}].sizes.media.width`"
											required
										>
											<template v-slot:append>
												<b-input-group-text>{{ $t('mm') }}</b-input-group-text>
											</template>
										</OfFormInput>
									</b-col>
									<b-col lg="4">
										<OfFormInput
											type="number"
											:label="$t('Height')"
											:name="`components[${index}].sizes.media.height`"
											required
										>
											<template v-slot:append>
												<b-input-group-text>{{ $t('mm') }}</b-input-group-text>
											</template>
										</OfFormInput>
									</b-col>
									<b-col lg="4">
										<OfFormInput type="number" :label="$t('Spine size')" :name="`components[${index}].spine.size`">
											<template v-slot:append>
												<b-input-group-text>{{ $t('mm') }}</b-input-group-text>
											</template>
										</OfFormInput>
									</b-col>
								</b-row>

								<b-row>
									<b-col lg="4">
										<OfFormInput type="number" :label="$t('Total Weight')" :name="`components[${index}].totalWeight`">
											<template v-slot:append>
												<b-input-group-text>{{ $t('g') }}</b-input-group-text>
											</template>
										</OfFormInput>
									</b-col>
									<b-col lg="4">
										<OfFormInput
											type="number"
											:label="`${$t('Page Weight')} (g)`"
											:name="`components[${index}].pageWeight`"
										>
											<template v-slot:append>
												<b-input-group-text>{{ $t('g') }}</b-input-group-text>
											</template>
										</OfFormInput>
									</b-col>
								</b-row>

								<b-card
									v-if="!(formData.components && formData.components[index] && formData.components[index].pdf)"
									body-class="no-vertical-padding"
									class="mb-2"
								>
									<template #header>
										<h6 class="mb-0">{{ $t('Bleed Box') }}</h6>
									</template>
									<b-row>
										<b-col lg="3" md="6">
											<OfFormInput type="number" :label="$t('Top')" :name="`components[${index}].sizes.bleed.top`">
												<template v-slot:append>
													<b-input-group-text>{{ $t('mm') }}</b-input-group-text>
												</template>
											</OfFormInput>
										</b-col>
										<b-col lg="3" md="6">
											<OfFormInput type="number" :label="$t('Right')" :name="`components[${index}].sizes.bleed.right`">
												<template v-slot:append>
													<b-input-group-text>{{ $t('mm') }}</b-input-group-text>
												</template>
											</OfFormInput>
										</b-col>
										<b-col lg="3" md="6">
											<OfFormInput
												type="number"
												:label="$t('Bottom')"
												:name="`components[${index}].sizes.bleed.bottom`"
											>
												<template v-slot:append>
													<b-input-group-text>{{ $t('mm') }}</b-input-group-text>
												</template>
											</OfFormInput>
										</b-col>
										<b-col lg="3" md="6">
											<OfFormInput type="number" :label="$t('Left')" :name="`components[${index}].sizes.bleed.left`">
												<template v-slot:append>
													<b-input-group-text>{{ $t('mm') }}</b-input-group-text>
												</template>
											</OfFormInput>
										</b-col>
									</b-row>
								</b-card>

								<b-card v-if="!formData.components[index].pdf" body-class="no-vertical-padding" class="mb-2">
									<template #header>
										<h6 class="mb-0">{{ $t('Trim Box') }}</h6>
									</template>
									<b-row>
										<b-col lg="3" md="6">
											<OfFormInput type="number" :label="$t('Top')" :name="`components[${index}].sizes.trim.top`">
												<template v-slot:append>
													<b-input-group-text>{{ $t('mm') }}</b-input-group-text>
												</template>
											</OfFormInput>
										</b-col>
										<b-col lg="3" md="6">
											<OfFormInput type="number" :label="$t('Right')" :name="`components[${index}].sizes.trim.right`">
												<template v-slot:append>
													<b-input-group-text>{{ $t('mm') }}</b-input-group-text>
												</template>
											</OfFormInput>
										</b-col>
										<b-col lg="3" md="6">
											<OfFormInput type="number" :label="$t('Bottom')" :name="`components[${index}].sizes.trim.bottom`">
												<template v-slot:append>
													<b-input-group-text>{{ $t('mm') }}</b-input-group-text>
												</template>
											</OfFormInput>
										</b-col>
										<b-col lg="3" md="6">
											<OfFormInput type="number" :label="$t('Left')" :name="`components[${index}].sizes.trim.left`">
												<template v-slot:append>
													<b-input-group-text>{{ $t('mm') }}</b-input-group-text>
												</template>
											</OfFormInput>
										</b-col>
									</b-row>
								</b-card>

								<h2>{{ $t('Attributes') }}</h2>

								<ProductAttributes
									:attributes="formData.components[index].attributes"
									:title="$t('Component Attributes')"
									@attributesChange="handleComponentAttributeChange(index, $event)"
								/>
							</b-tab>
							<template #tabs-end>
								<b-button variant="primary" size="sm" class="mt-2" @click="addComponent">
									{{ $t('Add') }}
								</b-button>
							</template>
						</b-tabs>
					</b-card>
				</b-tab>

				<b-tab :title="$t('Formats')">
					<ProductFormats :product-formats="formData.formats" :current-product-status="formData.status" />
				</b-tab>

				<b-tab :title="$t('Payout Rules')">
					<PayoutRule
						v-for="(rule, idx) in formData.contentPayoutRules"
						:key="`payout-rule-content-${idx}`"
						:rule="rule"
						:title="`${$t('Content Payout Rule')} #${idx + 1}`"
						:form-path="`contentPayoutRules[${idx}]`"
						class="mb-2"
						@remove="removePayoutRule(idx, 'contentPayoutRules')"
					/>
					<b-button variant="primary" @click="() => addPayoutRule('contentPayoutRules')">
						{{ $t('Add Content Payout Rule') }}
					</b-button>
					<hr />
					<PayoutRule
						v-for="(rule, idx) in formData.salesPayoutRules"
						:key="`payout-rule-sales-${idx}`"
						:rule="rule"
						:title="`${$t('Sales Payout Rule')} #${idx + 1}`"
						:form-path="`salesPayoutRules[${idx}]`"
						class="mb-2"
						@remove="removePayoutRule(idx, 'salesPayoutRules')"
					/>
					<b-button variant="primary" @click="() => addPayoutRule('salesPayoutRules')">
						{{ $t('Add Sales Payout Rule') }}
					</b-button>
				</b-tab>

				<b-tab :title="$t('Releases')" :disabled="!formData._id">
					<ProductReleases
						:product="product"
						:product-delivery-types="productDeliveryTypes"
						:fetch-product="fetchProduct"
					/>
				</b-tab>

				<b-tab v-if="productId" :title="$t('Sample')">
					<ProductSample :product-id="productId" :pdf-formats="formData.formats" />
				</b-tab>

				<b-tab :title="$t('License')" :disabled="!isProductHasDigitalFormat">
					<div class="row">
						<div class="col-md-10">
							<b-button variant="primary" :disabled="!isEmptyLicense" @click="setDefaultLicense">
								{{ $t('Add default license') }}
							</b-button>

							<OfFormTextarea :label="$t('License page')" name="license.pageText" :rows="10" show-errors />
						</div>
					</div>
				</b-tab>
			</b-tabs>
		</template>

		<template v-if="!isLoading && activeTabIndex <= 5" slot="actions">
			<b-button variant="primary" :disabled="!canSaveProduct" @click="saveProduct">
				{{ $t('Save') }}
			</b-button>
		</template>
	</OfsPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { required, url } from 'vuelidate/lib/validators';
import {
	ContentHeader,
	OfFormCheckbox,
	OfFormInput,
	OfFormRadio,
	OfFormTextarea,
	OfMultiSelect,
	OfsBadge,
	OfsPanel,
	validateWithMessage,
	withForm
} from '@oneflow/ofs-vue-layout';
import _ from 'lodash';
import { convertSizes, isObjectId } from '../../Create/Templates/HTMLTemplateEditor/helpers';
import Thumbnail from '@/components/Thumbnail';
import FloatButton from '@/components/FloatButton';
import MediaUploader from '@/components/MediaUploader';
import WarningArea from '@/components/WarningArea';
import LogsTable from '@/components/LogsTable';
import PayoutRule from '@/components/PayoutRule';
import AccountSelect from '@/components/AccountSelect';
import { getCurrencySymbol } from '@/lib/currency';
import ProductAttributes from './ProductAttributes';
import ProductSample from './ProductSample';
import ProductFormats, { ProductFormatsFormName } from './ProductFormats';
import {
	defaultContentComponent,
	defaultLicense,
	defaultProductData,
	productFormatFulfilment,
	productFormats
} from './constants';
import UpdateProductStateModal from './UpdateProductStateModal';
import ImageOptionsModal from './ImageOptionsModal.vue';
import ProductReleases from './releases/ProductReleases.vue';
import { productDeliveryTypes, activeReleasesByDeliveryType } from '@/containers/Create/Products/computed';
import withQuerystringState from '@/mixins/withQuerystringState';
import Loader from '@/components/Loader.vue';

export default {
	components: {
		OfsPanel,
		OfFormInput,
		OfFormRadio,
		OfMultiSelect,
		OfsBadge,
		Thumbnail,
		FloatButton,
		MediaUploader,
		ContentHeader,
		OfFormTextarea,
		OfFormCheckbox,
		ProductAttributes,
		LogsTable,
		UpdateProductStateModal,
		ImageOptionsModal,
		ProductSample,
		ProductFormats,
		PayoutRule,
		WarningArea,
		AccountSelect,
		ProductReleases,
		Loader
	},
	mixins: [
		withForm('productEdit'),
		withQuerystringState([{ name: 'activeTabIndex', defaultValue: 0, parseValue: Number }])
	],
	data() {
		return {
			typeOptions: [
				{ text: this.$t('Cover'), value: 'cover' },
				{ text: this.$t('Content'), value: 'content' }
			],
			rules: [],
			templates: [],
			isMediaUploaderVisible: false,
			acceptedFileTypes: 'image/jpeg, image/png, image/tiff',
			componentId: null,
			isTemplatesLoading: false,
			newQuestionId: null,
			isImageOptionsModalVisible: false,
			isUpdateStatusModalVisible: false,
			imageOptionsIndex: 0,
			productFormats,
			isPageInitialized: false,
			isProductAttributesValid: true,
			isComponentAttributesValid: {},
			categories: [],
			isCategoriesLoading: false,
			isLoading: false,
			isVideoUploaderVisible: false
		};
	},
	computed: {
		...mapGetters({
			product: 'product/product',
			productSpecifications: 'product/specifications',
			getFormData: 'form/getFormData',
			getFormSummary: 'form/getFormSummary',
			featuredProduct: 'product/featuredProduct'
		}),
		productId() {
			const { id } = this.$route.params;
			return id !== 'new' ? id : null;
		},
		componentOptions() {
			const templateComponents = _.filter(this.formData.components, ({ pdf }) => _.isNil(pdf?.pdfUrl));
			const options = _.map(templateComponents, component => ({ text: component.name, value: component._id }));
			return [{ text: this.$t('None'), value: null }, ...options];
		},
		mediaUploaderPath() {
			const additionalPath = this.componentId ? `/components/${this.componentId}` : '';
			return `pimienta/products/${this.productId}${additionalPath}`;
		},
		videoMediaUploaderPath() {
			return `pimienta/products/${this.productId}/video`;
		},
		validationRules() {
			const licenseRules = {
				pageText: { required: validateWithMessage(this.$t('License: Page text is required'), required) }
			};
			return {
				formData: {
					name: { required: validateWithMessage('Product name is required', required) },
					components: {
						$each: {
							name: {
								required: validateWithMessage('Component name is required', required),
								uniq: validateWithMessage('You should provide a unique name for each component', function(name) {
									return _.filter(this.formData.components, ['name', name]).length === 1;
								})
							},
							componentCode: {
								required: validateWithMessage('Component code is required', required)
							}
						}
					},
					license: this.isProductHasDigitalFormat ? licenseRules : {}
				}
			};
		},
		releaseDateFormatted() {
			const date = _.get(this.formData, 'expectedReleaseDate');
			// Display date in utc time
			return date ? moment.utc(date).format('MMM DD YYYY') : '-';
		},
		productStatus() {
			return _.get(this.formData, 'status', '-');
		},
		productAvailability() {
			return _.get(this.formData, 'availability', '-');
		},
		productTemplateId() {
			return _.get(this.formData, 'templateId', null);
		},
		isProductBasedOnJSONLayout() {
			return this.productTemplateId ? true : false;
		},
		productFormatsData() {
			const formatsForm = this.getFormData({ formName: ProductFormatsFormName });
			return _.get(formatsForm, 'formats', []);
		},
		isProductFormatsFormValid() {
			const formSummary = this.getFormSummary({ formName: ProductFormatsFormName });
			return !_.get(formSummary, 'invalid');
		},
		isProductFormatsFormDirty() {
			const formSummary = this.getFormSummary({ formName: ProductFormatsFormName });
			return _.get(formSummary, 'dirty');
		},
		isAttributesValid() {
			return this.isProductAttributesValid && _.every(this.isComponentAttributesValid);
		},
		canSaveProduct() {
			return (
				this.isProductFormatsFormValid &&
				(this.isProductFormatsFormDirty || this.canSubmit) &&
				!this.validationErrors.length &&
				this.isAttributesValid
			);
		},
		isProductHasDigitalFormat() {
			return _.some(this.productFormatsData, ['fulfilment', productFormatFulfilment.digital]);
		},
		isEmptyLicense() {
			const license = _.get(this.formData, 'license', {});
			return _.isEmpty(license) || !_.get(license, 'pageText');
		},
		showWarningArea() {
			return this.isPageInitialized && this.validationErrors.length > 0;
		},
		isCurrentProductFeatured() {
			return this.featuredProduct?._id === this.$route.params.id;
		},
		productDeliveryTypes,
		activeReleasesByDeliveryType
	},
	watch: {
		async '$route.params.id'() {
			this.isPageInitialized = false;
			await this.findProductSpecifications();
			await this.initialize();
			this.isPageInitialized = true;
		},
		'formData.accountId': {
			immediate: true,
			handler() {
				if (this.formData?.accountId) {
					return this.findFeaturedProduct({ query: { $where: { accountId: this.formData.accountId } } });
				}
			}
		}
	},
	async mounted() {
		this.isPageInitialized = false;
		await this.initialize();
		this.isPageInitialized = true;
	},
	methods: {
		...mapActions({
			findProductById: 'product/findById',
			createProduct: 'product/create',
			updateProductById: 'product/update',
			findProductSpecifications: 'product/findSpecifications',
			findTemplates: 'template/find',
			findCategories: 'categories/find',
			findFeaturedProduct: 'product/findFeatured'
		}),
		get: _.get,
		getCurrencySymbol,
		async prepareFormData(formData) {
			const components = this.convertComponentSizes(formData.components, 'mm');

			for (let i = 0; i < components.length; i++) {
				let query;
				if (!components[i].templateId) {
					query = { $where: { type: { $ne: 'email' } }, $limit: 10 };
				} else {
					query = { $where: { _id: components[i].templateId } };
				}
				const { data } = await this.findTemplates({ query: { query: { ...query } }, options: { skipMutations: true } });
				this.$set(this.templates, i, {
					isLoading: false,
					options: _.map(data, tpl => ({ text: tpl.name, value: tpl._id }))
				});
			}
			return { ...formData, components };
		},
		fetchProduct() {
			const { id } = this.$route.params;

			return this.findProductById({
				id,
				query: { query: { $populate: [{ path: 'activeReleases' }, { path: 'videoFile' }] } }
			});
		},
		async initialize() {
			this.isLoading = true;
			const { id, preset } = this.$route.params;
			let formData = { ...defaultProductData, ...(this.productSpecifications[preset] || {}) };
			if (isObjectId(id)) {
				formData = await this.fetchProduct();
			}
			await this.searchCategories();
			const preparedData = await this.prepareFormData(formData);
			await this.initFormData({ ...preparedData });
			this.isLoading = false;
		},
		convertComponentSizes(components, units) {
			return _.map(components, component => ({
				...component,
				sizes: convertSizes(component.sizes, units),
				spine: convertSizes(component.spine, units)
			}));
		},
		async saveProduct() {
			if (!this.canSaveProduct) return;

			const dataToSave = _.omit(this.formData, ['stripeProductId']);
			dataToSave.components = this.convertComponentSizes(dataToSave.components, 'px');
			dataToSave.formats = [...this.productFormatsData];

			let message = this.$t('Current product has been updated');
			try {
				if (this.formData._id) {
					await this.updateProductById({ id: this.formData._id, data: dataToSave });
					await this.initialize();
				} else {
					const product = await this.createProduct(dataToSave);
					message = this.$t('Product has been created');
					this.$router.push({ name: 'products.edit', params: { id: product._id } });
					await this.initialize();
				}
				this.$notify({
					type: 'success',
					title: 'Success',
					text: message
				});
			} catch (err) {
				this.$notify({
					type: 'error',
					title: 'Error',
					text: this.$t('Failed to save product')
				});
			}
		},
		async addComponent() {
			this.formData.components.push({ ...defaultContentComponent });
			const { data } = await this.findTemplates({
				query: { query: { $where: { type: { $ne: 'email' } }, $limit: 10 } },
				options: { skipMutations: true }
			});
			this.$set(this.templates, this.templates.length, {
				isLoading: false,
				options: _.map(data, tpl => ({ text: tpl.name, value: tpl._id }))
			});
		},
		async removeComponent(index) {
			const approved = await this.confirmComponentRemove();
			if (approved) {
				this.formData.components.splice(index, 1);
				this.templates.splice(index, 1);
				this.isComponentAttributesValid = _.omit(this.isComponentAttributesValid, [`${index}`]);
			}
		},
		prepareAttributes(attributesArray) {
			return _.reduce(attributesArray, (acc, item) => ({ ...acc, [item.key]: item.value }), {});
		},
		handleComponentAttributeChange(index, data) {
			this.$set(this.isComponentAttributesValid, `${index}`, data.isValid);
			if (!data.isValid) return;
			this.updateField(`components[${index}].attributes`, this.prepareAttributes(data.data));
		},
		addPayoutRule(path) {
			const currentPayoutRules = _.get(this.formData, path, []);
			const payoutRule = {
				rule: { if: [{ and: [true] }, 0, 0] },
				payeeId: null
			};

			this.updateField(path, [...currentPayoutRules, payoutRule]);
		},
		removePayoutRule(index, path) {
			const currentPayoutRules = _.get(this.formData, path, []);
			const filteredPayoutRules = _.filter(currentPayoutRules, (_, idx) => idx !== index);

			this.updateField(path, filteredPayoutRules);
		},
		addPreviewImage(url) {
			const currentImages = _.get(this.formData, 'previewImages', []);
			this.updateField('previewImages', [...currentImages, { url }]);
		},
		removePreviewImage(event, index) {
			event.stopPropagation();
			const currentImages = _.get(this.formData, 'previewImages', []);
			const filteredImages = currentImages.filter((_, idx) => idx !== index);
			this.updateField('previewImages', filteredImages);
		},
		openMediaUploader(componentId) {
			if (!this.productId) return;
			this.componentId = componentId;
			if (this.componentId) {
				this.acceptedFileTypes = 'application/pdf';
			} else {
				this.acceptedFileTypes = 'image/jpeg, image/png, image/tiff';
			}
			this.isMediaUploaderVisible = true;
		},
		closeMediaUploader() {
			this.isMediaUploaderVisible = false;
		},
		openPreviewImageOptions(imageIndex) {
			this.isImageOptionsModalVisible = true;
			this.imageOptionsIndex = imageIndex;
		},
		closePreviewImageOptions() {
			this.isImageOptionsModalVisible = false;
			this.imageOptionsIndex = 0;
		},
		isTemplateSelectDisabled(index) {
			return this.formData.components[index].pdf || this.isProductBasedOnJSONLayout;
		},
		onSaveImageOptions(options) {
			const images = _.map(this.formData.previewImages, (image, index) => {
				if (this.imageOptionsIndex === index) {
					return { ...image, options };
				}
				return image;
			});

			this.updateField('previewImages', images);
		},
		async onFilePicked(file) {
			if (!this.componentId) {
				this.addPreviewImage(file.urlData.url);
			} else {
				this.addComponentPdfFile(file, file.urlData.url);
			}
		},
		async addComponentPdfFile(file, fileUrl) {
			var componentIndex = _.findIndex(this.formData.components, c => c._id === this.componentId);
			const pdf = {
				pdfUrl: fileUrl,
				thumbnailUrl: file.thumbnailUrlData.url,
				pageCount: file.metadata.pageCount
			};
			this.updateField(`components[${componentIndex}].templateId`, undefined);
			this.formData.components[componentIndex].pdf = pdf;
		},
		removePdfComponent(componentId) {
			const index = _.findIndex(this.formData.components, ['_id', componentId]);
			this.updateField(`components[${index}].pdf`, undefined);
		},
		async confirmComponentRemove() {
			return this.$bvModal.msgBoxConfirm([this.$t('Are you sure to delete this component?')], {
				title: this.$t('Warning!'),
				okVariant: 'danger',
				size: 'md',
				centered: true
			});
		},
		async onTemplatesSearchChange(search, index) {
			const { templateId } = this.formData.components[index];
			if (!search && templateId) {
				await this.findAndSetTemplates({ query: { $where: { _id: templateId } } }, index);
			} else if (search && search.text) {
				await this.searchTemplates(
					{ query: { $where: { name: { $regex: `^${search.text}` }, active: true }, $limit: 10 } },
					index
				);
			}
		},
		searchTemplates: _.debounce(async function(query, index) {
			this.templates[index].isLoading = true;
			await this.findAndSetTemplates(query, index);
			this.templates[index].isLoading = false;
		}, 500),
		async findAndSetTemplates(query, componentIndex) {
			const { data } = await this.findTemplates({ query, options: { skipMutations: true } });
			this.templates[componentIndex].options = _.map(data, tpl => ({ text: tpl.name, value: tpl._id }));
		},
		templateOptions(index) {
			return _.get(this.templates, `${index}.options`, []);
		},
		isTemplateLoading(index) {
			return _.get(this.templates, `${index}.isLoading`, false);
		},
		async onUpdateProductStatusModalComplete() {
			this.isUpdateStatusModalVisible = false;
			const preparedData = await this.prepareFormData({ ...this.product });
			this.updateFormData({ ...preparedData });
		},
		setDefaultLicense() {
			this.updateField('license', defaultLicense);
		},
		async fetchCategories(searchString) {
			const query = {
				$where: {
					...(_.isString(searchString) ? { name: { $regex: searchString, $options: 'i' } } : {})
				},
				$limit: 10
			};

			const { data } = await this.findCategories({ query: { query } });
			this.categories = data;
		},
		searchCategories: _.debounce(async function(query) {
			this.isCategoriesLoading = true;
			await this.fetchCategories(query);
			this.isCategoriesLoading = false;
		}, 500),
		async onCategoriesSearch(search) {
			const searchString = search?.name;
			if (_.isUndefined(searchString) || _.isNull(searchString?.text)) {
				return;
			}
			await this.searchCategories(searchString);
		},
		handleVideoUploaderOpen() {
			this.isVideoUploaderVisible = true;
		},
		handleVideoPicked(file) {
			this.updateFormData({ videoFileId: file?._id, videoFile: file });
		}
	}
};
</script>

<style lang="scss">
@import 'src/styles/shared';

.ProductEdit {
	.TypeRadio {
		.disabled {
			border: 1px solid $of-color-grey-3;
		}
	}

	.ThumbnailItem {
		position: relative;
		cursor: pointer;

		&-RemoveBtn {
			position: absolute;
			top: -6px;
			right: 14px;
			z-index: 2;
			cursor: pointer;

			&:hover {
				color: $of-color-red;
			}
		}

		&-Options {
			position: absolute;
			bottom: 16px;
			right: 28px;
			z-index: 2;
		}
	}

	.QuestionCard {
		padding: 10px 20px;
		margin-bottom: 10px;

		&-Title {
			display: flex;
			align-items: center;
			margin-top: 5px;

			.fa-icon {
				margin-left: 7px;
				color: $of-color-yellow;
			}
		}
	}

	.d-flex {
		flex-wrap: wrap;
	}

	.add-media-btn {
		width: 150px;
		height: 150px;
		cursor: pointer;

		&--disabled {
			cursor: not-allowed;

			.card-body {
				background-color: $of-color-grey-3;

				&:hover {
					background-color: $of-color-grey-3 !important;
				}
			}
		}

		.card-body {
			display: flex;
			justify-content: center;
			align-items: center;

			&:hover {
				background-color: $of-color-light;
			}
		}
	}

	.remove-component-button {
		&--disabled {
			cursor: not-allowed;
		}
	}

	.no-vertical-padding {
		padding-top: 0;
		padding-bottom: 0;
	}

	.product-top-panel {
		flex-direction: row;
		align-items: center;
		margin-bottom: 10px;
		margin-left: 5px;

		div,
		button {
			margin-right: 20px;
		}

		&__releases {
			.OfsBadge {
				min-width: auto;
				width: 20px;
			}
		}
	}
}

.featuredLink {
	margin-bottom: 1rem;
	margin-top: 14px;
}
</style>
