<template>
	<b-form novalidate class="PayoutEdit-Form" @submit.prevent="onSave">
		<OfsPanel>
			<ContentHeader
				:title="payoutCodeId ? $t('Edit Payout Code') : $t('Create Payout Code')"
				class="mb-3"
				no-padding
			/>
			<div class="row">
				<div class="col-md-4">
					<OfFormInput :label="$t('Code')" name="code" required show-errors />
				</div>
				<div class="col-md-4">
					<OfFormInput name="source" :label="$t('Source')" required />
				</div>
				<div class="col-md-4">
					<OfMultiSelect
						name="payeeId"
						:options="creatorOptions"
						:placeholder="$t('Payee')"
						:label="$t('Payee')"
						:is-loading="isCreatorsFetching"
						@search-change="onPayeeSearchChange"
					/>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<OfFormTextarea :label="$t('Description')" name="description" :rows="3" />
				</div>
			</div>

			<div class="row d-flex flex-column">
				<div class="col">
					<label class="col-form-label mt-1">
						{{ $t('Website URL examples (generated on save)') }}
					</label>
				</div>
				<div v-if="payoutCodeLinks.length" class="mt-1">
					<div
						v-for="link in payoutCodeLinks"
						:key="link.title"
						class="col d-flex flex-column justify-content-center mb-3 pb-3 border-bottom"
					>
						<template>
							<label class="mb-0 mb-1">
								{{ link.title }}
							</label>
							<div>
								<b-button v-b-tooltip="$t('Copy to clipboard')" class="mr-2" @click="copyLink(link.url)">
									<icon name="clipboard" />
								</b-button>
								<a :href="link.url" target="_blank">{{ link.url }}</a>
							</div>
						</template>
					</div>
				</div>
				<p v-else class="font-weight-bold ml-2">-</p>
			</div>

			<template slot="actions">
				<of-submit-button v-t="'Save'" data-test-id="attribute-save" variant="primary" />
			</template>
		</OfsPanel>
	</b-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	OfsPanel,
	ContentHeader,
	withForm,
	OfFormTextarea,
	OfFormInput,
	OfSubmitButton,
	OfMultiSelect,
	validateWithMessage
} from '@oneflow/ofs-vue-layout';
import { $t } from '@/vuex';
import { urlSafe } from '@/lib/validators';

const initialFormData = {
	code: '',
	source: '',
	description: '',
	accountId: process.env.VUE_APP_HP_STORE_ACCOUNT_ID
};

export default {
	components: {
		OfsPanel,
		ContentHeader,
		OfFormTextarea,
		OfFormInput,
		OfSubmitButton,
		OfMultiSelect
	},
	mixins: [withForm('PayoutCodeForm')],
	data() {
		return {
			payoutCodeLinks: []
		};
	},
	computed: {
		...mapGetters({
			creators: 'creator/creators',
			isCreatorsFetching: 'creator/isFetching',
			products: 'product/products'
		}),
		validationRules() {
			return {
				formData: {
					code: {
						urlSafe: validateWithMessage(this.$t('Must be URL safe'), urlSafe)
					}
				}
			};
		},
		payoutCodeId() {
			const { id } = this.$route.params;
			return id !== 'new' ? id : null;
		},
		creatorOptions() {
			return _.map(this.creators.data, creator => {
				const text = `${creator.username}${creator.profile ? ` (@${creator.profile.handle})` : ''}`;
				return { value: creator._id, text };
			});
		}
	},
	async mounted() {
		if (!this.payoutCodeId) {
			return this.initFormData(initialFormData);
		}
		try {
			const payoutCode = await this.findPayoutCodeById({ id: this.payoutCodeId });
			this.initFormData(payoutCode);
			await this.onPayeeSearchChange();
			await this.generatePayoutLink();
		} catch (err) {
			this.$notify({
				type: 'error',
				title: this.$t('Error'),
				text: this.$t('An error occurred while fetching payout code')
			});
		}
	},
	methods: {
		...mapActions({
			findPayoutCodeById: 'payoutCode/findById',
			createPayoutCode: 'payoutCode/create',
			updatePayoutCode: 'payoutCode/update',
			findCreators: 'creator/find',
			findCreatorById: 'creator/findById',
			findProducts: 'product/find'
		}),
		async generatePayoutLink() {
			const { payeeId, code } = this.formData;
			const url = _.get(window.$config, 'hpStoreUrl', '');

			if (!payeeId) {
				this.payoutCodeLinks = [];
			} else {
				const updatedPayoutCodeLinks = [];

				const creator = await this.findCreatorById({ id: payeeId });
				const profileUrl = `${url}/creators/${creator?.profile?.handle || payeeId}?code=${code}`;

				updatedPayoutCodeLinks.push({ title: 'Profile Page', url: profileUrl });

				await this.searchCreatorProducts(creator.accountId);

				const productPayoutCodeLinks = _.map(this.products?.data, product => ({
					title: product.name,
					url: `${url}/products/${product._id}?code=${code}`
				}));

				updatedPayoutCodeLinks.push(...productPayoutCodeLinks);

				this.payoutCodeLinks = updatedPayoutCodeLinks;
			}
		},
		async searchCreatorProducts(accountId) {
			const query = { $limit: 100 };

			query.$where = {
				accountId
			};

			await this.findProducts({ query: { query } });
		},
		copyLink(url) {
			if (navigator?.clipboard) {
				navigator.clipboard
					.writeText(url)
					.then(() => this.$notify({ type: 'success', text: 'Link is copied' }))
					.catch(err => this.$notify({ type: 'error', text: err.message }));
			}
		},
		async onPayeeSearchChange(search) {
			const query = { $limit: 10 };
			if (_.isEmpty(search?.text)) {
				if (this.formData.payeeId) {
					query.$where = { _id: this.formData.payeeId };
				}
			} else {
				query.$where = {
					$or: [
						{ username: { $regex: `^${search.text}`, $options: 'i' } },
						{ 'profile.handle': { $regex: `^${search.text}`, $options: 'i' } }
					]
				};
			}
			await this.searchCreators({ query: { query } });
		},
		searchCreators: _.debounce(function(...args) {
			this.findCreators(...args);
		}, 800),
		async onSave() {
			try {
				let message = $t('Payout code updated successfully');
				if (this.formData._id) {
					await this.dispatchSubmit(this.updatePayoutCode({ id: this.formData._id, data: this.formData }));
				} else {
					message = $t('Payout code created successfully');
					const response = await this.dispatchSubmit(this.createPayoutCode(this.formData));

					this.updateField('_id', response._id);
					this.$router.push({
						name: 'payout-codes.edit',
						params: { id: response._id }
					});
				}
				await this.generatePayoutLink();
				this.$notify({ type: 'success', text: message });
			} catch (err) {
				this.$notify({ type: 'error', text: err.message });
			}
		}
	}
};
</script>
