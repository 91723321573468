<template>
	<OfsPanel class="layout-edit">
		<b-row class="TemplateEdit-header">
			<b-col md="11">
				<div class="back-btn" @click="goBack">
					<icon name="arrow-left" />
					<span>{{ $t('Back to layouts') }}</span>
				</div>
			</b-col>
		</b-row>
		<div v-if="isLayoutDataAnable">
			<template v-if="isHTMLLayout">
				<HTMLLayoutEditor />
			</template>
			<template v-else>
				<JSONTemplateEditor is-layout :data="layoutData" :is-edit-disabled="isSeparatedLayout" @save="onSave" />
			</template>
		</div>
	</OfsPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfsPanel } from '@oneflow/ofs-vue-layout';
import HTMLLayoutEditor from './editors/HTMLLayoutEditor';
import JSONTemplateEditor from '@/components/editors/JSONTemplateEditor';
import { templateFormats } from '../../../lib/constants';

export default {
	components: {
		OfsPanel,
		HTMLLayoutEditor,
		JSONTemplateEditor
	},
	data() {
		return {
			isLayoutDataAnable: false,
			isSeparatedLayout: false,
			blocksForPreview: {}
		};
	},
	computed: {
		...mapGetters({
			layout: 'layout/layout'
		}),
		isHTMLLayout() {
			return _.get(this.layout, 'format') === templateFormats.HTML;
		},
		layoutData() {
			const template = _.get(this.layout, 'template', {});
			return {
				...this.layout,
				template: {
					...template,
					...this.blocksForPreview
				}
			};
		}
	},
	watch: {
		async '$route.params.id'() {
			await this.initData();
		}
	},
	async mounted() {
		await this.initData();
	},
	methods: {
		...mapActions({
			findLayoutById: 'layout/findById',
			updateLayoutById: 'layout/update',
			getBlocksForPreview: 'layout/getBlocksForPreview'
		}),
		goBack() {
			this.$router.push({ name: 'layouts.list' });
		},
		async initData() {
			this.isLayoutDataAnable = false;
			const { id } = this.$route.params;
			await this.findLayoutById({ id });
			const blocks = _.get(this.layout, 'template.blocks', []);
			this.isSeparatedLayout = blocks.every(b => typeof b === 'string');
			if (this.isSeparatedLayout) {
				this.blocksForPreview = await this.getBlocksForPreview(id);
			}
			this.isLayoutDataAnable = true;
		},
		async onSave(data) {
			const template = this.isSeparatedLayout
				? {
					blocks: _.get(this.layout, 'template.blocks', []), //eslint-disable-line
					..._.omit(_.get(data, 'template', {}), ['blocks', 'contentDependencies', 'coverDependencies']) //eslint-disable-line
				  }
				: _.get(data, 'template');
			try {
				await this.updateLayoutById({ id: this.layout._id, data: { ...data, template } });
				this.$notify({
					type: 'success',
					title: 'Success',
					text: this.$t('Layout has been updated')
				});
			} catch (e) {
				this.$notify({
					type: 'error',
					title: 'Error',
					text: `${this.$t('Failed to update layout')}: ${e}`
				});
			}
		}
	}
};
</script>

<style lang="scss">
@import 'src/styles/shared';

.layout-edit {
	margin: 0;

	&-header {
		.back-btn {
			cursor: pointer;
			display: flex;
			align-items: center;
			font-weight: bold;

			.fa-icon {
				margin-right: 7px;
			}

			&:hover {
				color: $of-color-grey-2;
			}
		}
	}

	&-bottom {
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;
	}

	.cursor-pointer {
		cursor: pointer;
	}
}
</style>
