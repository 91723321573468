<template>
	<b-modal
		v-model="shouldShowModal"
		body-class="p-0"
		:title="title || $t('Choose File')"
		size="lg"
		modal-class="MediaUploader"
	>
		<b-tabs v-model="activeTabIndex" vertical>
			<b-tab :title="$t('File Upload')">
				<b-form class="MediaUploader_inner">
					<DropArea :disabled="!!file" :accepted-file-types="acceptedFileTypes" @input="fileSelected" />
					<Progress
						v-if="uploading && file"
						class="mt-2"
						:progress="progress"
						:filename="file.name"
						:file-type="file.type"
						@remove="reset"
					/>
					<b-row v-if="isWaitingForFileData" class="mt-2 ml-1">{{ $t('Waiting for file data...') }}</b-row>
				</b-form>
			</b-tab>
			<b-tab v-if="!disableUrl" :title="$t('URL')">
				<b-form class="MediaUploader_inner">
					<h3 class="mb-4">{{ $t('URL Link') }}</h3>
					<b-form-group :label="$t('Enter the URL of desired file')" class="mb-0">
						<b-input-group>
							<b-form-input v-model="fileUrl" type="url" :state="isFileUrlValid" :disabled="uploading" />
						</b-input-group>
					</b-form-group>
					<Progress
						v-if="progress && file"
						class="mt-2"
						:progress="progress"
						:filename="file.name"
						:file-type="file.type"
						@remove="reset"
					/>
				</b-form>
			</b-tab>
			<b-tab :title="$t('Media Library')">
				<section class="MediaUploader_inner">
					<Loader v-if="libraryLoading" />
					<template v-else>
						<span v-if="!libraryContent.length" v-t="'No files found'" />
						<MediaLibrary :content="libraryContent" @content-clicked="contentSelected" />
					</template>
				</section>
			</b-tab>
		</b-tabs>
		<template #modal-footer>
			<b-button @click="onClose">{{ $t('Cancel') }}</b-button>
			<b-button variant="primary" :disabled="submitDisabled" @click="onSubmit">{{ $t('Upload') }}</b-button>
		</template>
	</b-modal>
</template>

<script>
import axios from 'axios';
import { url } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import Loader from '../Loader';
import filters from './filters';
import Progress from './Progress';
import MediaLibrary from './MediaLibrary';
import DropArea from './DropArea';
import * as filestack from 'filestack-js';

export default {
	components: {
		Loader,
		Progress,
		MediaLibrary,
		DropArea
	},
	props: {
		timeout: {
			type: Number,
			default: 60000
		},
		title: {
			type: String,
			default: null
		},
		show: {
			type: Boolean,
			default: false
		},
		activeTab: {
			type: Number,
			default: 0
		},
		onClose: {
			type: Function,
			default: () => {},
			required: true
		},
		path: {
			type: String,
			default: ''
		},
		disableUrl: {
			type: Boolean,
			default: false
		},
		acceptedFileTypes: {
			type: String,
			default: 'image/jpeg, image/png, image/tiff, application/pdf, .ttf, .otf, .woff, .woff2'
		},
		accountId: { type: String }
	},
	data() {
		return {
			libraryLoading: true,
			libraryContent: [],
			paths: [],
			file: null,
			fileUrl: '',
			uploadedFile: null,
			uploading: false,
			progress: 0,
			progressLabel: '',
			activeTabIndex: 0,
			isWaitingForFileData: false
		};
	},
	computed: {
		...mapGetters({
			files: 'media-uploader/files'
		}),
		shouldShowModal: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				}
			}
		},
		isFileUrlValid() {
			return this.fileUrl && url(this.fileUrl);
		},
		submitDisabled() {
			return (
				(this.activeTabIndex === 0 && this.uploadedFile === null) ||
				(this.activeTabIndex === 1 && (!this.isFileUrlValid || this.uploading)) ||
				this.activeTabIndex === 2
			);
		}
	},
	watch: {
		show: {
			handler(value) {
				this.reset();
				if (value) {
					this.loadLibrary();
				}
			},
			immediate: true
		},
		activeTab: {
			handler(value) {
				this.activeTabIndex = value;
			},
			immediate: true
		}
	},
	methods: {
		...mapActions({
			browseFiles: 'media-uploader/browse',
			getFilestackUploadSignature: 'media-uploader/getFilestackUploadSignature',
			createPimientaFile: 'media-uploader/createPimientaFile'
		}),
		reset() {
			this.file = null;
			this.fileUrl = '';
			this.progress = 0;
			this.uploading = false;
			this.paths = [];
			this.uploadedFile = null;
		},
		async loadLibrary() {
			this.libraryLoading = true;
			const paths = [this.path, ...this.paths];
			this.libraryContent = await this.browseFiles({ path: paths.join('/'), type: 'file' });
			this.libraryLoading = false;
		},
		async upload() {
			if (!this.file) return;
			if (!this.acceptedFileTypes.includes(this.file.type)) {
				this.reset();
				this.$notify({
					type: 'error',
					text: this.$t('Unsupported file type')
				});
				return;
			}
			this.uploading = true;
			const { policy, signature } = await this.getFilestackUploadSignature();

			const client = filestack.init(window.$config.fileStackApiKey, { security: { policy, signature } });
			try {
				const controlToken = {};
				const result = await client.upload(
					this.file,
					{ onProgress: this.uploadProgress },
					{ path: `${this.path}/` },
					controlToken
				);
				const { _file: file } = result;
				this.uploadedFile = await this.createPimientaFile({
					path: this.path,
					name: file?.name,
					contentType: file?.type,
					metadata: {
						handle: result.handle,
						fileSize: file.size
					},
					accountId: this.accountId
				});
			} catch (error) {
				this.$notify({
					type: 'error',
					text: _.get(error, 'response.data.message', error.message)
				});
				this.file = null;
				this.progress = 0;
			} finally {
				this.uploading = false;
				this.file = null;
				this.activeTabIndex = 1;
				this.loadLibrary();
			}
		},
		async handleFileUrl() {
			if (!this.fileUrl) return;

			let fileType = '';
			try {
				// fetch file content type using HEAD request
				const { headers: fileHeaders } = await axios.head(this.fileUrl);
				fileType = fileHeaders['content-type'];
			} catch (error) {
				this.$notify({
					type: 'error',
					text: _.get(error, 'response.data.message', error.message)
				});
				throw error;
			}

			// skip uploading for non-images
			if (!fileType.startsWith('image/')) {
				return this.fileUrlPicked();
			}

			const fileName = this.getFileNameByUrl(this.fileUrl);
			const fileResponse = await axios.get(this.fileUrl, { responseType: 'blob' });

			const file = new File([fileResponse.data], fileName, { type: fileType });
			await this.fileSelected(file);
		},
		getFileNameByUrl(url) {
			let filename = uuidv4();
			try {
				const parsedUrl = new URL(url);
				filename = `${filename}-${_.last(parsedUrl.pathname.split('/'))}`;
			} catch {}
			return filename;
		},
		uploadProgress(event) {
			if (!this.uploading && event.totalPercent === 0) {
				this.progress = 100;
				this.progressLabel = '100%';
			} else {
				this.progress = event.totalPercent;
				this.progressLabel = `${event.totalPercent} %`;
			}
		},
		contentSelected(content) {
			this.filePicked(content);
		},
		async fileSelected(file) {
			this.file = file;
			await this.upload();
		},
		fileUrlPicked() {
			this.$emit('url-submitted', this.fileUrl);
			this.onClose();
		},
		filePicked(file) {
			this.$emit('file-picked', file);
			this.onClose();
		},
		async onSubmit() {
			if (this.activeTabIndex === 1 && this.fileUrl) {
				await this.handleFileUrl();
			}

			this.filePicked(this.uploadedFile);
			this.uploadedFile = null; // need to clear state before next upload
		}
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/src/styles/variables.scss';

.MediaUploader {
	max-width: 100%;
	width: 75vw !important;
	height: 90vh !important;
	margin: 5vh 12.5vw;

	.modal-dialog {
		height: 100%;
		max-width: 100%;
		margin-top: 0;
		margin-bottom: 0;
		overflow: hidden;
	}

	.modal-content {
		border-radius: 0;
		border: none;
		top: 0;
		height: 100%;
		overflow: hidden;
	}

	.modal-body {
		height: 100%;
		padding: 0;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	.modal-footer,
	.modal-header {
		border-top: 1px solid $of-color-grey-3;
	}

	.tabs.row {
		height: 100%;
	}

	.nav-tabs {
		height: 100%;
		padding: 20px 0 20px 20px;
	}

	&_inner {
		padding: 20px 20px 20px 0;
	}
}
</style>
