<template>
	<OfsPanel>
		<ListTable
			bordered
			hover
			:table-title="$t('Coupons')"
			:config="config"
			:items="coupons.data"
			:fields="fields"
			:sort="sort"
			:total-items="coupons.total"
			:current-page="currentPage"
			:per-page="perPage"
			:fetch-data="fetchData"
			:is-busy="isLoading"
			@table-change="handleTableChange"
			@row-clicked="handleRowClick"
		>
			<template #cell(percentOff)="{ item }">{{ item.percentOff }}%</template>
			<template #cell(actions)="{ item }">
				<b-dropdown size="sm">
					<b-dropdown-item @click="removeCoupon(item._id)">{{ $t('Delete') }}</b-dropdown-item>
				</b-dropdown>
			</template>
			<template slot="TableButtons-Slot-right" slot-scope="{}">
				<b-button variant="primary" class="ml-2" @click="handleCouponAdd()">
					{{ $t('Add Coupon') }}
				</b-button>
			</template>
			<template slot="TableButtons-Slot-left" slot-scope="{}">
				<OfFormInput
					:value="searchString"
					class="col-6 mr-2"
					:placeholder="$t('Search by code')"
					@input="handleSearchStringInput"
				/>
			</template>
		</ListTable>
	</OfsPanel>
</template>

<script>
import _get from 'lodash/get';
import debounce from 'lodash/debounce';
import { mapActions, mapGetters } from 'vuex';
import { OfsPanel, ListTable, OfFormInput } from '@oneflow/ofs-vue-layout';
import withQuerystringState from '@/mixins/withQuerystringState';
import { parseDefaultQuerystringFilterValues } from '@/lib/parseDefaultQuerystringFilterValues';

const config = {
	refresh: { visible: true }
};

export default {
	components: {
		OfsPanel,
		ListTable,
		OfFormInput
	},
	mixins: [
		withQuerystringState([
			{ name: 'currentPage', defaultValue: 1, parseValue: Number },
			{ name: 'perPage', defaultValue: 10, parseValue: Number },
			'searchString',
			{ name: 'sort', defaultValue: {}, parseValue: value => value ?? {} },
			{ name: 'filterValues', parseValue: parseDefaultQuerystringFilterValues }
		])
	],
	data() {
		const fields = [
			{ key: 'code', label: this.$t('Code'), sortable: true },
			{ key: 'name', label: this.$t('Name'), sortable: true },
			{ key: 'percentOff', label: this.$t('Percent Off'), sortable: true },
			{ key: 'intentOverride', label: this.$t('Intent Override'), sortable: true },
			{ key: 'actions', label: '' }
		];

		return {
			isLoading: false,
			fields,
			config
		};
	},
	computed: {
		...mapGetters({
			coupons: 'coupon/coupons'
		})
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			getCoupons: 'coupon/find',
			deleteCouponById: 'coupon/deleteById'
		}),
		_get,
		async fetchData() {
			try {
				this.isLoading = true;
				const query = {
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1)
				};
				if (Object.keys(this.sort).length > 0) {
					query.$sort = this.sort;
				}
				if (this.searchString) {
					query.$where = { code: { $regex: this.searchString, $options: 'i' } };
				}
				await this.getCoupons({ query: { query } });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching coupons')
				});

				throw err;
			} finally {
				this.isLoading = false;
			}
		},
		handleTableChange({ currentPage, perPage, sort }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			if (sort) {
				this.sort = sort;
			}
		},
		async confirmCouponRemove() {
			return this.$bvModal.msgBoxConfirm([this.$t('Are you sure to delete this coupon?')], {
				title: this.$t('Warning!'),
				okVariant: 'danger',
				size: 'md',
				centered: true
			});
		},
		async removeCoupon(couponId) {
			const approved = await this.confirmCouponRemove();
			if (!approved) return;

			try {
				await this.deleteCouponById({ id: couponId });
			} catch (e) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: e.response.data.message
				});
			}
			await this.fetchData();
		},
		handleRowClick(item, index, event) {
			if (event.target.type === 'button') return;
			this.$router.push({ name: 'coupons.edit', params: { id: item._id } });
		},
		handleCouponAdd() {
			this.$router.push({ name: 'coupons.edit', params: { id: 'new' } });
		},
		handleSearchStringInput: debounce(function(value) {
			this.searchString = value;
			this.currentPage = 1;
			this.fetchData();
		}, 800)
	}
};
</script>
