<template>
	<div>
		<b-form novalidate class="AccountEdit-Form" @submit.prevent="onSave">
			<OfsPanel>
				<ContentHeader :title="accountId ? $t('Edit account') : $t('Create account')" class="mb-3" no-padding />

				<b-tabs>
					<b-tab :title="$t('Settings')" class="pt-2">
						<b-row>
							<b-col sm="12" lg="6">
								<of-form-input
									name="displayName"
									type="text"
									:label="$t('Display name')"
									:placeholder="$t('Your account name here')"
									:show-errors="true"
									:disabled="!!accountId"
								/>
							</b-col>
							<b-col sm="12" lg="5">
								<of-form-input name="name" type="text" :label="$t('Name')" :show-errors="true" required disabled />
							</b-col>
						</b-row>
					</b-tab>
					<b-tab :title="$t('Members')" class="pt-2" :disabled="!accountId">
						<div class="d-flex justify-content-end mb-2">
							<b-button variant="primary" size="sm" @click="openMemberModal">
								{{ $t('Add member') }}
							</b-button>
						</div>
						<Datatable
							:items="members.data"
							:total-items="members.total"
							:fields="memberFields"
							:current-page="currentPage"
							:per-page="perPage"
							:fetch-data="fetchMembersData"
							@table-change="handleMembersTableChange"
						>
							<template #cell(active)="{ item }">
								<b-badge v-if="item.active" variant="primary">{{ $t('Active') }}</b-badge>
								<b-badge v-else variant="dark">{{ $t('Inactive') }}</b-badge>
							</template>

							<template #cell(actions)="{ item }">
								<b-dropdown size="sm" toggle-class="p-1" right no-caret>
									<template #button-content>
										<icon name="ellipsis-h" />
									</template>
									<b-dropdown-item @click.stop="handleRemoveMember(item._id)">
										<icon name="trash" class="mr-2" /> {{ $t('Remove') }}
									</b-dropdown-item>
								</b-dropdown>
							</template>
						</Datatable>
					</b-tab>
				</b-tabs>

				<template slot="actions">
					<of-submit-button v-t="'Save'" variant="primary" />
				</template>
			</OfsPanel>
		</b-form>
		<AccountMemberModal :is-visible="isMemberModalVisible" :account-id="accountId" @hidden="onHideMemberModal" />
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';
import {
	OfsPanel,
	ContentHeader,
	Datatable,
	OfFormInput,
	OfSubmitButton,
	validateWithMessage,
	withForm
} from '@oneflow/ofs-vue-layout';
import AccountMemberModal from './AccountMemberModal';
import { $t } from '@/vuex';

export default {
	components: {
		OfsPanel,
		ContentHeader,
		Datatable,
		OfFormInput,
		OfSubmitButton,
		AccountMemberModal
	},
	mixins: [withForm('AccountForm')],
	data() {
		return {
			currentPage: 1,
			perPage: 10,
			memberFields: [
				{ label: this.$t('Username'), key: 'name' },
				{ label: this.$t('Email'), key: 'email' },
				{ label: this.$t('Active'), key: 'active' },
				{ label: this.$t('Actions'), key: 'actions' }
			],
			isMemberModalVisible: false
		};
	},
	computed: {
		...mapGetters({
			account: 'account-management/account-management',
			members: 'account-management/members'
		}),
		validationRules() {
			return {
				formData: {
					displayName: {
						required: validateWithMessage(this.$t('Must be not empty'), required),
						minLength: validateWithMessage(this.$t('Must have at least 6 characters'), minLength(6)),
						maxLength: validateWithMessage(this.$t('Must have maximum 30 characters'), maxLength(30))
					}
				}
			};
		},
		accountId() {
			const { id } = this.$route.params;
			return id && id !== 'new' ? id : null;
		}
	},
	watch: {
		accountId: {
			immediate: true,
			async handler(value, oldValue) {
				if (value !== oldValue) {
					await this.initialize();
				}
			}
		},
		'formData.displayName'(displayName) {
			this.updateField('name', _.kebabCase(displayName));
		}
	},
	methods: {
		...mapActions({
			findAccounts: 'account-management/find',
			createAccount: 'account-management/create',
			updateAccount: 'account-management/update',
			getAccountMembers: 'account-management/getAccountMembers',
			removeAccountMember: 'account-management/removeAccountMember',
			getAccountList: 'account-management/getAccounts'
		}),
		async initialize() {
			let accountData = {};
			if (this.accountId) {
				accountData = await this.findAccounts({ authAccountId: this.accountId }).then(res => res.data[0]);
				await this.fetchMembersData();
			}
			this.initFormData(accountData);
		},
		async fetchMembersData() {
			const query = {
				$limit: this.perPage,
				$skip: this.perPage * (this.currentPage - 1),
				$where: { accountId: this.accountId }
			};
			await this.getAccountMembers({ query: { query } });
		},
		handleMembersTableChange({ currentPage, perPage }) {
			this.currentPage = currentPage;
			this.perPage = perPage;

			this.fetchMembersData();
		},
		async handleRemoveMember(recordId) {
			const isConfirmed = await this.$bvModal.msgBoxConfirm([this.$t('Are you sure to remove this member?')], {
				title: this.$t('Warning!'),
				okVariant: 'danger',
				size: 'md',
				centered: true
			});
			if (!isConfirmed) return;

			try {
				await this.removeAccountMember({ recordId });
				await this.fetchMembersData();

				this.$notify({
					type: 'success',
					title: this.$t('Success'),
					text: this.$t('Member has been removed from the account')
				});
			} catch (error) {
				this.$notify({
					type: 'error',
					title: _.get(error, 'response.data.name', error.name),
					text: _.get(error, 'response.data.message', error.message)
				});
			}
		},
		openMemberModal() {
			this.isMemberModalVisible = true;
		},
		async onHideMemberModal() {
			this.isMemberModalVisible = false;
			await this.fetchMembersData();
		},
		async onSave() {
			try {
				let message = $t('Account settings updated successfully');

				if (this.formData._id) {
					await this.dispatchSubmit(this.updateAccount({ id: this.formData._id, data: this.formData }));
				} else {
					message = $t('Account created successfully');
					const account = await this.dispatchSubmit(this.createAccount(this.formData));
					this.$router.push({
						name: 'accounts.edit',
						params: { id: account._id }
					});
				}
				this.$notify({ type: 'success', text: message });
			} catch (error) {
				this.$notify({
					type: 'error',
					title: _.get(error, 'response.data.name', error.name),
					text: _.get(error, 'response.data.message', error.message)
				});
			}
		}
	}
};
</script>

<style lang="scss">
@import '../../../styles/shared.scss';

.CollectionEdit-Form {
	.attribute-card {
		.card-body {
			padding: 0 1.25rem;
		}
	}
}
</style>
