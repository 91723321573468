export const browse = ({ dispatch }, { path = '', params } = {}) => {
	return dispatch('request', {
		method: 'GET',
		path: `files/browse?path=${encodeURIComponent(path)}`,
		params
	}).then(r => r.data);
};

export const getFilestackUploadSignature = ({ dispatch }, type) =>
	dispatch('request', { method: 'GET', path: `files/getFilestackUploadSignature` }).then(r => r.data);

export const createPimientaFile = ({ dispatch }, data) =>
	dispatch('request', {
		method: 'POST',
		path: `files`,
		payload: data
	}).then(r => r.data);
