var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-releases pt-4"},[(_vm.isLoading)?_c('div',{staticClass:"text-center p-5"},[_c('Loader')],1):_vm._l((_vm.productDeliveryTypes),function(delivery,index){return [_c('b-card',{key:("card-" + delivery),attrs:{"no-body":"","border-variant":"light"}},[_c('b-card-title',{staticClass:"mb-4"},[_c('b-row',{staticClass:"align-items-center"},[_c('b-col',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"text-capitalize mr-2 font-weight-bold"},[_vm._v(_vm._s(delivery))]),_c('span',{staticStyle:{"font-size":"16px"}},[(!!_vm.activeReleasesByDeliveryType[delivery])?_c('OfsBadge',{attrs:{"text":"✔","status":"ready"}}):_c('OfsBadge',{attrs:{"text":"!","status":"warning"}})],1)]),_c('b-col',{staticClass:"text-right"},[_c('b-btn',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.handleNewReleaseBtnClick(delivery)}}},[_vm._v("+ "+_vm._s(_vm.$t('New Release')))])],1)],1)],1),(_vm.releasesByDeliveryType[delivery])?_c('b-card-text',[_c('b-table-simple',{staticClass:"product-releases__table w-100 border-bottom-0",attrs:{"small":"","responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticStyle:{"width":"0"}}),_c('b-th',{staticStyle:{"width":"0"}},[_vm._v(_vm._s(_vm.$t('Number')))]),_c('b-th',[_vm._v(_vm._s(_vm.$t('Release Details')))]),_c('b-th',[_vm._v(_vm._s(_vm.$t('Components')))]),_c('b-th',[_vm._v(_vm._s(_vm.$t('Note')))]),_c('b-th')],1)],1),_c('b-tbody',_vm._l((_vm.releasesByDeliveryType[delivery]),function(release,releaseIndex){return _c('b-tr',{key:releaseIndex},[_c('b-td',[(
										_vm.activeReleasesByDeliveryType[delivery] &&
											_vm.activeReleasesByDeliveryType[delivery]._id === release._id
									)?_c('OfsBadge',{attrs:{"text":"active","status":"ready"}}):_vm._e()],1),_c('b-td',[_vm._v(_vm._s(_vm.releasesByDeliveryType[delivery].length - releaseIndex))]),_c('b-td',[(
										_vm.activeReleasesByDeliveryType[delivery] &&
											_vm.activeReleasesByDeliveryType[delivery]._id === release._id
									)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('Marked as active at'))+": "+_vm._s(_vm.formatDate(release.markedActiveAt))+" ")]):_vm._e(),_c('div',[_vm._v(_vm._s(_vm.$t('Created at'))+": "+_vm._s(_vm.formatDate(release.createdAt)))]),_c('div',[_vm._v("By: "+_vm._s(release.createdBy))])]),_c('b-td',_vm._l((release.files),function(file){return _c('div',{key:file.componentCode},[_c('router-link',{staticClass:"mr-2 d-inline-block",attrs:{"to":{ name: 'filePreview', params: { id: file.fileId } }}},[_c('a',{on:{"click":function($event){return _vm.handleFilePreview($event, file.fileId)}}},[_vm._v(" "+_vm._s(file.componentCode)+" ")])]),_c('span',[_vm._v("("+_vm._s(file.pageCount)+" "+_vm._s(_vm.$t('pages'))+")")])],1)}),0),_c('b-td',[_vm._v(_vm._s(release.note))]),_c('b-td',{staticClass:"text-right"},[_c('b-btn',{attrs:{"variant":"primary","size":"sm","disabled":_vm.activeReleasesByDeliveryType[delivery] &&
											_vm.activeReleasesByDeliveryType[delivery]._id === release._id},on:{"click":function($event){return _vm.handlePromoteClick(release._id)}}},[_vm._v(" "+_vm._s(_vm.$t('make active'))+" ")])],1)],1)}),1)],1)],1):_c('b-card-text',{staticClass:"text-center py-3 px-2"},[_vm._v(_vm._s(_vm.$t('No available releases to display')))])],1),(index !== _vm.productDeliveryTypes.length - 1)?_c('hr',{key:("hr-" + delivery),staticClass:"my-4"}):_vm._e()]}),_c('NewReleaseModal',{attrs:{"is-open":_vm.isNewReleaseModalVisible,"product":_vm.product,"delivery":_vm.selectedDeliveryType,"releases":_vm.releases},on:{"hide":_vm.handleNewReleaseModalHide}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }