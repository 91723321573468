<template>
	<div class="mt-4 LogsTable">
		<h2>{{ title }}</h2>

		<Datatable :fields="logFields" :items="items" class="LogsTable__datatable">
			<template v-slot:cell(level)="{ item }">
				<OfsBadge :text="item.level" :status="item.level" />
			</template>

			<template v-slot:cell(timestamp)="row">
				{{ formatDate(row.item.timestamp) }}
			</template>

			<template v-slot:cell(data)="row">
				<b-button size="sm" class="mr-2" :disabled="!row.item.data" @click="row.toggleDetails">
					{{ row.detailsShowing ? 'Hide' : 'Show' }}
				</b-button>
			</template>

			<template v-slot:row-details="{ item }">
				<b-card class="data-card">
					<pre>{{ JSON.stringify(item.data, null, 2) }}</pre>
				</b-card>
			</template>
		</Datatable>
	</div>
</template>

<script>
import { OfsBadge, Datatable } from '@oneflow/ofs-vue-layout';
import formatDate from '../lib/formatDate';

export default {
	components: {
		Datatable,
		OfsBadge
	},
	props: {
		items: {
			type: Array,
			default: () => []
		},
		title: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			logFields: [
				{ label: this.$t('Level'), key: 'level' },
				{ label: this.$t('Message'), key: 'message' },
				{ label: this.$t('Timestamp'), key: 'timestamp' },
				{ label: this.$t('Data'), key: 'data' }
			]
		};
	},
	methods: {
		formatDate
	}
};
</script>
<style lang="scss" scoped>
.LogsTable {
	&__datatable {
		max-height: 50vh;
	}
}
</style>
