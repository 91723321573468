<template>
	<codemirror :value="value" :options="codeMirrorOptions" class="code-editor" @input="val => $emit('input', val)" />
</template>

<script>
import { codemirror } from 'vue-codemirror';

// editor themes
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/rubyblue.css';

// linting styles
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/selection/active-line';

// linting scripts
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/json-lint';
import 'codemirror/addon/display/autorefresh';

// syntax modes
import 'codemirror/mode/handlebars/handlebars';
import 'codemirror/mode/xml/xml';

// addons
import 'codemirror/addon/display/autorefresh';

const defaultCodeMirrorOptions = {
	tabSize: 4,
	lineNumbers: true,
	line: true,
	theme: 'rubyblue',
	gutters: ['CodeMirror-lint-markers'],
	lint: true,
	autoRefresh: true,
	styleActiveLine: true,
	matchBrackets: true
};

export default {
	components: {
		codemirror
	},
	props: {
		value: {
			type: String,
			default: ''
		},
		mode: {
			type: String,
			default: 'json',
			validate(mode) {
				return ['json', 'html', 'handlebars'].includes(mode);
			}
		},
		options: {
			type: Object,
			default: () => {}
		}
	},
	computed: {
		codeMirrorMode() {
			if (this.mode === 'html') {
				return 'text/html';
			}
			if (this.mode === 'handlebars') {
				return 'text/x-handlebars-template';
			}
			return 'application/json';
		},
		codeMirrorOptions() {
			return {
				...defaultCodeMirrorOptions,
				...this.options,
				mode: this.codeMirrorMode
			};
		}
	}
};
</script>

<style lang="scss">
.vue-codemirror {
	height: 100%;

	.CodeMirror {
		height: 100% !important;
	}
}
</style>
