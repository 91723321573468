<template>
	<div class="product-formats" :class="{ 'product-formats--dragging': isDragging }">
		<draggable
			:list="formData.formats"
			ghost-class="ghost"
			handle=".handle"
			animation="200"
			:force-fallback="true"
			@start="handleDragStart"
			@end="handleDragEnd"
			@change="handleOrderChange"
		>
			<transition-group type="transition" :name="!isDragging ? 'flip-list' : null">
				<div v-for="(format, formatIdx) in formats" :key="formatIdx">
					<b-card class="mb-2 product-formats__format" :class="showFormatValidation(formatIdx) ? 'danger' : ''">
						<template #header>
							<div class="d-flex w-full justify-content-between align-items-center">
								<div>
									<div v-html="formatTitle(formData.formats[formatIdx])"></div>
								</div>
								<div class="handle" :style="{ cursor: isDragging ? 'grabbing' : 'move' }">
									<icon name="bars" />
								</div>
							</div>
						</template>

						<template #default>
							<b-row class="product-formats__format__details">
								<b-col md="6">
									<b-row>
										<b-col md="12">
											<OfFormInput
												:label="$t('Name')"
												:name="`formats[${formatIdx}].name`"
												:disabled="disabled"
												show-errors
												required
											/>
										</b-col>
										<b-col md="12">
											<OfFormSelect
												:label="$t('Format fulfilment')"
												:name="`formats[${formatIdx}].fulfilment`"
												:options="formatFulfilmentOptions"
												:disabled="disabled"
											/>
										</b-col>
										<b-col v-if="isSiteflowSelected(formatIdx)" md="12">
											<OfFormInput
												:label="$t('SKU format')"
												:name="`formats[${formatIdx}].siteflowSKU`"
												:disabled="disabled"
												show-errors
											/>
										</b-col>
										<b-col v-if="isFormatWithExternalSku(formatIdx)" md="12">
											<OfFormInput
												:label="$t('SKU format')"
												:name="`formats[${formatIdx}].externalSku`"
												:disabled="disabled"
												show-errors
											/>
										</b-col>
										<b-col md="12">
											<OfFormSelect
												:label="$t('Format availability')"
												:name="`formats[${formatIdx}].availability`"
												:options="formatAvailabilityOptions"
												:disabled="disabled"
												show-errors
											/>
										</b-col>
										<b-col md="12">
											<OfFormInput
												:label="$t('Tax code')"
												:name="`formats[${formatIdx}].taxCode`"
												:disabled="disabled"
												show-errors
											/>
										</b-col>
										<b-col v-if="productFormats[formatIdx] && !!productFormats[formatIdx]._id" md="12">
											<OfFormInput :label="$t('ID')" :name="`formats[${formatIdx}]._id`" disabled />
										</b-col>
									</b-row>
								</b-col>
								<b-col md="6">
									<b-row v-for="(price, priceIdx) in format.prices" :key="`${formatIdx}-${priceIdx}`" class="mb-1">
										<b-col md="6" class="product-formats__price-input-container">
											<OfFormInput
												:label="`${$t('Price')} (cents)`"
												:name="`formats[${formatIdx}].prices[${priceIdx}].amount`"
												:disabled="disabled"
												show-errors
											>
												<template v-slot:prepend>
													<b-input-group-text>
														{{ getCurrencySymbol(price.region) }}
													</b-input-group-text>
												</template>
												<template v-slot:append>
													<OfFormSelect
														:name="`formats[${formatIdx}].prices[${priceIdx}].region`"
														:options="currencyOptions"
														:disabled="disabled"
														class="h-100"
														without-label
													/>
												</template>
											</OfFormInput>
										</b-col>
										<b-col md="4">
											<OfFormSelect
												:label="$t('Tax Behaviour')"
												:name="`formats[${formatIdx}].prices[${priceIdx}].taxBehaviour`"
												:options="taxBehaviourOptions"
												:disabled="disabled"
												show-errors
											/>
										</b-col>
										<b-col md="2" class="d-flex align-items-center pt-2">
											<b-button variant="danger" :disabled="disabled" @click="removeFormatPrice(formatIdx, priceIdx)">
												{{ $t('Remove') }}
											</b-button>
										</b-col>
									</b-row>
									<b-button variant="primary" :disabled="disabled" @click="addFormatPrice(formatIdx)">
										{{ $t('Add Price') }}
									</b-button>
									<b-alert :show="isFormatPricesInvalid(formatIdx)" variant="danger" class="mt-2">
										{{ $t('You should define at least one price for the product format') }}
									</b-alert>
								</b-col>
							</b-row>
						</template>
					</b-card>
				</div>
			</transition-group>
		</draggable>
		<OfsFeatureButton class="mt-1" :label="$t('Add a format')" type="add" :disabled="disabled" @click="addFormat" />
	</div>
</template>

<script>
import { OfFormInput, OfFormSelect, OfsFeatureButton, validateWithMessage, withForm } from '@oneflow/ofs-vue-layout';
import draggable from 'vuedraggable';
import _ from 'lodash';
import {
	formatAvailabilityOptions,
	productFormatFulfilment,
	defaultFormatData,
	productStatus,
	productFormatAvailability
} from './constants';
import { taxBehaviourOptions } from '../../../lib/constants';
import { required, maxLength } from 'vuelidate/lib/validators';
import { getCurrencySymbol, currencyOptions } from '@/lib/currency';
import axios from 'axios';
import config from '@/config';
import { mapState } from 'vuex';

export const ProductFormatsFormName = 'productEditFormats';

export default {
	components: {
		OfFormInput,
		OfFormSelect,
		OfsFeatureButton,
		draggable
	},

	mixins: [withForm(ProductFormatsFormName)],

	props: {
		productFormats: {
			type: Array,
			default: () => []
		},
		disabled: {
			type: Boolean,
			default: false
		},
		currentProductStatus: {
			type: String,
			default: null
		}
	},

	data() {
		return {
			defaultFormatPrice: { region: 'US', amount: 0, taxBehaviour: 'inclusive' },
			formatAvailabilityOptions,
			currencyOptions,
			taxBehaviourOptions,
			isDragging: false
		};
	},

	computed: {
		...mapState('auth0', ['authToken']),
		validationRules() {
			return {
				formData: {
					formats: {
						required: validateWithMessage(this.$t('You should define at least one format for the product'), required),
						$each: {
							/*
								need to remove Siteflow sku field in the next release
							*/
							siteflowSKU: {
								required: validateWithMessage(this.$t('SKU is required'), (value, obj) => {
									return obj.fulfilment === productFormatFulfilment.print ? !!value : true;
								})
							},
							externalSku: {
								required: validateWithMessage(this.$t('SKU is required'), (value, obj) => {
									return obj.fulfilment === productFormatFulfilment.rpi ? !!value : true;
								})
							},
							name: {
								maxLength: validateWithMessage(this.$t('maxLengthValidation', { count: 20 }), maxLength(20))
							},
							prices: {
								required: validateWithMessage(
									this.$t('You should define at least one price for the product format'),
									required
								),
								$each: {
									amount: {
										required: validateWithMessage(this.$t('Format price amount is required'), required),
										uniqCurrency: validateWithMessage(
											this.$t('Currencies must not be duplicated'),
											(_amount, price) => {
												const formatIdx = _.findIndex(this.formData.formats, format => format.prices.includes(price));
												const prices = _.get(this.formData.formats[formatIdx], 'prices', []);
												return _.filter(prices, ['region', price.region]).length === 1;
											}
										)
									}
								}
							},
							availability:
								/* eslint-disable */
								this.currentProductStatus === productStatus.Published
									? {
											atLeastOne: validateWithMessage(
												this.$t('Should be at least one availble status for published product'),
												() => {
													const formats = _.get(this.formData, 'formats');
													return !_.every(formats, ['availability', productFormatAvailability.unavailable]);
												}
											)
									  }
									: {}
							/* eslint-enable */
						}
					}
				}
			};
		},
		formats() {
			return _.get(this.formData, 'formats', []);
		},
		formatFulfilmentOptions() {
			return [
				{ text: this.$t('SiteFlow'), value: productFormatFulfilment.print },
				{ text: this.$t('RPI UOS'), value: productFormatFulfilment.rpi },
				{
					text: this.$t('Digital File'),
					value: productFormatFulfilment.digital
				},
				{
					text: this.$t('ShipStation'),
					value: productFormatFulfilment.shipStation
				}
			];
		}
	},

	watch: {
		productFormats: {
			deep: true,
			handler() {
				this.initialize();
			}
		}
	},

	async mounted() {
		this.initialize();
	},

	methods: {
		getCurrencySymbol,
		initialize() {
			this.initFormData({ formats: [...this.productFormats] });
		},
		addFormat() {
			this.updateField('formats', [...this.formData?.formats, defaultFormatData]);
		},
		addFormatPrice(formatIdx) {
			const formatPrices = _.get(this.formData, `formats[${formatIdx}].prices`, []);
			this.updateField(`formats[${formatIdx}].prices`, [...formatPrices, this.defaultFormatPrice]);
		},
		removeFormatPrice(formatIdx, priceIdx) {
			const formatPrices = _.get(this.formData, `formats[${formatIdx}].prices`, []);
			const prices = _.filter(formatPrices, (_, ind) => ind !== priceIdx);
			this.updateField(`formats[${formatIdx}].prices`, prices);
		},
		isFormatPricesInvalid(ind) {
			return !_.get(this.$v, `formData.formats.$each.${ind}.prices.required`);
		},
		showFormatValidation(ind) {
			return this.isFormatPricesInvalid(ind);
		},
		isSiteflowSelected(formatIdx) {
			return _.get(this.formData, `formats[${formatIdx}].fulfilment`) === productFormatFulfilment.print;
		},
		isFormatWithExternalSku(formatIdx) {
			const fulfilment = _.get(this.formData, `formats[${formatIdx}].fulfilment`);

			return [productFormatFulfilment.rpi, productFormatFulfilment.shipStation].includes(fulfilment);
		},
		formatTitle(format) {
			if (!format.name) return '_';

			const primaryPart = _.compact([format.name, format.fulfilment]).join(' - ');

			const secondaryPart = _.compact([
				format.prices?.length ? `(${_.map(format.prices, 'region').join(', ')})` : '',
				format.availability === 'available' ? '' : this.$t('Unavailable')
			]).join(' - ');

			return `${primaryPart}<span class="font-weight-normal ml-1">${secondaryPart}</span>`;
		},
		handleDragStart() {
			this.isDragging = true;
		},
		handleDragEnd() {
			setTimeout(() => {
				this.isDragging = false;
			}, 800);
		},
		handleOrderChange() {
			// trigger the change detection
			this.updateFormData({
				formats: [...this.formData.formats]
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.product-formats {
	&__format {
		&.danger {
			border: 1px solid red;
		}
	}

	&__price-input-container {
		.form-group {
			margin-bottom: 0;
		}
	}
}
</style>

<style lang="scss">
.product-formats--dragging {
	.card-body {
		display: none;
	}
}
</style>
