var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"dialog-class":"file-previewer-modal","title":_vm.title,"visible":_vm.visible},on:{"hidden":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.emitEvent('hidden', args);
}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var hide = ref.hide;
return [_c('b-button',{on:{"click":hide}},[_vm._v(_vm._s(_vm.$t('Close')))])]}}])},[(_vm.isLoading)?_c('div',{staticClass:"p-5 text-center"},[_vm._v(_vm._s(_vm.$t('Loading...')))]):(_vm.error)?_c('div',{staticClass:"p-5 text-center"},[_c('div',[_vm._v(_vm._s(_vm.$t('An error happened while loading the file:')))]),_vm._v(" "+_vm._s(_vm.error.message)+" ")]):_c('embed',{attrs:{"src":_vm.pdfUrl}})])}
var staticRenderFns = []

export { render, staticRenderFns }