import Login from './Login';

export const routes = [
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: { layout: 'div' }
	},
	{
		path: '/logout',
		name: 'logout',
		component: Login,
		props: { isLogout: true },
		meta: { layout: 'div' }
	}
];

export default routes;
