<template>
	<OfsPanel>
		<ListTable
			bordered
			hover
			:table-title="$t('Templates')"
			:config="config"
			:items="templates.data"
			:fields="fields"
			:sort="sort"
			:total-items="templates.total"
			:current-page="currentPage"
			:per-page="perPage"
			:fetch-data="fetchData"
			:is-busy="isLoading"
			@row-clicked="handleRowClick"
			@table-change="handleTableChange"
		>
			<template slot="TableButtons-Slot-left" slot-scope="{}">
				<OfFormInput
					:value="searchString"
					class="col-6 mr-2"
					:placeholder="$t('Search by template name')"
					@input="handleSearchStringInput"
				/>

				<OfInlineFilter :filters="filters" :values="filterValues" @change="filtersChanged" />
			</template>

			<template slot="TableButtons-Slot-right" slot-scope="{}">
				<b-button variant="primary" class="ml-2" @click="() => (isModalVisible = true)">{{
					$t('Add Template')
				}}</b-button>
			</template>

			<template slot="TableHeader" slot-scope="{}">
				<OfFilterBar :filters="filters" :values="filterValues" @change="filtersChanged" />
			</template>

			<template v-slot:cell(preview)="{ item }">
				<Thumbnail :src="item.previewUrl" size="50" />
			</template>

			<template v-slot:cell(size)="{ item }">
				{{ _get(item, 'sizes.media.width', '-') }}x{{ _get(item, 'sizes.media.height', '-') }}
			</template>

			<template v-slot:cell(active)="{ item }">
				<b-badge v-if="item.active" variant="primary">{{ $t('True') }}</b-badge>
				<b-badge v-else variant="dark">{{ $t('False') }}</b-badge>
			</template>
		</ListTable>

		<TemplateModal :is-visible="isModalVisible" @create="createNewTemplate" @close="() => (isModalVisible = false)" />
	</OfsPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfsPanel, ListTable, OfInlineFilter, OfFilterBar, OfFormInput } from '@oneflow/ofs-vue-layout';
import Thumbnail from '../../../components/Thumbnail';
import { templateFormatOptions } from '@/lib/constants';
import TemplateModal from '../../../components/TemplateModal';
import withQuerystringState from '@/mixins/withQuerystringState';
import { parseDefaultQuerystringFilterValues } from '@/lib/parseDefaultQuerystringFilterValues';

const config = {
	refresh: { visible: true }
};

export default {
	components: {
		OfsPanel,
		ListTable,
		OfInlineFilter,
		OfFilterBar,
		Thumbnail,
		TemplateModal,
		OfFormInput
	},
	mixins: [
		withQuerystringState([
			{ name: 'currentPage', defaultValue: 1, parseValue: Number },
			{ name: 'perPage', defaultValue: 10, parseValue: Number },
			'searchString',
			{ name: 'sort', defaultValue: {}, parseValue: value => value ?? {} },
			{ name: 'filterValues', parseValue: parseDefaultQuerystringFilterValues }
		])
	],
	data() {
		const fields = [
			{ key: 'preview', label: this.$t('Preview') },
			{ key: 'name', label: this.$t('Name'), sortable: true },
			{ key: 'format', label: this.$t('Format'), sortable: true },
			{ key: 'size', label: this.$t('Media size (mm)') },
			{ key: 'account.displayName', label: this.$t('Account'), sortable: false },
			{ key: 'active', label: this.$t('Status'), sortable: true }
		];

		return {
			isModalVisible: false,
			isLoading: false,
			fields,
			config
		};
	},
	computed: {
		...mapGetters({
			templates: 'template/templates',
			accounts: 'account/accounts'
		}),
		filters() {
			return [
				{
					header: this.$t('Accounts'),
					key: 'accountId',
					type: 'checkbox',
					items: _.map(this.accounts?.data ?? [], ({ authAccountId, displayName }) => ({
						value: authAccountId,
						title: displayName
					}))
				},
				{
					header: this.$t('Status'),
					key: 'active',
					type: 'radio',
					items: [
						{ title: this.$t('Active'), value: true },
						{ title: this.$t('Inactive'), value: false }
					]
				},
				{
					header: this.$t('Format'),
					key: 'format',
					type: 'radio',
					items: templateFormatOptions
				}
			];
		}
	},
	async mounted() {
		await this.fetchData();
	},
	methods: {
		_get: _.get,
		...mapActions({
			getTemplates: 'template/find',
			fetchAccounts: 'account/find'
		}),
		async fetchData() {
			try {
				this.isLoading = true;
				const query = {
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1),
					$where: { type: { $ne: 'email' } },
					$populate: { path: 'account', select: { displayName: 1 } },
					$select: ['name', 'format', 'size', 'accountId', 'active']
				};

				if (!_.isEmpty(this.filterValues)) {
					query.$where = _.reduce(
						this.filterValues,
						($where, value, key) => ({
							...$where,
							[key]: Array.isArray(value) ? { $in: value } : value
						}),
						{}
					);
				}

				if (this.searchString) {
					query.$where = query.$where ?? {};
					query.$where.$and = [{ name: { $regex: this.searchString, $options: 'i' } }];
				}

				query.$sort = !_.isEmpty(this.sort) ? this.sort : { name: 1 };

				await this.getTemplates({ query: { query } });
				await this.fetchAccounts({ query: { query: { $limit: 1000, $sort: { displayName: 1 } } } });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching templates')
				});

				throw err;
			} finally {
				this.isLoading = false;
			}
		},
		filtersChanged(filters) {
			this.filterValues = filters;
			this.currentPage = 1;
			this.fetchData();
		},
		handleTableChange({ currentPage, perPage, filter, sort }) {
			this.currentPage = currentPage;
			this.perPage = perPage;

			if (filter !== undefined) {
				this.filter = filter;
			}
			if (sort) {
				this.sort = sort;
			}
		},
		handleRowClick(item, index, event) {
			if (event.target.type === 'button') return;
			this.$router.push({ name: 'templates.edit', params: { id: item._id } });
		},
		createNewTemplate(layoutId) {
			this.isModalVisible = false;
			const query = layoutId ? { id: layoutId } : {};
			this.$router.push({
				name: 'templates.edit',
				params: { id: `new` },
				query
			});
		},
		handleSearchStringInput: _.debounce(function(value) {
			this.searchString = value;
			this.currentPage = 1;
			this.fetchData();
		}, 250)
	}
};
</script>
