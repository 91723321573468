import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'recommendation-rule',
		transport: 'davinci',
		options: {
			namespaced: true
		}
	});
}
