import Vue from 'vue';
import * as actions from './actions';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'auth0',
		state: {
			isAuthenticated: false,
			loading: true,
			user: null,
			auth0Client: null,
			error: null,
			authToken: null,
			permissions: [],
			signedOut: false
		},
		getters: {
			user: state => state.user,
			userPermissions: state => state.permissions,
			isLoading: state => state.loading,
			email: state => state?.user?.email,
			isAuthenticated: state => state?.isAuthenticated,
			isAdmin: state => state.permissions.includes('pimienta-admin')
		},
		actions,
		mutations: {
			SET_AUTH0_STATE: (state, data) => {
				state.auth0Client = data.auth0Client;
				state.error = data.error;
				state.user = data.user;
				state.authToken = data.authToken;
				state.permissions = data.permissions;
				state.isAuthenticated = data.isAuthenticated;
				state.loading = data.loading;
			},
			LOG_OUT: state => {
				state.isAuthenticated = false;
				state.loading = true;
				state.user = null;
				state.auth0Client = null;
				state.error = null;
				state.authToken = null;
				state.permissions = [];
				state.signedOut = true;
			}
		}
	});
}
