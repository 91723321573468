const $t = str => str;

const menuStructure = [
	{
		title: $t('Create'),
		matches: [
			{ name: 'products(.+)' },
			{ name: '^templates(.+)' },
			{ name: 'filePreview' }
			// { name: 'assets(.+)' }
		],
		items: [
			{
				title: $t('Products'),
				items: [
					{
						title: 'All Products',
						name: 'products.list',
						matches: [{ name: 'products.edit' }, { name: 'products.add' }]
					},
					{ title: $t('Templates'), name: 'templates.list' }
				]
			}
		]
	},
	{
		title: $t('Sell'),
		matches: [
			// { name: 'dashboard' },
			// { name: 'reports' },
			{ name: 'orders(.+)' },
			{ name: 'payout-codes(.+)' },
			{ name: 'short-links(.+)' },
			{ name: 'coupons(.+)' },
			{ name: 'email-templates.list' }
		],
		items: [
			// {
			// 	title: $t('Analytics'),
			// 	items: [
			// 		{ title: $t('Dashboard'), name: 'dashboard' },
			// 		{ title: $t('Reports'), name: 'reports' }
			// 	]
			// },
			{
				title: $t('Commerce'),
				items: [
					{
						title: $t('Orders'),
						name: 'orders.list',
						matches: [{ name: 'orders.details' }]
					},
					{ title: $t('Pre-orders'), name: 'pre-orders.list' },
					{
						title: $t('Payout Codes'),
						name: 'payout-codes.list',
						matches: [{ name: 'payout-codes.edit' }]
					},
					{
						title: $t('Coupons'),
						name: 'coupons.list',
						matches: [{ name: 'coupons.edit' }, { name: 'coupons.add' }]
					}
				]
			}
		]
	},
	// {
	// 	title: $t('Settings'),
	// 	matches: [
	// 		{ name: 'users(.+)' },
	// 		{ name: 'account(.+)' }
	// 	],
	// 	items: [
	// 		{
	// 			title: $t('Configuration'),
	// 			items: [
	// 				{ title: $t('Manage users'), name: 'users.list' },
	// 				{ title: $t('Account settings'), name: 'account.settings' }
	// 			]
	// 		}
	// 	]
	// },
	{
		title: $t('Admin'),
		matches: [
			{ name: 'specification(.+)' },
			{ name: 'templates(.+)' },
			{ name: 'accounts(.+)' },
			{ name: 'creators(.+)' },
			{ name: 'dataTypes(.+)' },
			{ name: 'users(.+)' }
		],
		items: [
			{
				title: $t('Data Types'),
				items: [
					{
						title: $t('Data Types'),
						name: 'dataTypes.list',
						matches: [{ name: 'dataTypes.edit' }]
					}
				]
			},
			{
				title: $t('Master Layouts'),
				items: [{ title: $t('Layouts'), name: 'layouts.list' }]
			},
			{
				title: $t('Integrations'),
				items: [{ title: $t('Shipping Methods'), name: 'shippingMethods.list' }]
			},
			{
				title: $t('User management'),
				items: [
					{
						title: $t('Admin users'),
						name: 'admin-users.list'
					},
					{
						title: $t('Creator users'),
						name: 'creator-users.list'
					},
					{
						title: $t('Accounts'),
						name: 'accounts.list',
						matches: [{ name: 'accounts.edit' }]
					},
					{
						title: $t('Creator profiles'),
						name: 'creators.list',
						matches: [{ name: 'creators.edit' }]
					}
				]
			},
			{
				title: $t('Promotions'),
				items: [
					{
						title: $t('Product recommendations'),
						name: 'recommendationRules.list'
					}
				]
			}
		]
	}
];

export default menuStructure;
