var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('OfsPanel',[_c('ListTable',{attrs:{"bordered":"","hover":"","table-title":_vm.$t('Recommendation rules'),"config":_vm.config,"items":_vm.recommendationRules.data,"fields":_vm.fields,"total-items":_vm.recommendationRules.total,"current-page":_vm.currentPage,"per-page":_vm.perPage,"fetch-data":_vm.fetchData,"is-busy":_vm.isLoading},on:{"row-clicked":_vm.handleRowClick,"table-change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"TableButtons-Slot-left",fn:function(ref){return [_c('OfInlineFilter',{attrs:{"filters":_vm.filters,"values":_vm.filterValues},on:{"change":_vm.filtersChanged}})]}},{key:"TableHeader",fn:function(ref){return [_c('OfFilterBar',{attrs:{"filters":_vm.filters,"values":_vm.filterValues},on:{"change":_vm.filtersChanged}})]}},{key:"TableButtons-Slot-right",fn:function(ref){return [_c('b-button',{staticClass:"ml-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.openEditModal()}}},[_vm._v(" "+_vm._s(_vm.$t('New promotion'))+" ")])]}},{key:"cell(promoProducts)",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.getPromoProductIds(item)),function(productId,index){return _c('span',{key:productId},[_c('router-link',{attrs:{"to":_vm.getProductEditLink(productId),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm._get(_vm.promoProductsMap, ("[" + productId + "].name"), productId))+" ")]),(index < _vm.getPromoProductIds(item).length - 1)?[_vm._v(",")]:_vm._e()],2)})}},{key:"cell(active)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('of-toggle',{staticClass:"ml-2",attrs:{"value":item.active},on:{"input":function($event){return _vm.toggleActive(item)}}})],1)]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('b-button',{staticClass:"mr-1",attrs:{"disabled":!_vm.canChangePriority(_vm.DIRECTION.UP, index)},on:{"click":function($event){return _vm.changePriority(_vm.DIRECTION.UP, item, index)}}},[_c('icon',{attrs:{"name":"arrow-up"}})],1),_c('b-button',{staticClass:"mr-1",attrs:{"disabled":!_vm.canChangePriority(_vm.DIRECTION.DOWN, index)},on:{"click":function($event){return _vm.changePriority(_vm.DIRECTION.DOWN, item, index)}}},[_c('icon',{attrs:{"name":"arrow-down"}})],1)]}}])}),(_vm.isEditModalOpened)?_c('RecommendationRuleModal',{attrs:{"recommendation-rule-id":_vm.recommendationRuleId},on:{"close":_vm.closeEditModal,"ok":_vm.saveEditModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }