import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'user',
		transport: 'davinci',
		options: { namespaced: true },
		actions: {
			getUserRoles({ dispatch }, { userId }) {
				return dispatch('request', { method: 'GET', path: `users/${userId}/roles` }).then(r => r.data);
			},
			assignUserRoles({ dispatch }, { userId, roles }) {
				return dispatch('request', { method: 'PUT', path: `users/${userId}/roles`, data: { roles } }).then(r => r.data);
			},
			getUserMemberships({ dispatch }, { userId, query }) {
				return dispatch('request', {
					method: 'GET',
					path: `users/${userId}/memberships`,
					query
				}).then(r => r.data);
			}
		}
	});
}
