import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'shipping-method',
		pluralName: 'shipping-methods',
		transport: 'davinci',
		options: {
			namespaced: true
		}
	});
}
