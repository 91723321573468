<template>
	<OfsPanel>
		<ListTable
			bordered
			hover
			:table-title="$t('Products')"
			:config="config"
			:items="productsList"
			:fields="fields"
			:sort="sort"
			:total-items="products.total"
			:current-page="currentPage"
			:per-page="perPage"
			:fetch-data="fetchData"
			:is-busy="isLoading"
			@row-clicked="handleRowClick"
			@table-change="handleTableChange"
		>
			<template slot="TableButtons-Slot-left" slot-scope="{}">
				<OfFormInput
					:value="searchString"
					class="col-6 mr-2"
					:placeholder="$t('Search by product name')"
					@input="handleSearchStringInput"
				/>

				<OfInlineFilter :filters="filters" :values="filterValues" @change="filtersChanged" />
			</template>

			<template slot="TableButtons-Slot-right" slot-scope="{}">
				<b-dropdown variant="primary" :text="$t('New product')" class="PresetDropdown ml-2" right no-caret>
					<b-dropdown-item @click="handleAdd">
						{{ $t('Custom Product') }}
					</b-dropdown-item>
					<b-dropdown-divider />
					<b-dropdown-text class="dropdown-label">
						{{ $t('Suggested') }}
					</b-dropdown-text>
					<b-dropdown-item
						v-for="preset in productPresets"
						:key="preset.value"
						class="PresetDropdown-Item"
						@click="handleAdd(preset.value)"
					>
						{{ preset.name }} <b-badge variant="primary" class="ml-2">{{ preset.label }}</b-badge>
					</b-dropdown-item>
				</b-dropdown>
			</template>

			<template slot="TableHeader" slot-scope="{}">
				<OfFilterBar :filters="filters" :values="filterValues" @change="filtersChanged" />
			</template>

			<template v-slot:cell(thumbnail)="{ item }">
				<Thumbnail :src="_get(item, 'previewImages[0].url')" />
			</template>

			<template v-slot:cell(name)="{ item }">
				{{ item.name }}
			</template>

			<template v-slot:cell(mode)="{ item }">
				<OfsBadge v-if="item.mode" :text="item.mode" status="started" />
			</template>
			<template v-slot:cell(account)="{ item }">
				<template v-if="_get(item, 'account.name')">{{ item.account.name }}</template>
				<template v-else>{{ item.accountId }}</template>
			</template>

			<template v-slot:cell(format)="{ item }">
				{{ getProductFormats(item) }}
			</template>

			<template v-slot:cell(updatedAt)="{ item }">
				{{ fromNow(item.updatedAt) }}
			</template>

			<template v-slot:cell(status)="{ item }">
				<OfsBadge v-if="item.status" :text="item.status" status="ready" />
			</template>

			<template v-slot:cell(availability)="{ item }">
				<OfsBadge v-if="item.status" :text="item.availability" status="started" />
			</template>

			<template v-slot:cell(actions)="{ item }">
				<b-dropdown size="sm" toggle-class="p-1" right no-caret :disabled="isLoading">
					<template #button-content>
						<icon name="ellipsis-h" />
					</template>
					<b-dropdown-item @click.stop="removeProduct(item._id)">
						<icon name="trash" class="mr-2" /> {{ $t('Delete') }}
					</b-dropdown-item>
				</b-dropdown>
			</template>
		</ListTable>
	</OfsPanel>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { OfsPanel, ListTable, OfInlineFilter, OfFilterBar, OfsBadge, OfFormInput } from '@oneflow/ofs-vue-layout';
import Thumbnail from '@/components/Thumbnail';
import { productFormats } from './constants';
import withQuerystringState from '@/mixins/withQuerystringState';
import {
	parseDefaultQuerystringFilterValues,
	parseQuerystringBoolean
} from '@/lib/parseDefaultQuerystringFilterValues';

const config = {
	refresh: { visible: true }
};

export default {
	components: {
		OfsPanel,
		ListTable,
		OfInlineFilter,
		OfFilterBar,
		Thumbnail,
		OfsBadge,
		OfFormInput
	},
	mixins: [
		withQuerystringState([
			{ name: 'currentPage', defaultValue: 1, parseValue: Number },
			{ name: 'perPage', defaultValue: 10, parseValue: Number },
			'searchString',
			{ name: 'sort', defaultValue: { updatedAt: -1 }, parseValue: value => value ?? {} },
			{
				name: 'filterValues',
				parseValue: filterValues => ({
					...parseQuerystringBoolean('sharings.linkedAccountId', filterValues),
					...parseDefaultQuerystringFilterValues(filterValues)
				})
			}
		])
	],
	data() {
		const fields = [
			{ key: 'thumbnail', label: '' },
			{ key: 'name', label: this.$t('Name'), sortable: true },
			{ key: 'mode', label: '', sortable: false },
			{ key: 'account', label: this.$t('Account'), sortable: false },
			{ key: 'format', label: this.$t('Format') },
			{ key: 'updatedAt', label: this.$t('Last modified'), sortable: true },
			{ key: 'status', label: this.$t('Status'), sortable: true },
			{ key: 'availability', label: this.$t('Availability'), sortable: true },
			{ key: 'actions', label: this.$t('Actions'), sortable: false }
		];

		return {
			fields,
			config,
			productFormats,
			isLoading: false
		};
	},
	computed: {
		...mapGetters({
			products: 'product/products',
			productSpecifications: 'product/specifications',
			accounts: 'account/accounts'
		}),
		productPresets() {
			return _.map(this.productSpecifications, ({ name, label }, value) => ({ name, label, value }));
		},
		productsList() {
			return this.products.data;
		},
		filters() {
			return [
				{
					header: this.$t('Accounts'),
					key: 'accountId',
					type: 'checkbox',
					items: _.map(this.accounts?.data ?? [], ({ authAccountId, displayName }) => ({
						value: authAccountId,
						title: displayName
					}))
				},
				{
					header: this.$t('Status'),
					key: 'status',
					type: 'radio',
					items: [
						{ title: this.$t('Published'), value: 'published' },
						{ title: this.$t('Approved'), value: 'approved' },
						{ title: this.$t('Pending approval'), value: 'pending approval' },
						{ title: this.$t('Inactive'), value: 'inactive' }
					]
				},
				{
					header: this.$t('Availability'),
					key: 'availability',
					type: 'radio',
					items: [
						{ title: this.$t('Available'), value: 'available' },
						{ title: this.$t('Unavailable'), value: 'unavailable' },
						{ title: this.$t('Pre-order'), value: 'pre-order' }
					]
				},
				{
					header: this.$t('Product owner'),
					key: 'sharings.linkedAccountId',
					type: 'radio',
					items: [
						{ title: this.$t('Owned'), value: false },
						{ title: this.$t('Shared with me'), value: true }
					]
				}
			];
		}
	},
	async mounted() {
		await this.fetchData();
		await this.fetchAccounts({ query: { query: { $limit: 1000, $sort: { displayName: 1 } } } });
		await this.findProductSpecifications();
	},
	methods: {
		_get: _.get,
		...mapActions({
			getProducts: 'product/find',
			deleteProductById: 'product/deleteById',
			findProductSpecifications: 'product/findSpecifications',
			createProduct: 'product/createProduct',
			fetchAccounts: 'account/find'
		}),
		fromNow(date) {
			return moment(date).fromNow();
		},
		getProductFormats(product) {
			const formats = _.get(product, 'formats', []);
			const formatsCount = _.size(formats);

			if (formatsCount === 0) return '-';
			if (formatsCount === 1) return formats[0]?.name;
			return this.$t('Multiple');
		},
		async fetchData() {
			try {
				this.isLoading = true;
				const query = {
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1),
					$populate: { path: 'account' }
				};

				if (!_.isEmpty(this.filterValues)) {
					query.$where = _.reduce(
						this.filterValues,
						($where, value, key) => ({
							...$where,
							[key]: Array.isArray(value) ? { $in: value } : value
						}),
						{}
					);
				}

				if (this.searchString) {
					query.$where = query.$where ?? {};
					query.$where.$and = [{ name: { $regex: this.searchString, $options: 'i' } }];
				}

				query.$sort = !_.isEmpty(this.sort) ? this.sort : { name: 1 };
				await this.getProducts({ query: { query } });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching products')
				});

				throw err;
			} finally {
				this.isLoading = false;
			}
		},
		filtersChanged(filters) {
			this.filterValues = filters;
			this.currentPage = 1;
			this.fetchData();
		},
		handleTableChange({ currentPage, perPage, filter, sort }) {
			this.currentPage = currentPage;
			this.perPage = perPage;

			if (filter !== undefined) {
				this.filter = filter;
			}
			if (sort) {
				this.sort = sort;
			}
		},
		handleRowClick(item, index, event) {
			if (event.target.type === 'button') return;
			this.$router.push({ name: 'products.edit', params: { id: item._id } });
		},
		handleAdd(presetCode) {
			if (this.productSpecifications[presetCode]) {
				this.$router.push({ name: 'products.add', params: { id: 'new', preset: presetCode } });
			} else {
				this.$router.push({ name: 'products.edit', params: { id: 'new' } });
			}
		},
		async confirmProductRemove() {
			return this.$bvModal.msgBoxConfirm([this.$t('Are you sure to delete this product?')], {
				title: this.$t('Warning!'),
				okVariant: 'danger',
				size: 'md',
				centered: true
			});
		},
		async removeProduct(productId) {
			const approved = await this.confirmProductRemove();
			if (approved) {
				await this.deleteProductById({ id: productId });
				this.fetchData();
			}
		},
		handleSearchStringInput: _.debounce(function(value) {
			this.searchString = value;
			this.currentPage = 1;
			this.fetchData();
		}, 250)
	}
};
</script>

<style lang="scss">
@import 'src/styles/shared';

.PresetDropdown {
	&-Item a {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.dropdown-label .b-dropdown-text {
		@include ofTextStyleFormLabels();

		font-weight: $of-font-weight-bold;
		text-transform: uppercase;
	}
}

.Loader {
	position: absolute;
	left: 50%;
	top: 50%;
}
</style>
