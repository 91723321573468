import currency from './currency.json';
import { countryOptions } from '@/lib/country';

const allowedCurrency = ['USD', 'EUR', 'GBP', 'CAD'];
if (_.some(allowedCurrency, code => !currency[code])) {
	throw new Error('Some of allowed ISO currency codes are invalid!');
}

export const getCurrencySymbol = code => {
	const symbol =_.get(currency, `${code}.symbol`, '')
	if (symbol) {
		return symbol;
	}
	const country = countryOptions.find(country => country.value === code);
	return _.get(currency, `${country.currency}.symbol`, '');
};

export const currencyOptions = countryOptions.map(country => {
	return { ...country, region: country.value };
});
