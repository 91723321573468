<template>
	<div class="FilePreview d-flex flex-grow-1 align-items-center justify-content-center">
		{{ $t('Loading file preview...') }}
	</div>
</template>

<script>
export default {
	async created() {
		const fileId = this.$route.params.id;
		await this.$showFilePreviewer(fileId);

		if (window.history.length > 1) {
			return this.$router.back();
		}

		window.close();
	}
};
</script>

<style lang="scss" scoped>
.FilePreview {
	min-height: 500px;
}
</style>
