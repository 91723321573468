<template>
	<div>
		<b-alert v-for="(error, index) in validationErrors" :key="index" show variant="danger" class="mt-2 mb-1">
			<icon class="mr-2" name="exclamation-triangle" />
			{{ error.errorMessage }}
		</b-alert>
	</div>
</template>
<script>
export default {
	props: {
		validationErrors: {
			type: Array,
			default: () => []
		}
	}
};
</script>
