import countries from './countries';

const allowedCountries = [
	{
		code: 'US',
		currency: 'USD'
	},
	{
		code: 'GB',
		currency: 'GBP'
	},
	{
		code: 'CA',
		currency: 'CAD'
	},
	{
		code: 'LATAM',
		currency: 'USD'
	},
	{
		code: 'EU',
		currency: 'EUR'
	},
	{
		code: 'GO',
		currency: 'USD'
	}
];

if (allowedCountries.some(country => !countries[country.code])) {
	throw new Error('Some of allowed ISO country codes are invalid!');
}

export const countryOptions = allowedCountries.map(country => ({
	text: countries[country.code],
	value: country.code,
	currency: country.currency
}));

export const regionToCurrency = region => {
	return allowedCountries.find(el => el.code === region)?.currency || '';
};
