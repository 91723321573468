import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'product-release',
		pluralName: 'product-releases',
		transport: 'davinci',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data',
					countProp: 'count'
				}
			}
		},
		actions: {
			promote({ dispatch }, releaseId) {
				return dispatch('request', {
					method: 'POST',
					path: `product-releases/${releaseId}/promote`
				}).then(r => r.data);
			},
			startRenderComponentJob: async ({ dispatch }, { productId, componentCode, delivery }) => {
				return dispatch('request', {
					method: 'POST',
					path: `product-releases/render-component`,
					payload: { productId, componentCode, delivery }
				}).then(response => response.data);
			}
		}
	});
}
