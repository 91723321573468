export class PdfjsService {
	static async loadPdf(url) {
		return await this.initPdfjs(url);
	}

	/**
	 *
	 * @param {string} url
	 * @param {(loadingInfo: {loaded: number, total: number}) => void} onProgress
	 * @returns {Promise<number>}
	 */
	static async getPdfPageCount(url, onProgress) {
		const pdfJs = await this.initPdfjs();
		const task = await pdfJs.getDocument(url);
		task.onProgress = onProgress;
		const pdf = await task.promise;

		return pdf.numPages;
	}

	static async initPdfjs() {
		const pdfJs = await import('pdfjs-dist');
		pdfJs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.js`;

		return pdfJs;
	}
}
