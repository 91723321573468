<template>
	<div>
		<OfsPanel>
			<ListTable
				bordered
				hover
				table-title="Orders"
				:config="config"
				:items="orders.data"
				:fields="fields"
				:filter="filter"
				:sort="sort"
				:selected="selected"
				:total-items="orders.total"
				:current-page="currentPage"
				:per-page="perPage"
				:fetch-data="fetchData"
				:is-busy="isLoading"
				@row-clicked="handleRowClick"
				@table-change="handleTableChange"
			>
				<template slot="TableButtons-Slot-left" slot-scope="{}">
					<OfFormInput
						:value="searchString"
						class="col-6 mr-2"
						:placeholder="$t('Search by order ID or customer')"
						@input="handleSearchStringInput"
					/>
					<OfInlineFilter :filters="filters" :values="filterValues" @change="filtersChanged" />
				</template>
				<template slot="TableHeader" slot-scope="{}">
					<OfFilterBar :filters="filters" :values="filterValues" @change="filtersChanged" />
				</template>

				<template #cell(status)="{ item }">
					<OfsBadge v-if="item.status" :text="item.status" :status="getItemStatus(item.status)" />
				</template>

				<template #cell(customer)="{ item }">
					<div v-b-tooltip.hover.bottom class="orders-overflown-text" :title="_get(item, 'shipping.name', '')">
						{{ _get(item, 'shipping.name', '') }}
					</div>
				</template>

				<template #cell(shippingMethodId)="{ item }">
					{{ getShippingType(item) }}
				</template>

				<template #cell(type)="{ item }">
					{{ _get(orderIntentType[item.intent], '-') }}
				</template>

				<template #cell(quantity)="{ item }">
					{{ item.lineItems.reduce((acc, el) => el.quantity + acc, 0) }}
				</template>

				<template #cell(totalPrice)="{ item }">
					{{ getOrderTotalPrice(item) }}
				</template>

				<template #cell(createdAt)="{ item }">
					{{ formatDate(item.createdAt) }}
				</template>

				<template #cell(payoutCode)="{ item }">
					{{ _get(item, 'payoutCode.code') }}
				</template>
				<template #cell(shippingPrice)="{ item }">
					{{ getShippingPrice(item) }}
				</template>

				<template #cell(actions)="{ item }">
					<b-button size="sm" :href="`/#/orders/${item._id}`">{{ $t('View') }}</b-button>
				</template>
			</ListTable>
		</OfsPanel>
	</div>
</template>

<script>
import _ from 'lodash';
import {
	OfsPanel,
	ListTable,
	OfInlineFilter,
	OfFilterBar,
	OfsBadge,
	OfFormCheckbox,
	OfFormInput
} from '@oneflow/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import formatDate from '@/lib/formatDate';
import formatCents from '@/lib/formatCents';
import { orderStatus, orderStatusOptions, orderIntentOptions } from '@/lib/constants';
import { countryOptions } from '@/lib/country';
import withQuerystringState from '@/mixins/withQuerystringState';
import { parseDefaultQuerystringFilterValues } from '@/lib/parseDefaultQuerystringFilterValues';

const config = {
	refresh: { visible: true },
	columns: { visible: true }
};

export default {
	components: {
		OfsPanel,
		ListTable,
		OfInlineFilter,
		OfFilterBar,
		OfsBadge,
		OfFormInput
	},
	mixins: [
		withQuerystringState([
			{ name: 'currentPage', defaultValue: 1, parseValue: Number },
			{ name: 'perPage', defaultValue: 10, parseValue: Number },
			'searchString',
			{ name: 'sort', defaultValue: {}, parseValue: value => value ?? {} },
			{ name: 'filterValues', parseValue: parseDefaultQuerystringFilterValues }
		])
	],
	data() {
		const fields = [
			{ key: 'isSelected', label: this.$t('') },
			{ key: 'status', label: this.$t('Status') },
			{ key: 'channel.sourceData.storeRegion', label: this.$t('Region') },
			{ key: 'orderId', label: this.$t('Order ID') },
			{ key: 'intent', label: this.$t('Intent Type') },
			{
				key: 'customer',
				label: this.$t('Customer'),
				sortable: true,
				thStyle: 'width: 150px;',
				thClass: 'text-center',
				tdClass: 'text-center'
			},
			{ key: 'shippingMethodId', label: this.$t('Shipping Type') },
			{ key: 'quantity', label: this.$t('Quantity') },
			{ key: 'totalPrice', label: this.$t('Total Price') },
			{ key: 'createdAt', label: this.$t('Date'), sortable: true },
			{ key: 'payoutCode', label: this.$t('Payout code') },
			{ key: 'channel.source', label: this.$t('Channel') },
			{ key: 'actions', label: this.$t('Actions'), sortable: false }
		];

		return {
			isLoading: false,
			fields,
			selected: _.map(fields, 'key'),
			selectedItems: [],
			selectedItem: null,
			config,
			filter: true,
			orderStatus
		};
	},
	computed: {
		...mapGetters({
			orders: 'order/orders',
			shippingMethods: 'shipping-method/shipping-methods',
			products: 'product/products',
			accounts: 'account/accounts'
		}),
		filters() {
			const products = _.get(this.products, 'data', []);
			return [
				{
					header: this.$t('Accounts'),
					key: 'accountId',
					type: 'checkbox',
					items: _.map(this.accounts?.data ?? [], ({ authAccountId, displayName }) => ({
						value: authAccountId,
						title: displayName
					}))
				},
				{
					header: this.$t('Products'),
					key: 'productIds',
					type: 'checkbox',
					items: _.map(products, ({ _id, name }) => ({
						value: _id,
						title: name
					}))
				},
				{
					header: this.$t('Shipping Type'),
					key: 'shippingMethodId',
					type: 'checkbox',
					items: _.map(this.shippingMethods, ({ id, name }) => ({
						value: id,
						title: name || ''
					}))
				},
				{
					header: this.$t('Status'),
					key: 'status',
					type: 'checkbox',
					items: _.map(orderStatusOptions, ({ text, value }) => ({ title: text, value }))
				},
				{
					header: this.$t('Region'),
					key: 'channel.sourceData.storeRegion',
					type: 'checkbox',
					items: countryOptions.map(el => ({ ...el, title: el.text }))
				},
				{
					header: this.$t('Channel'),
					key: 'channel.source',
					type: 'checkbox',
					items: [
						{ title: 'Found.us', value: 'Found.us' },
						{ title: 'amazon', value: 'amazon' },
						{ title: 'tiktok', value: 'tiktok' }
					]
				}
			];
		},
		orderIntentType() {
			return _.keyBy(orderIntentOptions, 'value');
		},
		selectedItemsCount() {
			return _.size(this.selectedItems);
		},
		disableSelectAllBtn() {
			const isSelectable = order => this.isRowSelectable(order) && !this.checkIsItemSelected(order._id);
			return !_.filter(this.orders.data, isSelectable).length;
		}
	},
	async mounted() {
		await Promise.all([
			this.getShippingMethods(),
			this.fetchData(),
			this.fetchProducts({
				query: {
					query: {
						$select: ['name'],
						$limit: 0
					}
				}
			}),
			this.fetchAccounts({ query: { query: { $limit: 1000, $sort: { displayName: 1 } } } })
		]);
	},
	methods: {
		...mapActions({
			getOrders: 'order/find',
			resubmitOrder: 'order/resubmitOrder',
			getShippingMethods: 'shipping-method/findAll',
			fetchProducts: 'product/find',
			fetchAccounts: 'account/find'
		}),
		formatDate,
		formatCents,
		_get: _.get,
		_sumBy: _.sumBy,
		async fetchData() {
			try {
				this.isLoading = true;
				const query = {
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1),
					$populate: [
						{
							path: 'payoutCode'
						},
						{
							path: 'lineItems',
							$populate: {
								path: 'product'
							}
						}
					],
					$where: {
						$and: [{ status: { $nin: [orderStatus.Pending, orderStatus.Abandoned] } }]
					},
					...(this.filterValues?.productIds?.length && { productIds: this.filterValues.productIds })
				};

				if (!_.isEmpty(this.filterValues)) {
					query.$where = _.reduce(
						this.filterValues,
						(filterQuery, value, key) => {
							if (key !== 'productIds') {
								filterQuery.$and.push({ [key]: Array.isArray(value) ? { $in: value } : value });
							}

							return filterQuery;
						},
						{ $and: [...(query.$where.$and ?? [])] }
					);
				}

				if (this.searchString) {
					query.$where.$or = [{ 'shipping.name': { $regex: this.searchString, $options: 'i' } }];
					query.$where.$or.push({ orderId: this.searchString });

					const isNumber = !isNaN(this.searchString);
					if (isNumber) {
						query.$where.$or.push({ orderId: Number(this.searchString) });
					}
				}

				query.$sort = !_.isEmpty(this.sort) ? this.sort : { createdAt: -1 };

				await this.getOrders({ query: { query } });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching orders')
				});

				throw err;
			} finally {
				this.isLoading = false;
			}
		},
		handleTableChange({ currentPage, perPage, sort, filter, selectedCols }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			this.selected = selectedCols;
			if (filter !== undefined) {
				this.filter = filter;
			}
			if (sort) {
				if (_.has(sort, 'customer')) {
					this.sort = { 'shipping.name': sort.customer };
				} else {
					this.sort = sort;
				}
			}
		},
		filtersChanged(filters) {
			this.filterValues = filters;
			this.currentPage = 1;
			this.fetchData();
		},
		isRowSelectable(item) {
			return item.status !== orderStatus.PreOrder;
		},
		handleRowClick(item, _index, event) {
			if (event.target.type === 'button' || !this.isRowSelectable(item)) return;
			this.toggleIsOrderSelected(item._id);
		},
		toggleIsOrderSelected(itemId) {
			if (this.checkIsItemSelected(itemId)) {
				const indexToRemove = this.selectedItems.findIndex(({ id }) => id === itemId);
				this.selectedItems.splice(indexToRemove, 1);
			} else {
				this.selectedItems.push({ id: itemId, isLoading: false });
			}
		},
		checkIsItemSelected(itemId) {
			return this.selectedItems.some(({ id }) => id === itemId);
		},
		checkIsSelectedItemLoading(itemId) {
			const result =
				_.some(this.selectedItems, {
					id: itemId,
					isLoading: true
				}) ||
				(this.selectedItem?.id === itemId && this.selectedItem?.isLoading === true);

			return result;
		},
		updateSelectedItem(itemId, update) {
			const indexToUpdate = _.findIndex(this.selectedItems, { id: itemId });
			const itemToUpdate = this.selectedItems[indexToUpdate];

			this.selectedItems.splice(indexToUpdate, 1, {
				...itemToUpdate,
				...update
			});
		},
		getShippingType(order) {
			const { shippingType, shippingMethodId } = order;
			return shippingType || _.find(this.shippingMethods, item => item.id === shippingMethodId)?.name;
		},
		getOrderTotalPrice(order) {
			const items = _.get(order, 'lineItems');
			const isoCurrency = _.get(order, 'lineItems[0].isoCurrency');
			const sum = _.sumBy(items, item => item.salePrice);
			return formatCents(sum, isoCurrency) || 0;
		},
		getShippingPrice(order) {
			const isoCurrency = _.get(order, 'lineItems[0].isoCurrency');
			return formatCents(order?.shippingPrice, isoCurrency) || 0;
		},
		selectAllOrders() {
			const selectableOrders = _.filter(this.orders.data, o => o.status !== orderStatus.PreOrder);
			this.selectedItems = _.uniqBy(
				[
					...this.selectedItems,
					..._.map(selectableOrders, ({ _id }) => ({
						id: _id,
						isLoading: false
					}))
				],
				'id'
			);
		},
		unselectAllOrders() {
			this.selectedItems = [];
		},
		showSuccessResubmissionNotification(order) {
			this.$notify({
				type: 'success',
				title: 'Success',
				text: `Order #${order.orderId} has been successfully resubmitted`
			});
		},
		showErrorResubmissionNotification(text) {
			this.$notify({
				type: 'error',
				title: this.$t('Error'),
				text
			});
		},
		handleSearchStringInput: _.debounce(function(value) {
			this.searchString = value;
			this.currentPage = 1;
			this.fetchData();
		}, 800),
		getItemStatus(status) {
			switch (status) {
				case orderStatus.Processing: {
					return 'Running';
				}
				case orderStatus.Complete: {
					return 'Shipped';
				}
				case orderStatus.Blocked:
				case orderStatus.Error: {
					return 'Error';
				}
				case orderStatus.Cancelled: {
					return orderStatus.Cancelled;
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.orders-overflown-text {
	overflow: hidden;
	width: 150px;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>
