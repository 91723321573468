<template>
	<div>
		<template v-if="isRendering">
			<div>
				<Loader />
				<span class="ml-1">{{ $t('The render process has started') }}...</span>
			</div>
		</template>
		<template v-else-if="error">
			{{ $t('An error happened while trying to render the component') }}
			<div class="text-danger">{{ error.message }}</div>
		</template>
		<template v-else>
			<div class="mb-2">
				{{ $t('The render process completed successfully') }}
			</div>

			<b-btn v-if="file" size="sm" :to="{ name: 'filePreview', params: { id: file._id } }">
				<a @click="handleFilePreview($event)">
					{{ $t('Preview File') }}
				</a>
			</b-btn>
		</template>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import { to } from 'await-to-js';
import withPusher from '@/mixins/withPusher';
import Loader from '@/components/Loader';

export default {
	components: { Loader },
	mixins: [withPusher],
	props: {
		product: { type: Object },
		componentCode: { type: String },
		delivery: { type: String },
		releases: { type: Array }
	},
	data() {
		return {
			isRendering: true,
			error: null,
			renderJob: null,
			file: null
		};
	},
	mounted() {
		this.startRender();
	},
	destroyed() {
		this.unsubscribeToRenderUpdates();
	},
	methods: {
		...mapActions({
			startRenderComponentJob: 'product-release/startRenderComponentJob'
		}),
		async startRender() {
			this.isRendering = true;
			this.error = false;

			const [error, renderJob] = await to(
				this.startRenderComponentJob({
					productId: this.product._id,
					delivery: this.delivery,
					...(this.delivery === 'physical' && { componentCode: this.componentCode })
				})
			);

			this.renderJob = renderJob;
			this.error = error;

			if (this.error) {
				this.isRendering = false;
			}

			if (this.renderJob) {
				this.subscribeToRenderUpdates();
			}
		},
		subscribeToRenderUpdates() {
			const channelName = `account.${this.renderJob.accountId}.products.release`;
			this.pusherClient.subscribe(channelName, 'ReleaseComponentRender', message =>
				this.messageReceivedHandler(message)
			);
			this.pusherClient.subscribe(channelName, 'ReleaseComponentRenderFailed', message =>
				this.messageReceivedHandler(message)
			);
		},
		unsubscribeToRenderUpdates() {
			if (!this.renderJob) return;

			const channelName = `account.${this.renderJob.accountId}.products.release`;
			this.pusherClient.unsubscribe(channelName);
		},
		messageReceivedHandler(message) {
			if (message.streamId === this.renderJob._id) {
				this.isRendering = false;
				const data = message.data;
				if (message.type === 'ReleaseComponentRender') {
					this.file = data.file;

					this.$emit('file-selected', {
						pageCount: this.file.metadata.pageCount,
						file: this.file
					});
				}
				if (message.type === 'ReleaseComponentRenderFailed') {
					this.error = { message: 'An error happened during the render' };
				}
			}
		},
		handleFilePreview($event) {
			if (!$event.ctrlKey && !$event.metaKey) {
				$event.preventDefault();
				$event.stopPropagation();
				this.$showFilePreviewer(this.file._id);
			}
		}
	}
};
</script>
