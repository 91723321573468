import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'product-content',
		pluralName: 'product-contents',
		transport: 'davinci',
		options: {
			namespaced: true
		},
		actions: {
			getContentByProductId: async ({ dispatch }, payload) => {
				return dispatch('request', {
					method: 'GET',
					path: `product-contents/${payload}/content`
				}).then(response => response.data);
			}
		}
	});
}
