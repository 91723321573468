<template>
	<div>
		<b-button variant="primary" class="mb-2" :disabled="disabled" @click="addItem">
			{{ $t('Add Attribute') }}
		</b-button>
		<b-alert :show="!isAttributesValid" variant="danger" class="mt-1 mb-2">
			{{ $t('Attributes names should be unique.') }}
		</b-alert>
		<b-row :class="bodyClass">
			<b-col v-for="(item, index) in attributesData" :key="index" lg="8" md="12">
				<b-card class="attribute-card mb-2">
					<b-row>
						<b-col lg="6">
							<OfFormInput
								:label="$t('Name')"
								:value="item.key"
								:disabled="disabled"
								@input="handleChange(index, 'key', $event)"
							/>
						</b-col>
						<b-col lg="6">
							<OfFormInput
								:label="$t('Value')"
								:value="item.value"
								:disabled="disabled"
								@input="handleChange(index, 'value', $event)"
							/>
						</b-col>
						<icon
							name="times-circle"
							class="attribute-remove"
							:class="{ 'attribute-remove--disabled': disabled }"
							@click="removeItem(index)"
						/>
					</b-row>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script lang="js">
import {
	OfFormInput
} from '@oneflow/ofs-vue-layout';

export default {
	components: { OfFormInput },
	props: {
		// eslint-disable-next-line vue/require-default-prop
		attributes: {
			type: Object
		},
		title: {
			type: String,
			default: ''
		},
		headerClass: {
			type: String,
			default: ''
		},
		bodyClass: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		let attributesData = [];
		if(this.attributes) {
			attributesData = _.map(this.attributes, (value, key) => ({key, value}));
		}
		return {
			attributesData
		};
	},
	computed: {
		isAttributesValid() {
			return _.uniqBy(this.attributesData, 'key').length === this.attributesData.length;
		}
	},
	watch: {
		attributes(newValue) {
			this.attributesData = _.map(newValue, (value, key) => ({key, value}));
		}
	},
	methods: {
		handleChange: function(index, prop, value) {
			this.attributesData[index][prop] = value;
			this.$emit('attributesChange', {
				data: this.attributesData,
				isValid: this.isAttributesValid
			});
		},
		addItem: function() {
			this.attributesData.push({key: '', value: ''});
		},
		removeItem: function(index) {
			if (this.disabled) return;

			this.attributesData.splice(index, 1);
			this.$emit('attributesChange', {
				data: this.attributesData,
				isValid: this.isAttributesValid
			});
		}
	}
};
</script>

<style lang="scss">
.attribute-remove {
	position: absolute;
	right: 10px;

	&--disabled {
		cursor: not-allowed;
	}
}
</style>
