<template>
	<b-modal
		size="md"
		:title="$t('Add template')"
		:ok-title="$t('Add')"
		:ok-disabled="addButtonDisabledStatus"
		:cancel-title="$t('Cancel')"
		:visible="isVisible"
		@ok="onCreateTemplate"
		@hide="onCloseModal"
	>
		<b-row>
			<b-col md="5">{{ $t('Create template from layout') }}:</b-col>
			<b-col md="1">
				<OfToggle v-model="createTemplateFromLayout" :vuex="false" class="no-label" />
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<AccountSelect />
			</b-col>
		</b-row>
		<b-row v-if="createTemplateFromLayout" class="select-container">
			<b-col>
				<OfFormSelect
					v-if="!isEmailTemplate"
					v-model="layoutFormatValue"
					:label="$t('Layout format')"
					:vuex="false"
					:options="templateFormatsOptions"
				/>
			</b-col>
			<b-col>
				<OfMultiSelect
					v-model="selectedLayout"
					:disabled="!layoutFormatValue || !createTemplateFromLayout"
					:vuex="false"
					:placeholder="$t('Layout name')"
					:label="$t('Layout name')"
					:options="layoutsOptions"
					:is-loading="isLayoutLoading"
					@search-change="onLayoutSearch"
				/>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import { OfFormSelect, OfMultiSelect, OfToggle } from '@oneflow/ofs-vue-layout';
import { templateFormats, templatesTypes } from '../lib/constants';
import AccountSelect from '@/components/AccountSelect.vue';

export default {
	components: {
		AccountSelect,
		OfMultiSelect,
		OfFormSelect,
		OfToggle
	},
	props: {
		templateFormatValue: {
			type: String,
			default: templatesTypes.PRODUCT
		},
		isVisible: {
			type: Boolean,
			required: true
		},
		// eslint-disable-next-line vue/require-default-prop
		create: {
			type: Function
		}
	},
	data() {
		return {
			createTemplateFromLayout: true,
			selectedLayout: null,
			isLayoutLoading: false,
			layoutsOptions: [],
			layoutFormatValue: null
		};
	},
	computed: {
		isEmailTemplate() {
			return this.templateFormatValue === templatesTypes.EMAIL;
		},
		templateFormatsOptions() {
			return _.map(templateFormats, type => ({ text: type.toUpperCase(), value: type }));
		},
		addButtonDisabledStatus() {
			return !this.createTemplateFromLayout ? false : this.createTemplateFromLayout && !this.selectedLayout;
		}
	},
	watch: {
		layoutFormatValue: async function(newValue) {
			if (_.isNull(newValue)) return;
			this.selectedLayout = null;
			await this.fetchLayoutsOptions();
		},
		isVisible: function(newStatus) {
			if (newStatus) {
				this.initModalData();
			}
		}
	},
	methods: {
		_get: _.get,
		...mapActions({
			findLayouts: 'layout/find'
		}),
		async fetchLayoutsOptions(searchString) {
			const query = {
				$where: {
					...{ type: !this.isEmailTemplate ? { $ne: 'email' } : 'email' },
					...(this.layoutFormatValue ? { format: this.layoutFormatValue } : {}),
					...(_.isString(searchString) ? { name: { $regex: searchString, $options: 'i' } } : {})
				},
				$limit: 10
			};

			const { data } = await this.findLayouts({ query: { query } });
			this.layoutsOptions = _.map(data, lay => ({ text: lay.name, value: lay._id }));
		},
		searchLayouts: _.debounce(async function(layoutName) {
			this.isLayoutLoading = true;
			await this.fetchLayoutsOptions(layoutName);
			this.isLayoutLoading = false;
		}, 500),
		async onLayoutSearch(search) {
			if (_.isUndefined(search) || _.isNull(search?.text)) {
				return;
			}
			await this.searchLayouts(search.text);
		},
		onCloseModal(event) {
			if (event.trigger !== 'ok') {
				this.createTemplateFromLayout = true;
				this.layoutFormatValue = null;
				this.$emit('close');
			}
		},
		initModalData() {
			this.selectedLayout = null;
			this.layoutFormatValue = this.isEmailTemplate ? templateFormats.HTML : null;
		},
		onCreateTemplate() {
			return this.$emit('create', this.selectedLayout);
		}
	}
};
</script>

<style lang="scss" scoped>
.col-md-5 {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.select-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}
</style>
