<template>
	<div>
		<OfFormInput name="comment" :placeholder="$t('Leave a comment...')" no-label class="mb-3">
			<template v-slot:append>
				<b-button variant="primary" size="md" :disabled="!formData.comment" @click="addComment">
					{{ $t('Post') }}
				</b-button>
			</template>
		</OfFormInput>
		<ul class="timeline">
			<template v-for="(timelineItem, key, index) in timelineItems">
				<li :key="index" class="timeline__title">
					{{ key }}
					<div class="timeline__tail" />
				</li>
				<template v-for="item in timelineItem">
					<li
						v-if="item.type === logsTypes.event || !item.type"
						:key="item._id"
						class="timeline__item"
						:class="{ error: item.level === logsEventLevels.error }"
					>
						<div class="timeline__tail" />

						<div
							class="timeline__circle"
							:class="[{ error: item.level === logsEventLevels.error }, { user: isMe(_get(item, 'data.userId')) }]"
						></div>

						<b-row v-b-toggle="`timeline__item-${item._id}`" class="timeline__item-header ">
							<b-col>
								{{ item.message }}
								<icon v-if="item.data" name="chevron-right" class="arrow-right" />
								<icon name="chevron-down" class="arrow-left" />
							</b-col>
							<b-col md="2" class="timeline__item-time text-right">{{ getLogTime(item.timestamp) }}</b-col>
						</b-row>

						<b-collapse v-if="item.data" :id="`timeline__item-${item._id}`">
							<b-card class="timeline__item-details">
								<pre>{{ JSON.stringify(item.data, null, 2) }}</pre>
							</b-card>
						</b-collapse>
					</li>
					<li v-else :key="item._id" class="timeline__comment">
						<div class="timeline__tail" />
						<b-card class="timeline__comment-details">
							<b-row class="mb-2">
								<div class="timeline__comment-header">{{ item.message }}</div>
								<div class="timeline__comment-time">{{ getLogTime(item.timestamp) }}</div>
							</b-row>
							<b-row class="timeline__comment-message">{{ item.data.text }}</b-row>
						</b-card>
					</li>
				</template>
			</template>
		</ul>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import { logsEventLevels, logsTypes } from './constants.js';
import { OfFormInput, withForm } from '@oneflow/ofs-vue-layout';

export default {
	components: {
		OfFormInput
	},
	mixins: [withForm('TimelineForm')],
	props: {
		items: {
			type: Array,
			default: () => {}
		}
	},
	data() {
		return {
			logsEventLevels,
			logsTypes
		};
	},
	computed: {
		...mapGetters('auth0', ['user']),
		timelineItems() {
			const sortedItems = _.reverse(this.items.slice());
			const groupedItems = _.groupBy(sortedItems, item => this.getLogDate(item.timestamp));
			return groupedItems;
		}
	},
	async mounted() {
		await this.initialize();
	},
	methods: {
		async initialize() {
			this.initFormData({ comment: '' });
		},
		_get: _.get,
		getLogDate(date) {
			return moment(date).calendar(null, {
				sameDay: '[Today]',
				lastDay: '[Yesterday]',
				lastWeek: 'LL',
				sameElse: 'LL'
			});
		},
		getLogTime(date) {
			return moment(date).format('LT');
		},
		isMe(userId) {
			return _.isEqual(userId, this.user._id);
		},
		addComment() {
			this.$emit('submit', this.formData.comment);
			this.initFormData({ comment: '' });
		}
	}
};
</script>

<style lang="scss" scoped>
@import '~@oneflow/ofs-vue-layout/src/styles/variables.scss';

.timeline {
	padding: 0;
	position: relative;
	list-style: none;
	-webkit-font-smoothing: antialiased;
	margin: 10px 20px;

	&__title {
		color: $of-color-status-null;
		position: relative;
		display: inline-block;
		cursor: crosshair;
		padding: 15px 0 15px 28px;
		text-transform: uppercase;
		font-weight: 600;
	}

	&__item {
		position: relative;
		padding: 15px 0 15px 28px;

		&-header {
			.arrow-left,
			.arrow-right {
				width: 16px;
				color: #000000;
			}

			&.collapsed {
				.arrow-left {
					display: none;
				}
			}

			&:not(.collapsed) {
				.arrow-right {
					display: none;
				}
			}
		}

		&-details {
			background-color: $of-color-highlights;
			border: none;
			margin-top: 20px;
		}

		&-time {
			color: $of-color-status-null;
		}

		&.error {
			color: $of-color-red;
		}

		&:last-of-type {
			.timeline__tail {
				display: none;
			}
		}
	}

	&__circle {
		position: absolute;
		top: 1.28em;
		left: -6px;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		border: 1px solid $of-color-status-null;
		background-color: $of-color-status-null;
		z-index: 1;
		-webkit-box-sizing: content-box;
		box-sizing: content-box;

		&.error {
			border: 1px solid $of-color-red;
			background-color: $of-color-red;
		}

		&.user {
			border: 1px solid $of-color-aqua;
			background-color: $of-color-aqua;
		}
	}

	&__comment {
		position: relative;
		padding: 10px 0 10px 30px;

		&-details {
			background-color: $of-color-highlights;
			border: none;
		}

		&-time {
			color: $of-color-status-null;
			margin-left: 20px;
		}

		&-header {
			font-weight: 700;
		}
	}

	&__tail {
		position: absolute;
		content: '';
		left: 0;
		top: 10px;
		width: 1px;
		height: calc(100% + 10px);
		background-color: $of-color-grey-3;
	}
}
</style>
