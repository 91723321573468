<template>
	<OfsPanel>
		<ListTable
			bordered
			hover
			:config="config"
			:table-title="'Layouts'"
			:fields="fields"
			:items="layouts.data"
			:sort="sort"
			:total-items="layouts.total"
			:current-page="currentPage"
			:per-page="perPage"
			:fetch-data="fetchData"
			:is-busy="isLoading"
			@row-clicked="onRowClicked"
			@table-change="handleTableChange"
		>
			<template slot="TableButtons-Slot-left" slot-scope="{}">
				<OfInlineFilter :filters="filters" :values="filterValues" @change="filtersChanged" />
			</template>

			<template slot="TableHeader" slot-scope="{}">
				<OfFilterBar :filters="filters" :values="filterValues" @change="filtersChanged" />
			</template>
		</ListTable>
	</OfsPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfsPanel, ListTable, OfInlineFilter, OfFilterBar } from '@oneflow/ofs-vue-layout';
import { templateFormatOptions, templateTypeOptions } from '@/lib/constants';
import withQuerystringState from '@/mixins/withQuerystringState';
import { parseDefaultQuerystringFilterValues } from '@/lib/parseDefaultQuerystringFilterValues';

const config = {
	refresh: { visible: true }
};

export default {
	components: {
		OfsPanel,
		ListTable,
		OfInlineFilter,
		OfFilterBar
	},
	mixins: [
		withQuerystringState([
			{ name: 'currentPage', defaultValue: 1, parseValue: Number },
			{ name: 'perPage', defaultValue: 10, parseValue: Number },
			'searchString',
			{ name: 'sort', defaultValue: {}, parseValue: value => value ?? {} },
			{ name: 'filterValues', parseValue: parseDefaultQuerystringFilterValues }
		])
	],
	data: function() {
		const fields = [
			{ key: 'name', label: this.$t('Name') },
			{ key: 'type', label: this.$t('Type'), sortable: true },
			{ key: 'format', label: this.$t('Format'), sortable: true },
			{ key: 'active', label: this.$t('Status'), sortable: true }
		];

		const filters = [
			{
				header: this.$t('Status'),
				key: 'active',
				type: 'radio',
				items: [
					{ title: this.$t('Active'), value: true },
					{ title: this.$t('Inactive'), value: false }
				]
			},
			{
				header: this.$t('Format'),
				key: 'format',
				type: 'radio',
				items: templateFormatOptions
			},
			{
				header: this.$t('Type'),
				key: 'type',
				type: 'radio',
				items: templateTypeOptions
			}
		];

		return {
			isLoading: false,
			fields,
			filters,
			config
		};
	},
	computed: {
		...mapGetters({
			layouts: 'layout/layouts'
		})
	},
	async mounted() {
		await this.fetchData();
	},
	methods: {
		_get: _.get,
		...mapActions({
			getLayouts: 'layout/find'
		}),
		async fetchData() {
			try {
				this.isLoading = true;
				const query = {
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1),
					$select: ['name', 'type', 'format', 'active']
				};

				if (!_.isEmpty(this.filterValues)) {
					query.$where = _.reduce(
						this.filterValues,
						($where, value, key) => ({
							...$where,
							[key]: Array.isArray(value) ? { $in: value } : value
						}),
						{}
					);
				}

				query.$sort = !_.isEmpty(this.sort) ? this.sort : { name: 1 };

				await this.getLayouts({ query: { query } });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching	layouts')
				});

				throw err;
			} finally {
				this.isLoading = false;
			}
		},
		filtersChanged(filters) {
			this.filterValues = filters;
			this.currentPage = 1;
			this.fetchData();
		},
		handleTableChange({ currentPage, perPage, filter, sort }) {
			this.currentPage = currentPage;
			this.perPage = perPage;

			if (filter !== undefined) {
				this.filter = filter;
			}
			if (sort) {
				this.sort = sort;
			}
		},
		onRowClicked(item, index, event) {
			if (event.target.type === 'button') return;
			this.$router.push({
				name: 'layouts.edit',
				params: { id: item._id }
			});
		}
	}
};
</script>
