<template>
	<component :is="activeLayout">
		<router-view v-if="!auth0Loading" :key="$route.path" />
		<Notification />
	</component>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import { Notification } from '@oneflow/ofs-vue-layout';
import DefaultLayout from './components/layouts/DefaultLayout';
import FullscreenLayout from './components/layouts/FullscreenLayout';
import config from './config';

export default {
	components: {
		Notification,
		DefaultLayout,
		FullscreenLayout
	},
	computed: {
		...mapGetters({
			isAuthenticated: 'auth0/isAuthenticated',
			isAdmin: 'auth0/isAdmin',
			auth0Loading: 'auth0/isLoading',
			activeTopItem: 'menu/activeTopItem',
			activeSubmenu: 'menu/activeSubmenu',
			activeLayout: 'layoutProvider/activeLayout',
			lang: 'lang/lang'
		})
	},
	watch: {
		isAuthenticated: {
			immediate: true,
			async handler() {
				this.redirectIfNotAuth();
			}
		},
		isAdmin: {
			immediate: true,
			async handler() {
				this.redirectIfNotAuth();
			}
		}
	},
	async created() {
		this.setLanguage({ lang: this.lang });
		this.$router.afterEach(this.redirectIfNotAuth);

		try {
			await this.initAuth0State({
				domain: config.auth0.domain,
				clientId: config.auth0.clientId,
				authorizationParams: {
					redirect_uri: config.auth0.redirectUri,
					audience: config.auth0.audience
				}
			});
		} catch (err) {
			this.$notify({
				type: 'error',
				title: 'Authentication error',
				text: err.message
			});
		}
	},
	methods: {
		...mapActions({
			setLanguage: 'lang/setLanguage',
			initAuth0State: 'auth0/initAuth0'
		}),
		...mapActions('account', ['fetchBaseAccountData']),
		redirectIfNotAuth() {
			if (this.auth0Loading) return;

			const isAuthenticatedAdmin = this.isAuthenticated && this.isAdmin;

			if (!isAuthenticatedAdmin && this.$route.name !== 'login') {
				this.$router.push('/login');
			}

			if (this.$route.name === 'login' && isAuthenticatedAdmin) {
				this.$router.push('/');
			}
		}
	}
};
</script>
