<template>
	<OfsPanel>
		<ListTable
			bordered
			hover
			:table-title="$t('Shipping methods')"
			:config="config"
			:items="shippingMethods.data"
			:fields="fields"
			:sort="sort"
			:total-items="shippingMethods.total"
			:current-page="currentPage"
			:per-page="perPage"
			:fetch-data="fetchData"
			:is-busy="isLoading"
			@table-change="handleTableChange"
		>
			<template slot="TableButtons-Slot-left" slot-scope="{}">
				<OfInlineFilter :filters="filters" :values="filterValues" @change="filtersChanged" />
			</template>

			<template slot="TableHeader" slot-scope="{}">
				<OfFilterBar :filters="filters" :values="filterValues" @change="filtersChanged" />
			</template>

			<template slot="TableButtons-Slot-right" slot-scope="{}">
				<b-button variant="primary" class="ml-2" @click="() => (isEditModalVisible = true)">
					{{ $t('Create shipping method') }}
				</b-button>
			</template>

			<template v-slot:cell(active)="{ item }">
				<b-badge v-if="item.active" variant="primary">{{ $t('True') }}</b-badge>
				<b-badge v-else variant="dark">{{ $t('False') }}</b-badge>
			</template>

			<template #cell(isoCurrency)="{ item }">
				<b-badge
					v-for="region in getCurrencies(item.shippingRates)"
					:key="`${item._id}-${region}`"
					variant="primary"
					class="mr-1"
				>
					{{ region }}
				</b-badge>
			</template>

			<template #cell(actions)="{ item }">
				<b-dropdown size="sm" right>
					<b-dropdown-item @click="handleEditShippingMethod(item._id)">Edit</b-dropdown-item>
				</b-dropdown>
			</template>
		</ListTable>

		<ShippingMethodModal
			:is-visible="isEditModalVisible"
			:shipping-method-id="selectedShippingMethodId"
			@close="handleShippingMethodModalClose"
			@ok="fetchData"
		/>
	</OfsPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfsPanel, ListTable, OfInlineFilter, OfFilterBar } from '@oneflow/ofs-vue-layout';
import ShippingMethodModal from './ShippingMethodModal';
import { regionToCurrency } from '@/lib/country';
import withQuerystringState from '@/mixins/withQuerystringState';
import { parseDefaultQuerystringFilterValues } from '@/lib/parseDefaultQuerystringFilterValues';

const config = {
	refresh: { visible: true }
};

export default {
	components: {
		OfFilterBar,
		OfsPanel,
		ListTable,
		OfInlineFilter,
		ShippingMethodModal
	},
	mixins: [
		withQuerystringState([
			{ name: 'currentPage', defaultValue: 1, parseValue: Number },
			{ name: 'perPage', defaultValue: 10, parseValue: Number },
			'searchString',
			{ name: 'sort', defaultValue: {}, parseValue: value => value ?? {} },
			{ name: 'filterValues', parseValue: parseDefaultQuerystringFilterValues }
		])
	],
	data() {
		const fields = [
			{ key: 'name', label: this.$t('Name') },
			{ key: 'active', label: this.$t('Active') },
			{ key: 'alias', label: this.$t('Alias') },
			{ key: 'isoCurrency', label: this.$t('Currency') },
			{ key: 'actions', label: '' }
		];

		const filters = [
			{
				header: this.$t('Status'),
				key: 'active',
				type: 'radio',
				items: [
					{ title: this.$t('Active'), value: true },
					{ title: this.$t('Inactive'), value: false }
				]
			}
		];

		return {
			isLoading: false,
			fields,
			config: {
				refresh: { visible: true }
			},
			selectedShippingMethodId: null,
			isEditModalVisible: false,
			filters
		};
	},
	computed: {
		...mapGetters({
			shippingMethods: 'shipping-method/shipping-methods'
		})
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			getShippingMethods: 'shipping-method/find'
		}),
		_map: _.map,
		getCurrencies(shippingRates) {
			return [...new Set(shippingRates.map(rate => regionToCurrency(rate.region)))];
		},
		async fetchData() {
			const query = {
				$limit: this.perPage,
				$skip: this.perPage * (this.currentPage - 1)
			};

			if (!_.isEmpty(this.filterValues)) {
				query.$where = _.reduce(
					this.filterValues,
					($where, value, key) => ({
						...$where,
						[key]: Array.isArray(value) ? { $in: value } : value
					}),
					{}
				);
			}

			if (Object.keys(this.sort).length > 0) {
				query.$sort = this.sort;
			}
			try {
				this.isLoading = true;
				await this.getShippingMethods({ query: { query } });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching shipping methods')
				});

				throw err;
			} finally {
				this.isLoading = false;
			}
		},
		handleTableChange({ currentPage, perPage, sort }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			if (sort) {
				this.sort = sort;
			}
		},
		handleEditShippingMethod(id) {
			this.selectedShippingMethodId = id;
			this.isEditModalVisible = true;
		},
		handleShippingMethodModalClose() {
			this.selectedShippingMethodId = null;
			this.isEditModalVisible = false;
		},
		async filtersChanged(filters) {
			this.filterValues = filters;
			this.currentPage = 1;
			await this.fetchData();
		}
	}
};
</script>
<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/variables';
.header-info-tooltip {
	display: flex;

	span {
		padding-right: 5px;
	}

	.header-info-icon {
		color: $of-color-grey-2;
		height: 13px;
		width: 13px;

		&:hover {
			cursor: pointer;
		}
	}
}
.ShippingRate_tooltip {
	.tooltip-inner {
		min-width: 180px;
		max-width: 450px;
		background: $of-color-dark;
		padding: 12px 15px;
		font-size: 12px;
	}
}
</style>
