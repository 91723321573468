<template>
	<div>
		<b-row>
			<b-col md="3">
				<OfFormInput :label="$t('Name')" name="name" required />
			</b-col>
			<b-col md="3">
				<OfFormSelect
					v-model="selectedTemplateVariantName"
					:options="themeOptions"
					:label="$t('Template theme')"
					:vuex="false"
				/>
			</b-col>
			<b-col md="1" class="layout-toggle">
				{{ $t('Active') }}: <OfToggle name="active" no-label class="no-label" />
			</b-col>
			<b-col md="2">
				<OfMultiSelect
					v-model="productContentId"
					:vuex="false"
					:options="productContentOptions"
					:label="$t('View as part of Product content')"
					:is-loading="isProductContentOptionsLoading"
					@input="handleProductContentChanged"
					@search-change="onProductContentSearch"
				/>
			</b-col>

			<b-col md="2">
				<OfMultiSelect
					v-model="productId"
					:vuex="false"
					:options="productsOptions"
					:label="$t('Use content from product')"
					:is-loading="isProductOptionsLoading"
					@input="handleProductOptionsChanged"
					@search-change="onProductSearch"
				/>
			</b-col>
		</b-row>
		<b-row v-if="isLayout">
			<b-col md="6">
				<OfMultiSelect
					name="supportedTypes"
					:options="dataTypesOptions"
					:label="$t('Supported data types')"
					:is-loading="isDataTypesOptionsLoading"
					multiple
					required
					@search-change="onDataTypesSearch"
				/>
			</b-col>
		</b-row>
		<b-row :v-if="formData.blocks">
			<b-col md="5">
				<b-card no-body>
					<b-tabs
						justified
						card
						pills
						nav-wrapper-class="border-0"
						nav-class="border-0 layout-editor-tabs"
						active-nav-item-class="bg-primary font-weight-bold text-white"
						active
					>
						<b-tab :title="$t('Theme')" active>
							<b-row v-if="themeVariants">
								<b-tabs v-model="themeTabIndex" class="preview-tabs theme-editor" :vuex="false" fill>
									<b-tab v-for="item in themeVariants" :key="item.key" :title="item.name">
										<CodeEditor
											class="source-editor"
											:value="item.theme"
											:options="codeEditorOptions"
											@input="onThemeEditorInput"
										/>
									</b-tab>
									<b-tab v-if="customisationOptions" :title="$t('Customisation Options')">
										<CodeEditor
											class="source-editor"
											:value="customisationOptions"
											:options="codeEditorOptions"
											@input="onCustomisationOptionsInput"
										/>
									</b-tab>
								</b-tabs>
							</b-row>
						</b-tab>
						<b-tab :title="$t('Layout')">
							<b-row>
								<CodeEditor
									class="source-editor layout-editor"
									:value="bookLayout"
									:options="codeEditorOptions"
									@input="onLayoutEditorInput"
								/>
							</b-row>
						</b-tab>
						<b-tab :title="$t('Blocks')">
							<b-row v-if="blocksTemplates">
								<b-tabs v-model="tabIndex" class="preview-tabs" :vuex="false" fill>
									<b-tab v-for="item in blocksTemplates" :key="item.key" :title="item.name">
										<CodeEditor
											class="source-editor"
											:value="item.template"
											:options="codeEditorOptions"
											@input="onBlocksEditorInput"
										/>
									</b-tab>
								</b-tabs>
							</b-row>
							<b-row v-if="bookContent && (!productId || !productContentId)">
								<b-col md="12">
									<span class="code-editor__title">{{ $t('Book Data') }}</span>
								</b-col>
								<b-col md="12">
									<CodeEditor
										class="source-editor book-content-editor"
										:value="bookContent"
										:options="codeEditorOptions"
										@input="onBookDataChange"
									/>
								</b-col>
							</b-row>
						</b-tab>
						<template v-if="isSeparatedStructure && coverBlockTemplates && contentBlockTemplates">
							<b-tab :title="$t('Cover dependency blocks')" :disabled="!coverBlockTemplates.length">
								<b-row>
									<b-tabs v-model="coverBlocksTabIndex" class="preview-tabs" :vuex="false" fill>
										<b-tab v-for="item in coverBlockTemplates" :key="item.key" :title="item.name">
											<CodeEditor
												class="source-editor"
												:value="item.template"
												:options="codeEditorOptions"
												@input="onCoverBlocksEditorInput"
											/>
											<b-col v-if="item.exampleProps">
												<span class="code-editor__title">{{ $t('Example props data') }}</span>
												<CodeEditor
													class="source-editor book-content-editor"
													:options="codeEditorOptions"
													:value="item.exampleProps"
												/>
											</b-col>
										</b-tab>
									</b-tabs>
								</b-row>
							</b-tab>
							<b-tab :title="$t('Content dependency blocks')" :disabled="!contentBlockTemplates.length">
								<b-row>
									<b-tabs v-model="contentBlocksTabIndex" class="preview-tabs" :vuex="false" fill>
										<b-tab v-for="item in contentBlockTemplates" :key="item.key" :title="item.name">
											<CodeEditor
												class="source-editor"
												:value="item.template"
												:options="codeEditorOptions"
												@input="onContenBlocksEditorInput"
											/>
											<b-col v-if="item.exampleProps">
												<span class="code-editor__title">{{ $t('Example props data') }}</span>
												<CodeEditor
													class="source-editor book-content-editor"
													:options="codeEditorOptions"
													:value="item.exampleProps"
												/>
											</b-col>
										</b-tab>
									</b-tabs>
								</b-row>
							</b-tab>
						</template>
						<b-tab v-if="templateProps" :title="$t('Props')">
							<b-row v-if="templateProps">
								<b-col md="12">
									<CodeEditor
										class="source-editor book-content-editor"
										:value="templateProps"
										:options="{ readOnly: isPropsEditorDisabled ? 'nocursor' : false }"
										@input="onPropsEditorInput"
									/>
								</b-col>
							</b-row>
						</b-tab>
					</b-tabs>
				</b-card>
			</b-col>
			<b-col md="7">
				<b-card no-body>
					<div>
						<b-row md="12" class="preview-control">
							<div>
								<span class="preview-control__title">{{ $t('Book Preview') }}</span>
							</div>
							<div>
								<b-button :variant="defineActiveButton(pageTypes.cover)" @click="switchContentType(pageTypes.cover)">
									{{ $t('Cover') }}
								</b-button>
								<b-button
									:variant="defineActiveButton(pageTypes.content)"
									@click="switchContentType(pageTypes.content)"
								>
									{{ $t('Content') }}
								</b-button>
							</div>
							<b-button class="mr-2" :disabled="!canSubmit" variant="primary" @click="() => saveLayout()">
								{{ $t('Save') }}
							</b-button>
						</b-row>

						<div class="book-preview">
							<div
								id="bookPreview"
								:style="`display: ${bookPreviewDisplay} !important`"
								class="book-preview__container"
							></div>

							<div v-if="contentType === pageTypes.cover" class="shadow">
								<PagePreview
									v-for="(_, pageIndex) in coverPages"
									:key="pageIndex"
									:json="coverPages[pageIndex]"
									:fonts="fonts"
									is-cover
								/>
							</div>

							<div class="book-preview__render">
								<div v-for="(_, pageIndex) in contentPages" :key="pageIndex" class="book-preview__render-item">
									<div :key="pageIndex">
										<Intersect v-slot="{ shouldRender }">
											<div class="responsive-square">
												<PagePreview v-if="shouldRender" :json="contentPages[pageIndex]" :fonts="fonts" />
											</div>
										</Intersect>
									</div>
								</div>
							</div>
						</div>

						<b-input-group
							v-if="contentType !== pageTypes.cover"
							:prepend="`${displayedPageNumber}/${contentPages.length - 1}`"
							class="mt-3 mb-3"
						>
							<b-form-input
								ref="pagesSlider"
								v-model="currentPage"
								:min="0"
								:max="contentPages.length - 2"
								:step="2"
								type="range"
								@input="turnPage"
							></b-form-input>
						</b-input-group>
					</div>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { withForm, OfToggle, OfFormInput, OfFormSelect, OfMultiSelect } from '@oneflow/ofs-vue-layout';
import { PageFlip } from 'page-flip';
import { BookTemplateProcessor } from '@custom-content-technology/book-template-processor';
import CodeEditor from '../CodeEditor';
import PagePreview from '@/components/PagePreview';
import Intersect from '@/components/Intersect';
import { pageTypes } from './contants.js';
import { productFormats } from '@/containers/Create/Products/constants';

export default {
	components: {
		CodeEditor,
		OfFormInput,
		OfToggle,
		OfFormSelect,
		PagePreview,
		Intersect,
		OfMultiSelect
	},
	mixins: [withForm('layoutEdit')],
	props: {
		data: {
			type: Object,
			default: () => ({})
		},
		isLayout: {
			type: Boolean,
			default: false
		},
		isEditDisabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			tabIndex: 0,
			themeTabIndex: 0,
			selectedTemplateVariantName: null,
			blocksTemplates: null,
			templateProps: null,
			bookLayout: '',
			themeVariants: null,
			customisationOptions: null,
			bookContent: '',
			pageFlip: null,
			currentPage: 1,
			contentType: pageTypes.cover,
			pageTypes: pageTypes,
			coverComponents: ['cover-front', 'cover-back', 'cover'],
			productContentOptions: [],
			productsOptions: [],
			dataTypesOptions: [],
			productContentId: null,
			productId: null,
			originalProps: null,
			originalThemes: null,
			previewOptions: {
				sizes: null,
				renderProps: null
			},
			contentForPreview: null,
			isProductOptionsLoading: false,
			isProductContentOptionsLoading: false,
			isDataTypesOptionsLoading: false,
			productFormats,
			coverBlockTemplates: null,
			contentBlockTemplates: null,
			isSeparatedStructure: false,
			coverBlocksTabIndex: 0,
			contentBlocksTabIndex: 0
		};
	},
	computed: {
		...mapGetters({
			products: 'product/products',
			productContents: 'product-content/product-contents'
		}),
		bookPreviewDisplay() {
			return this.contentType === this.pageTypes.cover ? 'none' : 'block';
		},
		contentPages() {
			return _.filter(this.json, i => !this.coverComponents.includes(_.get(i, 'meta.productComponent')));
		},
		coverPages() {
			return _.filter(this.json, i => this.coverComponents.includes(_.get(i, 'meta.productComponent')));
		},
		displayedPageNumber() {
			return parseInt(this.currentPage) + 1;
		},
		templateVariants() {
			return _.get(this.formData, 'theme.variants');
		},
		themeOptions() {
			return _.map(this.templateVariants, theme => ({ text: theme.name, value: theme.name }));
		},
		themeVariant() {
			const { base, variants, customization = {} } = _.get(this.formData, 'theme');
			return _.merge(
				_.cloneDeep(customization),
				_.cloneDeep(base),
				_.find(variants, ['name', this.selectedTemplateVariantName])?.theme
			);
		},
		fonts() {
			return _.get(this.formData, 'assets.fonts');
		},
		json() {
			const { blocks, layout } = this.formData;

			if (!blocks || !layout || !this.bookContent) return [];
			const data = JSON.parse(this.bookContent);

			const props = _.get(this.previewOptions?.renderProps, 'templateProps') || _.get(this.formData, 'props');

			const dataToRender = {
				$theme: this.themeVariant,
				$book: data,
				$props: {
					...props,
					isPreview: true
				}
			};

			const blocksToRegister = [
				...blocks,
				...(_.get(this.formData, 'coverDependencies') || []),
				...(_.get(this.formData, 'contentDependencies') || [])
			];

			const bookTemplateProcessor = new BookTemplateProcessor();
			bookTemplateProcessor.registerBlocks(blocksToRegister);
			return bookTemplateProcessor.process(layout, dataToRender, props?.processOptions);
		},
		codeEditorOptions() {
			return { readOnly: this.isEditDisabled ? 'nocursor' : false };
		},
		isPropsEditorDisabled() {
			return (this.productId && this.productContentId) || this.isEditDisabled;
		}
	},
	watch: {
		data() {
			this.initialize();
		},
		selectedTemplateVariantName(value) {
			this.updateField('theme.selectedVariant', value);
		}
	},
	async mounted() {
		await this.fetchProductOptions();
		await this.fetchProductContentOptions();
		await this.fetchDataTypesOptions();

		await this.initialize();
	},
	methods: {
		...mapActions({
			updateLayoutById: 'layout/update',
			getPreviewOptions: 'template/getPreviewOptions',
			findContentById: 'product-content/getContentByProductId',
			findProducts: 'product/find',
			findProductContents: 'product-content/find',
			findDataTypes: 'data-type/find'
		}),
		get: _.get,
		async initialize() {
			const template = _.get(this.data, 'template', null) || _.get(this.data, 'source', null);
			if (!template) return;
			const data = _.omit(this.data, ['template', 'source']);
			this.initFormData({ ...template, ...data });
			const { sizes, renderProps } = this.previewOptions;

			this.isSeparatedStructure =
				_.has(this.formData, 'coverDependencies') && _.has(this.formData, 'contentDependencies');
			this.customisationOptions = _.has(this.formData, 'theme.customisationOptions')
				? this.toJSONString(_.get(this.formData, 'theme.customisationOptions'))
				: null;
			this.selectedTemplateVariantName =
				_.get(this.formData, 'theme.selectedVariant') || _.head(this.templateVariants)?.name;
			this.themeVariants = _.map(_.get(this.formData, 'theme.variants'), variant => ({
				name: variant.name,
				theme: this.toJSONString({ ...variant.theme, ...sizes })
			}));
			this.blocksTemplates = _.map(_.get(this.formData, 'blocks'), item => ({
				name: item.name,
				template: this.toJSONString(item.template)
			}));
			this.bookLayout = this.toJSONString(_.get(this.formData, 'layout'));

			if (this.isSeparatedStructure) {
				this.coverBlockTemplates = _.map(_.get(this.formData, 'coverDependencies'), item => ({
					name: item.name,
					template: this.toJSONString(item.template),
					exampleProps: item?.exampleProps ? this.toJSONString(item.exampleProps) : null
				}));
				this.contentBlockTemplates = _.map(_.get(this.formData, 'contentDependencies'), item => ({
					name: item.name,
					template: this.toJSONString(item.template),
					exampleProps: item?.exampleProps ? this.toJSONString(item.exampleProps) : null
				}));
			}

			if (!this.originalProps) {
				this.originalProps = _.get(this.formData, 'props');
			}
			if (!this.originalThemes) {
				this.originalThemes = _.get(this.formData, 'theme');
			}
			this.templateProps =
				this.toJSONString(_.get(renderProps, 'templateProps')) || this.toJSONString(_.get(this.formData, 'props'));

			this.createBookData();
		},
		initializePageFlip() {
			if (this.pageFlip) {
				return;
			}
			this.pageFlip = new PageFlip(document.getElementById('bookPreview'), {
				width: 400,
				height: 400,
				size: 'stretch',
				minWidth: 50,
				maxWidth: 2500,
				minHeight: 50,
				maxHeight: 2500,
				autoSize: true,
				showCover: false,
				mobileScrollSupport: false
			});
			this.pageFlip.on('flip', e => {
				this.currentPage = e.data;
			});
			this.pageFlip.loadFromHTML(document.querySelectorAll('.book-preview__render .book-preview__render-item'));
		},
		onBlocksEditorInput: _.debounce(function(value) {
			this.blocksTemplates[this.tabIndex].template = value;
			this.checkBlocksToUpdate('blocks', 'blocksTemplates');
		}, 2000),
		onContenBlocksEditorInput: _.debounce(function(value) {
			this.contentBlockTemplates[this.contentBlocksTabIndex].template = value;
			this.checkBlocksToUpdate('contentDependencies', 'contentBlockTemplates');
		}, 2000),
		onCoverBlocksEditorInput: _.debounce(function(value) {
			this.coverBlockTemplates[this.coverBlocksTabIndex].template = value;
			this.checkBlocksToUpdate('coverDependencies', 'coverBlockTemplates');
		}, 2000),
		onPropsEditorInput: _.debounce(function(value) {
			this.templateProps = value;
			this.updateProps();
		}, 2000),
		onThemeEditorInput: _.debounce(function(value) {
			this.themeVariants[this.themeTabIndex].theme = value;
			this.checkThemeToUpdate();
		}, 2000),
		onLayoutEditorInput: _.debounce(function(value) {
			this.bookLayout = value;
			this.updateLayout();
		}, 2000),
		onCustomisationOptionsInput: _.debounce(function(value) {
			this.customisationOptions = value;
			this.updateCustomisationOptions();
		}, 2000),
		onBookDataChange: _.debounce(function(value) {
			try {
				JSON.parse(value);
				this.bookContent = value;
			} catch (error) {
				this.$notify({
					type: 'error',
					title: this.$t('JSON parse error'),
					text: `Book data: ${error}`
				});
			}
		}, 2000),
		turnPage(newValue) {
			const parsedValue = parseInt(newValue);
			const pageNumber = parsedValue % 2 ? parsedValue - 1 : parsedValue;
			this.pageFlip.turnToPage(pageNumber);
		},
		toJSONString(obj) {
			return JSON.stringify(obj, null, 2);
		},
		checkBlocksToUpdate(propertyNameToUpdate, blocksNameToUpdate) {
			const blocks = this.formData[propertyNameToUpdate];
			const blocksToUpdate = _.map(blocks, (block, index) => {
				try {
					const jsonBlock = JSON.parse(this[blocksNameToUpdate][index].template);
					return {
						...block,
						template: jsonBlock
					};
				} catch (error) {
					this.$notify({
						type: 'error',
						title: this.$t('JSON parse error'),
						text: `${block.name}: ${error}`
					});
					return false;
				}
			});

			if (_.includes(blocksToUpdate, false)) return;
			this.updateField(propertyNameToUpdate, blocksToUpdate);
		},
		updateLayout() {
			try {
				const jsonLayout = JSON.parse(this.bookLayout);
				this.updateField('layout', jsonLayout);
			} catch (error) {
				this.$notify({
					type: 'error',
					title: this.$t('JSON parse error'),
					text: `Layout: ${error}`
				});
			}
		},
		updateCustomisationOptions() {
			try {
				const jsonOtions = JSON.parse(this.customisationOptions);
				this.updateField('theme.customisationOptions', jsonOtions);
			} catch (error) {
				this.$notify({
					type: 'error',
					title: this.$t('JSON parse error'),
					text: `Customization options: ${error}`
				});
			}
		},
		switchContentType(newValue) {
			if (this.contentType === newValue) return;
			this.initializePageFlip();
			this.contentType = newValue;
		},
		checkThemeToUpdate() {
			const { theme } = this.formData;
			const themeToUpdate = _.map(theme.variants, (variant, index) => {
				try {
					const jsonVariantTheme = JSON.parse(this.themeVariants[index].theme);
					return {
						...variant,
						theme: jsonVariantTheme
					};
				} catch (error) {
					this.$notify({
						type: 'error',
						title: this.$t('JSON parse error'),
						text: `${variant.name}: ${error}`
					});
					return false;
				}
			});

			if (_.includes(themeToUpdate, false)) return;

			this.updateField('theme.variants', themeToUpdate);
		},
		updateProps() {
			try {
				const templateProps = JSON.parse(this.templateProps);
				this.updateField('props', templateProps);
			} catch (error) {
				this.$notify({
					type: 'error',
					title: this.$t('JSON parse error'),
					text: `Props: ${error}`
				});
			}
		},
		saveLayout() {
			const dataToUpdate = _.omit(this.formData, [
				'blocks',
				'theme',
				'assets',
				'layout',
				'props',
				'coverDependencies',
				'contentDependencies'
			]);
			const { blocks, layout, assets, theme, props, coverDependencies, contentDependencies } = this.formData;
			const dependencyBlocks = this.isSeparatedStructure ? { coverDependencies, contentDependencies } : {};
			if (this.$route.params?.id === 'new') {
				this.$emit('create', {
					...dataToUpdate,
					template: { blocks, layout, assets, theme, props, ...dependencyBlocks }
				});
			} else {
				this.$emit('save', {
					...dataToUpdate,
					template: {
						blocks,
						layout,
						assets,
						props: this.productId || this.productContentId ? this.originalProps : props,
						theme: this.productId || this.productContentId ? this.originalThemes : theme,
						...dependencyBlocks
					}
				});
			}
		},
		async getProductContent() {
			this.contentForPreview = await this.findContentById(this.productContentId);
		},
		async handleProductOptionsChanged(value) {
			this.productId = value;

			if (!this.productId) {
				this.contentForPreview = null;
				this.previewOptions = {
					sizes: null,
					renderProps: null
				};
			}
			if (this.productId && this.productContentId) {
				this.previewOptions = await this.getPreviewOptions({
					productId: this.productId,
					productContentId: this.productContentId
				});
				await this.getProductContent();
			}
			await this.initialize();
		},
		async handleProductContentChanged(value) {
			this.productContentId = value;
			if (!this.productContentId) {
				this.contentForPreview = null;
				this.previewOptions = {
					sizes: null,
					renderProps: null
				};
			}
			if (this.productContentId && this.productId) {
				this.previewOptions = await this.getPreviewOptions({
					productId: this.productId,
					productContentId: this.productContentId
				});
				await this.getProductContent();
			}
			await this.initialize();
		},
		defineActiveButton(buttonName) {
			return buttonName === this.contentType ? 'primary' : '';
		},
		createBookData() {
			if (this.contentForPreview) {
				this.bookContent = this.toJSONString(this.contentForPreview);
				return;
			}
			const exampleProps = _.reduce(
				_.get(this.formData, 'blocks'),
				(props, block) => {
					if (block?.exampleProps) {
						return {
							...props,
							...block.exampleProps
						};
					}
					return props;
				},
				{}
			);
			this.bookContent = this.toJSONString(exampleProps);
		},
		async fetchProductOptions(searchString) {
			const query = {
				$where: {
					templateId: { $exists: true },
					productContentId: { $exists: true },
					format: productFormats.PHYSICAL,
					...(_.isString(searchString) ? { name: { $regex: searchString, $options: 'i' } } : {})
				},
				$limit: 10
			};

			const { data } = await this.findProducts({ query: { query } });
			this.productsOptions = _.map(data, prd => ({ text: prd.name, value: prd._id }));
		},
		searchProducts: _.debounce(async function(query) {
			this.isProductOptionsLoading = true;
			await this.fetchProductOptions(query);
			this.isProductOptionsLoading = false;
		}, 500),
		async onProductSearch(search) {
			if (_.isUndefined(search) || _.isNull(search?.text)) {
				return;
			}
			await this.searchProducts(search.text);
		},
		async fetchProductContentOptions(searchString) {
			const query = {
				$where: {
					...(_.isString(searchString) ? { 'content.headline': { $regex: searchString, $options: 'i' } } : {})
				},
				$limit: 10
			};

			const { data } = await this.findProductContents({ query: { query } });
			this.productContentOptions = _.map(data, item => ({ text: item?.content?.headline, value: item._id }));
		},
		searchProductContents: _.debounce(async function(query) {
			this.isProductContentOptionsLoading = true;
			await this.fetchProductContentOptions(query);
			this.isProductContentOptionsLoading = false;
		}, 500),
		async onProductContentSearch(search) {
			if (_.isUndefined(search) || _.isNull(search?.text)) {
				return;
			}
			await this.searchProductContents(search.text);
		},
		async fetchDataTypesOptions(searchString) {
			const query = {
				$where: {
					...(_.isString(searchString) ? { name: { $regex: searchString, $options: 'i' } } : {})
				},
				$limit: 10
			};

			const { data } = await this.findDataTypes({ query: { query } });
			this.dataTypesOptions = _.map(data, type => ({ text: type.name, value: type.name }));
		},
		searchDataTypes: _.debounce(async function(query) {
			this.isDataTypesOptionsLoading = true;
			await this.fetchDataTypesOptions(query);
			this.isDataTypesOptionsLoading = false;
		}, 500),
		async onDataTypesSearch(search) {
			if (_.isUndefined(search) || _.isNull(search?.text)) {
				return;
			}
			await this.searchDataTypes(search.text);
		}
	}
};
</script>

<style lang="scss" scoped>
.book-preview {
	padding: 25px 0px;
	overflow: hidden;
	overflow-x: auto;
	background-color: #f2f6fe;
	width: 100%;
	height: 100%;

	&__container {
		margin: 0 auto;
		overflow: hidden;
		width: 100%;
		min-width: 360px;
	}

	&__render {
		display: none;

		&-item {
			width: 816px;
			height: 816px;
			overflow: hidden;
		}
	}
}

.layout-toggle {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	fieldset {
		margin-left: 10px;
	}
}

.source-editor {
	max-height: 500px;
	width: 100%;

	&.layout-editor,
	&.theme-editor {
		height: 790px;
		max-height: unset;
	}
}

.code-editor__title {
	margin-top: 50px;
}

.preview-tabs {
	display: flex;
	flex-direction: column;
	min-height: 550px;
	margin-bottom: 50px;
	width: 100%;
	max-width: 100%;

	.tab-content {
		flex: 1;

		.tab-pane {
			height: 100%;

			&:nth-child(2) {
				max-height: 500px;
			}
		}

		.code-editor {
			height: 100%;

			.CodeMirror-sizer {
				min-width: 548.875px;
			}
		}
	}
}

.theme-editor {
	height: 790px;

	.source-editor {
		max-height: 790px;
	}

	.tab-content {
		.tab-pane {
			&:nth-child(2) {
				max-height: 790px;
			}
		}
	}
}

.preview-control {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 10px 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
	margin: 0 auto;

	&__title {
		margin: auto 10px;
	}
}

.responsive-square {
	position: relative;
	background: whitesmoke;
	&:before {
		content: '';
		display: block;
		padding-bottom: 100%;
	}
	& > * {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
</style>
