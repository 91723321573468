<template>
	<b-form novalidate class="CouponEdit-Form" @submit.prevent="onSave">
		<OfsPanel>
			<ContentHeader :title="couponId ? $t('Edit Coupon') : $t('Create Coupon')" class="mb-3" no-padding />
			<div class="row">
				<div class="col-md-12">
					<OfFormInput name="name" :label="$t('Name')" required />
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<OfFormInput :label="$t('Code')" :disabled="!!couponId" name="code" required show-errors />
				</div>
				<div class="col-md-2">
					<OfFormInput type="number" :label="$t('Percent Off')" :disabled="!!couponId" name="percentOff" required show-errors>
						<template v-slot:append>
							<b-input-group-text>%</b-input-group-text>
						</template>
					</OfFormInput>
				</div>
				<div class="col-md-4">
					<OfMultiSelect
						name="intentOverride"
						:options="intentOverrideOptions"
						:placeholder="$t('none')"
						:label="$t('Intent Override')"
						:is-loading="false"
					/>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<OfMultiSelect
						name="productIds"
						:label="$t('Products')"
						:placeholder="$t('Products')"
						multiple
						track-by="value"
						label-by="text"
						:options="productOptions"
						:is-loading="isProductOptionsLoading"
						@search-change="onProductSearch"
					/>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<OfFormTextarea :label="$t('Notes')" name="notes" :rows="3" />
				</div>
			</div>

			<template slot="actions">
				<of-submit-button v-t="'Save'" data-test-id="attribute-save" variant="primary" />
			</template>
		</OfsPanel>
	</b-form>
</template>

<script>
import { mapActions } from 'vuex';
import {
	OfsPanel,
	ContentHeader,
	withForm,
	OfFormTextarea,
	OfFormInput,
	OfSubmitButton,
	OfMultiSelect,
	validateWithMessage
} from '@oneflow/ofs-vue-layout';
import { $t } from '@/vuex';
import { codeSafe } from '@/lib/validators';
import { minValue, maxValue, minLength, maxLength } from 'vuelidate/lib/validators';
import { productAvailability, productStatus } from '@/containers/Create/Products/constants';

const initialFormData = {
	code: '',
	productIds: []
};

export default {
	components: {
		OfsPanel,
		ContentHeader,
		OfFormTextarea,
		OfFormInput,
		OfSubmitButton,
		OfMultiSelect
	},
	mixins: [withForm('CouponForm')],
	data() {
		return {
			productOptions: [],
			isProductOptionsLoading: false,
			productIds: []
		};
	},
	computed: {
		validationRules() {
			return {
				formData: {
					code: {
						minLength: validateWithMessage(this.$t('Must be at least 3 characters'), minLength(3)),
						maxLength: validateWithMessage(this.$t('Must be 24 characters or less'), maxLength(24)),
						codeSafe: validateWithMessage(this.$t('Can only include A-Z 0-9 and _'), codeSafe)
					},
					percentOff: {
						minValue: validateWithMessage(this.$t('Must be 0 or higher'), minValue(0)),
						maxValue: validateWithMessage(this.$t('Must be less than 100'), maxValue(100))
					}
				}
			};
		},
		couponId() {
			const { id } = this.$route.params;
			return id !== 'new' ? id : null;
		},
		intentOverrideOptions() {
			return ['test', 'sample', 'promo'].map(x => ({ text: x, value: x }));
		}
	},
	async mounted() {
		if (!this.couponId) {
			return this.initFormData(initialFormData);
		}
		try {
			const coupon = await this.findCouponById({
				id: this.couponId
			});
			await this.prefetchProducts(coupon.productIds);
			this.initFormData(coupon);
		} catch (err) {
			this.$notify({
				type: 'error',
				title: this.$t('Error'),
				text: this.$t('An error occurred while fetching coupon')
			});
		}
	},
	methods: {
		...mapActions({
			findCouponById: 'coupon/findById',
			createCoupon: 'coupon/create',
			updateCoupon: 'coupon/update',
			findProducts: 'product/find'
		}),
		async onSave() {
			try {
				let message = $t('Coupon updated successfully');
				if (this.formData._id) {
					await this.dispatchSubmit(this.updateCoupon({ id: this.formData._id, data: this.formData }));
				} else {
					message = $t('Coupon created successfully');
					const response = await this.dispatchSubmit(this.createCoupon(this.formData));

					this.updateField('_id', response._id);
					this.$router.push({
						name: 'coupons.edit',
						params: { id: response._id }
					});
				}
				this.$notify({ type: 'success', text: message });
			} catch (err) {
				this.$notify({ type: 'error', text: err.message });
			}
		},
		async prefetchProducts(productIds) {
			const query = {
				$where: {
					_id: { $in: productIds }
				}
			};
			const { data } = await this.findProducts({ query: { query } });
			this.productOptions = _.map(data, prd => ({ text: prd.name, value: prd._id }));
		},
		async fetchProductOptions(searchString) {
			const query = {
				$where: {
					status: productStatus.Published,
					availability: [productAvailability.Available, productAvailability.PreOrder],
					...(this.productIds.length ? { _id: { $nin: this.productIds } } : {}),
					...(_.isString(searchString) ? { name: { $regex: searchString, $options: 'i' } } : {})
				},
				$limit: 10
			};

			const { data } = await this.findProducts({ query: { query } });
			this.productOptions = _.map(data, prd => ({ text: prd.name, value: prd._id }));
		},
		searchProducts: _.debounce(async function(query) {
			this.isProductOptionsLoading = true;
			await this.fetchProductOptions(query);
			this.isProductOptionsLoading = false;
		}, 500),
		async onProductSearch(search) {
			if (_.isUndefined(search) || _.isNull(search?.text)) {
				return;
			}
			await this.searchProducts(search.text);
		}
	}
};
</script>
