import FilePreviewerComponent from './FilePreviewer.vue';

export const FilePreviewerPlugin = {
	install(Vue, rootStore) {
		const VueFilePreviewer = Vue.extend(FilePreviewerComponent);

		function showFilePreviewer(fileOrId) {
			return new Promise(resolve => {
				// instantiate
				const filePreviewerComponent = new VueFilePreviewer({
					el: document.createElement('div'),
					store: rootStore
				});
				if (typeof fileOrId === 'string') {
					filePreviewerComponent.$props.fileId = fileOrId;
				} else if (typeof fileOrId === 'object') {
					filePreviewerComponent.$props.file = fileOrId;
				}

				filePreviewerComponent.$props.visible = true;

				// append and show
				document.body.appendChild(filePreviewerComponent.$el);

				// lifecycle
				filePreviewerComponent.$on('hidden', () => {
					filePreviewerComponent.$destroy();
					resolve();
				});
			});
		}

		if (!Vue.prototype.$showFilePreviewer) {
			Vue.prototype.$showFilePreviewer = showFilePreviewer;
		}
	}
};
