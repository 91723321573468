import _ from 'lodash';

/**
 * Utility function that parses the filterValues object
 * based on a common set of rules
 *
 * @param {Record<string, unknown> & { active?: string }} filterValues
 */
export const parseDefaultQuerystringFilterValues = filterValues => {
	if (typeof filterValues !== 'object') return {};

	return {
		...filterValues,
		...parseQuerystringBoolean('active', filterValues)
	};
};

/**
 * Utility function to parse boolean values
 *
 * @param {string} name
 * @param {Record<string, unknown> & { active?: string }} filterValues
 */
export function parseQuerystringBoolean(name, filterValues) {
	const stringValue = _.get(filterValues, name);
	const booleanValue = stringValue ? stringValue === 'true' : undefined;

	return typeof booleanValue === 'boolean' ? { [name]: booleanValue } : {};
}
