var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('OfsPanel',[_c('ListTable',{attrs:{"bordered":"","hover":"","table-title":_vm.$t('Products'),"config":_vm.config,"items":_vm.productsList,"fields":_vm.fields,"sort":_vm.sort,"total-items":_vm.products.total,"current-page":_vm.currentPage,"per-page":_vm.perPage,"fetch-data":_vm.fetchData,"is-busy":_vm.isLoading},on:{"row-clicked":_vm.handleRowClick,"table-change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"TableButtons-Slot-left",fn:function(ref){return [_c('OfFormInput',{staticClass:"col-6 mr-2",attrs:{"value":_vm.searchString,"placeholder":_vm.$t('Search by product name')},on:{"input":_vm.handleSearchStringInput}}),_c('OfInlineFilter',{attrs:{"filters":_vm.filters,"values":_vm.filterValues},on:{"change":_vm.filtersChanged}})]}},{key:"TableButtons-Slot-right",fn:function(ref){return [_c('b-dropdown',{staticClass:"PresetDropdown ml-2",attrs:{"variant":"primary","text":_vm.$t('New product'),"right":"","no-caret":""}},[_c('b-dropdown-item',{on:{"click":_vm.handleAdd}},[_vm._v(" "+_vm._s(_vm.$t('Custom Product'))+" ")]),_c('b-dropdown-divider'),_c('b-dropdown-text',{staticClass:"dropdown-label"},[_vm._v(" "+_vm._s(_vm.$t('Suggested'))+" ")]),_vm._l((_vm.productPresets),function(preset){return _c('b-dropdown-item',{key:preset.value,staticClass:"PresetDropdown-Item",on:{"click":function($event){return _vm.handleAdd(preset.value)}}},[_vm._v(" "+_vm._s(preset.name)+" "),_c('b-badge',{staticClass:"ml-2",attrs:{"variant":"primary"}},[_vm._v(_vm._s(preset.label))])],1)})],2)]}},{key:"TableHeader",fn:function(ref){return [_c('OfFilterBar',{attrs:{"filters":_vm.filters,"values":_vm.filterValues},on:{"change":_vm.filtersChanged}})]}},{key:"cell(thumbnail)",fn:function(ref){
var item = ref.item;
return [_c('Thumbnail',{attrs:{"src":_vm._get(item, 'previewImages[0].url')}})]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"cell(mode)",fn:function(ref){
var item = ref.item;
return [(item.mode)?_c('OfsBadge',{attrs:{"text":item.mode,"status":"started"}}):_vm._e()]}},{key:"cell(account)",fn:function(ref){
var item = ref.item;
return [(_vm._get(item, 'account.name'))?[_vm._v(_vm._s(item.account.name))]:[_vm._v(_vm._s(item.accountId))]]}},{key:"cell(format)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getProductFormats(item))+" ")]}},{key:"cell(updatedAt)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fromNow(item.updatedAt))+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('OfsBadge',{attrs:{"text":item.status,"status":"ready"}}):_vm._e()]}},{key:"cell(availability)",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('OfsBadge',{attrs:{"text":item.availability,"status":"started"}}):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"size":"sm","toggle-class":"p-1","right":"","no-caret":"","disabled":_vm.isLoading},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('icon',{attrs:{"name":"ellipsis-h"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){$event.stopPropagation();return _vm.removeProduct(item._id)}}},[_c('icon',{staticClass:"mr-2",attrs:{"name":"trash"}}),_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }