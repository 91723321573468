<template>
	<b-modal
		dialog-class="file-previewer-modal"
		:title="title"
		:visible="visible"
		@hidden="(...args) => emitEvent('hidden', args)"
	>
		<div v-if="isLoading" class="p-5 text-center">{{ $t('Loading...') }}</div>
		<div v-else-if="error" class="p-5 text-center">
			<div>{{ $t('An error happened while loading the file:') }}</div>
			{{ error.message }}
		</div>
		<embed v-else :src="pdfUrl" />

		<template #modal-footer="{ hide }">
			<b-button @click="hide">{{ $t('Close') }}</b-button>
		</template>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { $t } from '@/vuex';

export default {
	props: {
		visible: { type: Boolean },
		fileId: { type: String },
		file: { type: Object }
	},
	data() {
		return { isLoading: false, error: null };
	},
	computed: {
		...mapGetters({ fetchedFile: 'file/file' }),
		pdfUrl() {
			const file = this.file ?? this.fetchedFile;

			return file?.urlData?.url;
		},
		title() {
			if (this.isLoading) {
				return this.$t('Loading file to preview...');
			}

			const file = this.file ?? this.fetchedFile;
			return [this.$t('Preview file'), file?.name].concat().join(': ');
		}
	},
	watch: {
		fileId: {
			immediate: true,
			handler() {
				if (this.fileId) {
					this.isLoading = true;
					return this.getFileById({ id: this.fileId })
						.catch(err => {
							this.error = err;
						})
						.finally(() => {
							this.isLoading = false;
						});
				}
			}
		}
	},
	methods: {
		$t,
		...mapActions({ getFileById: 'file/get' }),
		emitEvent(eventType, args) {
			this.$emit(eventType, ...args);
		}
	}
};
</script>

<style lang="scss">
.file-previewer-modal {
	max-width: 100%;
	height: 100vh;
	margin: 0;
	.modal-content {
		height: 100vh;
	}
	.modal-body {
		padding: 0;
	}

	embed {
		width: 100%;
		height: 100%;
	}
}
</style>
