import { createAuth0Client } from '@auth0/auth0-spa-js';
import config from '@/config';

const onRedirectCallback = () => window.history.replaceState({}, document.title, window.location.pathname);

function parseJwt(token) {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(function(c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);

	return JSON.parse(jsonPayload);
}

export const initAuth0 = async ({ commit, state }, options) => {
	if (state.auth0Client) {
		return;
	}
	// Create a new instance of the SDK client using members of the given options object
	const auth0Client = await createAuth0Client({
		...options,
		client_id: options.clientId
	});
	let isAuthenticated;
	let error;
	let loading;
	let authToken;
	let user;
	let permissions;

	try {
		// If the user is returning to the app after authentication..
		if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
			// handle the redirect and retrieve tokens
			const { appState } = await auth0Client.handleRedirectCallback();
			error = null;
			// Notify subscribers that the redirect callback has happened, passing the appState
			// (useful for retrieving any pre-authentication state)
			onRedirectCallback(appState);
		}
	} catch (e) {
		error = e;
		loading = false;
	} finally {
		try {
			// Initialize our internal authentication state
			isAuthenticated = await auth0Client.isAuthenticated();
			authToken = await auth0Client.getTokenSilently({ timeoutInSeconds: 2 });
			user = await auth0Client.getUser();
			permissions = parseJwt(authToken)?.permissions ?? [];
			loading = false;
		} catch (err) {
			error = err;
			loading = false;
		} finally {
			commit('SET_AUTH0_STATE', {
				authToken,
				user,
				isAuthenticated,
				permissions,
				auth0Client,
				error,
				loading
			});
		}
	}
};

export const logOut = ({ commit, state }) => {
	commit('LOG_OUT');
	return state?.auth0Client.logout({ returnTo: config.auth0.redirectUri });
};

export const loginWithRedirect = ({ state }) => {
	return state.auth0Client.loginWithRedirect();
};
