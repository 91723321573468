import Vue from 'vue';
import config from '@/config';

const sampleInitState = {
	productId: null,
	itemId: null,
	workqueueId: null
};

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'product',
		pluralName: 'products',
		transport: 'davinci',
		options: {
			namespaced: true
		},
		state: {
			sample: { ...sampleInitState },
			specifications: {},
			featuredProduct: null
		},
		getters: {
			sample: state => state.sample,
			specifications: state => state.specifications,
			featuredProduct: state => state.featuredProduct
		},
		actions: {
			async findFeatured({ dispatch, commit }, params) {
				const product = await dispatch('request', {
					method: 'GET',
					path: `products/featured`,
					params
				}).then(r => r.data);
				commit('SET_FEATURED_PRODUCT_DATA', product);
				return product;
			},
			async updateProductState({ dispatch, commit }, { productId, payload }) {
				const product = await dispatch('request', {
					method: 'PATCH',
					path: `products/${productId}/state`,
					payload
				}).then(r => r.data);
				commit('SET_PRODUCT_DATA', product);
			},
			async requestSample({ dispatch, commit }, { productId, mode, productFormatId }) {
				dispatch('cleanSample');
				const path = `products/${productId}/sample`;
				const payload = { productId, mode, productFormatId };
				const response = await dispatch('request', { method: 'POST', path, payload });

				commit('SET_SAMPLE_DATA', { productId, ...response.data });
			},
			cleanSample({ commit }) {
				commit('SET_SAMPLE_DATA', { ...sampleInitState });
			},
			async findSpecifications({ dispatch, commit }) {
				const path = `${config.apiBase}/public/productSpecifications.json`;
				const response = await dispatch('request', { path, method: 'GET' });

				commit('SET_SPECIFICATIONS', response.data);
				return response.data;
			},
			async createProduct({ dispatch }, payload) {
				return dispatch('request', {
					method: 'POST',
					path: 'products',
					payload
				}).then(response => response.data);
			}
		},
		mutations: {
			SET_PRODUCT_DATA: (state, data) => {
				state.product = data;
			},
			SET_FEATURED_PRODUCT_DATA: (state, data) => {
				state.featuredProduct = data;
			},
			SET_SAMPLE_DATA: (state, data) => {
				state.sample.productId = data.productId;
				state.sample.itemId = data.itemId;
				state.sample.workqueueId = data.workqueueId;
			},
			SET_SPECIFICATIONS: (state, data) => {
				state.specifications = data;
			}
		}
	});
}
