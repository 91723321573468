<template>
	<OfsPanel>
		<ListTable
			bordered
			hover
			:table-title="$t('Accounts')"
			:config="config"
			:items="accounts.data"
			:fields="fields"
			:sort="sort"
			:total-items="accounts.total"
			:current-page="currentPage"
			:per-page="perPage"
			:fetch-data="fetchData"
			:is-busy="isLoading"
			@table-change="handleTableChange"
			@row-clicked="handleRowClick"
		>
			<template slot="TableButtons-Slot-right" slot-scope="{}">
				<b-button variant="primary" class="ml-2" @click="handleAddClick">
					{{ $t('Create account') }}
				</b-button>
			</template>

			<template slot="TableButtons-Slot-left" slot-scope="{}">
				<OfFormInput
					:value="searchString"
					class="col-6 mr-2"
					:placeholder="$t('Search by account name')"
					@input="handleSearchStringInput"
				/>
			</template>

			<template v-slot:cell(updatedAt)="{ item }">
				{{ fromNow(item.updatedAt) }}
			</template>
		</ListTable>
	</OfsPanel>
</template>

<script>
import moment from 'moment';
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { OfsPanel, ListTable, OfFormInput } from '@oneflow/ofs-vue-layout';
import withQuerystringState from '@/mixins/withQuerystringState';
import { parseDefaultQuerystringFilterValues } from '@/lib/parseDefaultQuerystringFilterValues';

const config = {
	refresh: { visible: true }
};

export default {
	components: {
		OfsPanel,
		ListTable,
		OfFormInput
	},
	mixins: [
		withQuerystringState([
			{ name: 'currentPage', defaultValue: 1, parseValue: Number },
			{ name: 'perPage', defaultValue: 10, parseValue: Number },
			'searchString',
			{ name: 'sort', defaultValue: {}, parseValue: value => value ?? {} },
			{ name: 'filterValues', parseValue: parseDefaultQuerystringFilterValues }
		])
	],
	data() {
		const fields = [
			{ key: 'displayName', label: this.$t('Display name'), sortable: true },
			{ key: 'name', label: this.$t('Account name'), sortable: true },
			{ key: 'updatedAt', label: this.$t('Last modified') }
		];

		return {
			isLoading: false,
			fields,
			config: {
				refresh: { visible: true }
			}
		};
	},
	computed: {
		...mapGetters({
			accounts: 'account-management/accounts'
		})
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			getAccounts: 'account-management/find'
		}),
		_map: _.map,
		fromNow(date) {
			return moment(date).fromNow();
		},
		async fetchData() {
			const query = {
				$limit: this.perPage,
				$skip: this.perPage * (this.currentPage - 1)
			};
			if (Object.keys(this.sort).length > 0) {
				query.$sort = this.sort;
			}
			if (this.searchString) {
				query.$or = [
					{ displayName: { $regex: this.searchString, $options: 'i' } },
					{ name: { $regex: this.searchString, $options: 'i' } }
				];
			}
			try {
				this.isLoading = true;
				await this.getAccounts({ query: { query } });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching accounts')
				});

				throw err;
			} finally {
				this.isLoading = false;
			}
		},
		handleTableChange({ currentPage, perPage, sort }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			if (sort) {
				this.sort = sort;
			}
		},
		handleRowClick(item, index, event) {
			if (event.target.type === 'button') return;
			this.$router.push({ name: 'accounts.edit', params: { id: item.authAccountId } });
		},
		handleAddClick() {
			this.$router.push({ name: 'accounts.edit', params: { id: 'new' } });
		},
		handleSearchStringInput: debounce(function(value) {
			this.searchString = value;
			this.currentPage = 1;
			this.fetchData();
		}, 800)
	}
};
</script>
