<template>
	<b-modal size="md" :title="title" :visible="isOpen" @hide="handleHide">
		<!-- Root error, useful for displaying non-validation errors (I.E. password too weak) -->
		<ServerError name="_root" class="mb-4" />

		<form @submit.prevent="handleSubmit">
			<OfFormInput name="user.name" :label="$t('Full name')" show-errors />
			<ServerError name="user.name" />

			<OfFormInput name="user.email" :label="$t('Email')" show-errors />
			<ServerError name="user.email" />

			<OfFormInput name="user.password" type="password" :label="$t('Password')" show-errors />
			<ServerError name="user.password" />

			<AccountSelect v-if="!userType || isCreatorUserType" name="accountIds" :label="$t('Accounts')" multiple />
		</form>

		<template #modal-footer>
			<b-button @click="$emit('close')">{{ $t('Cancel') }}</b-button>
			<b-button variant="primary" :disabled="!canSubmit" @click="handleSubmit">
				{{ $t('Create') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { OfFormInput, validateWithMessage, withForm } from '@oneflow/ofs-vue-layout';
import { required, email } from 'vuelidate/lib/validators';
import AccountSelect from '@/components/AccountSelect';
import ServerError from '@/components/form/ServerError';
import { withServerErrors } from '@/mixins/withServerErrors';

const roleOptions = [
	{ text: 'pimienta-api-admin', value: 'pimienta-api-admin' },
	{ text: 'pimienta-api-creator', value: 'pimienta-api-creator' }
];

export default {
	components: {
		OfFormInput,
		AccountSelect,
		ServerError
	},
	mixins: [withForm('createUser'), withServerErrors()],
	props: {
		isOpen: {
			type: Boolean
		},
		userType: {
			type: String,
			validator: val => !val || ['admin', 'creator'].includes(val),
			default: null
		}
	},
	data() {
		return { roleOptions };
	},
	computed: {
		isAdminUserType() {
			return this.userType === 'admin';
		},
		isCreatorUserType() {
			return this.userType === 'creator';
		},
		title() {
			if (this.isAdminUserType) return this.$t('New Admin user');
			if (this.isCreatorUserType) return this.$t('New Creator user');

			return this.$t('New user');
		},
		validationRules() {
			return {
				formData: {
					user: {
						name: { required: validateWithMessage(this.$t('Field required'), required) },
						email: {
							required: validateWithMessage(this.$t('Field required'), required),
							email: validateWithMessage(this.$t('Invalid email format'), email)
						},
						password: { required: validateWithMessage(this.$t('Field required'), required) }
					}
				}
			};
		}
	},
	watch: {
		isOpen: {
			immediate: true,
			handler() {
				if (this.isOpen) {
					this.resetFormData();
					this.resetServerErrors();

					let roles = [];
					if (this.isAdminUserType) {
						roles = ['pimienta-api-admin'];
					}
					if (this.isCreatorUserType) {
						roles = ['pimienta-api-creator'];
					}
					this.updateFormData({ roles });
				}
			}
		}
	},
	methods: {
		...mapActions({ createUser: 'user/create' }),
		handleHide() {
			this.$emit('close');
		},
		async handleSubmit() {
			this.serverValidationPromise = this.createUser(this.formData);
			await this.dispatchSubmit(this.serverValidationPromise);
			this.$emit('save');

			this.$notify({
				type: 'success',
				title: 'Success',
				text: this.$t('User created')
			});
		}
	}
};
</script>
