function formatCents(price, currencyCode, fallback = undefined) {
	if (typeof price === 'number' && currencyCode) {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: currencyCode
		});
		return formatter.format(price / 100);
	}
	return fallback;
}

export default formatCents;
