<template>
	<OfsPanel class="CreatorEdit">
		<ContentHeader
			:title="creatorId ? `${$t('Creator')} &quot;${formData.username}&quot;` : 'New Creator'"
			class="mb-3"
			no-padding
		>
		</ContentHeader>
		<b-tabs content-class="mt-3">
			<b-tab :title="$t('General')">
				<b-row>
					<b-col>
						<OfFormInput :label="$t('Creator Name')" name="name" required show-errors />
					</b-col>
					<b-col>
						<OfMultiSelect
							name="accountId"
							:options="accounts.options"
							:placeholder="$t('Linked account')"
							:label="$t('Linked account')"
							:is-loading="accounts.isLoading"
							required
							show-errors
							@search-change="onAccountsSearchChange"
						/>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<OfFormInput :label="$t('Creator Email')" name="email" show-errors />
					</b-col>
					<b-col>
						<OfFormInput :label="$t('Creator Phone')" name="phone" required show-errors />
					</b-col>
				</b-row>
				<hr />
				<b-row>
					<b-col>
						<OfFormInput :label="$t('Address 1')" name="address.address1" show-errors />
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<OfFormInput :label="$t('Address 2')" name="address.address2" show-errors />
					</b-col>
					<b-col>
						<OfFormInput :label="$t('Address 3')" name="address.address3" show-errors />
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<OfFormSelect
							:label="$t('Country')"
							:name="`address.isoCountry`"
							:options="countryOptions"
							:show-errors="true"
							required
						/>
					</b-col>
					<b-col>
						<OfFormInput :label="$t('State')" name="address.state" show-errors />
					</b-col>
					<b-col>
						<OfFormInput :label="$t('City')" name="address.city" show-errors />
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<OfFormInput :label="$t('Postcode')" name="address.postcode" show-errors />
					</b-col>
					<b-col>
						<OfFormSelect :label="$t('Currency')" name="profile.isoCurrency" :options="currencyOptions" required />
					</b-col>
				</b-row>
				<b-row class="mt-3">
					<b-col>
						<b>{{ $t('Creator platforms') }}</b>
					</b-col>
				</b-row>
				<hr class="mt-1" />
				<b-row v-for="(platform, index) in _get(formData, 'platforms', [])" :key="platform.platformId">
					<b-col md="9">
						<OfFormInput :label="getPlatformLabel(platform)" required :name="`platforms[${index}].link`" show-errors>
						</OfFormInput>
					</b-col>
					<b-col md="2">
						<OfFormInput :label="$t('Followers')" type="number" :name="`platforms[${index}].followers`"> </OfFormInput>
					</b-col>
					<b-col class="d-flex flex-column justify-content-end">
						<b-button variant="outline-danger" class="mb-3" @click="deletePlatform(index)">{{ $t('Delete') }}</b-button>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-dropdown variant="primary" :text="$t('Add')" right>
							<b-dropdown-item
								v-for="format in platformsOptions()"
								:key="format._id"
								class="text-capitalize"
								@click="addPlatform(format)"
							>
								{{ format.name }}
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
				</b-row>
			</b-tab>
			<b-tab :title="$t('Profile')">
				<div class="d-flex align-items-center">
					<OfToggle name="profile.visible" :right-side-label="$t('Visible')" />
				</div>
				<b-row>
					<b-col>
						<OfFormInput :label="$t('Creator handle (for use on store)')" name="profile.handle" required show-errors>
							<template v-slot:prepend>
								<b-input-group-text> @ </b-input-group-text>
							</template>
						</OfFormInput>
					</b-col>
					<b-col v-if="!creatorId">
						<OfFormInput :label="$t('Creator username')" name="username" required show-errors></OfFormInput>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<OfFormInput :label="$t('Creator Tagline')" name="profile.tagline" show-errors />
					</b-col>
					<b-col>
						<OfFormInput :label="$t('Brand name')" name="profile.brandName" required show-errors />
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<OfFormTextarea :label="$t('Short Description')" name="profile.shortDescription" required show-errors />
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<OfFormTextarea :label="$t('Biography')" name="profile.biography" :rows="5" required show-errors />
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<OfMultiSelect
							:label="$t('Creator Tags')"
							:placeholder="$t('Add custom tags')"
							:taggable="true"
							:allow-clear="false"
							name="profile.tags"
							show-errors
						/>
					</b-col>
					<b-col>
						<OfFormInput :label="$t('Video URL')" name="profile.videoUrl" show-errors />
					</b-col>
				</b-row>
				<b-row class="mt-3">
					<b-col>
						<b>{{ $t('Creator photos') }}</b>
					</b-col>
				</b-row>
				<hr class="mt-1" />
				<b-alert :show="!isMainPhotoUploaded" variant="warning">
					{{ $t('At least one photo with role Main is required to save') }}
				</b-alert>
				<b-alert :show="!formData.accountId" variant="warning">
					{{ $t('Please select the linked account first') }}
				</b-alert>
				<b-alert :show="!!creatorPhotosError" variant="danger">
					{{ creatorPhotosError }}
				</b-alert>
				<b-row v-if="formData.accountId">
					<b-col>
						<div class="d-flex flex-wrap">
							<div v-for="(photo, index) in creatorPhotos" :key="index" class="ThumbnailItem mr-3 mb-3">
								<Thumbnail :src="photo.imageUrl" size="150" class="mb-2 cursor-pointer" />
								<icon name="times-circle" scale="1.2" class="ThumbnailItem-RemoveBtn" @click="removePhoto(index)" />
								<OfFormSelect
									:name="`profile.photos[${index}].role`"
									:placeholder="$t('Role')"
									:options="photoRolesOptions"
									class="d-block"
									no-label
									required
								/>
							</div>
							<span class="d-inline-block">
								<b-card class="AddMediaBtn" @click="openMediaUploader(null)">
									<icon name="plus" scale="1.5" />
								</b-card>
							</span>
							<MediaUploader
								:title="$t('Upload creator photo')"
								:show="isMediaUploaderVisible"
								:path="mediaUploaderPath"
								:active-tab="1"
								:on-close="closeMediaUploader"
								:accepted-file-types="acceptedFileTypes"
								:account-id="formData.accountId"
								disable-url
								@file-picked="onFilePicked"
							/>
						</div>
					</b-col>
				</b-row>
			</b-tab>
			<b-tab :title="$t('Commission')">
				<b-row>
					<b-col md="3">
						<OfFormInput
							:label="$t('Cross Sales Percent')"
							:step="0.01"
							name="profile.crossSalesPercent"
							type="number"
							show-errors
						>
							<template v-slot:append>
								<b-input-group-text>%</b-input-group-text>
							</template>
						</OfFormInput>
					</b-col>
				</b-row>
			</b-tab>
		</b-tabs>
		<template slot="actions">
			<b-button variant="primary" :disabled="!canSubmit || !isMainPhotoUploaded" @click="saveCreator">
				{{ $t('Save') }}
			</b-button>
		</template>
	</OfsPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { between, required, url, email } from 'vuelidate/lib/validators';
import {
	ContentHeader,
	OfFormInput,
	OfFormSelect,
	OfFormTextarea,
	OfMultiSelect,
	OfsPanel,
	OfToggle,
	validateWithMessage,
	withForm
} from '@oneflow/ofs-vue-layout';
import Thumbnail from '@/components/Thumbnail';
import MediaUploader from '@/components/MediaUploader';
import { photoRoles, photoRolesOptions } from './constants';
import { step } from '@/lib/validators';
import { currencyOptions } from '@/lib/currency';
import countries from '@/lib/country/countries.json';

const validateProfileHandle = value => {
	if (!value) return true;

	const pattern = /^[a-zA-Z0-9_.]*$/g;
	return pattern.test(value);
};

export default {
	components: {
		OfsPanel,
		OfFormInput,
		OfMultiSelect,
		OfToggle,
		Thumbnail,
		MediaUploader,
		ContentHeader,
		OfFormTextarea,
		OfFormSelect
	},
	mixins: [withForm('creatorEdit')],
	data() {
		return {
			accounts: {
				isLoading: false,
				options: []
			},
			fetchedPlatforms: [],
			currencyOptions: _.uniqBy(
				currencyOptions.map(currency => ({
					...currency,
					text: currency.currency,
					value: currency.currency
				})),
				el => el.currency
			),
			isMediaUploaderVisible: false,
			acceptedFileTypes: 'image/jpeg, image/png, image/tiff',
			photoRolesOptions
		};
	},
	computed: {
		...mapGetters({
			creator: 'creator/creator'
		}),
		countryOptions() {
			return Object.entries(countries).map(([code, text]) => ({ text, value: code }));
		},
		creatorId() {
			const { id } = this.$route.params;
			return id !== 'new' ? id : null;
		},
		mediaUploaderPath() {
			return `pimienta/creators/${this.creatorId}`;
		},
		validationRules() {
			return {
				formData: {
					email: { required: validateWithMessage(this.$t('Must be a valid email address'), email) },
					username: this.creatorId
						? {}
						: {
								required: validateWithMessage(
									this.$t('Must be a valid username'),
									value => _.isEmpty(value) || /^[a-zA-Z0-9_-]*$/.test(value)
								)
						  },
					phone: {
						required: validateWithMessage(
							this.$t('Must be a valid phone number'),
							value => _.isEmpty(value) || /^[+-]?\d+(\.\d+)?$/.test(value)
						)
					},
					platforms: {
						$each: {
							link: { required: validateWithMessage(this.$t('The field must be valid url'), url) }
						}
					},
					profile: {
						handle: {
							required: validateWithMessage(this.$t('Creator handle is required'), required),
							isSymbolsValid: validateWithMessage(
								this.$t('Only numbers, latin letters, "underscore" and "dot" characters are allowed'),
								validateProfileHandle
							)
						},
						brandName: { required: validateWithMessage(this.$t('Brand name is required'), required) },
						shortDescription: { required: validateWithMessage(this.$t('Short description is required'), required) },
						biography: { required: validateWithMessage(this.$t('Biography is required'), required) },
						photos: {
							$each: {
								imageUrl: { required: validateWithMessage(this.$t('Photo URL is required'), required) },
								role: { required: validateWithMessage(this.$t('Photo role is required'), required) }
							}
						},
						crossSalesPercent: {
							required: validateWithMessage(this.$t('Cross sales percent is required'), required),
							between: validateWithMessage(this.$t('Must be greater than 0 and lower than 100'), between(0, 100)),
							step: validateWithMessage(this.$t('Must have up to 2 decimal digits'), step(0.01))
						},
						videoUrl: {
							url: validateWithMessage(this.$t('The field must be valid url'), url)
						}
					}
				}
			};
		},
		creatorPhotos: {
			get() {
				return _.get(this.formData, 'profile.photos', []);
			},
			set(value) {
				this.updateField('profile.photos', value);
			}
		},
		creatorPhotosError() {
			const error = _.find(this.validationErrors, error => _.includes(error.path, 'profile.photos'));
			return _.get(error, 'errorMessage', null);
		},
		isMainPhotoUploaded() {
			return _.find(this.creatorPhotos, ['role', photoRoles.Main]);
		}
	},
	watch: {
		async '$route.params.id'() {
			await this.initialize();
		}
	},
	async mounted() {
		await this.initialize();
	},
	methods: {
		platformsOptions() {
			const { platforms } = this.formData;
			return this.fetchedPlatforms.filter(
				platform => !platforms?.some(existingPlatform => existingPlatform.platformId === platform._id)
			);
		},
		_get: _.get,
		...mapActions({
			findCreatorById: 'creator/findById',
			updateCreatorById: 'creator/update',
			createCreator: 'creator/create',
			findAccounts: 'account/find',
			getPlatforms: 'creator/getPlatforms'
		}),
		async initialize() {
			this.fetchedPlatforms = await this.getPlatforms();
			let formData = {};
			if (this.creatorId) {
				formData = await this.findCreatorById({ id: this.creatorId });
			}

			if (formData.accountId) {
				const { data } = await this.findAccounts({
					query: { query: { $where: { authAccountId: formData.accountId } } },
					options: { skipMutations: true }
				});
				this.accounts = {
					isLoading: false,
					options: _.map(data, account => ({ text: account.name, value: account.authAccountId }))
				};
			}

			await this.initFormData({
				platforms: [],
				...formData,
				profile: {
					tags: [],
					...formData.profile
				}
			});
		},
		getPlatformLabel(platform) {
			let label = '';
			if (platform.name) {
				label = platform.name;
			} else {
				const { platformId } = platform;
				for (const platform of this.fetchedPlatforms) {
					if (platformId.includes(platform._id)) {
						label = platform.name;
					}
				}
			}
			return _.capitalize(label);
		},
		deletePlatform(index) {
			const { platforms } = this.formData;
			this.formData.platforms = platforms.slice(0, index).concat(platforms.slice(index + 1));
		},
		addPlatform(format) {
			this.formData.platforms.unshift({
				...format,
				followers: 0,
				engagement: 0,
				following: 0,
				link: '',
				updatedAt: new Date()
			});
		},
		async saveCreator() {
			const dataToSave = { ...this.formData };

			let message = '';
			try {
				if (this.creatorId) {
					await this.updateCreatorById({ id: this.formData._id, data: dataToSave });
					await this.initialize();
					message = this.$t('Creator has been updated');
				} else {
					const creator = await this.createCreator({ ...dataToSave, stage: { name: 'Signed' } });
					this.$router.push({ name: 'creators.edit', params: { id: creator._id } });
					await this.initialize();
					message = this.$t('Creator has been created');
				}
				this.$notify({
					type: 'success',
					title: 'Success',
					text: message
				});
			} catch (err) {
				this.$notify({
					type: 'error',
					title: 'Error',
					text: err.message || this.$t('Failed to save creator')
				});
			}
		},
		removePhoto(index) {
			const currentImages = _.get(this.formData, 'profile.photos', []);
			const filteredImages = currentImages.filter((_, idx) => idx !== index);
			this.updateField('profile.photos', filteredImages);
		},
		openMediaUploader() {
			this.isMediaUploaderVisible = true;
		},
		closeMediaUploader() {
			this.isMediaUploaderVisible = false;
		},
		async onFilePicked(file) {
			const image = {
				imageUrl: file.urlData.url,
				fileId: file._id,
				role: photoRoles.Main
			};
			this.creatorPhotos = _.concat(this.creatorPhotos, image);
		},
		async onAccountsSearchChange(search) {
			const { accountId } = this.formData;
			if (!search && accountId) {
				await this.findAndSetAccount({ query: { $where: { authAccountId: accountId } } });
			} else if (search && search.text) {
				await this.searchAccounts({ query: { $where: { name: { $regex: `^${search.text}` } }, $limit: 10 } });
			}
		},
		searchAccounts: _.debounce(async function(query) {
			this.accounts.isLoading = true;
			await this.findAndSetAccount(query);
			this.accounts.isLoading = false;
		}, 500),
		async findAndSetAccount(query) {
			const { data } = await this.findAccounts({ query, options: { skipMutations: true } });
			this.accounts.options = _.map(data, account => ({ text: account.name, value: account.authAccountId }));
		}
	}
};
</script>

<style lang="scss" scoped>
@import 'src/styles/shared';

.CreatorEdit {
	.ContentHeader {
		.OfToggle {
			margin-bottom: 0;
		}
	}

	.ThumbnailItem {
		position: relative;

		&-RemoveBtn {
			position: absolute;
			top: -6px;
			right: -6px;
			z-index: 2;
			cursor: pointer;

			&:hover {
				color: $of-color-red;
			}
		}
	}

	.AddMediaBtn {
		width: 150px;
		height: 150px;
		cursor: pointer;

		.card-body {
			display: flex;
			justify-content: center;
			align-items: center;

			&:hover {
				background-color: $of-color-light;
			}
		}
	}
}
</style>
