var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('OfsPanel',[_c('ListTable',{attrs:{"bordered":"","hover":"","table-title":_vm.$t('Admin users'),"config":_vm.config,"items":_vm.users.data,"fields":_vm.fields,"total-items":_vm.users.total,"current-page":_vm.currentPage,"per-page":_vm.perPage,"fetch-data":_vm.fetchData,"is-busy":_vm.isLoading},on:{"table-change":_vm.handleTableChange,"row-clicked":_vm.handleRowClick},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('b-avatar',{staticClass:"mr-3",attrs:{"src":item.picture,"size":"2em"}}),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"cell(last_login)",fn:function(ref){
var item = ref.item;
return [(_vm.loadingHash[item.user_id])?_c('div',[_c('b-skeleton')],1):_c('div',[_vm._v(" "+_vm._s(item.last_login ? _vm.fromNow(item.last_login) : _vm.$t('Never'))+" ")])]}},{key:"cell(email_verified)",fn:function(ref){
var item = ref.item;
return [(_vm.loadingHash[item.user_id])?_c('div',[_c('b-skeleton')],1):_c('OfsBadge',{attrs:{"text":item.email_verified ? 'verified' : 'not verified',"status":item.email_verified ? 'success' : 'pending'}})]}},{key:"TableButtons-Slot-right",fn:function(){return [_c('b-button',{staticClass:"ml-2",attrs:{"variant":"primary"},on:{"click":function($event){_vm.isCreateUserModalOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('New admin user'))+" ")])]},proxy:true}])}),_c('CreateUserModal',{attrs:{"is-open":_vm.isCreateUserModalOpen,"user-type":"admin"},on:{"close":function($event){_vm.isCreateUserModalOpen = false},"save":function($event){_vm.isCreateUserModalOpen = false;
			_vm.fetchData();}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }