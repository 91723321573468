/**
 * Mixin that parses and returns validation errors returned by the server
 */
export const withServerErrors = () => {
	return {
		provide() {
			return { parentVm: this };
		},
		data() {
			return {
				serverValidationPromise: null,
				serverErrors: {}
			};
		},
		watch: {
			serverValidationPromise: {
				immediate: true,
				handler() {
					if (this.serverValidationPromise) {
						this.serverValidationPromise.catch(err => {
							const validationErrors = err?.response?.data?.errors ?? [];

							this.serverErrors = validationErrors.reduce(
								(acc, err) => {
									let path = _.trim(err.dataPath.replace(/^\/data/, '').replaceAll('/', '.'), '.');
									if (err.keyword === 'required') {
										path = _.compact([path, err.params.missingProperty]).join('.');
									}

									acc[path] = acc[path] ?? { errors: [] };
									acc[path].errors.push(err.message);
									// eslint-disable-next-line no-underscore-dangle
									acc._root = null;

									return acc;
								},
								{ _root: { errors: [err.response?.data?.message ?? err.message] } }
							);
						});
					}
				}
			}
		},
		methods: {
			resetServerErrors() {
				this.serverErrors = {};
			}
		}
	};
};
