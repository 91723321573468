import Vue from 'vue';
import OfsMenu, { OfsForm } from '@oneflow/ofs-vue-layout';
import BootstrapVue from 'bootstrap-vue';
import VueTippy, { TippyComponent } from 'vue-tippy';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import '@oneflow/ofs-vue-layout/dist/index.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store, { i18n } from './vuex';
import router, { menuStructure } from './router';
import App from './App';
import './registerServiceWorker';
import packageJson from '../package.json';
import VueCarousel from 'vue-carousel';
import config from './config';
import { FilePreviewerPlugin } from '@/components/FilePreviewer/plugin';

window.$config = Object.assign({}, window.$config, config);

// Sentry Error Logging
const sentryKey = window.$config.sentryKey;
if (sentryKey && window.location.hostname !== 'localhost') {
	Sentry.init({
		dsn: sentryKey,
		release: packageJson.version,
		environment: window.$config.environment,
		integrations: [new Integrations.Vue({ Vue })]
	});
}

Vue.config.productionTip = false;
Vue.use(OfsMenu, { router, store, menu: menuStructure });
Vue.use(BootstrapVue);
Vue.use(OfsForm, { store });
Vue.use(VueTippy);
Vue.use(VueCarousel);
Vue.use(FilePreviewerPlugin, store);

Vue.component('tippy', TippyComponent);

/* eslint-disable no-new */
new Vue({
	i18n,
	el: '#app',
	router,
	store,
	components: { App },
	template: '<App/>'
});
