import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'order',
		pluralName: 'orders',
		transport: 'davinci',
		options: {
			namespaced: true
		},
		state: {
			releasingOrders: [],
			failedToReleaseOrders: [],
			releasingOrdersTotal: 0
		},
		actions: {
			async cancelOrder({ dispatch }, { orderId, payload }) {
				return dispatch('request', { method: 'PATCH', path: `orders/${orderId}/cancel`, payload }).then(r => r.data);
			},
			async cancelProductionOrder({ dispatch }, { orderId, payload, productionOrderId }) {
				return dispatch('request', {
					method: 'PATCH',
					path: `orders/${orderId}/${productionOrderId}/cancel`,
					payload
				}).then(r => r.data);
			},
			async releaseOrders({ dispatch, commit }, { orders }) {
				commit('SET_RELEASING_ORDERS', orders);
				commit('SET_RELEASING_ORDERS_TOTAL', _.size(orders));
				await dispatch('request', { method: 'POST', path: `orders/release`, payload: { orders } }).then(r => r.data);
			},
			async resubmitOrder({ dispatch }, { orderId, payload, productionOrderId }) {
				return dispatch('request', {
					method: 'POST',
					path: `orders/${orderId}/${productionOrderId}/resubmit`,
					payload
				}).then(r => r.data);
			},
			async updateOrder({ dispatch }, { orderId, payload }) {
				return dispatch('request', { method: 'PATCH', path: `orders/${orderId}`, payload }).then(r => r.data);
			},
			async addOrderComment({ dispatch }, { orderId, payload }) {
				return dispatch('request', { method: 'POST', path: `orders/${orderId}/comment`, payload }).then(r => r.data);
			}
		},
		mutations: {
			SET_RELEASING_ORDERS(state, value) {
				state.releasingOrders = value;
			},
			SET_RELEASING_ORDERS_TOTAL(state, value) {
				state.releasingOrdersTotal = value;
			},
			SET_FAILED_TO_RELEASE_ORDERS(state, value) {
				state.failedToReleaseOrders = value;
			},
			RESET_ORDERS_RELEASE(state) {
				state.releasingOrders = [];
				state.failedToReleaseOrders = [];
				state.releasingOrdersTotal = 0;
			}
		}
	});
}
