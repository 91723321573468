import Vue from 'vue';
import OfsCrud from '@ofs-vuex-crud';
import httpConnectHttpTransport from './httpConnectHttpTransport';
import httpProviderInstance from './httpProviderInstance';
import httpDaVinciTransport from './httpDaVinciTransport';
import httpAssetLockerTransport from './httpAssetLockerTransport';

import registerAccountModule from './account';
import registerAccountManagementModule from './account-management';
import registerLangStoreModule from './lang';
import registerNotificationStoreModule from './version-notification';
import registerMediaUploaderModule from './media-uploader';
import registerOrderStoreModule from './order';
import registerTemplateStoreModule from './template';
import registerShippingMethodStoreModule from './shipping-method';
import registerDocumentStoreModule from './document';
import registerProductStoreModule from './product';
import registerPayoutCodeStoreModule from './payoutCode';
import registerCouponStoreModule from './coupon';
import registerLayoutStoreModule from './layout';
import registerLayoutProviderStoreModule from './layoutProvider';
import registerProductContentModule from './productContent';
import registerCreatorModule from './creator';
import registerDataTypeModule from './dataType';
import registerRecommendationRuleModule from './recommendationRule';
import registerAuth0Module from './auth0';
import registerCategoriesModule from './categories';
import registerUserModule from './user';
import registerProductReleaseModule from './productRelease';
import registerFileModule from './file';

const setupModules = (store, i18n) => {
	Vue.use(OfsCrud, { store });

	Vue.$ofsCrud.registerTransport({ name: 'connect', fn: () => httpConnectHttpTransport });
	Vue.$ofsCrud.registerTransport({ name: 'http', fn: () => httpProviderInstance });
	Vue.$ofsCrud.registerTransport({ name: 'davinci', fn: () => httpDaVinciTransport });
	Vue.$ofsCrud.registerTransport({ name: 'assetlocker', fn: () => httpAssetLockerTransport });

	// Register Modules
	registerAccountModule(store);
	registerLangStoreModule(store, i18n);
	registerAccountManagementModule(store);
	registerNotificationStoreModule(store);
	registerMediaUploaderModule(store);
	registerOrderStoreModule(store);
	registerTemplateStoreModule(store);
	registerShippingMethodStoreModule(store);
	registerDocumentStoreModule(store);
	registerProductStoreModule(store);
	registerPayoutCodeStoreModule(store);
	registerCouponStoreModule(store);
	registerLayoutStoreModule(store);
	registerLayoutProviderStoreModule(store);
	registerProductContentModule(store);
	registerCreatorModule(store);
	registerDataTypeModule(store);
	registerRecommendationRuleModule(store);
	registerAuth0Module(store);
	registerCategoriesModule(store);
	registerUserModule(store);
	registerProductReleaseModule(store);
	registerFileModule(store);
};

export default setupModules;
