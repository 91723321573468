<template>
	<div>
		<h2>{{ $t('Order items') }}</h2>
		<b-card
			v-for="({ product, productFormatId, productId, options, quantity, _id, pdfUrl, payouts }, index) in items"
			:key="_id"
			class="OrderItem mb-2"
			body-class="OrderItem__body"
			header-class="p-2 bg-white"
		>
			<template #header>
				<div v-b-toggle="`order-item-${index}`" class="OrderItem__header container" :disabled="!isViewMode">
					<div class="row">
						<div v-if="isViewMode" class="col-auto d-flex align-items-center pr-0">
							<icon name="caret-right" class="arrow-right mr-2" />
							<icon name="caret-down" class="arrow-left mr-2" />
						</div>
						<div class="col-md-1">
							<Thumbnail :src="_get(product, 'previewImages.[0].url')" size="46" />
						</div>
						<div class="col">
							<div class="row">
								<div class="col-md-8">
									<router-link :to="buildRouterLink('products.edit', productId)" class="font-weight-bold text-dark">
										{{ _get(product, 'name') }}
									</router-link>
								</div>
								<div class="col-md-4">
									<OfsBadge
										v-if="getProductFormatFulfilment(product, productFormatId)"
										:text="getProductFormatFulfilment(product, productFormatId)"
										status="open"
									/>
								</div>
							</div>
							<div class="row d-flex ">
								<div class="OrderItem__info-text col-auto text-black-50 pr-0">
									{{ $t('Price') }}:
									<span class="mr-0 text-dark">
										{{ getOrderPrice(items[index]) }}
									</span>
								</div>
								<div v-if="isViewMode" class="OrderItem__info-text col-auto text-black-50 p-0">
									<icon name="circle" scale="0.2" class="m-2 text-primary" />
									{{ $t('Copies') }}: <span class="text-dark">{{ quantity }}</span>
								</div>
								<div
									v-if="isViewMode && isItemWithDigitalFormat(product, productFormatId)"
									class="OrderItem__info-text col-auto text-black-50 p-0"
								>
									<icon name="circle" scale="0.2" class="m-2 text-primary" />
									<a :href="pdfUrl" target="_blank">{{ $t('PDF file') }}</a>
								</div>
							</div>
						</div>
					</div>
					<div v-if="!isViewMode" class="row">
						<div class="col-md-6">
							<div class="col-auto pr-0 text-white">{{ $t('Copies') }}</div>
							<OfFormInput
								:disabled="lineItemIds && lineItemIds.length ? !lineItemIds.includes(_id) : false"
								:name="`${_id}.quantity`"
								:label="$t('Copies')"
								type="number"
							/>
						</div>
					</div>
				</div>
			</template>
			<b-collapse v-if="isViewMode" :id="`order-item-${index}`" class="ItemBody">
				<div class="ml-2 mb-1 mt-2">
					<b>{{ $t('Pricing Breakdown') }}</b>
				</div>
				<div class="ItemAmounts">
					<div class="text-black-50">
						{{ $t('List Price') }}:
						<span class="mr-0 text-dark">
							{{ formatCents(items[index].listPrice, items[index].isoCurrency, '-') }}
						</span>
					</div>
					<div class="text-black-50">
						{{ $t('Discount Amount') }}:
						<span class="text-dark">
							{{ formatCents(items[index].discountAmount, items[index].isoCurrency, '-') }}
							{{ items[index].discountRate > 0 ? `(${items[index].discountRate * 100}%)` : '' }}
						</span>
					</div>
					<div class="text-black-50">
						{{ $t('Sale Price') }}:
						<span class="text-dark">
							{{ formatCents(items[index].salePrice, items[index].isoCurrency, '-') }}
						</span>
					</div>
					<div class="text-black-50">
						{{ $t('Tax Amount') }}:
						<span class="text-dark">
							{{ formatCents(items[index].taxAmount, items[index].isoCurrency, '-') }}
						</span>
					</div>
					<div class="text-black-50">
						{{ $t('Net Price') }}:
						<span class="text-dark">
							{{ formatCents(items[index].netPrice, items[index].isoCurrency, '-') }}
						</span>
					</div>
				</div>

				<template v-if="payouts.length">
					<div class="ml-2 mb-1 mt-2">
						<b>{{ $t('Payouts') }}</b>
					</div>
					<Datatable :items="payouts" :fields="payoutFields">
						<template #cell(payee)="{ item }">
							<router-link :to="buildRouterLink('creators.edit', _get(item, 'payee._id'))">
								{{ _get(item, 'payee.username') }}
							</router-link>
						</template>
						<template #cell(reason)="{ item }"><OfsBadge :text="item.reason"/></template>
						<template #cell(percent)="{ item }">{{ item.percent }}%</template>
						<template #cell(value)="{ item }">{{ formatCents(item.value, item.isoCurrency) }}</template>
					</Datatable>
				</template>
			</b-collapse>
		</b-card>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { OfFormInput } from '@oneflow/ofs-vue-layout/src/components/form';
import Thumbnail from '@/components/Thumbnail';
import formatCents from '@/lib/formatCents';
import { withForm, OfsBadge, Datatable } from '@oneflow/ofs-vue-layout';
import { productFormatFulfilment } from '@/containers/Create/Products/constants';
import { integer, minValue, required } from 'vuelidate/lib/validators';

export default {
	components: {
		Thumbnail,
		OfFormInput,
		OfsBadge,
		Datatable
	},
	mixins: [withForm('OrderItems')],
	props: {
		items: {
			type: Array,
			default: () => []
		},
		isViewMode: {
			type: Boolean,
			default: true
		},
		lineItemIds: {
			type: Array,
			default: null
		}
	},
	data() {
		return {
			payoutFields: [
				{ label: this.$t('Payee'), key: 'payee' },
				{ label: this.$t('Reason'), key: 'reason' },
				{ label: this.$t('Percent'), key: 'percent' },
				{ label: this.$t('Value'), key: 'value' }
			]
		};
	},
	computed: {
		...mapGetters({
			products: 'product/products'
		}),
		validationRules() {
			const quantityValidations = {
				required,
				integer,
				minValue: minValue(0),
				trueInteger: val => {
					const expr = /^(0|[1-9][0-9]*)$/g;
					return expr.test(val);
				}
			};
			const keys = _.map(this.items, '_id');
			const validations = _.reduce(
				keys,
				(acc, value) => {
					if (value) {
						acc[value] = {
							quantity: quantityValidations
						};
					}
					return acc;
				},
				{}
			);
			return {
				formData: {
					...validations
				}
			};
		}
	},
	watch: {
		isViewMode(newIsViewMode) {
			if (!newIsViewMode) {
				this.orderItemKeys = _.map(this.items, '_id');
				this.initFormData(this.buildInitialFormData(this.items));
			}
		},
		formData(newValue, oldValue) {
			if (this.isViewMode || _.isEmpty(oldValue) || (_.isEmpty(newValue) && _.isEmpty(oldValue))) {
				return;
			}

			const eventValue = _.reduce(
				newValue,
				(acc, value, key) => {
					acc[key] = {
						productId: value.productId,
						quantity: value.quantity
					};
					return acc;
				},
				{}
			);
			this.$emit('change', { value: eventValue, isValid: !this.$v.$invalid });
		}
	},
	methods: {
		_get: _.get,
		_isEmpty: _.isEmpty,
		formatCents,
		getOrderPrice(item) {
			return formatCents(item?.salePrice, item?.isoCurrency) || 0;
		},
		getProductFormatFulfilment(product, formatId) {
			const formats = _.get(product, 'formats');
			return _.find(formats, ['_id', formatId])?.fulfilment;
		},
		isItemWithDigitalFormat(product, formatId) {
			return this.getProductFormatFulfilment(product, formatId) === productFormatFulfilment.digital;
		},
		buildRouterLink(path, id) {
			return {
				name: path,
				params: { id }
			};
		},
		buildInitialFormData(orderItems) {
			return _.reduce(
				orderItems,
				(acc, orderItem) => {
					acc[orderItem._id] = {
						productId: orderItem.productId,
						quantity: orderItem.quantity
					};
					return acc;
				},
				{}
			);
		}
	}
};
</script>

<style lang="scss">
@import '../../styles/shared';

.OrderItem {
	&__info-text {
		font-size: 14px !important;
	}

	&__header {
		font-weight: normal;

		.arrow-left,
		.arrow-right {
			width: 16px;
		}

		&.collapsed {
			.arrow-left {
				display: none;
			}
		}

		&:not(.collapsed) {
			.arrow-right {
				display: none;
			}
		}
	}

	&__body {
		padding: 0;

		.ItemBody {
			&-header {
				margin: 0;
				padding: 10px 15px;
			}

			.ItemAmounts {
				padding-left: 20px;
				padding-bottom: 10px;
				border-bottom: 1px solid $of-color-grey-3;
			}
		}
	}
}
</style>
