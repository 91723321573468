import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'template',
		pluralName: 'templates',
		transport: 'davinci',
		options: {
			namespaced: true
		},
		actions: {
			async renderTemplate({ dispatch }, { template, data, productId }) {
				const path = 'templates/render';
				const payload = { template, data, productId };
				const { data: result } = await dispatch('request', { path, method: 'POST', payload });
				return result;
			},
			getPreviewOptions: async ({ dispatch }, payload) => {
				return dispatch('request', {
					method: 'POST',
					path: 'templates/preview-options',
					payload
				}).then(response => response.data);
			}
		}
	});
}
