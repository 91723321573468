<template>
	<div class="product-sample">
		<b-row>
			<b-col md="12" class="mb-2 d-flex flex-column">
				<label>{{ $t('Please select product format for preview before requesting a sample') }}</label>
				<b-form-select
					v-model="productFormatId"
					:options="pdfFormatOptions"
					:disabled="isLoading"
					class="product-sample__format-select"
				></b-form-select>
			</b-col>
			<b-col md="5" class="d-flex flex-row-start">
				<b-button
					v-for="sampleConfig in baseSampleConfigurations"
					:key="sampleConfig.mode"
					:disabled="isLoading || isDisabled"
					class="mr-2 mb-1"
					variant="secondary"
					@click="onRequestProductSample(sampleConfig.mode)"
				>
					{{ sampleConfig.text }}
				</b-button>
				<b-button
					v-if="isSampleFormatDigital"
					:disabled="isLoading || isDisabled"
					class="mb-1"
					@click="onRequestProductSample(pdfSampleModeTypes.creatorsUse)"
				>
					{{ $t('Request sample for creators') }}
				</b-button>
			</b-col>
		</b-row>
		<b-alert variant="success" :show="productSample.workqueueId" class="mt-2">
			<div>
				{{ $t('Render Workqueue') }}: <a :href="workqueueUrl" target="_blank">{{ $t('View') }}</a>
			</div>
		</b-alert>
	</div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { pdfSampleModeTypes } from '../../../lib/constants';
import { productFormatFulfilment } from './constants';

export default {
	props: {
		productId: {
			type: String,
			required: true
		},
		pdfFormats: {
			type: Array,
			default: () => []
		}
	},
	data() {
		const baseSampleConfigurations = [
			{
				text: this.$t('Request sample for review'),
				mode: pdfSampleModeTypes.customerReview
			},
			{
				text: this.$t('Request HD sample'),
				mode: pdfSampleModeTypes.internalUse
			}
		];

		return {
			pdfSampleModeTypes,
			isLoading: false,
			workforce: _.get(window.$config, 'workforce', {}),
			productFormatId: null,
			baseSampleConfigurations
		};
	},
	computed: {
		...mapGetters({
			productSample: 'product/sample'
		}),
		workqueueUrl() {
			const { host, appId } = _.get(window.$config, 'workforce', {});
			return `${host}/#/apps/${appId}/workqueues/${this.productSample.workqueueId}`;
		},
		pdfFormatOptions() {
			return _.map(this.pdfFormats, ({ _id, fulfilment, name }) => ({ text: `${name}-${fulfilment}`, value: _id }));
		},
		isDisabled() {
			return !_.size(this.pdfFormatOptions) || !this.productFormatId;
		},
		isSampleFormatDigital() {
			const productFormat = _.find(this.pdfFormats, ['_id', this.productFormatId]);
			return _.get(productFormat, 'fulfilment') === productFormatFulfilment.digital;
		}
	},
	mounted() {
		if (this.productSample.productId !== this.productId) {
			this.cleanProductSample();
		}
	},
	methods: {
		...mapActions({
			requestProductSample: 'product/requestSample',
			cleanProductSample: 'product/cleanSample'
		}),
		async onRequestProductSample(mode) {
			this.isLoading = true;

			try {
				const { productId, productFormatId } = this;
				await this.requestProductSample({ productId, mode, productFormatId });
			} catch (error) {
				this.$notify({
					type: 'error',
					title: this.$t('Product sample request error'),
					text: _.get(error, 'response.data.message', error.message)
				});
			} finally {
				this.isLoading = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.product-sample {
	&__format-select {
		max-width: 450px;
		min-width: 250px;
	}
}
</style>
