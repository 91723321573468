<template>
	<b-modal
		size="md"
		:title="$t('Update Product State')"
		:ok-title="$t('Update')"
		:cancel-title="$t('Cancel')"
		:visible="isVisible"
		:ok-disabled="submitDisabled"
		@close="hideModal"
		@hide="hideModal"
		@ok="onSubmit"
	>
		<b-row>
			<b-alert :show="!hasProductAvailbleFormats" variant="warning">
				{{ $t("Can't publish product until one of the formats doesn't have availble format") }}
			</b-alert>
		</b-row>
		<b-row>
			<b-col md="6">
				<OfMultiSelect
					name="status"
					:allow-clear="false"
					:options="productStatusOptions"
					:placeholder="$t('Status')"
					:label="$t('Status')"
					required
				/>
			</b-col>
			<b-col md="6">
				<OfMultiSelect
					name="availability"
					:allow-clear="false"
					:options="productAvailabilityOptions"
					:placeholder="$t('Availability')"
					:label="$t('Availability')"
					required
				/>
			</b-col>
			<b-col md="6">
				<label class="col-form-label" for="expectedReleaseDate_datePicker">{{ $t('Expected Available Date') }}</label>
				<Datepicker
					id="expectedReleaseDate_datePicker"
					v-model="expectedReleaseDate"
					:input-class="isReleaseDateRequired ? 'form-control is-invalid' : 'form-control'"
					:clearable="false"
					value-type="format"
				/>
			</b-col>
		</b-row>
		<b-row class="mt-3">
			<b-col md="12">
				<OfFormInput name="updateReason" no-label :placeholder="$t('Update reason')" required />
			</b-col>
		</b-row>

		<div v-if="error" class="mt-4 request-error">
			<span> {{ $t('Request failed') }} : {{ error }} </span>
		</div>
	</b-modal>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { required, requiredIf } from 'vuelidate/lib/validators';
import Datepicker from 'vue2-datepicker';
import { withForm, OfMultiSelect, OfFormInput, validateWithMessage } from '@oneflow/ofs-vue-layout';
import {
	productStatusOptions,
	productAvailabilityOptions,
	productAvailability,
	productStatus,
	productFormatAvailability
} from './constants';
import 'vue2-datepicker/index.css';

export default {
	components: {
		OfFormInput,
		OfMultiSelect,
		Datepicker
	},
	mixins: [withForm('updateProductStateModal')],
	props: {
		isVisible: {
			type: Boolean,
			default: () => false
		}
	},
	data() {
		return {
			productStatusOptions,
			productAvailabilityOptions,
			isLoading: false,
			error: null
		};
	},
	computed: {
		...mapGetters({
			product: 'product/product'
		}),
		expectedReleaseDate: {
			get() {
				// Display date in local time
				return moment(this.formData.expectedReleaseDate).format('YYYY-MM-DD');
			},
			set(value) {
				const utcDate = value
					? moment(value)
						.utcOffset(0, false) //eslint-disable-line
						.format() //eslint-disable-line
					: null; // Store date in UTC format with universal locale
				this.updateField('expectedReleaseDate', utcDate);
			}
		},
		isPreOrderAvailability() {
			return _.get(this.formData, 'availability') === productAvailability.PreOrder;
		},
		isProductPublished() {
			return _.get(this.formData, 'status') === productStatus.Published;
		},
		isReleaseDateRequired() {
			return (
				this.isPreOrderAvailability && this.isProductPublished && _.isNil(_.get(this.formData, 'expectedReleaseDate'))
			);
		},
		hasProductAvailbleFormats() {
			const formats = _.get(this.formData, 'formats');
			return _.some(formats, ['availability', productFormatAvailability.available]);
		},
		canUpdateStatus() {
			const formStatus = _.get(this.formData, 'status');
			return !(formStatus === productStatus.Published && !this.hasProductAvailbleFormats);
		},
		submitDisabled() {
			return this.isInvalid || this.isLoading || this.isReleaseDateRequired || !this.canUpdateStatus;
		}
	},
	watch: {
		isVisible(value) {
			if (value) {
				this.initFormData(_.pick(this.product, ['status', 'availability', 'expectedReleaseDate', 'formats']));
			} else {
				this.initFormData({});
			}
		}
	},
	methods: {
		...mapActions({
			updateProductState: 'product/updateProductState'
		}),
		async onSubmit(event) {
			event.preventDefault();
			this.isLoading = true;

			try {
				await this.updateProductState({ productId: this.product.id, payload: this.formData });
				this.$emit('complete');
			} catch (err) {
				this.error = _.get(err, 'response.data.message') || err.message;
			} finally {
				this.isLoading = false;
			}
		},
		hideModal(event) {
			if (event.trigger !== 'ok') {
				this.$emit('close');
			}
		}
	}
};
</script>
