<template>
	<OfsPanel class="UserEdit">
		<div v-if="isLoadingUser" class="text-center py-5">
			<Loader />
		</div>
		<template v-else>
			<div class="flex row align-items-center mb-3">
				<div class="flex-grow-0 mr-2">
					<b-avatar :src="user.picture" size="4em" />
				</div>
				<div>
					<ContentHeader :title="`${$t('User')} &quot;${user.name}&quot;`" no-padding>
						<div class="ml-2">
							<OfsBadge v-if="isLoadingRoles" :text="$t('loading')" />
							<template v-else>
								<OfsBadge v-if="userRoles.includes('pimienta-api-admin')" :text="$t('Administrator')" status="live" />
								<OfsBadge
									v-if="userRoles.includes('pimienta-api-creator')"
									:text="$t('Creator')"
									class="ml-1"
									status="warning"
								/>
							</template>
						</div>
					</ContentHeader>
				</div>
			</div>

			<div>
				<div class="mt-6">
					<dl class="row">
						<div class="border-top border-gray-100 py-2 col-12 col-sm-6">
							<dt class="text-sm font-weight-bold text-gray-900">{{ $t('Name') }}</dt>
							<dd class="mt-1 text-sm text-gray-700">{{ user.name }}</dd>
						</div>
						<div class="border-top border-gray-100 py-2 col-12 col-sm-6">
							<dt class="text-sm font-weight-bold text-gray-900">{{ $t('Email address') }}</dt>
							<dd class="mt-1 text-sm text-gray-700">{{ user.email }}</dd>
						</div>
						<div class="border-top border-gray-100 py-2 col-12 col-sm-6">
							<dt class="text-sm font-weight-bold text-gray-900">{{ $t('Signed up') }}</dt>
							<dd class="mt-1 text-sm text-gray-700">{{ user.created_at ? fromNow(user.created_at) : '-' }}</dd>
						</div>
						<div class="border-top border-gray-100 py-2 col-12 col-sm-6">
							<dt class="text-sm font-weight-bold text-gray-900">{{ $t('Last Login') }}</dt>
							<dd class="mt-1 text-sm text-gray-700">{{ user.last_login ? fromNow(user.last_login) : '-' }}</dd>
						</div>
					</dl>
				</div>
			</div>

			<b-row v-if="isCreator">
				<b-col sm="12" lg="6">
					<div class="d-flex justify-content-between mb-2">
						<h2 class="font-semibold leading-7 text-gray-900">Accounts</h2>

						<b-button size="sm" @click="isNewMemberModalVisible = true">
							<icon name="plus" aria-hidden="true" class="mr-1" />
							{{ $t('Add') }}
						</b-button>
					</div>

					<Datatable
						:items="userMemberships ? userMemberships.data : []"
						:total-items="userMemberships ? userMemberships.total : null"
						:fields="userMembershipsFields"
						:current-page="currentPage"
						:per-page="perPage"
						:fetch-data="fetchMemberships"
						:is-busy="isLoadingMemberships"
						@table-change="handleMembershipsTableChange"
					>
						<template #cell(actions)="row">
							<b-button @click="handleDeleteMembership(row.item)">
								<icon name="trash" aria-hidden="true" />
							</b-button>
						</template>
					</Datatable>
				</b-col>
			</b-row>
		</template>

		<AddUserToAccountModal
			v-if="user"
			:is-visible="isNewMemberModalVisible"
			:user="user"
			@save="
				isNewMemberModalVisible = false;
				fetchMemberships();
			"
			@close="isNewMemberModalVisible = false"
		/>
	</OfsPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { OfsPanel, ContentHeader, OfsBadge, Datatable } from '@oneflow/ofs-vue-layout';
import Loader from '@/components/Loader';
import AddUserToAccountModal from './AddUserToAccountModal';

export default {
	components: {
		OfsPanel,
		ContentHeader,
		Loader,
		OfsBadge,
		Datatable,
		AddUserToAccountModal
	},
	data() {
		return {
			isLoadingRoles: false,
			userRoles: [],
			isLoadingMemberships: false,
			userMemberships: null,
			userMembershipsFields: [
				{ key: 'account.name', label: this.$t('Account') },
				{ key: 'actions', class: 'text-right' }
			],
			currentPage: 1,
			perPage: 10,
			isNewMemberModalVisible: false
		};
	},
	computed: {
		...mapGetters({
			user: 'user/user',
			isLoadingUser: 'user/isFetchingItem'
		}),
		userId() {
			return this.$route.params.id;
		},
		isCreator() {
			return this.userRoles.includes('pimienta-api-creator');
		}
	},
	watch: {
		userId: {
			immediate: true,
			handler() {
				return this.fetchData();
			}
		}
	},
	methods: {
		...mapActions({
			getUserById: 'user/findById',
			getUserRoles: 'user/getUserRoles',
			getUserMemberships: 'user/getUserMemberships',
			removeAccountMember: 'account-management/removeAccountMember'
		}),
		async fetchData() {
			if (this.userId) {
				try {
					await Promise.all([this.getUserById({ id: this.userId }), this.fetchRoles(), this.fetchMemberships()]);
				} catch (err) {
					this.$notify({
						type: 'error',
						title: this.$t('Error'),
						text: this.$t('An error occurred while fetching the user details')
					});

					throw err;
				}
			}
		},
		async fetchRoles() {
			this.isLoadingRoles = true;
			this.userRoles = await this.getUserRoles({ userId: this.userId })
				.then(res => res.roles)
				.finally(() => {
					this.isLoadingRoles = false;
				});
		},
		async fetchMemberships() {
			this.isLoadingMemberships = true;
			const query = {
				$limit: this.perPage,
				$skip: this.perPage * (this.currentPage - 1)
			};

			this.userMemberships = await this.getUserMemberships({ userId: this.userId, query: { query } }).finally(() => {
				this.isLoadingMemberships = false;
			});
		},
		fromNow(date) {
			return moment(date).fromNow();
		},
		handleMembershipsTableChange({ currentPage, perPage }) {
			this.currentPage = currentPage;
			this.perPage = perPage;

			return this.fetchMemberships();
		},
		async handleDeleteMembership(accountMember) {
			const isConfirmed = await this.$bvModal.msgBoxConfirm(
				[this.$t('Are you sure you want to remove the user from the account?')],
				{
					title: this.$t('Warning!'),
					okVariant: 'danger',
					size: 'md',
					centered: true
				}
			);
			if (!isConfirmed) return;

			try {
				await this.removeAccountMember({ recordId: accountMember._id });
				await this.fetchMemberships();

				this.$notify({
					type: 'success',
					title: this.$t('Success'),
					text: this.$t('Member has been removed from the account')
				});
			} catch (error) {
				this.$notify({
					type: 'error',
					title: this.$t('An error happened while trying to remove the user from the account'),
					text: error.response?.data?.message ?? error.message
				});

				throw error;
			}
		}
	}
};
</script>

<style lang="scss">
.UserEdit .ContentHeader {
	.ContentHeader-title {
		text-transform: none !important;
	}

	.OfsBadge {
		font-size: 16px;
		height: auto;
		padding: 2px 4px;
	}
}
</style>
