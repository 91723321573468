<template>
	<b-row class="TemplateEditor">
		<div class="col-md-12">
			<div class="row">
				<div class="col-md-7">
					<div class="d-flex justify-content-between">
						<h2>{{ $t('Template') }}*</h2>
						<b-button v-tippy size="sm" :content="$t('Download template source')" @click="handleSourceDownload">
							<icon name="download" />
						</b-button>
					</div>
					<CodeEditor mode="handlebars" class="source-editor" :value="value" @input="value => $emit('input', value)" />
				</div>
				<div class="col-md-5">
					<b-tabs>
						<b-tab :title="$t('JSON')">
							<CodeEditor v-model="templateDataRaw" class="data-editor" />
						</b-tab>
					</b-tabs>
				</div>
			</div>
		</div>
		<div class="col-md-12">
			<b-tabs class="preview-tabs">
				<template v-slot:tabs-end>
					<li class="preview-controls">
						<icon v-if="pages" name="chevron-circle-left" class="mr-1" @click="goBackPage" />
						<icon v-if="pages" name="chevron-circle-right" class="mr-2" @click="goNextPage" />
						<icon
							v-if="boxes"
							name="border-all"
							class="mr-2"
							:class="{ 'is-active': showBoxes }"
							@click="toggleBoxes"
						/>
						<span>{{ $t('Zoom') }}: {{ (previewZoom * 100).toFixed(2) }}%</span>
					</li>
				</template>
				<b-tab :title="$t('Visual')">
					<iframe ref="iframePreview" frameborder="1" class="preview-iframe" />
				</b-tab>
				<b-tab :title="$t('HTML')" class="preview-editor">
					<CodeEditor :value="renderedTemplate" mode="html" />
				</b-tab>
			</b-tabs>
		</div>
		<div>
			<b-button variant="primary" class="mr-2" @click="generatePreview">{{ $t('Preview') }}</b-button>
		</div>
	</b-row>
</template>

<script>
import { mapActions } from 'vuex';
import FileSaver from 'file-saver';
import CodeEditor from './CodeEditor';

export const getBoxStyles = (color, padding) => `\
	position: fixed;\
	outline: 1px solid ${color};\
	top: ${padding}px;\
	left: ${padding}px;\
	width: calc(100% - ${padding * 2}px);\
	height: calc(100% - ${padding * 2}px);`;

export default {
	components: {
		CodeEditor
	},
	props: {
		data: {
			type: Object,
			default: () => ({})
		},
		value: {
			type: String,
			default: ''
		},
		sizes: {
			type: Object,
			default: () => ({
				media: { width: 1000, height: 1000 }
			})
		},
		boxes: {
			type: Boolean,
			default: false
		},
		pages: {
			type: Boolean,
			default: false
		},
		productId: {
			type: String,
			default: undefined
		}
	},
	data() {
		return {
			templateData: {},
			renderedTemplate: '',
			previewZoom: 1,
			showBoxes: false
		};
	},
	computed: {
		templateDataRaw: {
			get() {
				return JSON.stringify(this.templateData, null, 2);
			},
			set(data) {
				this.templateData = JSON.parse(data);
			}
		}
	},
	watch: {
		data: {
			deep: true,
			immediate: true,
			handler(data) {
				if (!_.isEqual(data, this.templateData)) {
					this.templateData = data;
				}
			}
		},
		sizes: {
			deep: true,
			handler(sizes) {
				const { width, height } = sizes.media;
				this.processIFrameSize(width, height);
			}
		}
	},
	mounted() {
		const { width, height } = this.sizes.media;
		this.processIFrameSize(width, height);
	},
	methods: {
		...mapActions({
			renderTemplate: 'template/renderTemplate'
		}),
		handleSourceDownload() {
			const blob = new Blob([this.value], { type: 'text/html;charset=utf-8' });
			FileSaver.saveAs(blob, 'email-template.hbs');
		},
		processIFrameSize(width, height) {
			const iframe = _.get(this.$refs, 'iframePreview');
			if (!iframe) return;

			const clientWidth = document.body.clientWidth - 40; // subtract padding
			const fullWidth = +width;

			this.previewZoom = clientWidth < fullWidth ? clientWidth / fullWidth : 1;

			iframe.style.width = `${fullWidth * this.previewZoom}px`;
			iframe.style.height = `${height * this.previewZoom}px`;
		},
		processTemplate(template) {
			let result = template;
			if (this.previewZoom !== 1) {
				result += `<style>html { zoom:${this.previewZoom} }</style>`; // scaling
			}
			if (this.showBoxes) {
				result += `<div style="${getBoxStyles('red', _.get(this.sizes, 'bleed.top', 0))}"></div>`; // bleedBox
				result += `<div style="${getBoxStyles('blue', _.get(this.sizes, 'trim.top', 0))}"></div>`; // trimBox
			}
			return result;
		},
		loadTemplatePreview(template) {
			const frameDocument = _.get(this.$refs, 'iframePreview.contentWindow.document');
			if (frameDocument) {
				const templatePreview = this.processTemplate(template);
				frameDocument.open();
				frameDocument.write(templatePreview);
				frameDocument.close();
			}
		},
		toggleBoxes() {
			this.showBoxes = !this.showBoxes;
			this.loadTemplatePreview(this.renderedTemplate);
		},
		calculateCurrentPreviewPage() {
			const currentPosition = this.$refs['iframePreview'].contentWindow.scrollY;
			const pageHeight = _.get(this.sizes, 'media.height', 0);

			return Math.ceil(currentPosition / pageHeight);
		},
		goBackPage() {
			const pageHeight = _.get(this.sizes, 'media.height', 0);
			const scrollPosition = (this.calculateCurrentPreviewPage() - 1) * pageHeight;
			this.$refs['iframePreview'].contentWindow.scrollTo({
				top: scrollPosition,
				left: 0,
				behavior: 'smooth'
			});
		},
		goNextPage() {
			const pageHeight = _.get(this.sizes, 'media.height', 0);
			const scrollPosition = (this.calculateCurrentPreviewPage() + 1) * pageHeight;
			this.$refs['iframePreview'].contentWindow.scrollTo({
				top: scrollPosition,
				left: 0,
				behavior: 'smooth'
			});
		},
		async generatePreview() {
			const renderOptions = {
				template: this.value,
				data: this.templateData,
				productId: this.productId
			};

			try {
				this.renderedTemplate = await this.renderTemplate(renderOptions);
				this.loadTemplatePreview(this.renderedTemplate);
			} catch (error) {
				this.$notify({
					type: 'error',
					title: this.$t('Template render error'),
					text: _.get(error, 'response.data.message', error.message)
				});
			}
		}
	}
};
</script>

<style lang="scss">
@import 'src/styles/shared';

.TemplateEditor {
	.source-editor,
	.data-editor {
		height: 50vh;
	}

	.preview-tabs {
		display: flex;
		flex-direction: column;
		height: 100%;

		.tab-content {
			flex: 1;

			.tab-pane {
				height: 100%;

				&:nth-child(1) {
					text-align: center;
				}

				&:nth-child(2) {
					max-height: 450px;
				}

				.preview-iframe,
				.preview-editor {
					width: 100%;
					height: 100%;
				}

				.preview-iframe {
					border: none;
					outline: 2px inset rgb(238, 238, 238);
				}
			}
		}
	}

	.preview-controls {
		flex: 1;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.fa-icon {
			cursor: pointer;
			color: $of-color-grey-1;

			&:hover,
			&.is-active {
				color: $of-color-aqua;
			}
		}
	}
}
</style>
