<template>
	<div>
		<OfsPanel>
			<ListTable
				bordered
				hover
				table-title="Pre-orders"
				:config="config"
				:items="orders.data"
				:fields="fields"
				:filter="filter"
				:sort="sort"
				:selected="selected"
				:total-items="orders.total"
				:current-page="currentPage"
				:per-page="perPage"
				:fetch-data="fetchData"
				:is-busy="isLoading"
				@row-clicked="handleRowClick"
				@table-change="handleTableChange"
			>
				<template slot="TableButtons-Slot-right" slot-scope="{}">
					<b-button
						variant="primary"
						class="ml-2 text-nowrap"
						:disabled="!selectedOrdersCount"
						@click="showReleaseModal"
					>
						{{ $t('Release Orders') }} ({{ selectedOrdersCount }})
					</b-button>
					<b-button variant="primary" class="ml-2 text-nowrap" :disabled="disableSelectAllBtn" @click="selectAllOrders">
						{{ $t('Select All') }}
					</b-button>

					<b-button
						variant="primary"
						class="ml-2 text-nowrap"
						:disabled="selectedOrdersCount === 0"
						@click="unselectAllOrders"
					>
						{{ $t('Unselect All') }}
					</b-button>
				</template>

				<template slot="TableButtons-Slot-left" slot-scope="{}">
					<OfFormInput
						:value="searchString"
						class="col-6 mr-2"
						:placeholder="$t('Search by order ID or customer')"
						@input="handleSearchStringInput"
					/>
					<OfInlineFilter :filters="filters" :values="filterValues" @change="filtersChanged" />
				</template>
				<template slot="TableHeader" slot-scope="{}">
					<OfFilterBar :filters="filters" :values="filterValues" @change="filtersChanged" />
				</template>

				<template v-slot:cell(isSelected)="{ item }">
					<of-form-checkbox
						:value="checkIsOrderSelected(item._id)"
						:disabled="!isRowSelectable(item)"
						@input="toggleIsOrderSelected(item)"
					></of-form-checkbox>
				</template>

				<template v-slot:cell(availability)="{ item }">
					<OfsBadge v-if="readyOrdersMap[item._id]" :text="$t('Available')" status="true" />
					<OfsBadge v-else :text="$t('Unavailable')" status="false" />
				</template>

				<template v-slot:cell(isTest)="{ item }">
					{{ item.isTest ? item.isTest : false }}
				</template>

				<template #cell(customer)="{ item }">
					<div v-b-tooltip.hover.bottom class="preOrders-overflown-text" :title="_get(item, 'shipping.name', '')">
						{{ _get(item, 'shipping.name', '') }}
					</div>
				</template>

				<template v-slot:cell(quantity)="{ item }">
					{{ item.lineItemIds.length }}
				</template>

				<template v-slot:cell(totalPrice)="{ item }">
					{{ getOrderTotalPrice(item) }}
				</template>

				<template v-slot:cell(createdAt)="{ item }">
					{{ formatDate(item.createdAt) }}
				</template>

				<template #cell(actions)="{ item }">
					<b-button size="sm" :href="`/#/orders/${item._id}`">{{ $t('View') }}</b-button>
				</template>
			</ListTable>
		</OfsPanel>
		<b-modal
			:visible="isReleaseModalVisible"
			:title="$t('Release orders')"
			:cancel-title="$t('No')"
			:ok-title="$t('Yes')"
			ok-variant="primary"
			size="md"
			modal-class="ReleasePreOrdersModal"
			@hide="hideReleaseModal"
		>
			<b-col>
				<template v-if="ordersReleaseFinished">
					<b-alert :show="true" variant="success">
						<span>{{ $t('Released') }} {{ releasedOrdersCount }} / {{ ordersNumberToRelease }}</span>
					</b-alert>
					<b-alert :show="Boolean(failedToReleaseOrdersCount)" variant="danger">
						<span>{{ $t('Failed to release') }}</span>
						<ul>
							<li v-for="order in failedToReleaseOrders" :key="order.id">
								<router-link :to="`/orders/${order.id}`">{{ order.orderId || order.id }}</router-link>
							</li>
						</ul>
					</b-alert>
				</template>
				<template v-else>
					<p v-if="canSubmitReleaseOrders">{{ $t('Are you sure you want to release all selected pre-orders') }}</p>
					<p v-else>{{ $t('Releasing orders...') }}</p>
					<span>{{ processedOrdersCount }} / {{ ordersNumberToRelease }}</span>
					<b-progress :max="ordersNumberToRelease" :value="processedOrdersCount" animated />
				</template>
			</b-col>
			<div slot="modal-footer">
				<b-button v-if="canSubmitReleaseOrders" class="mr-2" @click="hideReleaseModal">
					{{ $t('No') }}
				</b-button>
				<b-button v-if="ordersReleaseFinished" variant="primary" @click="hideReleaseModal">
					{{ $t('Done') }}
				</b-button>
				<b-button v-else-if="canSubmitReleaseOrders" variant="primary" @click="releaseSelectedOrders">
					{{ $t('Yes') }}
				</b-button>
				<b-button v-else-if="!canSubmitReleaseOrders" variant="primary" :disabled="true">
					{{ $t('In progress') }}
				</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import {
	OfsPanel,
	ListTable,
	OfInlineFilter,
	OfFilterBar,
	OfsBadge,
	OfFormCheckbox,
	OfFormInput
} from '@oneflow/ofs-vue-layout';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import formatDate from '@/lib/formatDate';
import formatCents from '@/lib/formatCents';
import { productAvailability } from '../../Create/Products/constants';
import { intentTypes, orderStatus } from '../../../lib/constants';
import withOrderReleaseEvents from '@/mixins/withOrderReleaseEvents';
import withQuerystringState from '@/mixins/withQuerystringState';
import { parseDefaultQuerystringFilterValues } from '@/lib/parseDefaultQuerystringFilterValues';

const config = {
	refresh: { visible: true },
	columns: { visible: true }
};

export default {
	components: {
		OfsPanel,
		ListTable,
		OfInlineFilter,
		OfFilterBar,
		OfsBadge,
		OfFormCheckbox,
		OfFormInput
	},
	mixins: [
		withOrderReleaseEvents,
		withQuerystringState([
			{ name: 'currentPage', defaultValue: 1, parseValue: Number },
			{ name: 'perPage', defaultValue: 10, parseValue: Number },
			'searchString',
			{ name: 'sort', defaultValue: {}, parseValue: value => value ?? {} },
			{ name: 'filterValues', parseValue: parseDefaultQuerystringFilterValues }
		])
	],
	data() {
		const fields = [
			{ key: 'isSelected', label: this.$t('') },
			{ key: 'availability', label: this.$t('Availability') },
			{ key: 'channel.sourceData.storeRegion', label: this.$t('Region') },
			{ key: 'orderId', label: this.$t('Order ID') },
			{ key: 'intent', label: this.$t('Intent Type') },
			{
				key: 'customer',
				label: this.$t('Customer'),
				sortable: true,
				thStyle: 'width: 150px;',
				thClass: 'text-center',
				tdClass: 'text-center'
			},
			{ key: 'quantity', label: this.$t('Quantity') },
			{ key: 'totalPrice', label: this.$t('Total Price') },
			{ key: 'createdAt', label: this.$t('Date'), sortable: true },
			{ key: 'actions', label: '', tdClass: 'align-right' }
		];

		return {
			isLoading: false,
			fields,
			selected: _.map(fields, 'key'),
			selectedOrders: [],
			config,
			filter: true,
			isReleaseModalVisible: false,
			canSubmitReleaseOrders: true
		};
	},
	computed: {
		...mapGetters({
			orders: 'order/orders',
			products: 'product/products',
			accounts: 'account/accounts'
		}),
		...mapState('order', ['releasingOrders', 'failedToReleaseOrders', 'releasingOrdersTotal']),
		filters() {
			return [
				{
					header: this.$t('Accounts'),
					key: 'accountId',
					type: 'checkbox',
					items: _.map(this.accounts?.data ?? [], ({ authAccountId, displayName }) => ({
						value: authAccountId,
						title: displayName
					}))
				},
				{
					header: this.$t('Products'),
					key: 'productIds',
					type: 'checkbox',
					items: _.map(this.products.data, ({ _id, name }) => ({
						value: _id,
						title: name
					}))
				},
				{
					header: this.$t('Availability'),
					key: 'availability',
					type: 'radio',
					items: [
						{
							value: productAvailability.Available,
							title: productAvailability.Available
						},
						{
							value: productAvailability.Unavailable,
							title: productAvailability.Unavailable
						}
					]
				}
			];
		},
		selectedOrdersCount() {
			return _.size(this.selectedOrders);
		},
		disableSelectAllBtn() {
			const isSelectable = order => this.isRowSelectable(order) && !this.checkIsOrderSelected(order._id);
			return !_.filter(this.orders.data, isSelectable).length;
		},
		readyOrdersMap() {
			return _.reduce(
				_.get(this.orders, 'data', []),
				(acc, order) => ({ ...acc, [order._id]: this.isReadyForRelease(order) }),
				{}
			);
		},
		processedOrdersCount() {
			return this.releasingOrdersTotal - _.size(this.releasingOrders);
		},
		failedToReleaseOrdersCount() {
			return _.size(this.failedToReleaseOrders);
		},
		releasedOrdersCount() {
			return this.releasingOrdersTotal - this.failedToReleaseOrdersCount;
		},
		ordersNumberToRelease() {
			return this.releasingOrdersTotal || this.selectedOrdersCount;
		},
		ordersReleaseFinished() {
			return this.processedOrdersCount === this.ordersNumberToRelease;
		}
	},
	async mounted() {
		const productsQuery = {
			$where: { availability: { $in: [productAvailability.PreOrder, productAvailability.Available] } },
			$select: ['name', 'status', 'availability'],
			$limit: 0
		};
		await Promise.all([
			this.fetchData(),
			this.fetchProducts({ query: { query: productsQuery } }),
			this.fetchAccounts({ query: { query: { $limit: 1000, $sort: { displayName: 1 } } } })
		]);
	},
	methods: {
		...mapActions({
			getOrders: 'order/find',
			releaseOrders: 'order/releaseOrders',
			fetchProducts: 'product/find',
			fetchAccounts: 'account/find'
		}),
		...mapMutations({
			RESET_ORDERS_RELEASE: 'order/RESET_ORDERS_RELEASE'
		}),
		formatDate,
		formatCents,
		_get: _.get,
		_sumBy: _.sumBy,
		async fetchData() {
			try {
				this.isLoading = true;
				const query = {
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1),
					$where: { status: orderStatus.PreOrder },
					availabilityFilter: _.get(this.filterValues, 'availability'),
					$populate: [{ path: 'lineItems', $populate: { path: 'product' } }],
					...(this.filterValues?.productIds?.length && { productIds: this.filterValues.productIds })
				};

				if (!_.isEmpty(this.filterValues)) {
					query.$where = _.reduce(
						this.filterValues,
						(filterQuery, value, key) => {
							if (key !== 'productIds' && key !== 'availability') {
								filterQuery[key] = Array.isArray(value) ? { $in: value } : value;
							}

							return filterQuery;
						},
						{ status: orderStatus.PreOrder }
					);
				}

				if (this.searchString) {
					query.$where.$or = [{ 'shipping.name': { $regex: this.searchString, $options: 'i' } }];
					query.$where.$or.push({ orderId: this.searchString });

					const isNumber = !isNaN(this.searchString);
					if (isNumber) {
						query.$where.$or.push({ orderId: Number(this.searchString) });
					}
				}

				query.$sort = !_.isEmpty(this.sort) ? this.sort : { createdAt: -1 };
				await this.getOrders({ query: { query } });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching data')
				});

				throw err;
			} finally {
				this.isLoading = false;
			}
		},
		handleTableChange({ currentPage, perPage, sort, filter, selectedCols }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			this.selected = selectedCols;
			if (filter !== undefined) {
				this.filter = filter;
			}
			if (sort) {
				if (_.has(sort, 'customer')) {
					this.sort = { 'shipping.name': sort.customer };
				} else {
					this.sort = sort;
				}
			}
		},
		filtersChanged(filters) {
			this.filterValues = filters;
			this.currentPage = 1;
			this.fetchData();
		},
		handleRowClick(item, _index, event) {
			if (event.target.type === 'button' || !this.isRowSelectable(item)) return;
			this.toggleIsOrderSelected(item);
		},
		isRowSelectable(item) {
			return !!this.readyOrdersMap[item._id];
		},
		toggleIsOrderSelected(order) {
			const { _id, orderId } = order;
			if (this.checkIsOrderSelected(_id)) {
				this.selectedOrders = this.selectedOrders.filter(({ id }) => id !== _id);
			} else {
				this.selectedOrders.push({ id: _id, orderId: orderId });
			}
		},
		checkIsOrderSelected(itemId) {
			return this.selectedOrders.some(({ id }) => id === itemId);
		},
		selectAllOrders() {
			const ordersData = _.get(this.orders, 'data', []);
			const selectableOrders = ordersData.filter(this.isRowSelectable);
			const selectableOrdersData = _.map(selectableOrders, ({ _id, orderId }) => ({
				id: _id,
				orderId
			}));
			this.selectedOrders = _.uniqBy([...this.selectedOrders, ...selectableOrdersData], 'id');
		},
		unselectAllOrders() {
			this.selectedOrders = [];
		},
		getOrderTotalPrice(order) {
			const items = _.get(order, 'lineItems');
			const isoCurrency = _.get(order, 'lineItems[0].isoCurrency');
			const sum = _.sumBy(items, item => item.salePrice);
			return formatCents(sum, isoCurrency) || 0;
		},
		isOrderItemAvailable(item) {
			return _.get(item, 'product.availability') === productAvailability.Available;
		},
		isPayableOrder(intent) {
			return intent === intentTypes.Standard || intent === intentTypes.Promo;
		},
		isReadyForRelease(order) {
			if (!this.isPayableOrder(order.intent)) return true;

			return _.every(order.lineItems, this.isOrderItemAvailable);
		},
		async releaseSelectedOrders() {
			try {
				this.canSubmitReleaseOrders = false;
				await this.releaseOrders({ orders: this.selectedOrders });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while starting orders release process')
				});
			}
		},
		handleSearchStringInput: _.debounce(function(value) {
			this.searchString = value;
			this.currentPage = 1;
			this.fetchData();
		}, 800),
		showReleaseModal() {
			this.RESET_ORDERS_RELEASE();
			this.isReleaseModalVisible = true;
		},
		hideReleaseModal() {
			this.isReleaseModalVisible = false;
			// wait until modal hide animation is finished
			setTimeout(() => {
				this.canSubmitReleaseOrders = true;
			}, 500);

			if (!this.ordersReleaseFinished) return;
			this.selectedOrders = [];
			this.currentPage = 1;
			this.fetchData();
		}
	}
};
</script>

<style lang="scss" scoped>
.preOrders-overflown-text {
	overflow: hidden;
	width: 150px;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>
