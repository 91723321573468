var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('h2',[_vm._v(_vm._s(_vm.title))]),_c('Datatable',{attrs:{"fields":_vm.versionFields,"items":_vm.items},scopedSlots:_vm._u([{key:"cell(externalSourceOrderId)",fn:function(ref){
var item = ref.item;
return [(item.externalOrderId)?_c('a',{attrs:{"href":_vm.getExternalLink(item),"target":"_blank"}},[_vm._v(_vm._s(item.externalSourceOrderId))]):_c('span',[_vm._v(" "+_vm._s(item.externalSourceOrderId)+" ")])]}},{key:"cell(submittedAt)",fn:function(ref){
var item = ref.item;
return [(!_vm.formatResubmittedDate(item.submittedAt))?_c('div',{staticClass:"active-badge"},[_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Active")])],1):_c('span',[_vm._v(" "+_vm._s(_vm.formatResubmittedDate(item.submittedAt))+" ")])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+" ")]}},{key:"cell(fulfilment)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fulfilment)+" ")]}},{key:"cell(orderData)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{staticClass:"mt-2 mr-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.showViewSourceModal(item)}}},[_vm._v(" "+_vm._s(_vm.$t('Source'))+" ")]),(!item.submittedAt)?_c('b-button',{staticClass:"mt-2 mr-2",attrs:{"size":"sm","variant":"outline-info","disabled":_vm.isResubmitDisabled},on:{"click":function($event){return _vm.handleResubmitVisible(item)}}},[_vm._v(" "+_vm._s(_vm.$t('Resubmit'))+" ")]):_vm._e(),(!item.submittedAt && item.fulfilment !== 'Digital File')?_c('b-button',{staticClass:"mt-2 mr-2",attrs:{"size":"sm","variant":"outline-danger","disabled":_vm.isCancelDisabled(item)},on:{"click":function($event){return _vm.showCancelModal(item)}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]):_vm._e()]}}])}),_c('b-modal',{attrs:{"visible":_vm.isSourceModalVisible,"title":_vm.sourceModalTitle,"size":"xl","modal-class":"SourceModal","ok-only":""},on:{"hide":function($event){_vm.isSourceModalVisible = false}}},[_c('CodeEditor',{attrs:{"value":_vm.versionSource}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }