<template>
	<b-img
		thumbnail
		:src="theSrc"
		:style="!fluid ? `width: ${size}px; height: ${size}px` : ''"
		class="thumbnail"
		@error="handleError"
	/>
</template>

<script>
import placeholder from '../../public/images/placeholder.svg';

export default {
	props: {
		src: {
			type: String,
			default: ''
		},
		size: {
			type: [Number, String],
			default: 35
		},
		fluid: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			hasError: false
		};
	},
	computed: {
		theSrc() {
			return this.hasError || !this.src ? placeholder : this.src;
		}
	},
	watch: {
		src(newValue, oldValue) {
			if (newValue && newValue !== oldValue) {
				this.hasError = false;
			}
		}
	},
	methods: {
		handleError() {
			this.hasError = true;
		}
	}
};
</script>

<style lang="scss" scoped>
@import 'src/styles/shared';

.thumbnail {
	padding: 0;
	object-fit: contain;
	border: 1px solid #d2ddf4;
	background-color: $of-color-highlights;
	border-radius: $of-border-radius;
}
</style>
