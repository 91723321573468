import Vue from 'vue';
import * as actions from './actions';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'account-management',
		pluralName: 'accounts',
		transport: 'davinci',
		options: {
			namespaced: true
		},
		state: {
			members: {
				total: 0,
				data: [],
				limit: 10,
				skip: 0
			}
		},
		getters: {
			members: state => state.members
		},
		actions,
		mutations: {
			SET_ACCOUNT_MEMBERS(state, members) {
				state.members = members;
			}
		}
	});
}
