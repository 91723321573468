import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

const createStore = () => {
	Vue.use(Vuex);
	return new Vuex.Store({
		plugins: [
			createPersistedState({
				paths: ['lang.lang', 'auth0.isAuthenticated', 'auth0.authToken', 'auth0.user']
			})
		]
	});
};

export default createStore;
