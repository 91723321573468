export default function(store) {
	const defaultLayout = 'DefaultLayout';

	const layoutStore = {
		namespaced: true,
		state: {
			layout: defaultLayout
		},
		getters: {
			activeLayout: state => state.layout || defaultLayout
		},
		mutations: {
			SET_LAYOUT: (state, layout) => {
				if (state) {
					state.layout = layout;
				}
			}
		}
	};

	store.registerModule('layoutProvider', layoutStore);
}
