<template>
	<div class="product-releases pt-4">
		<div v-if="isLoading" class="text-center p-5">
			<Loader />
		</div>
		<template v-for="(delivery, index) in productDeliveryTypes" v-else>
			<b-card :key="`card-${delivery}`" no-body border-variant="light">
				<b-card-title class="mb-4">
					<b-row class="align-items-center">
						<b-col class="d-flex align-items-center">
							<span class="text-capitalize mr-2 font-weight-bold">{{ delivery }}</span>

							<span style="font-size: 16px">
								<OfsBadge v-if="!!activeReleasesByDeliveryType[delivery]" text="✔" status="ready" />

								<OfsBadge v-else text="!" status="warning" />
							</span>
						</b-col>
						<b-col class="text-right">
							<b-btn variant="primary" @click="handleNewReleaseBtnClick(delivery)">+ {{ $t('New Release') }}</b-btn>
						</b-col>
					</b-row>
				</b-card-title>

				<b-card-text v-if="releasesByDeliveryType[delivery]">
					<b-table-simple small responsive class="product-releases__table w-100 border-bottom-0">
						<b-thead>
							<b-tr>
								<b-th style="width: 0"></b-th>
								<b-th style="width: 0">{{ $t('Number') }}</b-th>
								<b-th>{{ $t('Release Details') }}</b-th>
								<b-th>{{ $t('Components') }}</b-th>
								<b-th>{{ $t('Note') }}</b-th>
								<b-th></b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr v-for="(release, releaseIndex) in releasesByDeliveryType[delivery]" :key="releaseIndex">
								<b-td>
									<OfsBadge
										v-if="
											activeReleasesByDeliveryType[delivery] &&
												activeReleasesByDeliveryType[delivery]._id === release._id
										"
										text="active"
										status="ready"
									/>
								</b-td>
								<b-td>{{ releasesByDeliveryType[delivery].length - releaseIndex }}</b-td>
								<b-td>
									<div
										v-if="
											activeReleasesByDeliveryType[delivery] &&
												activeReleasesByDeliveryType[delivery]._id === release._id
										"
									>
										{{ $t('Marked as active at') }}: {{ formatDate(release.markedActiveAt) }}
									</div>
									<div>{{ $t('Created at') }}: {{ formatDate(release.createdAt) }}</div>
									<div>By: {{ release.createdBy }}</div>
								</b-td>
								<b-td>
									<div v-for="file in release.files" :key="file.componentCode">
										<router-link :to="{ name: 'filePreview', params: { id: file.fileId } }" class="mr-2 d-inline-block">
											<a @click="handleFilePreview($event, file.fileId)">
												{{ file.componentCode }}
											</a>
										</router-link>
										<span>({{ file.pageCount }} {{ $t('pages') }})</span>
									</div>
								</b-td>

								<b-td>{{ release.note }}</b-td>
								<b-td class="text-right">
									<b-btn
										variant="primary"
										size="sm"
										:disabled="
											activeReleasesByDeliveryType[delivery] &&
												activeReleasesByDeliveryType[delivery]._id === release._id
										"
										@click="handlePromoteClick(release._id)"
									>
										{{ $t('make active') }}
									</b-btn>
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-card-text>

				<b-card-text v-else class="text-center py-3 px-2">{{ $t('No available releases to display') }}</b-card-text>
			</b-card>

			<hr v-if="index !== productDeliveryTypes.length - 1" :key="`hr-${delivery}`" class="my-4" />
		</template>

		<NewReleaseModal
			:is-open="isNewReleaseModalVisible"
			:product="product"
			:delivery="selectedDeliveryType"
			:releases="releases"
			@hide="handleNewReleaseModalHide"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { OfsBadge } from '@oneflow/ofs-vue-layout';
import formatDate from '@/lib/formatDate';
import NewReleaseModal from '@/containers/Create/Products/releases/NewReleaseModal';
import Loader from '@/components/Loader';
import { activeReleasesByDeliveryType } from '@/containers/Create/Products/computed';

export default {
	components: { NewReleaseModal, OfsBadge, Loader },
	props: {
		product: { type: Object },
		productDeliveryTypes: { type: Array },
		fetchProduct: { type: Function }
	},
	data() {
		return {
			isLoading: false,
			isNewReleaseModalVisible: false,
			selectedDeliveryType: null
		};
	},
	computed: {
		...mapGetters({ releases: 'product-release/product-releases' }),
		releasesByDeliveryType() {
			return _.groupBy(this.releases, 'delivery');
		},
		activeReleasesByDeliveryType
	},
	watch: {
		'product._id': {
			immediate: true,
			async handler() {
				const productId = this.product?._id;
				if (productId) {
					return this.fetchProductReleases();
				}
			}
		}
	},
	methods: {
		...mapActions({ findReleases: 'product-release/find', promoteRelease: 'product-release/promote' }),
		formatDate,
		async fetchProductReleases() {
			const productId = this.product?._id;
			if (productId) {
				this.isLoading = true;
				await this.findReleases({
					query: { query: { $where: { productId }, $sort: { createdAt: -1 }, $limit: 1000 } }
				}).finally(() => {
					this.isLoading = false;
				});
			}
		},
		handleNewReleaseBtnClick(delivery) {
			this.selectedDeliveryType = delivery;
			this.isNewReleaseModalVisible = true;
		},
		handleNewReleaseModalHide() {
			this.isNewReleaseModalVisible = false;
			this.selectedDeliveryType = null;
			this.fetchProductReleases();
		},
		async handlePromoteClick(releaseId) {
			const isConfirmed = await this.$bvModal.msgBoxConfirm(
				[this.$t('Are you sure you want to promote the release?')],
				{
					title: this.$t('Promote Release'),
					size: 'md',
					centered: true
				}
			);

			if (isConfirmed) {
				try {
					await this.promoteRelease(releaseId);
					this.$notify({
						type: 'success',
						title: this.$t('The release has been marked as active')
					});

					this.fetchProduct();
					this.fetchProductReleases();
				} catch (err) {
					this.$notify({
						type: 'error',
						title: this.$t('An error happened while marking the release as active'),
						text: err.message
					});

					throw err;
				}
			}
		},
		handleFilePreview($event, fileId) {
			if (!$event.ctrlKey && !$event.metaKey) {
				$event.preventDefault();
				$event.stopPropagation();
				this.$showFilePreviewer(fileId);
			}
		}
	}
};
</script>

<style lang="scss">
.product-releases {
	.card-title {
		.OfsBadge {
			min-width: auto;
			width: 20px;
		}
	}

	&__table {
		table {
			border-bottom: 0 !important;
		}
	}
}
</style>
