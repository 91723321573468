<template>
	<OfsPanel>
		<ListTable
			bordered
			hover
			:table-title="$t('Recommendation rules')"
			:config="config"
			:items="recommendationRules.data"
			:fields="fields"
			:total-items="recommendationRules.total"
			:current-page="currentPage"
			:per-page="perPage"
			:fetch-data="fetchData"
			:is-busy="isLoading"
			@row-clicked="handleRowClick"
			@table-change="handleTableChange"
		>
			<template slot="TableButtons-Slot-left" slot-scope="{}">
				<OfInlineFilter :filters="filters" :values="filterValues" @change="filtersChanged" />
			</template>

			<template slot="TableHeader" slot-scope="{}">
				<OfFilterBar :filters="filters" :values="filterValues" @change="filtersChanged" />
			</template>

			<template slot="TableButtons-Slot-right" slot-scope="{}">
				<b-button variant="primary" class="ml-2" @click="openEditModal()">
					{{ $t('New promotion') }}
				</b-button>
			</template>

			<template v-slot:cell(promoProducts)="{ item }">
				<span v-for="(productId, index) in getPromoProductIds(item)" :key="productId">
					<router-link :to="getProductEditLink(productId)" target="_blank">
						{{ _get(promoProductsMap, `[${productId}].name`, productId) }}
					</router-link>
					<template v-if="index < getPromoProductIds(item).length - 1">,</template>
				</span>
			</template>

			<template v-slot:cell(active)="{ item }">
				<div class="d-flex align-items-center">
					<of-toggle class="ml-2" :value="item.active" @input="toggleActive(item)" />
				</div>
			</template>

			<template #cell(actions)="{ item, index }">
				<b-button
					class="mr-1"
					:disabled="!canChangePriority(DIRECTION.UP, index)"
					@click="changePriority(DIRECTION.UP, item, index)"
				>
					<icon name="arrow-up" />
				</b-button>
				<b-button
					class="mr-1"
					:disabled="!canChangePriority(DIRECTION.DOWN, index)"
					@click="changePriority(DIRECTION.DOWN, item, index)"
				>
					<icon name="arrow-down" />
				</b-button>
			</template>
		</ListTable>

		<RecommendationRuleModal
			v-if="isEditModalOpened"
			:recommendation-rule-id="recommendationRuleId"
			@close="closeEditModal"
			@ok="saveEditModal"
		/>
	</OfsPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfsPanel, ListTable, OfInlineFilter, OfToggle, OfFilterBar } from '@oneflow/ofs-vue-layout';
import RecommendationRuleModal from './RecommendationRuleModal.vue';
import withQuerystringState from '@/mixins/withQuerystringState';
import { parseDefaultQuerystringFilterValues } from '@/lib/parseDefaultQuerystringFilterValues';

const config = {
	refresh: { visible: true }
};

const DIRECTION = {
	UP: 'Up',
	DOWN: 'Down'
};

export default {
	components: {
		OfsPanel,
		ListTable,
		OfInlineFilter,
		OfToggle,
		RecommendationRuleModal,
		OfFilterBar
	},
	mixins: [
		withQuerystringState([
			{ name: 'currentPage', defaultValue: 1, parseValue: Number },
			{ name: 'perPage', defaultValue: 10, parseValue: Number },
			'searchString',
			{ name: 'sort', defaultValue: {}, parseValue: value => value ?? {} },
			{ name: 'filterValues', parseValue: parseDefaultQuerystringFilterValues }
		])
	],
	data() {
		const fields = [
			{ key: 'priority', label: this.$t('Priority') },
			{ key: 'name', label: this.$t('Name') },
			{ key: 'promoProducts', label: this.$t('Promo product(s)') },
			{ key: 'active', label: this.$t('Status') },
			{ key: 'actions', label: '' }
		];

		const filters = [
			{
				header: this.$t('Status'),
				key: 'active',
				type: 'radio',
				items: [
					{ title: this.$t('Active'), value: true },
					{ title: this.$t('Inactive'), value: false }
				]
			}
		];

		return {
			isLoading: false,
			DIRECTION,
			fields,
			filters,
			config: {
				refresh: { visible: true }
			},
			isEditModalOpened: false,
			recommendationRuleId: null,
			promoProductsMap: {}
		};
	},
	computed: {
		...mapGetters({
			recommendationRules: 'recommendation-rule/recommendation-rules'
		})
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			getRecommendationRules: 'recommendation-rule/find',
			updateRecommendationRule: 'recommendation-rule/update',
			getAllProducts: 'product/findAll'
		}),
		_get: _.get,
		_map: _.map,
		async fetchData() {
			const query = {
				$limit: this.perPage,
				$skip: this.perPage * (this.currentPage - 1),
				$sort: { priority: -1 }
			};

			if (!_.isEmpty(this.filterValues)) {
				query.$where = _.reduce(
					this.filterValues,
					($where, value, key) => ({
						...$where,
						[key]: Array.isArray(value) ? { $in: value } : value
					}),
					{}
				);
			}

			try {
				this.isLoading = true;
				await this.getRecommendationRules({ query: { query } });
				await this.fetchPromoProducts();
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching recommendation rules')
				});

				throw err;
			} finally {
				this.isLoading = false;
			}
		},
		async filtersChanged(filters) {
			this.filterValues = filters;
			this.currentPage = 1;

			await this.fetchData();
		},
		getPromoProductIds(recommendationRule) {
			return _.flatMap(recommendationRule.rules, 'output.productIds');
		},
		getProductEditLink(productId) {
			return { name: 'products.edit', params: { id: productId } };
		},
		async fetchPromoProducts() {
			const productIds = _.flatMap(this.recommendationRules.data, this.getPromoProductIds);
			const query = {
				$where: { _id: { $in: _.uniq(productIds) } },
				$select: ['name']
			};

			const products = await this.getAllProducts({ query: { query }, options: { skipMutations: true } });
			this.promoProductsMap = _.keyBy(products, '_id');
		},
		handleTableChange({ currentPage, perPage }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
		},
		handleRowClick(item, _index, event) {
			if (event.target.type === 'button') return;
			this.openEditModal(item.id);
		},
		openEditModal(id) {
			this.recommendationRuleId = id;
			this.isEditModalOpened = true;
		},
		closeEditModal() {
			this.recommendationRuleId = null;
			this.isEditModalOpened = false;
		},
		async saveEditModal() {
			this.closeEditModal();
			await this.fetchData();
		},
		async toggleActive(item) {
			await this.updateRecommendationRule({ id: item.id, data: { ...item, active: !item.active } });
			await this.fetchData();
		},
		canChangePriority(direction, index) {
			const total = _.size(this.recommendationRules.data);
			return (direction === DIRECTION.UP && index > 0) || (direction === DIRECTION.DOWN && index < total - 1);
		},
		async changePriority(direction, currentRule, index) {
			if (!this.canChangePriority(direction, index)) return;

			const rules = _.get(this.recommendationRules, 'data', []);

			let ruleToSwipeWith;
			if (direction === DIRECTION.UP) {
				// find a rule with higher priority: [x+2, x+1 <-, current, ...]
				ruleToSwipeWith = _.findLast(rules, ({ priority }) => priority > currentRule.priority, index - 1);
			}
			if (direction === DIRECTION.DOWN) {
				// find a rule with lower priority: [..., current, -> x-1, x-2]
				ruleToSwipeWith = _.find(rules, ({ priority }) => priority < currentRule.priority, index + 1);
			}

			if (!ruleToSwipeWith || currentRule.id === ruleToSwipeWith.id) return;

			await Promise.all([
				this.updateRecommendationRule({ id: currentRule.id, data: { priority: ruleToSwipeWith.priority } }),
				this.updateRecommendationRule({ id: ruleToSwipeWith.id, data: { priority: currentRule.priority } })
			]);

			await this.fetchData();
		}
	}
};
</script>
