<template>
	<OfsPanel>
		<ListTable
			bordered
			hover
			:table-title="$t('Creator users')"
			:config="config"
			:items="users.data"
			:fields="fields"
			:total-items="users.total"
			:current-page="currentPage"
			:per-page="perPage"
			:fetch-data="fetchData"
			:is-busy="isLoading"
			@table-change="handleTableChange"
			@row-clicked="handleRowClick"
		>
			<template #cell(name)="{ item }">
				<b-avatar :src="item.picture" class="mr-3" size="2em" />
				{{ item.name }}
			</template>

			<template v-slot:cell(last_login)="{ item }">
				<div v-if="loadingHash[item.user_id]">
					<b-skeleton />
				</div>
				<div v-else>
					{{ item.last_login ? fromNow(item.last_login) : $t('Never') }}
				</div>
			</template>

			<template #cell(email_verified)="{ item }">
				<div v-if="loadingHash[item.user_id]">
					<b-skeleton />
				</div>
				<OfsBadge
					v-else
					:text="item.email_verified ? 'verified' : 'not verified'"
					:status="item.email_verified ? 'success' : 'pending'"
				/>
			</template>

			<template #TableButtons-Slot-right>
				<b-button variant="primary" class="ml-2" @click="isCreateUserModalOpen = true">
					{{ $t('New creator user') }}
				</b-button>
			</template>
		</ListTable>

		<CreateUserModal
			:is-open="isCreateUserModalOpen"
			user-type="creator"
			@close="isCreateUserModalOpen = false"
			@save="
				isCreateUserModalOpen = false;
				fetchData();
			"
		/>
	</OfsPanel>
</template>

<script>
import _ from 'lodash';
import _fp from 'lodash/fp';
import { mapActions } from 'vuex';
import moment from 'moment';
import { ListTable, OfsBadge, OfsPanel } from '@oneflow/ofs-vue-layout';
import Promise from 'bluebird';
import withQuerystringState from '@/mixins/withQuerystringState';
import CreateUserModal from '@/containers/Admin/Users/CreateUserModal';

export default {
	components: {
		OfsPanel,
		ListTable,
		OfsBadge,
		CreateUserModal
	},
	mixins: [
		withQuerystringState([
			{ name: 'currentPage', defaultValue: 1, parseValue: Number },
			{ name: 'perPage', defaultValue: 10, parseValue: Number }
		])
	],
	data() {
		const fields = [
			{ key: 'name', label: this.$t('Name') },
			{ key: 'email', label: this.$t('Email') },
			{ key: 'last_login', label: this.$t('Last Login') },
			{ key: 'email_verified', label: this.$t('Status') }
		];

		return {
			users: {},
			loadingHash: {},
			isLoading: false,
			fields,
			config: {
				refresh: { visible: true }
			},
			isCreateUserModalOpen: false
		};
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({ findUsers: 'user/find', getUserById: 'user/findById' }),
		async fetchData() {
			const query = {
				$limit: this.perPage,
				$skip: this.perPage * (this.currentPage - 1),
				$where: { role: 'pimienta-api-creator' }
			};

			try {
				this.isLoading = true;
				this.users = await this.findUsers({ query: { query }, skipMutations: true });
				this.loadingHash = _.reduce(this.users.data, (acc, { user_id }) => ({ ...acc, [user_id]: true }), {});

				// the details returned by the list users endpoint of Auth0 are limited,
				// so we are complementing them by fetching the user details for each user
				Promise.map(
					this.users?.data ?? [],
					async (usr, index) => {
						const user = await this.getUserById({ id: usr.user_id, skipMutations: true });
						this.users.data = _fp.set(index, { ...usr, ...user }, this.users.data);
						this.loadingHash[usr.user_id] = false;
					},
					{ concurrency: 2 }
				).finally(() => {
					this.loadingHash = {};
				});
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching the list of admin users')
				});

				throw err;
			} finally {
				this.isLoading = false;
			}
		},
		handleTableChange({ currentPage, perPage, sort }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			if (sort) {
				this.sort = sort;
			}
		},
		handleRowClick(item, index, event) {
			if (event.target.type === 'button') return;
			this.$router.push({ name: 'users.edit', params: { id: item.user_id } });
		},
		fromNow(date) {
			return moment(date).fromNow();
		}
	}
};
</script>
