<template>
	<div class="MediaLibrary">
		<div class="MediaLibrary_Header">
			<h2 class="MediaLibrary_Header_Title">{{ $t('Media Library') }}</h2>
			<div class="MediaLibrary_Header_Actions">
				<icon
					name="th-large"
					:class="['MediaLibrary_ViewModeIcon', viewMode === 'grid' ? 'MediaLibrary_ViewModeIcon--active' : '']"
					@click="setViewMode('grid')"
				/>
				<icon
					name="list"
					:class="['MediaLibrary_ViewModeIcon', viewMode === 'list' ? 'MediaLibrary_ViewModeIcon--active' : '']"
					@click="setViewMode('list')"
				/>
			</div>
		</div>
		<span class="MediaLibrary_FileNumber">{{ fileNumberText }}</span>
		<FileGrid v-if="viewMode === 'grid'" :content="content" @content-clicked="contentClicked" />
		<FileList v-if="viewMode === 'list'" :content="content" @content-clicked="contentClicked" />
	</div>
</template>

<script>
import FileGrid from './FileGrid';
import FileList from './FileList';

export default {
	components: {
		FileGrid,
		FileList
	},
	props: {
		content: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			viewMode: 'grid'
		};
	},
	computed: {
		fileNumberText() {
			return `${this.$t('Showing')} ${this.content.length} ${this.$t('files')}`;
		}
	},
	methods: {
		contentClicked(content) {
			this.$emit('content-clicked', content);
		},
		setViewMode(mode) {
			this.viewMode = mode;
		}
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/src/styles/variables.scss';
@import '~@oneflow/ofs-vue-layout/src/styles/mixins.scss';

.MediaLibrary {
	&_Header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		&_Actions {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 50px;
			margin-right: 12px;
		}
	}

	&_FileTypesSelect {
		&_Label {
			@include ofTextStyleFormLabels();
		}
	}

	&_FileNumber {
		@include ofTextStyleFormLabels();
		margin-bottom: 15px;
		display: inline-block;
	}

	&_ViewModeIcon {
		color: $of-color-grey-3;
		cursor: pointer;

		&--active {
			color: $of-color-dark;
		}
	}
}
</style>
