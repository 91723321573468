<template>
	<div>
		<b-modal
			size="lg"
			modal-class="ShippingEditModal"
			:ok-disabled="isInvalid"
			:ok-title="$t('Save')"
			:title="shippingMethodId ? $t('Edit Shipping Method') : $t('Create Shipping Method')"
			:visible="isVisible"
			@close="$emit('close')"
			@hide="$emit('close')"
			@ok="onSave"
		>
			<b-row>
				<b-col md="5">
					<OfFormInput :label="$t('Name')" name="name" required />
				</b-col>
				<b-col md="5">
					<OfFormInput :label="$t('SiteFlow Alias')" name="alias" required />
				</b-col>
				<b-col md="2">
					<OfToggle name="active" :label="$t('Active')" />
				</b-col>
			</b-row>
			<b-row>
				<b-col md="6">
					<h2 class="mt-4">{{ $t('Shipping Rates') }}</h2>
				</b-col>
				<b-col md="6" class="d-flex justify-content-end">
					<div class="d-flex align-items-end">
						<b-button variant="primary" size="xs" class="m-1" @click="onAddShippingRate">
							{{ $t('Add shipping rate') }}
						</b-button>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col md="12">
					<div class="w-100" style="height: 1px; background-color: #d3d8e0;"></div>
				</b-col>
			</b-row>
			<b-row
				v-for="(shippingRate, index) in _get(formData, 'shippingRates', [])"
				:key="`${index}-${shippingRate.region}`"
			>
				<b-col md="4">
					<OfFormInput :label="$t('Description')" :name="`shippingRates[${index}].description`" :show-errors="true" />
				</b-col>
				<b-col md="4">
					<OfFormInput
						:label="`${$t('Price')} (cents)`"
						:name="`shippingRates[${index}].amount`"
						:show-errors="true"
						required
					>
						<template v-slot:prepend>
							<b-input-group-text class="w-100">
								{{ getCurrencySymbol(_get(formData, `shippingRates[${index}].region`)) }}
							</b-input-group-text>
						</template>
						<template v-slot:append>
							<OfFormSelect
								:name="`shippingRates[${index}].region`"
								:options="currencyOptions"
								class="h-100"
								without-label
							/>
						</template>
					</OfFormInput>
				</b-col>
				<b-col md="3">
					<OfFormSelect
						:label="$t('Tax Behaviour')"
						:name="`shippingRates[${index}].taxBehaviour`"
						:options="taxBehaviourOptions"
						show-errors
					/>
				</b-col>
				<b-col md="1">
					<b-button variant="danger" class="shipping-rates__trash-button" @click="onRemoveShippingRate(index)">
						<icon name="trash" />
					</b-button>
				</b-col>
				<b-col md="4">
					<OfFormCheckbox
						:right-side-label="$t('Show delivery limits')"
						:name="`shippingRates[${index}].showDeliveryLimits`"
						@input="onToggleDeliveryLimits($event, index)"
					/>
				</b-col>
				<b-col v-if="_get(shippingRate, 'showDeliveryLimits', false)" md="3">
					<OfFormInput
						type="number"
						:label="$t('Minimum delivery time (days)')"
						:name="`shippingRates[${index}].deliveryLimits.min`"
						:min="0"
						:show-errors="true"
					/>
				</b-col>
				<b-col v-if="_get(shippingRate, 'showDeliveryLimits', false)" md="3">
					<OfFormInput
						type="number"
						:label="$t('Maximum delivery time (days)')"
						:name="`shippingRates[${index}].deliveryLimits.max`"
						:min="0"
						:show-errors="true"
					/>
				</b-col>
				<b-col md="12">
					<div class="w-100" style="height: 1px; background-color: #d3d8e0;"></div>
				</b-col>
			</b-row>
			<!-- <b-row>
				<b-col md="12">
					<b-button variant="primary" class="mt-2" @click="onAddShippingRate">{{ $t('Add shipping rate') }}</b-button>
				</b-col>
			</b-row> -->
			<b-row v-if="!formData || !formData.shippingRates || !formData.shippingRates.length">
				<b-col md="12">
					<div class="mt-2 mb-4 d-flex justify-content-center">
						<icon class="mr-2 mt-1" name="exclamation-triangle" />Add at least one shipping rate to the shipping method
					</div>
				</b-col>
			</b-row>
		</b-modal>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import {
	OfFormInput,
	OfFormSelect,
	OfToggle,
	OfFormCheckbox,
	withForm,
	validateWithMessage
} from '@oneflow/ofs-vue-layout';
import { getCurrencySymbol, currencyOptions } from '@/lib/currency';
import { taxBehaviourOptions } from '../../../lib/constants';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';

export default {
	components: {
		OfFormInput,
		OfFormSelect,
		OfToggle,
		OfFormCheckbox
	},
	mixins: [withForm('shippingMethodForm')],
	props: {
		isVisible: {
			type: Boolean,
			required: true
		},
		shippingMethodId: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			showDeliveryLimits: false,
			currencyOptions,
			taxBehaviourOptions
		};
	},
	computed: {
		validationRules() {
			return {
				formData: {
					shippingRates: {
						required: validateWithMessage(this.$t('You should add at least one shipping rate'), required),
						$each: {
							description: {
								maxLength: validateWithMessage(
									this.$t('maxLengthValidation', { count: 100 }),
									value => _.size(value) <= 100
								)
							},
							amount: {
								uniqCurrency: validateWithMessage(
									this.$t('Currencies must not be duplicated'),
									(_amount, { region }) => {
										const { shippingRates } = this.formData;
										return _.filter(shippingRates, ['region', region]).length === 1;
									}
								)
							},
							deliveryLimits: {
								min: {
									minValue: validateWithMessage(this.$t('Must be 1 or more'), minValue(1)),
									maxValue: validateWithMessage(this.$t('Must be less than 31'), maxValue(31)),
									lessThanMax: validateWithMessage(this.$t("Can't be more than max"), (value, deliveryLimit) => {
										if (value === undefined || deliveryLimit === undefined) return true;
										return value <= deliveryLimit.max;
									})
								},
								max: {
									minValue: validateWithMessage(this.$t('Must be 1 or more'), minValue(1)),
									maxValue: validateWithMessage(this.$t('Must be less than 31'), maxValue(31)),
									moreThanMin: validateWithMessage(this.$t("Can't be less than min"), (value, deliveryLimit) => {
										if (value === undefined || deliveryLimit === undefined) return true;
										return value >= deliveryLimit.min;
									})
								}
							}
						}
					}
				}
			};
		}
	},
	watch: {
		async isVisible(isVisible) {
			if (isVisible) {
				if (this.shippingMethodId) {
					const response = await this.findShippingMethodById({ id: this.shippingMethodId });
					response.shippingRates.forEach(sr => {
						if (sr.deliveryLimits && sr.deliveryLimits.min && sr.deliveryLimits.max) {
							sr.showDeliveryLimits = true;
						}
					});
					this.initFormData(response);
				}
			} else {
				this.resetFormData();
			}
		}
	},
	methods: {
		...mapActions({
			findShippingMethodById: 'shipping-method/findById',
			createShippingMethod: 'shipping-method/create',
			updateShippingMethod: 'shipping-method/update'
		}),
		_get: _.get,
		_padEnd: _.padEnd,
		getCurrencySymbol,
		onAddShippingRate() {
			const shippingRate = {
				description: '',
				amount: 0,
				region: 'US',
				taxBehaviour: 'inclusive',
				showDeliveryLimits: false
			};
			const shippingRates = _.get(this.formData, 'shippingRates', []);

			this.updateField('shippingRates', [...shippingRates, shippingRate]);
		},
		onRemoveShippingRate(index) {
			const shippingRates = _.get(this.formData, 'shippingRates', []);
			const filtered = _.filter(shippingRates, (_, idx) => idx !== index);

			this.updateField('shippingRates', filtered);
		},
		onToggleDeliveryLimits(show, index) {
			if (show) this.formData.shippingRates[index].deliveryLimits = { min: 0, max: 0 };
			else delete this.formData.shippingRates[index].deliveryLimits;
		},
		async onSave() {
			let notification;
			const payload = {
				...this.formData,
				shippingRates: _.map(_.get(this.formData, 'shippingRates', []), shippingRate =>
					_.omit(shippingRate, ['showDeliveryLimits'])
				)
			};
			try {
				if (this.shippingMethodId) {
					await this.updateShippingMethod({ id: this.shippingMethodId, data: payload });
					notification = this.$t('Shipping method updated successfully');
				} else {
					await this.createShippingMethod(payload);
					notification = this.$t('Shipping method created successfully');
				}
				this.$notify({
					type: 'success',
					title: notification
				});
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Shipping method request error'),
					text: err.message
				});
			}

			this.$emit('ok');
		}
	}
};
</script>

<style lang="scss">
.ShippingEditModal {
	.alert-warning {
		width: 100%;
		display: flex;
		justify-content: space-between;

		span {
			display: block;
			width: 80%;
		}
	}

	.shipping-rates {
		&__trash-button {
			margin-top: 27px;
		}
	}
}

.StoresModal {
	.modal-body {
		height: 70vh;
	}
}
</style>
