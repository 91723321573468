<template>
	<OfsPanel>
		<ListTable
			bordered
			hover
			:table-title="$t('Creator profiles')"
			:config="config"
			:items="creators.data"
			:fields="fields"
			:sort="sort"
			:total-items="creators.total"
			:current-page="currentPage"
			:per-page="perPage"
			:fetch-data="fetchData"
			:is-busy="isLoading"
			@table-change="handleTableChange"
			@row-clicked="handleRowClick"
		>
			<template slot="TableButtons-Slot-left" slot-scope="{}">
				<OfFormInput
					:value="searchString"
					class="col-6 mr-2"
					:placeholder="$t('Search by name or handle')"
					@input="handleSearchStringInput"
				/>
				<OfInlineFilter :filters="filters" :values="filterValues" @change="onFiltersChanged" />
			</template>
			<template slot="TableHeader" slot-scope="{}">
				<OfFilterBar :filters="filters" :values="filterValues" @change="filtersChanged" />
			</template>

			<template #cell(profile.visible)="{ item }">
				<OfsBadge :text="getVisibility(item)" :status="getVisibility(item)" />
			</template>

			<template #TableButtons-Slot-right>
				<b-button variant="primary" class="ml-2" @click="addCreator">
					{{ $t('New Creator') }}
				</b-button>
			</template>
		</ListTable>
	</OfsPanel>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { OfsPanel, ListTable, OfsBadge, OfInlineFilter, OfFormInput, OfFilterBar } from '@oneflow/ofs-vue-layout';
import withQuerystringState from '@/mixins/withQuerystringState';
import {
	parseDefaultQuerystringFilterValues,
	parseQueryStringBoolean
} from '@/lib/parseDefaultQuerystringFilterValues';

export default {
	components: {
		OfsPanel,
		ListTable,
		OfsBadge,
		OfInlineFilter,
		OfFormInput,
		OfFilterBar
	},
	mixins: [
		withQuerystringState([
			{ name: 'currentPage', defaultValue: 1, parseValue: Number },
			{ name: 'perPage', defaultValue: 10, parseValue: Number },
			'searchString',
			{ name: 'sort', defaultValue: {}, parseValue: value => value ?? {} },
			{
				name: 'filterValues',
				parseValue: filterValues => ({
					...parseDefaultQuerystringFilterValues(filterValues),
					...parseQueryStringBoolean('profile.visible', filterValues)
				})
			}
		])
	],
	data() {
		const fields = [
			{ key: 'username', label: this.$t('Name'), sortable: true },
			{ key: 'profile.handle', label: this.$t('Handle'), sortable: true },
			{ key: 'account.name', label: this.$t('Linked account') },
			{ key: 'profile.visible', label: this.$t('Visible'), sortable: true }
		];

		const filters = [
			{
				header: this.$t('Visible'),
				key: 'profile.visible',
				type: 'radio',
				items: [
					{ title: this.$t('True'), value: true },
					{ title: this.$t('False'), value: false }
				]
			}
		];

		return {
			isLoading: false,
			fields,
			filters,
			config: {
				refresh: { visible: true }
			}
		};
	},
	computed: {
		...mapGetters({
			creators: 'creator/creators'
		})
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			getCreators: 'creator/find'
		}),
		addCreator() {
			this.$router.push({ name: 'creators.edit', params: { id: 'new' } });
		},
		async fetchData() {
			const query = {
				$limit: this.perPage,
				$skip: this.perPage * (this.currentPage - 1),
				$populate: { path: 'account', $select: { name: 1 } }
			};
			let filterWhereQuery = {};
			let searchWhereQuery = {};

			if (!_.isEmpty(this.filterValues)) {
				filterWhereQuery = _.reduce(
					this.filterValues,
					(filterQuery, value, key) => {
						return {
							...filterQuery,
							...(key === 'profile.visible' && !value
								? { $or: [{ [key]: Array.isArray(value) ? { $in: value } : value }, { [key]: { $exists: false } }] }
								: { [key]: Array.isArray(value) ? { $in: value } : value })
						};
					},
					{}
				);
			}
			if (this.searchString) {
				searchWhereQuery.$or = [
					{ username: { $regex: this.searchString, $options: 'i' } },
					{ 'profile.handle': { $regex: this.searchString, $options: 'i' } }
				];
			}
			_.set(query, '$where.$and', [searchWhereQuery, filterWhereQuery]);

			if (Object.keys(this.sort).length > 0) {
				query.$sort = this.sort;
			}
			try {
				this.isLoading = true;
				await this.getCreators({ query: { query } });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching creator profiles')
				});

				throw err;
			} finally {
				this.isLoading = false;
			}
		},
		handleTableChange({ currentPage, perPage, sort }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			if (sort) {
				this.sort = sort;
			}
		},
		handleRowClick(item, index, event) {
			if (event.target.type === 'button') return;
			this.$router.push({ name: 'creators.edit', params: { id: item._id } });
		},
		getVisibility(item) {
			const isVisible = _.get(item, 'profile.visible', false);
			return _.toString(isVisible);
		},
		async onFiltersChanged(filters) {
			this.filterValues = filters;
			this.currentPage = 1;
			await this.fetchData();
		},
		handleSearchStringInput: _.debounce(function(value) {
			this.searchString = value;
			this.currentPage = 1;
			this.fetchData();
		}, 800),
		filtersChanged(filters) {
			this.filterValues = filters;
			this.currentPage = 1;
			this.fetchData();
		}
	}
};
</script>
