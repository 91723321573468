<template>
	<b-modal :visible="visible" :ok-disabled="!canSubmit" hide-header @show="onShow" @ok="onSubmit" @hidden="onHidden">
		<OfFormSelect
			name="componentId"
			:label="$t('Preview component')"
			:options="componentOptions"
			:show-errors="true"
			@input="onComponentChange"
		/>
		<OfFormInput
			v-if="formData.componentId"
			name="pageNumber"
			:label="$t('Page number')"
			:show-errors="true"
			type="number"
		/>
	</b-modal>
</template>

<script>
import { numeric, minValue, requiredIf } from 'vuelidate/lib/validators';
import { withForm, OfFormSelect, OfFormInput, validateWithMessage } from '@oneflow/ofs-vue-layout';

const defaultOptions = { componentId: null, pageNumber: 1 };

export default {
	components: {
		OfFormSelect,
		OfFormInput
	},
	mixins: [withForm('ProductImageOptions')],
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		componentOptions: {
			type: Array,
			default: () => []
		},
		options: {
			type: Object,
			required: true,
			default: () => defaultOptions
		}
	},
	computed: {
		validationRules() {
			return {
				formData: {
					pageNumber: {
						numeric: validateWithMessage('Page number must be numeric', numeric),
						minValue: validateWithMessage('Page number must start from 1', minValue(1)),
						required: validateWithMessage(
							'Required',
							requiredIf(options => !_.isNil(options?.componentId))
						)
					}
				}
			};
		}
	},
	methods: {
		onComponentChange(componentId) {
			if (_.isNil(componentId)) {
				this.updateField('pageNumber', defaultOptions.pageNumber);
			}
		},
		onShow() {
			this.initFormData(this.options);
		},
		onSubmit() {
			this.$emit('ok', this.formData);
		},
		onHidden() {
			this.$emit('hidden');
		}
	}
};
</script>
