<template>
	<OfMultiSelect :name="name" :label="label" :options="options" :multiple="multiple" />
</template>

<script>
import { OfMultiSelect } from '@oneflow/ofs-vue-layout';
import { mapActions } from 'vuex';

export default {
	components: { OfMultiSelect },
	props: {
		label: {
			type: String,
			default() {
				return this.$t('Account');
			}
		},
		name: {
			type: String,
			default: () => 'accountId'
		},
		value: {
			type: null
		},
		multiple: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return { accounts: [] };
	},
	computed: {
		options() {
			return this.accounts?.data?.map(account => ({ text: account.displayName, value: account.authAccountId }));
		}
	},
	async mounted() {
		this.accounts = await this.findAccounts({
			query: { query: { $limit: 1000 } },
			options: { skipMutations: true }
		});
	},
	methods: {
		...mapActions({ findAccounts: 'account/find' })
	}
};
</script>
