export const getAccountMembers = async ({ dispatch, commit }, { query = {} }) => {
	const path = `accountMembers/`;
	const members = await dispatch('request', { method: 'GET', path, params: query }).then(r => r.data);
	commit('SET_ACCOUNT_MEMBERS', members);

	return members;
};
export const createAccountMember = async ({ dispatch }, data) => {
	const { accountId, email, name } = data;

	const path = `accountMembers/`;
	const payload = { accountId, email, name };

	return dispatch('request', { method: 'POST', path, payload }).then(r => r.data);
};

export const removeAccountMember = async ({ dispatch }, data) => {
	const { recordId } = data;

	const path = `accountMembers/${recordId}`;
	return dispatch(`request`, { method: 'DELETE', path }).then(r => r.data);
};

export const getAccounts = async ({ dispatch, getters }, userEmail) => {
	const path = `accountMembers/`;
	const params = { query: { $populate: 'account', $where: { email: userEmail } } };
	const {
		data: { data: memberships }
	} = await dispatch('request', { path, method: 'GET', params });
	// Filter out memberships to closed accounts
	return memberships.map(m => m.account).filter(a => a);
};
