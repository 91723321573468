<template>
	<div class="upload-file">
		<div class="cursor-pointer" @click.prevent="handleMediaUploaderOpen">
			<div class="mb-2">
				<b-form-group :label="$t('Upload or select file')" label-class="font-weight-bold text-black">
					<b-input-group v-if="file">
						<b-form-file :browse-text="$t('Change')" no-drop>
							<template #placeholder>
								<b-avatar :src="file.thumbnailUrlData.url" square size="20px" class="mr-1" />&nbsp;
								{{ file.name }}
							</template>
						</b-form-file>
						<b-input-group-append>
							<b-input-group-text>
								<router-link :to="{ name: 'filePreview', params: { id: file._id } }">
									<a @click.stop="handleFilePreview($event, file._id)">{{ $t('Preview') }}</a>
								</router-link>
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
					<b-form-file v-else :placeholder="$t('Click to select a file')" no-drop />
				</b-form-group>
			</div>
		</div>

		<b-form-group
			:description="isLoadingPageCount ? `${$t('calculating page count')}... ${pageCountPercentLoaded}%` : ''"
		>
			<template #label>
				<legend class="col-form-label font-weight-bold text-black">
					{{ $t('Page count') }}
				</legend>
			</template>

			<b-form-input v-model="pageCount" type="number" />
		</b-form-group>

		<MediaUploader
			:title="$t('Upload file')"
			:show="isMediaUploaderVisible"
			:path="baseFileUploadPath"
			:account-id="product.accountId"
			disable-url
			:on-close="handleMediaUploaderClose"
			@file-picked="handleFilePicked"
		/>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import MediaUploader from '@/components/MediaUploader';
import { PdfjsService } from '@/lib/PdfjsService';

export default {
	components: { MediaUploader },
	props: {
		product: { type: Object },
		componentCode: { type: String },
		delivery: { type: String },
		releases: { type: Array }
	},
	data() {
		return {
			isMediaUploaderVisible: false,
			pageCount: null,
			pageCountPercentLoaded: 0,
			file: null,
			isLoadingPageCount: false
		};
	},
	computed: {
		baseFileUploadPath() {
			const isoDate = new Date().toISOString();
			return `pimienta/products/${this.product._id}/releases/${isoDate}/${this.delivery}/${this.componentCode}`;
		}
	},
	watch: {
		pageCount() {
			if (this.pageCount) {
				this.$emit('file-selected', {
					pageCount: this.pageCount,
					file: this.file
				});
			}
		}
	},
	methods: {
		...mapActions({ createRelease: 'releases/create' }),
		handleMediaUploaderOpen() {
			this.isMediaUploaderVisible = true;
		},
		handleMediaUploaderClose() {
			this.isMediaUploaderVisible = false;
		},
		async getPageCount(pdfUrl) {
			this.isLoadingPageCount = true;
			return PdfjsService.getPdfPageCount(pdfUrl, ({ total, loaded }) => {
				this.pageCountPercentLoaded = Math.ceil((loaded / total) * 100);
			}).finally(() => {
				this.isLoadingPageCount = false;
			});
		},
		async handleFilePicked(file) {
			this.file = file;
			this.pageCount = file.contentType === 'application/pdf' ? await this.getPageCount(file.urlData.url) : null;
			this.$emit('file-selected', {
				pageCount: this.pageCount,
				file: this.file
			});
		},
		handleFilePreview($event, fileId) {
			if (!$event.ctrlKey && !$event.metaKey) {
				$event.preventDefault();
				$event.stopPropagation();
				this.$showFilePreviewer(fileId);
			}
		}
	}
};
</script>

<style lang="scss">
.upload-file {
	label,
	legend {
		color: black;
	}

	&__page-count {
		.input-group-append {
			.input-group-text {
			}
		}
	}
}
</style>
