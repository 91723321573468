<template>
	<div v-if="errorMessages.length" class="d-block invalid-feedback">
		<div v-for="error in errorMessages" :key="error">
			{{ error }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			required: true
		}
	},
	inject: {
		parentVm: {}
	},
	computed: {
		errorMessages() {
			return this.parentVm.serverErrors?.[this.name]?.errors ?? [];
		}
	}
};
</script>
