<template>
	<div>
		<OfsPanel>
			<div class="row mb-4">
				<div class="col-sm-12 col-md-6">
					<h1>{{ $t('Order Details') }} #{{ order.orderId }}</h1>
				</div>
				<div class="col-sm-12 col-md-6 text-md-right">
					<b-dropdown variant="primary" :text="$t('Actions')" right>
						<b-dropdown-item :disabled="!isEditEnabled" @click="handleEditVisible">
							<icon name="pen" class="mr-2 mt-1 mb-1 dropdown_icon" />
							{{ $t('Edit order') }}
						</b-dropdown-item>
						<!--						<b-dropdown-item :disabled="isResubmitDisabled" @click="handleResubmitVisible">-->
						<!--							<icon name="redo" class="mr-2 mt-1 mb-1 dropdown_icon" />-->
						<!--							{{ $t('Resubmit') }}-->
						<!--						</b-dropdown-item>-->
						<!--						<b-dropdown-divider />-->
						<b-dropdown-item :disabled="!isCancelButtonVisible" @click="showCancelModal(null)">
							<icon name="times-circle" class="mr-2 mt-1 mb-1 dropdown_icon" />
							{{ $t('Cancel (Refund)') }}
						</b-dropdown-item>
					</b-dropdown>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<OrderItemsSection
						:items="validOrderItems"
						:is-view-mode="!isOrderItemsEditable"
						:line-item-ids="productionOrder ? productionOrder.lineItemIds : []"
						@change="onOrderItemsChange"
					/>
					<OrderLogs :items="order.logs" @change="onOrderLogsChange" />
				</div>
				<div class="col-md-5 offset-xl-1 OrderDetails">
					<b-row class="order-details__section-title">
						<h2>
							{{ $t('Order Details') }}
							<a class="DetailsField-link" href="#" @click.prevent="switchModal"> - {{ $t('View Source') }}</a>
						</h2>
					</b-row>
					<div class="row">
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Order Id') }}</div>
							<div class="DetailsField-value">{{ order._id }}</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Human Order Id') }}</div>
							<div class="DetailsField-value">{{ order.orderId }}</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Submitted') }}</div>
							<div class="DetailsField-value">
								{{ order.createdAt ? formatDate(order.createdAt) : '' }}
							</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Status') }}</div>
							<div class="DetailsField-value">
								<OfsBadge v-if="order.status" :text="order.status" :status="order.status" />
							</div>
						</div>
						<div v-if="isOrderShipped" class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Courier') }}</div>
							<div class="DetailsField-value">{{ orderCourierService }}</div>
						</div>
						<div v-if="isOrderShipped" class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Tracking number') }}</div>
							<div class="DetailsField-value">{{ orderTrackingNumber }}</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Order Intent') }}</div>
							<OfsBadge :text="orderIntentBadgeText" />
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Channel') }}</div>
							<div class="DetailsField-value">
								{{ _get(order, 'channel.source') }}
							</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Payment Provider') }}</div>
							<div class="DetailsField-value">
								{{ _get(order, 'payment.provider') }}
							</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Payment Provider data') }}</div>
							<div class="DetailsField-value">
								<b-btn v-b-modal.modal-payment-provider-data variant="link" class="p-0">
									{{ $t('Click to open') }}
								</b-btn>

								<b-modal
									id="modal-payment-provider-data"
									:title="$t('Payment Provider data')"
									:ok-title="$t('Close')"
									ok-only
									ok-variant="secondary"
									size="xl"
								>
									<CodeEditor :value="JSON.stringify(_get(order, 'payment.providerData'), null, 2)" />
								</b-modal>
							</div>
						</div>
						<div v-if="_get(order, 'payoutCode.code')" class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Payout code') }}</div>
							<OfsBadge :text="_get(order, 'payoutCode.code')" />
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Total Sale Price') }}</div>
							<div class="DetailsField-value">
								{{ formatCents(order.totalSalePrice, order.isoCurrency, '-') }}
							</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Total Tax Amount') }}</div>
							<div class="DetailsField-value">
								{{ formatCents(order.totalTaxAmount, order.isoCurrency, '-') }}
							</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Total Net Price') }}</div>
							<div class="DetailsField-value">
								{{ formatCents(order.totalNetPrice, order.isoCurrency, '-') }}
							</div>
						</div>
						<template v-if="!isViewMode">
							<b-col md="12" class="DetailsField d-flex justify-content-between">
								<div class="DetailsField-label">{{ $t('Shipping method') }}</div>
								<div class="DetailsField-value">{{ _get(order, 'shippingMethod.name', '-') }}</div>
							</b-col>
							<b-col md="12" class="DetailsField d-flex justify-content-between">
								<div class="DetailsField-label">{{ $t('Shipping Price') }}</div>
								<template v-if="_get(order, 'shippingPrice') || _get(order, 'shippingPrice') === 0">
									{{ getShippingPrice(order) }}
								</template>
							</b-col>
						</template>
						<OrderVersionsTable
							:items="order.separatedProductionOrders"
							:is-resubmit-disabled="isResubmitDisabled"
							:is-cancel-butto-visible="isCancelButtonVisible"
							:title="$t('Production Orders')"
							@resubmit="handleResubmitVisible"
							@cancel="showCancelModal"
						/>
					</div>
					<b-row class="order-details__section-title mt-4">
						<h2>
							{{ $t('Shipping Details') }}
						</h2>
					</b-row>
					<ShippingDetails
						v-if="order.shipping"
						:working-mode="
							workingMode === orderDetailsWorkingModes.Resubmit ? orderDetailsWorkingModes.View : workingMode
						"
						@change="onShipmentUpdate"
					/>
					<b-col v-if="!isViewMode" md="12" class="d-flex justify-content-end">
						<b-button
							v-if="workingMode !== orderDetailsWorkingModes.Resubmit"
							variant="secondary"
							class="ml-2 mb-1"
							@click="handleCancelEdit"
						>
							{{ $t('Cancel') }}
						</b-button>
						<b-button
							v-if="isSaveButtonVisible"
							variant="primary"
							class="ml-2 mb-1"
							:disabled="!isFormChanged || !isFormValid"
							@click="update"
						>
							{{ $t('Save') }}
						</b-button>
					</b-col>
				</div>
			</div>
		</OfsPanel>
		<b-modal
			:visible="isSourceModalVisible"
			:title="`${$t('Order Source')} #${order.orderId}`"
			size="xl"
			modal-class="SourceModal"
			ok-only
			@hide="switchModal"
		>
			<CodeEditor :value="orderSource" />
		</b-modal>
		<b-modal
			:visible="isCancelModalVisible"
			:title="$t('Cancel order')"
			:cancel-title="$t('No')"
			:ok-title="$t('Yes')"
			:ok-disabled="isOkDisabled"
			ok-variant="danger"
			size="md"
			modal-class="CancelModal"
			@hide="
				isCancelModalVisible = false;
				productionOrder = null;
			"
			@ok="() => onCancelOrder({ orderId: order._id })"
		>
			<span v-if="productionOrder && isCancelPossible">
				{{ $t('Are you sure you want to cancel production order') }} ? <br />
				<strong v-if="productionOrder">sourceOrder#{{ productionOrder.externalSourceOrderId }}</strong> <br />
				<strong v-if="productionOrder">productionOrder#{{ productionOrder._id }}</strong>
			</span>
			<span v-else-if="isCancelPossible">
				{{ $t('Are you sure you want to cancel order') }}
				<strong>#{{ order.orderId }}</strong
				>?
			</span>
			<span v-else>
				{{ $t('Order') }}
				<strong>#{{ order.orderId }}</strong>
				{{ $t('cannot be cancelled') }}
			</span>

			<OfFormInput v-if="isCancelPossible" v-model="cancellationReason" :placeholder="$t('Cancellation reason')" />
			<template v-if="!productionOrder && isRefundPossible">
				<OfFormCheckbox v-model="refund" :right-side-label="$t('Request a refund')" />
				<OfFormInput v-model="refundReason" :placeholder="$t('Refund reason - Optional')" />
			</template>
		</b-modal>
		<b-modal
			:visible="isResubmitModalVisible"
			:title="$t('Resubmit order')"
			:cancel-title="$t('Cancel')"
			:ok-title="$t('Confirm')"
			:ok-disabled="!resubmitionReason"
			ok-variant="primary"
			size="md"
			modal-class="ResubmitOrdersModal"
			@hide="hideResubmissionModal"
			@ok="processResubmit"
		>
			{{ $t('Are you sure you want to resubmit selected production order') }}?
			<OfFormInput v-model="resubmitionReason" :placeholder="$t('Resubmission reason')" />

			<OfFormCheckbox v-model="resendNotifications" :right-side-label="$t('Restart end customer notification')" />
		</b-modal>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfsBadge, OfsPanel, OfFormInput, OfFormCheckbox, withForm } from '@oneflow/ofs-vue-layout';
import CodeEditor from '@/components/CodeEditor';
import OrderLogs from '@/components/Orders/OrderLogs.vue';
import OrderVersionsTable from '../../../components/OrderVersionsTable';
import formatDate from '../../../lib/formatDate';
import formatCents from '../../../lib/formatCents';
import { orderIntentOptions, orderStatus, intentTypes } from '@/lib/constants';
import ShippingDetails from '@/components/Orders/ShippingDetails.vue';
import { orderPaymentStatus } from '../../Create/Products/constants';
import OrderItemsSection from '@/components/Orders/OrderItemsSection.vue';
import { orderDetailsWorkingModes } from '../../../lib/constants';

export default {
	components: {
		OfsBadge,
		OrderVersionsTable,
		CodeEditor,
		OfFormInput,
		OfsPanel,
		OfFormCheckbox,
		ShippingDetails,
		OrderItemsSection,
		OrderLogs
	},
	mixins: [withForm('OrderDetails')],
	data() {
		return {
			orderDetailsWorkingModes,
			workingMode: orderDetailsWorkingModes.View,
			workforce: _.get(window.$config, 'workforce', {}),
			isSourceModalVisible: false,
			isCancelModalVisible: false,
			cancellationReason: null,
			refund: false,
			refundReason: null,
			isResubmitModalVisible: false,
			resubmitionReason: null,
			updatedShipment: null,
			originalShipment: null,
			updatedOrderItems: null,
			originalOrderItems: null,
			itemsSectionValid: true,
			resendNotifications: false,
			productionOrder: null
		};
	},
	computed: {
		...mapGetters({
			order: 'order/order'
		}),
		orderId() {
			return _.get(this.$route.params, 'id');
		},
		orderSource() {
			return JSON.stringify(this.order, null, 2);
		},
		orderIntentBadgeText() {
			const option = _.find(orderIntentOptions, ['value', this.order.intent]);
			return _.get(option, 'text', '').toUpperCase();
		},
		orderTrackingNumber() {
			return _.get(this.order, 'shipment.trackingNumber');
		},
		orderCourierService() {
			const code = _.get(this.order, 'shipment.carrier.code');
			const service = _.get(this.order, 'shipment.carrier.service');
			return code && service ? `${code} / ${service}` : code || service;
		},
		isCancelButtonVisible() {
			if (
				[orderStatus.PreOrder, orderStatus.Live, orderStatus.Error, orderStatus.Blocked].includes(this.order.status)
			) {
				return true;
			}
			if (this.order.status === orderStatus.Complete && this.isRefundPossible) {
				return true;
			}
			return false;
		},
		isCancelPossible() {
			return this.order.status !== orderStatus.Complete;
		},
		isRefundPossible() {
			return (
				_.get(this.order, 'payment.status', null) !== orderPaymentStatus.Refunded &&
				_.get(this.order, 'intent') !== intentTypes.Test &&
				_.get(this.order, 'intent') !== intentTypes.Sample
			);
		},
		isRefundDefaultPicked() {
			return [orderStatus.PreOrder, orderStatus.Complete].includes(this.order.status);
		},
		isOkDisabled() {
			if (this.isCancelPossible && !this.cancellationReason) {
				return true;
			}
			if (!this.isCancelPossible && !this.refund) {
				return true;
			}
			return false;
		},
		isOrderShipped() {
			return this.order.status === orderStatus.Complete;
		},
		validOrderItems() {
			return this.order.lineItems;
		},
		isResubmitDisabled() {
			const status = _.get(this.order, 'status');
			return !status || status === orderStatus.PreOrder;
		},
		isEditEnabled() {
			const status = _.get(this.order, 'status');
			return (
				status &&
				[
					orderStatus.Pending,
					orderStatus.PreOrder,
					orderStatus.Blocked,
					orderStatus.Live,
					orderStatus.Complete
				].includes(status)
			);
		},
		isFormChanged() {
			const isShipmentUpdated = !_.isEqual(this.updatedShipment, this.originalShipment);
			const isOrderItemsUpdated = !_.isEqual(this.updatedOrderItems, this.originalOrderItems);
			return isShipmentUpdated || isOrderItemsUpdated;
		},
		isOrderItemsViewMode() {
			const status = _.get(this.order, 'status');
			return false;
		},
		isViewMode() {
			return this.workingMode === orderDetailsWorkingModes.View;
		},
		isSaveButtonVisible() {
			return this.workingMode === orderDetailsWorkingModes.Edit;
		},
		isResubmitButtonVisible() {
			return this.workingMode === orderDetailsWorkingModes.Resubmit;
		},
		isOrderItemsEditable() {
			switch (this.workingMode) {
				case orderDetailsWorkingModes.Resubmit:
					return true;
				case orderDetailsWorkingModes.Edit:
					return this.order.status === orderStatus.PreOrder;
				default:
					return false;
			}
		},
		isFormValid() {
			return this.itemsSectionValid;
		},
		isVipOrder() {
			return _.get(this.order, 'discount.metadata.vip', false);
		}
	},
	watch: {
		'$route.params.id': {
			immediate: true,
			async handler(orderId) {
				await this.initialize(orderId);
			}
		}
	},
	methods: {
		...mapActions({
			findById: 'order/findById',
			cancelOrder: 'order/cancelOrder',
			resubmitOrder: 'order/resubmitOrder',
			cancelProductionOrder: 'order/cancelProductionOrder',
			updateOrder: 'order/updateOrder'
		}),
		_get: _.get,
		formatDate,
		formatCents,
		async findOrderById(orderId) {
			return this.findById({
				id: orderId,
				query: {
					query: {
						$populate: [
							{
								path: 'lineItems',
								$populate: [
									{ path: 'product' },
									{ path: 'payouts', $populate: { path: 'payee', $select: { username: 1 } } }
								],
								$limit: 50
							},
							{ path: 'payoutCode' },
							{ path: 'shippingMethod' },
							{ path: 'separatedProductionOrders' }
						]
					}
				}
			});
		},
		showCancelModal(item) {
			this.productionOrder = item;
			this.refund = this.isRefundDefaultPicked;
			this.isCancelModalVisible = true;
		},
		async onCancelOrder({ orderId }) {
			try {
				if (this.productionOrder) {
					await this.cancelProductionOrder({
						orderId,
						payload: { reason: this.cancellationReason, refund: this.refund, refundReason: this.refundReason },
						productionOrderId: this.productionOrder._id
					});
				} else {
					await this.cancelOrder({
						orderId,
						payload: { reason: this.cancellationReason, refund: this.refund, refundReason: this.refundReason }
					});
				}
				await this.findOrderById(orderId);
				this.$notify({ type: 'success', text: this.generateCancelNotify() });
			} catch (err) {
				this.$notify({ type: 'error', text: err.message });
			} finally {
				this.productionOrder = null;
			}
		},
		generateCancelNotify() {
			if (this.cancellationReason && !this.refund) return this.$t('Order cancelled successfully');
			if (!this.cancellationReason && this.refund) return this.$t('Order refunded successfully');
			if (this.cancellationReason && this.refund) return this.$t('Order cancelled and refunded successfully');
		},
		getShippingPrice(order) {
			const fallbackValue = 0;
			return formatCents(order?.shippingPrice, order?.isoCurrency) || fallbackValue;
		},
		handleEditVisible() {
			this.workingMode = orderDetailsWorkingModes.Edit;
		},
		handleCancelEdit() {
			this.workingMode = orderDetailsWorkingModes.View;
		},
		handleResubmitVisible(item) {
			this.productionOrder = item;
			this.workingMode = orderDetailsWorkingModes.Resubmit;
			this.isResubmitModalVisible = true;
		},
		hideResubmissionModal() {
			this.isResubmitModalVisible = false;
			this.productionOrder = null;
			this.workingMode = orderDetailsWorkingModes.View;
		},
		onOrderItemsChange(event) {
			const orderItemsUpdate = event.value;
			this.itemsSectionValid = event.isValid;
			if (!this.originalOrderItems && !_.isEmpty(orderItemsUpdate)) {
				this.originalOrderItems = orderItemsUpdate;
			}
			this.updatedOrderItems = orderItemsUpdate;
		},
		async onOrderLogsChange() {
			await this.findOrderById(this.orderId);
		},
		async onShipmentUpdate(shipment) {
			if (!this.originalShipment && !_.isEmpty(shipment)) {
				this.originalShipment = shipment;
			}
			this.updatedShipment = shipment;
		},
		switchModal() {
			this.isSourceModalVisible = !this.isSourceModalVisible;
		},
		async update() {
			try {
				const payload = {
					...this.buildUpdatePatch()
				};
				await this.dispatchSubmit(this.updateOrder({ orderId: this.order._id, payload }));
				this.handleCancelEdit();
				this.initialize(this.orderId);
				this.$notify({ type: 'success', text: 'Order shipment has been updated' });
			} catch (error) {
				this.$notify({
					type: 'error',
					text: _.get(error, 'response.data.message', error.message)
				});
			}
		},
		showSuccessResubmissionNotification(order) {
			this.$notify({
				type: 'success',
				title: 'Success',
				text: `Order #${order.orderId} has been successfully resubmitted`
			});
		},
		showErrorResubmissionNotification(text) {
			this.$notify({
				type: 'error',
				title: this.$t('Error'),
				text
			});
		},
		showResubmitConfirmationModal() {
			this.isResubmitModalVisible = true;
		},
		buildUpdatePatch() {
			const updatedShipping = _.get(this.updatedShipment, 'shipping');
			const updatedShippingMethodId = _.get(this.updatedShipment, 'shippingMethodId');

			const originalShippingMethodId = _.get(this.originalShipment, 'shippingMethodId');

			const patch = {};
			patch.shipping = updatedShipping;
			if (!_.isEmpty(updatedShippingMethodId) && !_.isEqual(updatedShippingMethodId, originalShippingMethodId)) {
				patch.shippingMethodId = updatedShippingMethodId;
			}
			if (!_.isEmpty(this.updatedOrderItems) && !_.isEqual(this.updatedOrderItems, this.originalOrderItems)) {
				patch.itemsPatch = _.reduce(
					this.updatedOrderItems,
					(acc, value, key) => {
						acc.push({
							...value,
							_id: key
						});
						return acc;
					},
					[]
				);
			}
			return patch;
		},
		async processResubmit() {
			const orderPatch = this.buildUpdatePatch();
			const payload = {
				reason: this.resubmitionReason,
				resendNotifications: this.resendNotifications,
				...orderPatch
			};
			const resubmitOrderPromise = this.resubmitOrder({
				orderId: this.orderId,
				payload,
				productionOrderId: this.productionOrder._id
			});

			try {
				const order = await resubmitOrderPromise;
				this.showSuccessResubmissionNotification(order);
				this.initialize(this.orderId);
			} catch (e) {
				const message = _.get(e, 'response.data.message', this.$t('An error occurred while resubmitting an order'));
				this.showErrorResubmissionNotification(message);
			} finally {
				this.productionOrder = null;
			}
		},
		async initialize(orderId) {
			this.originalShipment = null;
			this.updatedShipment = null;
			this.workingMode = orderDetailsWorkingModes.View;
			return this.findOrderById(orderId);
		}
	}
};
</script>

<style lang="scss">
@import '../../../styles/shared';

#refund-checkbox-wrapper {
	display: inline-block;
}

.data-card {
	max-height: 450px;
	overflow: scroll;
	background: #112435;
	color: #fff;

	pre {
		color: #fff;
	}
}

.dropdown_icon {
	width: 16px;
}

.DetailsField {
	margin: 6px 0 6px 0;

	&-label {
		font-size: 14px;
		color: #8a9bb4;
	}

	&-value {
		@include ofTextStyleFormValues();
	}

	&-link {
		@include ofTextStyleLinks;
	}
}

.SourceModal {
	.modal-body {
		height: 70vh;
	}
}

.order-details__section-title {
	background-color: #f2f6fe;
	display: flex;
	padding: 10.5px;
	margin-bottom: 16px;

	h2 {
		font-size: 15px;
		margin: 0;
	}
}

.order-details__section-divider {
	height: 5px;
	background-color: #f2f6fe;
	width: calc(100% - 20px);
	border: none;
	margin: 10px auto;
}
</style>
