<template>
	<div>
		<div class="mt-4">
			<h2>{{ $t('Timeline') }}</h2>
		</div>
		<div class="mt-2">
			<LogsTimeline :items="items" @submit="onCommentSubmit" />
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import LogsTimeline from '@/components/LogsTimeline';

export default {
	components: {
		LogsTimeline
	},
	props: {
		items: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		orderId() {
			return _.get(this.$route.params, 'id');
		}
	},
	methods: {
		...mapActions({
			addOrderComment: 'order/addOrderComment'
		}),
		async onCommentSubmit(message) {
			try {
				await this.addOrderComment({ orderId: this.orderId, payload: { message } });
				this.$emit('change');
			} catch (err) {
				this.$notify({ type: 'error', text: err.message });
			}
		}
	}
};
</script>
