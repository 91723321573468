import loginRoutes from '../containers/Login';
import createRoutes from '../containers/Create';
import sellRoutes from '../containers/Sell';
import adminRoutes from '../containers/Admin';
import FilePreview from '../containers/FilePreview';

const routes = [
	...loginRoutes,
	...createRoutes,
	...sellRoutes,
	...adminRoutes,
	{
		name: 'filePreview',
		path: '/file-preview/:id',
		component: FilePreview
	},
	{
		path: '/',
		redirect: '/create'
	}
];

export default routes;
