import Pusher from 'pusher-js';
import _ from 'lodash';

Pusher.logToConsole = window.$config?.environment !== 'production';

export default class PusherClient {
	static instance = null;

	constructor() {
		if (PusherClient.instance) return;

		const appKey = window.$config?.pusher?.appKey;
		const cluster = window.$config?.pusher?.cluster;
		PusherClient.instance = new Pusher(appKey, {
			cluster: cluster || 'eu'
		});
	}

	subscribe(channelName, eventName, handler) {
		// pusher caches channels internally
		const channel = PusherClient.instance.subscribe(channelName);
		channel.bind(eventName, handler);
	}

	unsubscribe(channelName) {
		PusherClient.instance.unsubscribe(channelName);
	}

	getChannels() {
		return PusherClient.instance.allChannels();
	}
}
