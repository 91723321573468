export default {
	apiBase: process.env.VUE_APP_API_BASE,
	filesApiBase: process.env.VUE_APP_FILES_API_BASE,
	fileStackApiKey: process.env.VUE_APP_FILESTACK_API_KEY,
	brandcentreBase: process.env.VUE_APP_BRANDCENTRE_BASE,
	sentryKey: process.env.VUE_APP_SENTRY_KEY,
	hpStoreUrl: process.env.VUE_APP_HP_STORE_URL,
	hpStoreAccountID: process.env.VUE_APP_HP_STORE_ACCOUNT_ID,
	auth0: {
		domain: process.env.VUE_APP_AUTH_DOMAIN,
		clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
		redirectUri: process.env.VUE_APP_AUTH_REDIRECT_URL,
		audience: process.env.VUE_APP_AUTH_AUDIENCE
	},
	workforce: {
		host: process.env.VUE_APP_WORKFORCE_HOST,
		appId: process.env.VUE_APP_WORKFORCE_APP_ID
	},
	pusher: {
		appKey: process.env.VUE_APP_PUSHER_APP_KEY,
		cluster: process.env.VUE_APP_PUSHER_CLUSTER
	}
};
