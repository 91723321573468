import { mapState, mapMutations, mapGetters } from 'vuex';
import { EventTypes } from '../lib/constants';
import withPusher from './withPusher';
import config from '@/config';

export default {
	mixins: [withPusher],
	computed: {
		...mapState('order', ['releasingOrders', 'failedToReleaseOrders']),
		ordersReleaseChannelName() {
			return `account.${config.hpStoreAccountID}.orders.release`;
		}
	},
	mounted() {
		if (!config.hpStoreAccountID) return;
		this.initializePusherSubscribers();
	},
	beforeDestroy() {
		if (!this.pusherClient) return;
		this.pusherClient.unsubscribe(this.ordersReleaseChannelName);
	},
	methods: {
		...mapMutations({
			SET_RELEASING_ORDERS: 'order/SET_RELEASING_ORDERS',
			SET_FAILED_TO_RELEASE_ORDERS: 'order/SET_FAILED_TO_RELEASE_ORDERS'
		}),
		orderReleasedHandler(message) {
			const releasedOrderId = _.get(message, 'data.order.orderId');
			const releasedOrderObjectId = _.get(message, 'data.order.id');
			this.SET_RELEASING_ORDERS(_.filter(this.releasingOrders, ({ id }) => id !== releasedOrderObjectId));
			/*
					Prevent showing more than 1 pre-order release notification.
					It cleans all notifications before showing new one, as ofs-oneflow-layout does not support notification groups
			*/
			this.$notify({ clean: true });
			this.$notify({
				type: 'success',
				title: 'Success',
				text: `Order #${releasedOrderId} has been released`
			});
		},
		orderReleaseFailedHandler(message) {
			const text = _.get(message, 'data.error.message', this.$t('Error while releasing an order'));
			const failedToReleaseOrderObjectId = _.get(message, 'data.details.id');
			const failedToReleaseOrderId = _.get(message, 'data.details.orderId');
			this.SET_RELEASING_ORDERS(_.filter(this.releasingOrders, ({ id }) => id !== failedToReleaseOrderObjectId));
			this.SET_FAILED_TO_RELEASE_ORDERS(
				_.uniqBy(
					[...this.failedToReleaseOrders, { id: failedToReleaseOrderObjectId, orderId: failedToReleaseOrderId }],
					'id'
				)
			);
			/*
				Prevent showing more than 1 pre-order release notification.
				It cleans all notifications before showing new one, as ofs-oneflow-layout does not support notification groups
			*/
			this.$notify({ clean: true });
			this.$notify({
				type: 'error',
				title: this.$t('Error'),
				text
			});
		},
		initializePusherSubscribers() {
			try {
				const existingChannels = this.pusherClient.getChannels();
				const existingOrderReleaseChannel = _.find(existingChannels, ['name', this.ordersReleaseChannelName]);
				if (existingOrderReleaseChannel) return;
				this.pusherClient.subscribe(this.ordersReleaseChannelName, EventTypes.OrderReleased, this.orderReleasedHandler);
				this.pusherClient.subscribe(
					this.ordersReleaseChannelName,
					EventTypes.OrderReleaseFailed,
					this.orderReleaseFailedHandler
				);
			} catch (error) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('Error while initializing order release pusher subscribers')
				});
			}
		}
	}
};
