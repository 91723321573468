<template>
	<b-row v-if="isViewMode" class="row">
		<b-col md="12" mb="3" class="DetailsField">
			<div class="DetailsField-label">{{ $t('Name') }}</div>
			<div v-b-tooltip.hover.bottom :title="_get(order, 'shipping.name')" class="DetailsField-value">
				{{ _get(order, 'shipping.name') }}
			</div>
		</b-col>
		<b-col md="12" mb="3" class="DetailsField">
			<div class="DetailsField-label">{{ $t('Email') }}</div>
			<div v-b-tooltip.hover.bottom :title="_get(order, 'shipping.email')" class="DetailsField-value">
				{{ _get(order, 'shipping.email') }}
			</div>
		</b-col>
		<b-col md="12" class="DetailsField">
			<div class="DetailsField-label">{{ $t('Phone') }}</div>
			<div v-b-tooltip.hover.bottom :title="_get(order, 'shipping.phone', '-')" class="DetailsField-value">
				{{ _get(order, 'shipping.phone', '-') }}
			</div>
		</b-col>

		<hr class="order-details__section-divider" />
		<b-col md="12" class="DetailsField">
			<div class="DetailsField-label">{{ $t('Shipping Method') }}</div>
			<div v-b-tooltip.hover.bottom :title="orderShipmentMethod" class="DetailsField-value">
				{{ orderShipmentMethod }}
			</div>
		</b-col>
		<b-col md="12" class="DetailsField">
			<div class="DetailsField-label">{{ $t('Price') }}</div>
			<div class="DetailsField-value">{{ formatCents(order.shippingPrice, order.isoCurrency, '-') }}</div>
		</b-col>

		<b-col md="12" class="DetailsField">
			<div class="DetailsField-label">{{ $t('Tax') }}</div>
			<div class="DetailsField-value">{{ formatCents(order.shippingTaxAmount, order.isoCurrency, '-') }}</div>
		</b-col>

		<b-col md="12" class="DetailsField">
			<div class="DetailsField-label">{{ $t('Net Price') }}</div>
			<div class="DetailsField-value">{{ formatCents(order.shippingNetPrice, order.isoCurrency, '-') }}</div>
		</b-col>

		<hr class="order-details__section-divider" />
		<b-col md="12" class="DetailsField">
			<div class="DetailsField-label">{{ $t('Address') }}</div>
			<div v-b-tooltip.hover.bottom :title="_get(order, 'shipping.address1', '-')" class="DetailsField-value">
				{{ _get(order, 'shipping.address1', '-') }}
			</div>
		</b-col>
		<b-col md="12" class="DetailsField">
			<div class="DetailsField-label">{{ `${$t('Address')} 2` }}</div>
			<div v-b-tooltip.hover.bottom :title="_get(order, 'shipping.address2', '-')" class="DetailsField-value">
				{{ _get(order, 'shipping.address2', '-') }}
			</div>
		</b-col>
		<b-col md="12" class="DetailsField">
			<div class="DetailsField-label">{{ `${$t('Address')} 3` }}</div>
			<div v-b-tooltip.hover.bottom :title="_get(order, 'shipping.address3', '-')" class="DetailsField-value">
				{{ _get(order, 'shipping.address3', '-') }}
			</div>
		</b-col>
		<b-col md="12" class="DetailsField">
			<div class="DetailsField-label">{{ $t('Postcode') }}</div>
			<div class="DetailsField-value">{{ _get(order, 'shipping.postcode') }}</div>
		</b-col>
		<b-col md="12" class="DetailsField">
			<div class="DetailsField-label">{{ $t('State') }}</div>
			<div class="DetailsField-value">{{ _get(order, 'shipping.state', '-') }}</div>
		</b-col>
		<b-col md="12" class="DetailsField">
			<div class="DetailsField-label">{{ $t('City') }}</div>
			<div v-b-tooltip.hover.bottom :title="_get(order, 'shipping.city', '-')" class="DetailsField-value">
				{{ _get(order, 'shipping.city', '-') }}
			</div>
		</b-col>
		<b-col md="12" class="DetailsField">
			<div class="DetailsField-label">{{ $t('Country') }}</div>
			<div class="DetailsField-value">{{ _get(order, 'shipping.country', '-') }}</div>
		</b-col>
		<b-col md="12" class="DetailsField">
			<div class="DetailsField-label">{{ $t('ISO Country') }}</div>
			<div class="DetailsField-value">{{ _get(order, 'shipping.isoCountry') }}</div>
		</b-col>
	</b-row>
	<b-row v-else>
		<b-row>
			<b-col md="12">
				<OfFormInput name="shipping.name" :label="$t('Name')" show-errors required />
			</b-col>
			<b-col md="12">
				<OfFormInput name="shipping.email" :label="$t('Email')" show-errors required />
			</b-col>
			<b-col md="12">
				<OfFormInput name="shipping.phone" :label="$t('Phone')" show-errors required />
			</b-col>
			<hr class="order-details__section-divider" />
			<b-col md="12">
				<OfFormInput name="shipping.address1" :label="$t('Address')" show-errors required />
			</b-col>
			<b-col md="12">
				<OfFormInput name="shipping.address2" :label="`${$t('Address')} 2`" show-errors />
			</b-col>
			<b-col md="12">
				<OfFormInput name="shipping.address3" :label="`${$t('Address')} 3`" show-errors />
			</b-col>
			<b-col md="12">
				<OfFormInput name="shipping.postcode" :label="$t('Postcode')" show-errors required />
			</b-col>
			<b-col md="12">
				<OfFormInput name="shipping.state" :label="$t('State')" show-errors />
			</b-col>
			<b-col md="12">
				<OfFormInput name="shipping.city" :label="$t('City')" show-errors required />
			</b-col>
			<b-col md="12">
				<OfFormSelect
					name="shipping.country"
					:label="$t('Country')"
					:options="countryOptions"
					:disabled="shippingCountrySelectorDisabled"
					required
				/>
			</b-col>
			<b-col md="12">
				<OfFormInput name="shipping.isoCountry" :label="$t('ISO Country')" disabled required />
			</b-col>
			<hr class="order-details__section-divider" />
			<b-col md="12">
				<OfFormSelect name="shippingMethodId" :label="$t('Shipping method')" :options="shippingMethodOptions" />
			</b-col>
			<b-col md="12">
				<OfFormInput :value="orderShipmentPrice" :label="$t('Shipping price')" disabled />
			</b-col>
		</b-row>
	</b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfFormInput, OfFormSelect, withForm } from '@oneflow/ofs-vue-layout';
import countries from '@/lib/country/countries';
import formatCents from '@/lib/formatCents';
import { orderStatus, orderDetailsWorkingModes } from '../../lib/constants';

const countriesByName = _.invert(countries);

export default {
	components: {
		OfFormInput,
		OfFormSelect
	},
	mixins: [withForm('EditShipmentForm')],
	props: {
		workingMode: {
			type: String,
			default: orderDetailsWorkingModes.View
		}
	},
	computed: {
		...mapGetters({
			order: 'order/order',
			shippingMethods: 'shipping-method/shipping-methods'
		}),
		shippingMethodOptions() {
			return _.map(this.shippingMethods, method => ({ value: method._id, text: method.name }));
		},
		countryOptions() {
			return _.map(countries, value => ({ value, text: value }));
		},
		orderShipmentMethod() {
			return _.get(
				_.find(this.shippingMethodOptions, method => method.value === this.order.shippingMethodId),
				'text',
				''
			);
		},
		orderShipmentPrice() {
			if (!this.order) return;
			return formatCents(this.order?.shippingPrice, this.order.isoCurrency) || 0;
		},
		shippingCountrySelectorDisabled() {
			if (this.isResubmitMode) return false;
			return this.order.status === orderStatus.Live;
		},
		isViewMode() {
			return this.workingMode === orderDetailsWorkingModes.View;
		},
		isResubmitMode() {
			return this.workingMode === orderDetailsWorkingModes.Resubmit;
		}
	},
	watch: {
		workingMode: {
			immediate: true,
			async handler(workingMode, oldWorkingMode) {
				if (workingMode === orderDetailsWorkingModes.View || workingMode === oldWorkingMode) return;
				await this.initialize();
			}
		},
		'formData.shipping.country'(country) {
			this.updateField('shipping.isoCountry', countriesByName[country]);
		},
		formData(newValue) {
			if (this.isViewMode) {
				return;
			}

			this.$emit('change', _.cloneDeep(newValue));
		}
	},
	async mounted() {
		await this.fetchShippingMethods();
	},
	methods: {
		...mapActions({
			findAllShippingMethods: 'shipping-method/findAll'
		}),
		_get: _.get,
		async initialize() {
			const { shippingMethodId, shipping, shippingPrice } = _.cloneDeep(this.order);
			// Convert to country name if set as ISO country
			if (!countriesByName[shipping.country] && countries[shipping.country]) {
				shipping.country = countries[shipping.country];
			}
			this.initFormData({ shipping, shippingMethodId, shippingPrice });
		},
		async fetchShippingMethods() {
			const query = { $select: ['name', 'shippingRates'] };
			return this.findAllShippingMethods({ query: { query } });
		},
		formatCents
	}
};
</script>

<style lang="scss" scoped>
.DetailsField {
	display: flex;
	justify-content: space-between;

	&-label {
		max-width: 50%;
	}

	&-value {
		max-width: 70%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		text-align: right;
	}
}
</style>
