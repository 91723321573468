<template>
	<OfsPanel>
		<ListTable
			bordered
			hover
			:table-title="$t('Payout codes')"
			:config="config"
			:items="payoutCodes.data"
			:fields="fields"
			:sort="sort"
			:total-items="payoutCodes.total"
			:current-page="currentPage"
			:per-page="perPage"
			:fetch-data="fetchData"
			:is-busy="isLoading"
			@table-change="handleTableChange"
			@row-clicked="handleRowClick"
		>
			<template #cell(payee)="{ item }">
				<template v-if="item.payee">
					{{ _get(item, 'payee.username', item.payee._id) }}
				</template>
			</template>
			<template #cell(actions)="{ item }">
				<b-dropdown size="sm">
					<b-dropdown-item @click="removePayoutCode(item._id)">{{ $t('Delete') }}</b-dropdown-item>
				</b-dropdown>
			</template>
			<template slot="TableButtons-Slot-right" slot-scope="{}">
				<b-button variant="primary" class="ml-2" @click="handlePayoutCodeAdd()">
					{{ $t('Add Payout Code') }}
				</b-button>
			</template>
			<template slot="TableButtons-Slot-left" slot-scope="{}">
				<OfFormInput
					:value="searchString"
					class="col-6 mr-2"
					:placeholder="$t('Search by code')"
					@input="handleSearchStringInput"
				/>
				<OfInlineFilter :filters="filters" :values="filterValues" @change="filtersChanged" />
			</template>
			<template slot="TableHeader" slot-scope="{}">
				<OfFilterBar :filters="filters" :values="filterValues" @change="filtersChanged" />
			</template>
		</ListTable>
	</OfsPanel>
</template>

<script>
import _get from 'lodash/get';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import { mapActions, mapGetters } from 'vuex';
import { OfsPanel, ListTable, OfFormInput, OfInlineFilter, OfFilterBar } from '@oneflow/ofs-vue-layout';
import withQuerystringState from '@/mixins/withQuerystringState';
import { parseDefaultQuerystringFilterValues } from '@/lib/parseDefaultQuerystringFilterValues';

const config = {
	refresh: { visible: true }
};

export default {
	components: {
		OfFilterBar,
		OfInlineFilter,
		OfsPanel,
		ListTable,
		OfFormInput
	},
	mixins: [
		withQuerystringState([
			{ name: 'currentPage', defaultValue: 1, parseValue: Number },
			{ name: 'perPage', defaultValue: 10, parseValue: Number },
			'searchString',
			{ name: 'sort', defaultValue: {}, parseValue: value => value ?? {} },
			{ name: 'filterValues', parseValue: parseDefaultQuerystringFilterValues }
		])
	],
	data() {
		const fields = [
			{ key: 'code', label: this.$t('Code'), sortable: true },
			{ key: 'source', label: this.$t('Source'), sortable: true },
			{ key: 'description', label: this.$t('Description') },
			{ key: 'payee', label: this.$t('Payee') },
			{ key: 'account.displayName', label: this.$t('Account'), sortable: false },
			{ key: 'actions', label: '' }
		];

		return {
			isLoading: false,
			fields,
			config
		};
	},
	computed: {
		...mapGetters({
			payoutCodes: 'payoutCode/payoutCodes',
			accounts: 'account/accounts'
		}),
		filters() {
			return [
				{
					header: this.$t('Accounts'),
					key: 'accountId',
					type: 'checkbox',
					items: _.map(this.accounts?.data ?? [], ({ authAccountId, displayName }) => ({
						value: authAccountId,
						title: displayName
					}))
				}
			];
		}
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			getPayoutCodes: 'payoutCode/find',
			deletePayoutCodeById: 'payoutCode/deleteById',
			fetchAccounts: 'account/find'
		}),
		_get,
		async fetchData() {
			try {
				this.isLoading = true;
				const query = {
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1),
					$populate: [{ path: 'payee', $select: ['username'] }, { path: 'account' }]
				};
				if (Object.keys(this.sort).length > 0) {
					query.$sort = this.sort;
				}
				if (this.searchString) {
					query.$where = { code: { $regex: this.searchString, $options: 'i' } };
				}
				if (!_.isEmpty(this.filterValues)) {
					const excludedWhereKeys = ['product'];
					query.$where = {
						...query.$where,
						..._.reduce(
							this.filterValues,
							(filterQuery, $in, key) => {
								if (excludedWhereKeys.includes(key)) return filterQuery;
								filterQuery[key] = { $in };
								return filterQuery;
							},
							{}
						)
					};
				}
				await this.getPayoutCodes({ query: { query } });
				await this.fetchAccounts({ query: { query: { $limit: 1000, $sort: { displayName: 1 } } } });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching payout codes')
				});

				throw err;
			} finally {
				this.isLoading = false;
			}
		},
		handleTableChange({ currentPage, perPage, sort }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			if (sort) {
				this.sort = sort;
			}
		},
		async confirmPayoutCodeRemove() {
			return this.$bvModal.msgBoxConfirm([this.$t('Are you sure to delete this payout code?')], {
				title: this.$t('Warning!'),
				okVariant: 'danger',
				size: 'md',
				centered: true
			});
		},
		async removePayoutCode(productId) {
			const approved = await this.confirmPayoutCodeRemove();
			if (!approved) return;

			try {
				await this.deletePayoutCodeById({ id: productId });
			} catch (e) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: e.response.data.message
				});
			}
			await this.fetchData();
		},
		handleRowClick(item, index, event) {
			if (event.target.type === 'button') return;
			this.$router.push({ name: 'payout-codes.edit', params: { id: item._id } });
		},
		handlePayoutCodeAdd() {
			this.$router.push({ name: 'payout-codes.edit', params: { id: 'new' } });
		},
		handleSearchStringInput: debounce(function(value) {
			this.searchString = value;
			this.currentPage = 1;
			this.fetchData();
		}, 800),
		filtersChanged(filters) {
			this.filterValues = filters;
			this.currentPage = 1;
			this.fetchData();
		}
	}
};
</script>
