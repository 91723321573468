<template>
	<div>
		<b-modal
			size="lg"
			:ok-disabled="isInvalid"
			:ok-title="$t('Save')"
			:title="recommendationRuleId ? $t('Edit promotion') : $t('Create promotion')"
			:visible="true"
			@hidden="$emit('close')"
			@ok="onSave"
		>
			<b-row>
				<b-col md="9">
					<OfFormInput name="name" :label="$t('Name')" show-errors />
				</b-col>
				<b-col md="2">
					<OfFormInput name="priority" :label="$t('Priority')" type="number" show-errors />
				</b-col>
				<b-col md="1">
					<OfToggle name="active" :label="$t('Active')" />
				</b-col>
			</b-row>
			<b-card
				v-for="(rule, index) in _get(formData, 'rules', [])"
				:key="rule.id"
				:header="$t('Rule #{index}', { index: index + 1 })"
			>
				<OfMultiSelect
					:name="`rules[${index}].output.productIds`"
					:label="$t('Products')"
					:options="productOptions"
					track-by="id"
					label-by="name"
					multiple
					required
					@search-change="onProductSearch"
				/>

				<h3>{{ $t('Condition') }}</h3>
				<CodeEditor
					:value="formData.rules[index].condition"
					@input="updateField(`rules[${index}].condition`, $event)"
				/>
			</b-card>
		</b-modal>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfFormInput, OfToggle, OfMultiSelect, withForm, validateWithMessage } from '@oneflow/ofs-vue-layout';
import { required, minLength, numeric } from 'vuelidate/lib/validators';
import { json } from '@/lib/validators';
import CodeEditor from '@/components/CodeEditor';
import { productStatus, productAvailability } from '../../Create/Products/constants';

const recommendationRuleDefault = {
	active: true,
	rules: [
		{
			condition: { if: [{ and: [true] }] },
			output: { productIds: [] }
		}
	]
};

export default {
	components: {
		OfFormInput,
		OfToggle,
		OfMultiSelect,
		CodeEditor
	},
	mixins: [withForm('recommendationRuleForm')],
	props: {
		recommendationRuleId: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			selectedProducts: [],
			searchProducts: []
		};
	},
	computed: {
		...mapGetters({
			recommendationRule: 'recommendation-rule/recommendation-rule'
		}),
		validationRules() {
			return {
				formData: {
					name: {
						required: validateWithMessage(this.$t('Name is required'), required),
						minLength: validateWithMessage(this.$t('Must have at least 5 characters'), minLength(5))
					},
					priority: {
						required: validateWithMessage(this.$t('Priority is required'), required),
						number: validateWithMessage(this.$t('Must be numeric'), numeric)
					},
					active: {
						required
					},
					rules: {
						$each: {
							condition: {
								json,
								required: validateWithMessage(this.$t('Rule condition is required'), required)
							}
						}
					}
				}
			};
		},
		productOptions() {
			return [...this.selectedProducts, ...this.searchProducts];
		}
	},
	mounted() {
		this.initialize();
	},
	destroyed() {
		this.resetFormData();
	},
	methods: {
		...mapActions({
			findRecommendationRuleById: 'recommendation-rule/findById',
			createRecommendationRule: 'recommendation-rule/create',
			updateRecommendationRule: 'recommendation-rule/update',
			findProducts: 'product/find'
		}),
		_get: _.get,
		async initialize() {
			if (!this.recommendationRuleId) {
				return this.initFormData(this.preProcessFormData(recommendationRuleDefault));
			}

			try {
				const recommendationRule = await this.findRecommendationRuleById({ id: this.recommendationRuleId });
				this.initFormData(this.preProcessFormData(recommendationRule));
			} catch (error) {
				his.$notify({ type: 'error', title: this.$t('Failed to fetch recommendation rule'), text: error.message });
			}

			await this.fetchSelectedProducts();
		},
		async fetchSelectedProducts() {
			const selectedProductIds = _.flatMap(this.recommendationRule?.rules, 'output.productIds');
			const query = { $where: { _id: { $in: selectedProductIds } }, $select: ['name'] };

			try {
				const { data } = await this.findProducts({ query: { query } });
				this.selectedProducts = data;
			} catch (error) {
				this.$notify({ type: 'error', title: this.$t('Failed to fetch products'), text: error.message });
			}
		},
		onProductSearch: _.debounce(async function(search) {
			const searchString = search?.name;
			const selectedProductIds = _.map(this.selectedProducts, 'id');

			const query = {
				$where: {
					_id: { $nin: selectedProductIds },
					status: productStatus.Published,
					availability: { $in: [productAvailability.PreOrder, productAvailability.Available] },
					...(searchString ? { name: { $regex: searchString, $options: 'i' } } : {})
				},
				$select: ['name']
			};

			const { data: products } = await this.findProducts({ query: { query }, options: { skipMutations: true } });
			this.searchProducts = products;
		}, 800),
		async onSave(event) {
			event?.preventDefault();
			try {
				const data = this.postProcessFormData(this.formData);

				if (data._id) {
					await this.updateRecommendationRule({ id: data._id, data });
				} else {
					await this.createRecommendationRule(data);
				}

				this.$notify({ type: 'success', title: this.$t('Recommendation rule saved') });
				this.$emit('ok');
			} catch (error) {
				this.$notify({ type: 'error', title: this.$t('Recommendation rule saving error'), text: error.message });
			}
		},
		preProcessFormData(formData) {
			return _.cloneDeepWith(formData, value => {
				// stringify condition rules to display in the code editor
				if (_.isPlainObject(value) && value.condition) {
					return { ...value, condition: JSON.stringify(value.condition, null, 2) };
				}
			});
		},
		postProcessFormData(formData) {
			return _.cloneDeepWith(formData, value => {
				// parse condition rules string to plain json object
				if (_.isPlainObject(value) && value.condition) {
					return { ...value, condition: JSON.parse(value.condition) };
				}
			});
		}
	}
};
</script>
