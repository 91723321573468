<template>
	<OfsPanel class="TemplateEdit">
		<div class="row TemplateEdit-header">
			<div class="col-md-8">
				<div class="back-btn" @click="goBack">
					<icon name="arrow-left" />
					<span>{{ $t('Back to templates') }}</span>
				</div>
			</div>
		</div>
		<div v-if="templateData">
			<template v-if="isHTMLTemplate">
				<HTMLTemplateEditor />
			</template>
			<template v-else>
				<JSONTemplateEditor :data="templateData" @save="updateJSONTemplate" @create="createTemplate" />
			</template>
		</div>
	</OfsPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfsPanel, withForm, OfToggle, OfFormInput, OfFormSelect } from '@oneflow/ofs-vue-layout';
import HTMLTemplateEditor from './HTMLTemplateEditor';
import JSONTemplateEditor from '@/components/editors/JSONTemplateEditor';
import { templateFormats } from '../../../lib/constants';
import { templateDefaults } from './HTMLTemplateEditor/constants';
import coverTemplateSource from 'raw-loader!./HTMLTemplateEditor/templates/cover.hbs';

export default {
	components: {
		OfsPanel,
		HTMLTemplateEditor,
		JSONTemplateEditor
	},
	data() {
		return {
			templateData: null,
			isSeparatedLayout: false
		};
	},
	computed: {
		...mapGetters({
			template: 'template/template',
			layout: 'layout/layout'
		}),
		isHTMLTemplate() {
			if (!this.templateData) return;
			return this.templateData.format === templateFormats.HTML;
		}
	},
	watch: {
		'$route.params.id': async function() {
			await this.getTemplateData();
		}
	},
	async mounted() {
		await this.getTemplateData();
	},
	methods: {
		...mapActions({
			findTemplateById: 'template/findById',
			updateTemplateById: 'template/update',
			templateCreate: 'template/create',
			findLayoutById: 'layout/findById',
			getBlocksForPreview: 'layout/getBlocksForPreview'
		}),
		async getTemplateData() {
			if (this.$route.params.id !== 'new') {
				this.templateData = await this.findTemplateById({ id: this.$route.params.id });
			} else if (!this.$route.query?.id) {
				const type = { type: 'product' };
				this.templateData = {
					...templateDefaults,
					source: coverTemplateSource,
					...type,
					format: templateFormats.HTML,
					active: true
				};
			} else {
				this.templateData = await this.getInitialJsonTemplateData();
			}
		},
		async getInitialJsonTemplateData() {
			const { id } = this.$route.query;
			const layout = await this.findLayoutById({ id });
			this.isSeparatedLayout = _.every(_.get(layout, 'template.blocks', []), b => typeof b === 'string');

			if (this.isSeparatedLayout) {
				const blocksWithDependencies = await this.getBlocksForPreview(id);
				return {
					..._.pick(layout, ['type', 'format', 'active']),
					template: {
						..._.get(layout, 'template', {}),
						...blocksWithDependencies
					}
				};
			}
			return _.pick(layout, ['type', 'format', 'template', 'active']);
		},
		goBack() {
			this.$router.push({ name: 'templates.list' });
		},
		async updateJSONTemplate(data) {
			try {
				const dataToUpdate = _.omit(data, ['template']);
				const { template } = data;
				const { id } = this.$route.params;
				await this.updateTemplateById({
					id,
					data: {
						...dataToUpdate,
						source: {
							...template
						}
					}
				});
				this.$notify({
					type: 'success',
					title: 'Success',
					text: this.$t('Template has been updated')
				});
			} catch (e) {
				this.$notify({
					type: 'error',
					title: 'Error',
					text: `${this.$t('Failed to update template')}: ${e}`
				});
			}
		},
		async createTemplate(data) {
			try {
				const dataToUpdate = _.omit(data, ['template']);
				const { template } = data;
				const parentLayoutId = this.$route.query.id;
				const parentLayoutInfo = this.isSeparatedLayout
					? {
						contentLayoutId: parentLayoutId, //eslint-disable-line
						coverLayoutId: parentLayoutId //eslint-disable-line
					  }
					: { parentLayoutId };
				const createdTemplate = await this.templateCreate({
					...dataToUpdate,
					source: {
						...template
					},
					...parentLayoutInfo
				});
				this.$notify({
					type: 'success',
					title: 'Success',
					text: this.$t('Template has been created')
				});
				this.$router.push({
					name: 'templates.edit',
					params: { id: createdTemplate._id }
				});
			} catch (e) {
				this.$notify({
					type: 'error',
					title: 'Error',
					text: `${this.$t('Failed to create template')}: ${e}`
				});
			}
		}
	}
};
</script>

<style lang="scss">
@import 'src/styles/shared';

.TemplateEdit {
	margin: 0;

	&-header {
		.back-btn {
			cursor: pointer;
			display: flex;
			align-items: center;
			font-weight: bold;

			.fa-icon {
				margin-right: 7px;
			}

			&:hover {
				color: $of-color-grey-2;
			}
		}
	}

	&-bottom {
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;
	}

	.cursor-pointer {
		cursor: pointer;
	}
}
</style>
