import _fp from 'lodash/fp';

const MM_TO_PX = 3.7795275591;

export const mmToPx = value => Math.round(value * MM_TO_PX);

export const pxToMm = value => Math.round(value / MM_TO_PX);

export const unitConverter = {
	mm: pxToMm,
	px: mmToPx
};

export const convertSizes = (sizes, units) => {
	const convert = fn => value => (['number', 'string'].includes(typeof value) ? fn(value) : undefined);
	return _.cloneDeepWith(sizes, convert(unitConverter[units]));
};

export const isObjectId = value => /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i.test(value);
