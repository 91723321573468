import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'creator',
		pluralName: 'creators',
		transport: 'davinci',
		options: {
			namespaced: true
		},
		actions: {
			async getPlatforms({ dispatch, commit }, params) {
				const platforms = await dispatch('request', {
					method: 'GET',
					path: `creators/platforms`,
					params
				}).then(r => r.data.map(platform => ({ ...platform, platformId: platform._id })));
				commit('SET_PLATFORMS', platforms);
				return platforms;
			}
		},
		mutations: {
			SET_PLATFORMS: (state, data) => {
				state.platforms = data;
			}
		}
	});
}
