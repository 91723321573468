import PusherClient from '../lib/PusherClient';

export default {
	data() {
		return {
			pusherClient: null
		};
	},
	mounted() {
		this.pusherClient = new PusherClient();
	}
};
