import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'layout',
		pluralName: 'layouts',
		transport: 'davinci',
		options: {
			namespaced: true
		},
		actions: {
			async getBlocksForPreview({ dispatch }, layoutId) {
				const payload = {
					path: `layouts/${layoutId}/preview-blocks`,
					method: 'GET'
				};
				const response = await dispatch('request', payload);
				return response.data;
			}
		}
	});
}
