import { $t } from '@/vuex';

export const photoRoles = {
	Main: 'Main',
	Spotlight: 'Spotlight',
	Discover: 'Discover'
};

export const photoRolesOptions = [
	{ text: $t('Main'), value: photoRoles.Main },
	{ text: $t('Spotlight'), value: photoRoles.Spotlight },
	{ text: $t('Discover'), value: photoRoles.Discover }
];
