<template>
	<b-modal :title="$t('Create new release')" size="md" :visible="isOpen" @hide="$emit('hide')">
		<div class="mb-3">
			<b> {{ $t('Delivery type') }}: </b>
			<span class="text-capitalize">{{ delivery }}</span>
		</div>

		<b-card v-for="componentCode in releaseComponentCodes" :key="componentCode" class="mb-3" no-body>
			<div class="release-modal_card-title d-flex align-items-center mb-2 py-2 px-3">
				<b-btn
					v-if="selectedReleaseMethods[componentCode]"
					class="mr-2"
					size="sm"
					@click="handleReleaseMethodSelect(componentCode, null)"
				>
					<icon name="chevron-left" scale=".7" />
				</b-btn>

				<div>
					<b>{{ $t('Component') }}:</b>&nbsp;{{ componentCode }}
				</div>
			</div>

			<b-card-body class="pt-0">
				<b-list-group v-if="!selectedReleaseMethods[componentCode]" flush horizontal>
					<b-list-group-item button @click="handleReleaseMethodSelect(componentCode, 'useComponentConfiguration')">
						<b-row>
							<b-col class="flex-grow-1">
								{{ $t('Use component configuration') }}
							</b-col>
							<b-col class="flex-grow-0">
								<icon name="chevron-right" />
							</b-col>
						</b-row>
					</b-list-group-item>
					<b-list-group-item button @click="handleReleaseMethodSelect(componentCode, 'uploadFiles')">
						<b-row>
							<b-col class="flex-grow-1">
								{{ $t('Upload or select file') }}
							</b-col>
							<b-col class="flex-grow-0">
								<icon name="chevron-right" />
							</b-col>
						</b-row>
					</b-list-group-item>
				</b-list-group>

				<UploadFile
					v-if="selectedReleaseMethods[componentCode] === 'uploadFiles'"
					:product="product"
					:component-code="componentCode"
					:delivery="delivery"
					:releases="releases"
					@file-selected="({ file, pageCount }) => handleFileSelection({ componentCode, file, pageCount })"
				/>

				<RenderFromTemplate
					v-if="selectedReleaseMethods[componentCode] === 'renderFromTemplate'"
					:product="product"
					:component-code="componentCode"
					:delivery="delivery"
					:releases="releases"
					@file-selected="({ file, pageCount }) => handleFileSelection({ componentCode, file, pageCount })"
				/>
			</b-card-body>
		</b-card>

		<b-form-group :label="$t('Note')">
			<b-textarea v-model="release.note"></b-textarea>
		</b-form-group>

		<template #modal-footer>
			<div class="d-flex justify-content-between w-100">
				<b-btn @click="$emit('hide')">Cancel</b-btn>

				<b-btn variant="primary" :disabled="!canSubmit || isSubmitting" @click="handleSubmit">
					<template v-if="isSubmitting">{{ $t('Creating the release...') }}</template>
					<template v-else>{{ $t('Create Release') }}</template>
				</b-btn>
			</div>
		</template>
	</b-modal>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import formatDate from '@/lib/formatDate';
import UploadFile from '@/containers/Create/Products/releases/release-methods/UploadFile.vue';
import RenderFromTemplate from '@/containers/Create/Products/releases/release-methods/RenderFromTemplate.vue';
import { productComponentsHash } from '@/containers/Create/Products/computed';

export default {
	components: { RenderFromTemplate, UploadFile },
	props: {
		isOpen: { type: Boolean },
		product: { type: Object },
		delivery: { type: String },
		releases: { type: Array }
	},
	data() {
		return {
			selectedReleaseMethods: {},
			release: {},
			isSubmitting: false
		};
	},
	computed: {
		productComponentsHash,
		releaseComponentCodes() {
			return this.delivery === 'digital' ? ['ITM'] : this.product.components?.map(c => c.componentCode) ?? [];
		},
		canSubmit() {
			return this.releaseComponentCodes.every(componentCode =>
				this.release.files?.find(
					file => file.componentCode === componentCode && file.fileId && file.pageCount && !isNaN(file.pageCount)
				)
			);
		}
	},
	watch: {
		isOpen: {
			immediate: true,
			handler() {
				if (this.isOpen) {
					this.release = {
						productId: this.product._id,
						accountId: this.product.accountId,
						delivery: this.delivery,
						files: this.releaseComponentCodes.map(componentCode => ({ componentCode }))
					};
				} else {
					this.selectedReleaseMethods = {};
					this.release = {};
				}
			}
		}
	},
	methods: {
		...mapActions({ createRelease: 'product-release/create' }),
		get: _.get,
		formatDate,
		async handleReleaseMethodSelect(componentCode, releaseMethod) {
			if (releaseMethod === 'useComponentConfiguration') {
				this.selectedReleaseMethods = {
					...this.selectedReleaseMethods,
					[componentCode]: 'renderFromTemplate'
				};
			} else {
				this.selectedReleaseMethods = { ...this.selectedReleaseMethods, [componentCode]: releaseMethod };
			}

			this.release.files = this.release.files.map(file => {
				if (file.componentCode === componentCode) {
					return { ...file, fileId: null };
				}

				return file;
			});
		},
		handleFileSelection({ componentCode, file, pageCount }) {
			this.release.files = this.release.files.map(releaseFile => {
				if (releaseFile.componentCode === componentCode) {
					return { ...releaseFile, fileId: file?._id, pageCount };
				}

				return releaseFile;
			});
		},
		async handleSubmit() {
			this.isSubmitting = true;
			try {
				await this.createRelease(this.release);

				this.$notify({ type: 'success', text: this.$t('Release created successfully') });
				this.$emit('hide');
			} catch (err) {
				this.$notify({ type: 'error', title: 'An error happened while creating the release', text: err.message });
			} finally {
				this.isSubmitting = false;
			}
		}
	}
};
</script>

<style lang="scss">
.release-modal_card-title {
	background: #f8f9fa !important;
}
</style>
