import { mmToPx } from './helpers';

export const templateDefaults = {
	active: true,
	sizes: {
		media: {
			width: mmToPx(210 * 2 + 3 * 2), // 426mm
			height: mmToPx(210 + 3 * 2) // 216mm
		},
		bleed: {
			top: mmToPx(0),
			right: mmToPx(0),
			bottom: mmToPx(0),
			left: mmToPx(0)
		},
		trim: {
			top: mmToPx(3),
			right: mmToPx(3),
			bottom: mmToPx(3),
			left: mmToPx(3)
		}
	}
};

export const templateData = {
	...templateDefaults.sizes,
	spineSize: 0,
	variables: {},
	collections: {}
};
