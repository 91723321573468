<template>
	<OfsPanel class="dataTypeEdit">
		<ContentHeader
			:title="dataTypeId ? `${$t('Data Type')} &quot;${formData.name}&quot;` : $t('New Data Type')"
			class="mb-3"
			no-padding
		>
			<div class="d-flex">
				<OfToggle name="active" :right-side-label="$t('Active')" class="mb-0" required />
			</div>
		</ContentHeader>
		<div>
			<b-row>
				<b-col md="3">
					<OfFormInput name="name" :label="$t('Name')" required show-errors />
				</b-col>
				<b-col md="2"> </b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-card no-body>
						<CodeEditor :value="formData.schema" @input="updateSchema" />
					</b-card>
				</b-col>
			</b-row>
		</div>
		<template slot="actions">
			<b-button variant="primary" :disabled="!canSubmit" @click="onSave">
				{{ $t('Save') }}
			</b-button>
		</template>
	</OfsPanel>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { OfFormInput, OfsPanel, OfToggle, withForm, ContentHeader } from '@oneflow/ofs-vue-layout';
import { json } from '@/lib/validators';
import CodeEditor from '../../../components/CodeEditor';
import { $t } from '../../../vuex';

const initialData = {
	schema: '{}',
	name: '',
	active: true
};

export default {
	components: {
		OfFormInput,
		OfsPanel,
		OfToggle,
		CodeEditor,
		ContentHeader
	},
	mixins: [withForm('dataTypeEdit')],
	computed: {
		validationRules() {
			return {
				formData: {
					schema: { required, json }
				}
			};
		},
		dataTypeId() {
			const { id } = this.$route.params;
			return id !== 'new' ? id : null;
		}
	},
	watch: {
		async dataTypeId(dataTypeId, oldDataTypeId) {
			if (dataTypeId === oldDataTypeId) return;
			await this.initData();
		}
	},
	mounted() {
		this.initData();
	},
	methods: {
		...mapActions({
			findDataTypeById: 'data-type/findById',
			updateDataTypeById: 'data-type/update',
			createDataType: 'data-type/create'
		}),
		toJSONString(obj) {
			return JSON.stringify(obj, null, 2);
		},
		async initData() {
			if (!this.dataTypeId) {
				return this.initFormData(initialData);
			}

			try {
				const dataType = await this.findDataTypeById({ id: this.dataTypeId });
				const schema = JSON.stringify(dataType.schema, null, 2);
				this.initFormData({ ...dataType, schema });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching data type')
				});
			}
		},
		updateSchema(data) {
			this.updateField('schema', data);
		},
		async onSave() {
			try {
				let message = $t('Data type has been updated');

				// convert string schema to the JSON object
				const schema = JSON.parse(this.formData.schema);
				const data = { ...this.formData, schema };

				if (this.dataTypeId) {
					await this.dispatchSubmit(this.updateDataTypeById({ id: this.formData._id, data }));
				} else {
					message = $t('Data type has been created');
					const response = await this.dispatchSubmit(this.createDataType(data));
					this.$router.push({
						name: 'dataTypes.edit',
						params: { id: response._id }
					});
				}
				this.$notify({ type: 'success', text: message });
			} catch (err) {
				this.$notify({ type: 'error', text: err.message });
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.dataTypeEdit {
	.vue-codemirror {
		height: 800px !important;
	}
}
</style>
