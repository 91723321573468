<template>
	<OfsPanel class="TemplateEdit">
		<div class="row">
			<div class="col-md-6">
				<OfFormInput :label="$t('Name')" name="name" required />
			</div>
			<div class="col-md-2 d-flex justify-content-center align-items-center">
				<Thumbnail v-b-modal.preview-edit-modal :src="formData.previewUrl" size="60" class="cursor-pointer" />

				<b-modal id="preview-edit-modal" ok-only hide-header>
					<div class="d-flex justify-content-center mb-4">
						<Thumbnail :src="formData.previewUrl" size="250" class="cursor-pointer" />
					</div>
					<OfFormInput name="previewUrl" :label="$t('Template Preview URL')" />
				</b-modal>
			</div>
			<div class="col-md-2"><OfToggle name="active" :right-side-label="$t('Active')" /></div>
		</div>
		<div class="row">
			<div class="col-md-2">
				<OfFormInput type="number" :label="`${$t('Media Width')} (mm)`" :name="`sizes.media.width`" />
			</div>
			<div class="col-md-3 d-flex">
				<OfFormInput
					type="number"
					:label="`${$t('BleedBox')} ${$t('Top')} (mm)`"
					:name="`sizes.bleed.top`"
					class="mr-2"
				/>
				<OfFormInput type="number" :label="`${$t('BleedBox')} ${$t('Right')} (mm)`" :name="`sizes.bleed.right`" />
			</div>
			<div class="col-md-3 d-flex">
				<OfFormInput
					type="number"
					:label="`${$t('TrimBox')} ${$t('Top')} (mm)`"
					:name="`sizes.trim.top`"
					class="mr-2"
				/>
				<OfFormInput type="number" :label="`${$t('TrimBox')} ${$t('Right')} (mm)`" :name="`sizes.trim.right`" />
			</div>
			<div class="col-md-2">
				<MediaLibrary :template-id="formData._id" class="mt-3" />
			</div>
		</div>
		<div class="row">
			<div class="col-md-2">
				<OfFormInput type="number" :label="`${$t('Media Height')} (mm)`" :name="`sizes.media.height`" />
			</div>
			<div class="col-md-3 d-flex">
				<OfFormInput
					type="number"
					:label="`${$t('BleedBox')} ${$t('Bottom')} (mm)`"
					:name="`sizes.bleed.bottom`"
					class="mr-2"
				/>
				<OfFormInput type="number" :label="`${$t('BleedBox')} ${$t('Left')} (mm)`" :name="`sizes.bleed.left`" />
			</div>
			<div class="col-md-3 d-flex">
				<OfFormInput
					type="number"
					:label="`${$t('TrimBox')} ${$t('Bottom')} (mm)`"
					:name="`sizes.trim.bottom`"
					class="mr-2"
				/>
				<OfFormInput type="number" :label="`${$t('TrimBox')} ${$t('Left')} (mm)`" :name="`sizes.trim.left`" />
			</div>
			<div class="col-md-2 d-flex">
				<OfFormSelect v-model="viewAs" :label="$t('View as part of Product/component')" :options="viewAsOptions" />
			</div>

			<div class="col-md-2 d-flex">
				<OfFormSelect v-model="productId" :label="$t('Use content from')" :options="productsOptions" />
			</div>
		</div>
		<div class="row">
			<b-col>
				<TemplateEditor
					:sizes="convertSizes(formData.sizes, 'px')"
					:data="templateData"
					:value="formData.source"
					:boxes="true"
					:pages="true"
					:product-id="productId"
					@input="source => updateField('source', source)"
				/>
			</b-col>
		</div>
		<div class="row">
			<div class="TemplateEdit-bottom">
				<b-button variant="success" :disabled="!canSubmit" @click="onSave">
					{{ $t('Save') }}
				</b-button>
			</div>
		</div>
	</OfsPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfsPanel, withForm, OfToggle, OfFormInput, OfFormSelect } from '@oneflow/ofs-vue-layout';
import Thumbnail from '@/components/Thumbnail';
import MediaLibrary from './MediaLibrary';
import { templateDefaults, templateData } from './constants';
import { isObjectId, convertSizes } from './helpers';
import TemplateEditor from '../../../../components/TemplateEditor';

/* default template sources */
import coverTemplateSource from 'raw-loader!./templates/cover.hbs';
import { templateFormats } from '../../../../lib/constants';

export default {
	components: {
		OfsPanel,
		OfToggle,
		OfFormInput,
		OfFormSelect,
		Thumbnail,
		MediaLibrary,
		TemplateEditor
	},
	mixins: [withForm('templateEdit')],
	data() {
		return {
			templateData,
			spineSize: 0,
			viewAsOptions: [{ text: this.$t('None'), value: 'NONE' }],
			productsOptions: [{ text: this.$t('None'), value: null }],
			componentSizes: {},
			viewAs: '',
			productId: null
		};
	},
	computed: {
		...mapGetters({
			products: 'product/products'
		})
	},
	watch: {
		'formData.sizes': {
			deep: true,
			handler(sizes) {
				if (!sizes && !this.viewAs) return;
				this.setTemplateSizes(sizes || {});
			}
		},
		'$route.params.id'() {
			this.initialize();
		},
		viewAs() {
			this.setTemplateSizes(this.formData.sizes || {});
		}
	},
	async mounted() {
		await this.getProducts();
		await this.initialize();
	},
	methods: {
		...mapActions({
			findLayoutById: 'layout/findById',
			findTemplateById: 'template/findById',
			createTemplate: 'template/create',
			updateTemplateById: 'template/update',
			renderTemplate: 'template/renderTemplate',
			getProducts: 'product/findAll'
		}),
		get: _.get,
		convertSizes,
		async initialize() {
			const type = { type: 'product' };
			// initialize form data
			if (isObjectId(this.$route.params.id)) {
				const template = await this.findTemplateById({ id: this.$route.params.id });
				template.sizes = convertSizes(template.sizes, 'mm');
				this.initFormData({ ...template });
			} else if (!this.$route.query?.id) {
				templateDefaults.sizes = convertSizes(templateDefaults.sizes, 'mm');
				this.initFormData({ ...templateDefaults, source: coverTemplateSource, ...type, format: templateFormats.HTML });
			} else {
				const { id } = this.$route.query;
				const { template, format, type } = await this.findLayoutById({ id });
				template.sizes = convertSizes(template.sizes, 'mm');
				this.initFormData({ ...templateDefaults, ...template, type, format });
			}
			const preparedComponents = _.reduce(
				this.products,
				(result, product) => {
					product.components.forEach(component => {
						const key = `${product._id}_${component._id}`;
						result[key] = {
							sizes: component.sizes || {},
							name: `${product.name} / ${component.name}`
						};
					});
					return result;
				},
				{}
			);
			this.viewAsOptions = [
				...this.viewAsOptions,
				..._.map(preparedComponents, (item, key) => ({ text: item.name, value: key }))
			];

			this.productsOptions = [
				...this.productsOptions,
				..._.map(this.products, item => ({ text: item.name, value: item._id }))
			];
			this.componentSizes = _.transform(
				preparedComponents,
				(result, value, key) => {
					result[key] = value.sizes;
					return result;
				},
				{}
			);
		},
		setTemplateSizes(sizes) {
			const componentSizes = _.get(this.componentSizes, this.viewAs, {});
			const templateSizes = _.merge({}, sizes, componentSizes);
			const printSizes = convertSizes(templateSizes, 'px');

			this.templateData = { ...this.templateData, ...printSizes };
		},
		async onSave() {
			const dataToSave = _.cloneDeep(this.formData);
			dataToSave.sizes = convertSizes(dataToSave.sizes, 'px');
			if (this.formData._id) {
				this.updateTemplateById({ id: this.formData._id, data: dataToSave });
			} else {
				const template = await this.createTemplate(dataToSave);
				this.$router.push({
					name: 'templates.edit',
					params: { id: template._id }
				});
			}
			this.$notify({
				type: 'success',
				title: 'Success',
				text: this.$t('Current template has been updated')
			});
		}
	}
};
</script>

<style lang="scss">
@import 'src/styles/shared';

.TemplateEdit {
	margin: 0;

	&-header {
		.back-btn {
			cursor: pointer;
			display: flex;
			align-items: center;
			font-weight: bold;

			.fa-icon {
				margin-right: 7px;
			}

			&:hover {
				color: $of-color-grey-2;
			}
		}
	}

	&-bottom {
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;
	}

	.cursor-pointer {
		cursor: pointer;
	}
}
</style>
