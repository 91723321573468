<template>
	<div>
		<b-row>
			<b-col md="4">
				<OfFormInput :label="$t('Name')" name="name" required />
			</b-col>
			<b-col md="1">{{ $t('Active') }}: <OfToggle name="active" no-label class="no-label"/></b-col>
		</b-row>
		<div>
			<div class="row">
				<div class="col-md-2">
					<OfFormInput type="number" :label="`${$t('Media Width')} (mm)`" :name="`sizes.media.width`" />
				</div>
				<div class="col-md-3 d-flex">
					<OfFormInput
						type="number"
						:label="`${$t('BleedBox')} ${$t('Top')} (mm)`"
						:name="`sizes.bleed.top`"
						class="mr-2"
					/>
					<OfFormInput type="number" :label="`${$t('BleedBox')} ${$t('Right')} (mm)`" :name="`sizes.bleed.right`" />
				</div>
				<div class="col-md-3 d-flex">
					<OfFormInput
						type="number"
						:label="`${$t('TrimBox')} ${$t('Top')} (mm)`"
						:name="`sizes.trim.top`"
						class="mr-2"
					/>
					<OfFormInput type="number" :label="`${$t('TrimBox')} ${$t('Right')} (mm)`" :name="`sizes.trim.right`" />
				</div>
			</div>
			<div class="row">
				<div class="col-md-2">
					<OfFormInput type="number" :label="`${$t('Media Height')} (mm)`" :name="`sizes.media.height`" />
				</div>
				<div class="col-md-3 d-flex">
					<OfFormInput
						type="number"
						:label="`${$t('BleedBox')} ${$t('Bottom')} (mm)`"
						:name="`sizes.bleed.bottom`"
						class="mr-2"
					/>
					<OfFormInput type="number" :label="`${$t('BleedBox')} ${$t('Left')} (mm)`" :name="`sizes.bleed.left`" />
				</div>
				<div class="col-md-3 d-flex">
					<OfFormInput
						type="number"
						:label="`${$t('TrimBox')} ${$t('Bottom')} (mm)`"
						:name="`sizes.trim.bottom`"
						class="mr-2"
					/>
					<OfFormInput type="number" :label="`${$t('TrimBox')} ${$t('Left')} (mm)`" :name="`sizes.trim.left`" />
				</div>
			</div>
			<b-row>
				<b-col>
					<TemplateEditor
						:sizes="templateData"
						:data="templateData"
						:value="formData.source"
						:boxes="true"
						:pages="true"
						@input="source => updateField('source', source)"
					/>
				</b-col>
			</b-row>
		</div>
		<div class="TemplateEdit-bottom">
			<b-button variant="success" :disabled="!canSubmit" @click="onSave">
				{{ $t('Save') }}
			</b-button>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfFormInput, OfToggle, withForm } from '@oneflow/ofs-vue-layout';
import { ordersQuery } from '@/lib/constants';
import { convertSizes } from '../../../Create/Templates/HTMLTemplateEditor/helpers';
import { templateData } from '../../../Create/Templates/HTMLTemplateEditor/constants';
import TemplateEditor from '../../../../components/TemplateEditor';

export default {
	components: {
		OfToggle,
		OfFormInput,
		TemplateEditor
	},
	mixins: [withForm('templateEdit')],
	data() {
		return {
			templateData,
			previewSizes: {
				media: {
					width: 800,
					height: 800
				}
			}
		};
	},
	computed: {
		...mapGetters({
			layout: 'layout/layout'
		})
	},
	watch: {
		'formData.sizes': {
			deep: true,
			handler(sizes) {
				if (!sizes) return;
				this.setTemplateSizes(sizes || {});
			}
		}
	},
	async mounted() {
		await this.initialize();
	},
	methods: {
		...mapActions({
			findLayoutById: 'layout/findById',
			updateLayoutById: 'layout/update',
			findOrders: 'order/find'
		}),
		get: _.get,
		convertSizes,
		async initialize() {
			const { id } = this.$route.params;
			let { template, format, type, name, active } = {};

			if (this.layout && this.layout.id === id) {
				({ template, format, type, name, active } = this.layout);
			} else {
				({ template, format, type, name, active } = await this.findLayoutById({ id }));
			}

			if (template?.sizes) {
				template.sizes = convertSizes(template.sizes, 'mm');
			}
			this.initFormData({ ...template, type, format, name, active });
		},
		goBack() {
			this.$router.push({ name: 'layouts.list' });
		},
		setTemplateSizes(sizes) {
			this.templateData = { ...this.templateData, ...convertSizes(sizes, 'px') };
		},
		async fetchTemplateOrderData() {
			const {
				data: [order]
			} = await this.findOrders({ query: ordersQuery });
			if (!order) return;
		},
		async onSave() {
			const data = _.cloneDeep(this.formData);
			/* eslint-disable */
			const dataToSave = data?.sizes
				? {
						..._.pick(data, ['name', 'active']),
						template: {
							source: data.source,
							sizes: convertSizes(data.sizes, 'px')
						}
				  }
				: {
						..._.pick(data, ['name', 'active']),
						template: {
							source: data.source
						}
				  };
			/* eslint-enable */
			try {
				await this.updateLayoutById({ id: this.$route.params.id, data: dataToSave });
				this.$notify({
					type: 'success',
					title: 'Success',
					text: this.$t('Current layout has been updated')
				});
			} catch (e) {
				this.$notify({
					type: 'error',
					title: 'error',
					text: this.$t('Current layout has not been updated')
				});
			}
		}
	}
};
</script>
