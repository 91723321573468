import _ from 'lodash';
import _fp from 'lodash/fp';

export function productDeliveryTypes() {
	return _fp.flow(
		_fp.map(format => (format.fulfilment === 'Digital File' ? 'digital' : 'physical')),
		_fp.uniq
	)(this.product.formats);
}

export function activeReleasesByDeliveryType() {
	return _fp.flow(_fp.groupBy('delivery'), _fp.mapValues(_fp.first))(this.product.activeReleases);
}

export function productComponentsHash() {
	return this.delivery === 'digital'
		? {
				ITM: {
					componentCode: 'ITM'
				}
		  }
		: _.keyBy(this.product.components, 'componentCode');
}
