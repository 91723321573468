<template>
	<b-modal
		:title="$t('Add account')"
		size="md"
		:ok-title="$t('Add')"
		:ok-disabled="!canSubmit"
		:visible="isVisible"
		@ok="onSubmit"
		@hidden="$emit('close')"
	>
		<b-row>
			<b-col>
				<AccountSelect :label="$t('Accounts')" name="accountIds" multiple />
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { withForm } from '@oneflow/ofs-vue-layout';
import Promise from 'bluebird';
import AccountSelect from '@/components/AccountSelect';

export default {
	components: {
		AccountSelect
	},
	mixins: [withForm('addUserToAccountForm')],
	props: {
		isVisible: {
			type: Boolean,
			required: true
		},
		user: {
			type: Object,
			required: true
		}
	},
	computed: {
		canSubmit() {
			return this.formData?.accountIds?.length > 0;
		}
	},
	methods: {
		...mapActions({
			createAccountMember: 'account-management/createAccountMember'
		}),
		async onSubmit(event) {
			event.preventDefault();

			try {
				await this.dispatchSubmit(
					Promise.map(this.formData.accountIds ?? [], accountId => {
						return this.createAccountMember({
							accountId: accountId,
							email: this.user.email,
							name: this.user.name
						}).catch(err => {
							// ignore the error if the user is already part of the account
							if (err.response?.status !== 409) throw err;
						});
					})
				);

				this.$notify({
					type: 'success',
					title: this.$t('Success'),
					text: this.$t('Account added successfully')
				});

				this.resetFormData();
				this.$emit('save');
			} catch (error) {
				this.$notify({
					type: 'error',
					title: this.$t('An error happened while trying to add the user to the accounts'),
					text: error.response?.data?.message ?? error.message
				});

				throw error;
			}
		}
	}
};
</script>
