/**
 * Vue.js mixin that enables a 2-way synchronization of data variables with the route querystring parameters
 * @param {Array<string | { name: string, defaultValue: unknown, parseValue: (value: unknown) => unknown }>} variableNames
 */
export default variableNames => ({
	data() {
		return variableNames.reduce((acc, varName) => {
			const isStringNotation = typeof varName === 'string';
			const name = isStringNotation ? varName : varName.name;
			const parseValueFn = isStringNotation ? val => val : varName.parseValue;

			const value = isStringNotation
				? this.$route.query[name] ?? undefined
				: this.$route.query[name] ?? varName.defaultValue ?? undefined;

			const isNotUndefinedOrNull = typeof value !== 'undefined' && value !== null;
			const parsedValue = isNotUndefinedOrNull ? parseValueFn(value) : value;

			return {
				...acc,
				[name]: parsedValue
			};
		}, {});
	},
	watch: variableNames.reduce((acc, varName) => {
		const name = typeof varName === 'string' ? varName : varName.name;
		const watcherConfig = {
			immediate: true,
			handler(value) {
				this.$router
					.replace({
						query: {
							...this.$route.query,
							[name]: value
						}
					})
					.catch(error => {
						if (error.name !== 'NavigationDuplicated') {
							throw error;
						}
					});
			}
		};

		return {
			...acc,
			[name]: watcherConfig
		};
	}, {})
});
