import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'file',
		pluralName: 'files',
		transport: 'davinci',
		actions: {
			async getByStorageUrl({ dispatch, commit }, storageUrl) {
				const file = await dispatch('request', {
					method: 'GET',
					path: `files/by-storage-url/${encodeURIComponent(storageUrl)}`
				}).then(r => r.data);

				commit('GET_ITEM_SUCCESS', { data: file });

				return file;
			}
		}
	});
}
